import React, { useState } from "react";

const Pagination = ({
  totalItems,
  itemsPerPage,
  onPageChange,
  currentPage,
  setCurrentPage = () => {},
}) => {
  // const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    onPageChange(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPageButtons = 5; // Number of page buttons to display

    let startPage = Math.max(1, currentPage - Math.floor(maxPageButtons / 2));
    let endPage = Math.min(totalPages, startPage + maxPageButtons - 1);

    if (endPage - startPage + 1 < maxPageButtons) {
      startPage = Math.max(1, endPage - maxPageButtons + 1);
    }

    if (startPage > 1) {
      pageNumbers.push(
        <button
          key="first"
          onClick={() => handlePageChange(1)}
          className="w-10 aspect-square flex justify-center items-center mx-1 bg-white text-slate-800 border border-slate-800 rounded-full"
        >
          1
        </button>
      );
      if (startPage > 2) {
        pageNumbers.push(
          <span
            key="ellipsis-start"
            className="w-10 aspect-square flex justify-center items-center mx-1 bg-white text-slate-800 border border-slate-800 rounded-full"
          >
            ...
          </span>
        );
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`w-10 aspect-square flex justify-center items-center mx-1 rounded-full ${
            currentPage === i
              ? "bg-slate-800 text-white"
              : "bg-white text-slate-800 border border-slate-800"
          }`}
        >
          {i}
        </button>
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pageNumbers.push(
          <span
            key="ellipsis-end"
            className="w-10 aspect-square flex justify-center items-center mx-1 bg-white text-slate-800 border border-slate-800 rounded-full"
          >
            ...
          </span>
        );
      }
      pageNumbers.push(
        <button
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
          className="w-10 aspect-square flex justify-center items-center mx-1 bg-white text-slate-800 border border-slate-800 rounded-full"
        >
          {totalPages}
        </button>
      );
    }

    return pageNumbers;
  };

  return (
    <div className="flex justify-center mt-10">
      <button
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className="px-3 py-1 mx-1 disabled:opacity-50 rounded-full"
      >
        <i class="fa-solid fa-angles-left"></i>
      </button>
      {renderPageNumbers()}
      <button
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className="px-3 py-1 mx-1 disabled:opacity-50 rounded-full"
      >
        <i class="fa-solid fa-chevrons-right"></i>
      </button>
    </div>
  );
};

export default Pagination;
