import { apiRequest } from "../util/apiUtils";

export const settingsDetails = async (params) => {
  try {
    const res = await apiRequest("get", `admin/site-settings/details`, {});
    return res?.data;
  } catch (err) {
    return err;
  }
};

export const editSettings = async (params) => {
  try {
    const res = await apiRequest(
      "post",
      "admin/site-settings/edit",
      {
        body: { ...params },
      },
      "multipart/form-data"
    );
    return res;
  } catch (error) {
    return error;
  }
};
