import React, { useCallback, useEffect, useState } from "react";
import OrderDetailsItems from "../Items/OrderDetailsItems";
import Pagination from "../form/Pagination";
import { useNavigate } from "react-router-dom";
import Button from "../form/Button";

const MyCurrentOrdersDetails = ({ data , title }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const itemsPerPage = 2;
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentItems = data?.slice(startIndex, endIndex);

  return (
    <>
      {currentItems?.length > 0 ? (
        <div className="">
          {currentItems?.map((item, index) => (
            <div
              className="relative cursor-pointer"
              onClick={() => navigate(`/order-details/${item?.order_id}`)}
              key={index}
            >
              <OrderDetailsItems key={index} data={item} />
            </div>
          ))}
        </div>
      ) : (
        <div className="flex flex-col items-center space-y-8 mt-16">
          <div className="w-40 h-40 rounded-full bg-[#F0F9F4] overflow-hidden flex items-center justify-center ">
            <div className="text-[#e73737] text-8xl">
              <i className="fa-regular fa-bag-shopping"></i>
            </div>
          </div>
          <div className="text-cape-cod text-3xl">You have no {title} orders yet.</div>
          <div className="text-concord text-base max-w-md text-center">
            You don’t have any products in the {title} list yet. 
          </div>
         
        </div>
      )}
      {data?.length > 0 && (
        <Pagination
          totalItems={data?.length}
          itemsPerPage={itemsPerPage}
          onPageChange={handlePageChange}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </>
  );
};

export default MyCurrentOrdersDetails;
