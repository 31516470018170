import React, { useEffect, useRef, useState } from "react";
import { classNames } from "../../helpers/classNames";
import Input from "./Input";


const HideableInput = ({
  value = "",
  rename = false,
  setRename = () => {},
  onSave = () => {},
  onChange = () => {},
  type = "transparent",
  inputClasses,
  inputBodyclass,
  hideInputClasses,
}) => {
  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState("");
  const [showEditor, setShowEditor] = useState(false);

  useEffect(() => {
    if (rename) {
      setShowEditor(true);
    }
  }, [rename]);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  useEffect(() => {
    if (showEditor) {
      if (inputRef.current) {
        inputRef.current.querySelector(".hideableInput").focus();
      }
    }
  }, [showEditor]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setRename(false);
        if (value) {
          setShowEditor(false);
        }
        if (typeof inputValue === "string" && inputValue.trim()) { 
          if (value !== inputValue) {
            onSave({
              value: inputValue,
            });
          }
        }
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [value, inputValue]);
  

  const handleEditorClick = () => {
    if (!showEditor) {
      setShowEditor(true);
    }
  };

  const onEnterHendler = (e) => {
    const targetValue = e.target.value;
    if (targetValue.trim()) {
      if (value !== targetValue) {
        onSave({
          value: targetValue,
        });
      }
    }
    setShowEditor(false);
    setRename(false);
  };

  return (
    <div ref={inputRef} className={"w-full"}>
      <div
        onClick={() => handleEditorClick()}
        className={classNames(inputBodyclass)}
      >
        {showEditor ? (
          <Input
            value={inputValue}
            onKeyDown={(e) => {
              if (e.key == "Enter") {
                onEnterHendler(e);
              } else {
                return;
              }
            }}
            onChange={(e) => {
              setInputValue(e.target.value);
              onChange(e.target.value);
            }}
            inputClasses={`hideableInput ${inputClasses}`}
          />
        ) : (
          <div className="w-full h-10 flex flex-col justify-center">
            <div
              className={classNames(
                "w-full tracking-normal bg-transparent border border-transparent px-3 text-sm font-medium text-denim-800",
                hideInputClasses
              )}
            >
              {inputValue}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HideableInput;
