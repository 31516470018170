import React from "react";
import state from "../../../assets/images/state.jpg";
import Image from "../../../components/elements/Image";
import Button from "../../../components/form/Button";
import { toast } from "react-toastify";
import { changeVatStatus } from "../../../services/vatService";

export const VatDetailsModal = ({
  onClose = () => {},
  details,
  setDetails = () => {},
  loadList = () => {},
}) => {
  const handleStatusChange = async (data) => {
    changeVatStatus({
      id: data.id,
      status: data.status === 1 ? 2 : 1,
    }).then((res) => {
      if (res?.status === 200) {
        setDetails((pre) => ({
          ...pre,
          status: pre.status === 1 ? 2 : 1,
        }));
        loadList();
        toast.success("Status Change Successful");
      } else {
        toast.error("Somthing Went Wrong");
      }
    });
  };

  return (
    <div>
      <div className="flex justify-end">
        <Button
          buttonClasses="!bg-white !h-4 !text-2xl !text-black hover:!text-red-600 !text-center  gap-3 transition duration-200 rounded-md mt-0 sm:mt-0"
          buttonIcon="fa-regular fa-xmark"
          buttonIconPosition="right"
          buttonEffect="filled"
          buttonFunction={onClose}
        />
      </div>
      <div className="max-w-[37rem] mx-auto ">
        <div className=" w-full flex items-center justify-center dark:bg-red-600">
          <div className="relative w-full max-w-8xl my-4 md:my-4 flex flex-col items-start space-y-4 sm:flex-row sm:space-y-0 sm:space-x-6 px-8 py-8 border-2 border-dashed border-gray-400 dark:border-gray-400 shadow-lg rounded-lg justify-center">
            <div class="!w-1/3 flex justify-center sm:justify-start sm:w-36">
              <Image
                src={state}
                alt=""
                className="w-full h-full object-cover"
                effect="blur"
              />
            </div>

            <div className="!w-full sm:w-auto flex flex-col items-center sm:items-start">
              <p
                className="font-display mb-2 text-4xl font-bold dark:text-gray-200 text-red-600"
                itemprop="author"
              >
                {details?.stateInfo?.name || ""}
              </p>

              <div className="mb-4 md:text-lg text-gray-600">
                <p className="flex items-center">
                  <span className="text-xl md:text-xl text-red-600 pr-4">
                    <i className="fa-solid fa-city"></i>
                  </span>
                  <span>
                    {details?.stateInfo?.name || ""}-{details?.stateInfo?.id}
                  </span>
                </p>
                <p className="flex items-center">
                  <span className="text-xl md:text-xl text-red-600 pr-4">
                    <i className="fa-solid fa-dollar-sign"></i>
                  </span>
                  <span>{details?.price || ""}</span>
                </p>
              </div>
              {/* {details?.status === 1 ? (
                <div
                  className="w-24 rounded-md mt-3 text-base font-bold bg-blue-100 text-blue-600 flex justify-center items-center cursor-pointer"
                  onClick={() => handleStatusChange(details)}>
                  Active
                </div>
              ) : (
                <div
                  className="w-24 rounded-md mt-3 text-base font-bold bg-red-100 text-red-600 flex justify-center items-center cursor-pointer"
                  onClick={() => handleStatusChange(details)}>
                  In-Active
                </div>
              )} */}
              <div className="flex gap-2 !justify-end !w-full">
                {details?.status === 1 ? (
                  <Button
                    buttonHasLink={false}
                    buttonLink={""}
                    buttonType={"submit"}
                    buttonIcon={"fa-light fa fa-refresh"}
                    buttonIconPosition={"left"}
                    buttonClasses={
                      "!bg-black !text-org-silver hover:!bg-blue-700"
                    }
                    buttonLabel={"Active"}
                    buttonLabelClasses={"md:block hidden"}
                    buttonFunction={() => handleStatusChange(details)}
                  />
                ) : (
                  <Button
                    buttonHasLink={false}
                    buttonLink={""}
                    buttonType={"submit"}
                    buttonIcon={"fa-light fa fa-refresh"}
                    buttonIconPosition={"left"}
                    buttonClasses={
                      "!bg-black !text-org-silver hover:!bg-red-600"
                    }
                    buttonLabel={"In-Active"}
                    buttonLabelClasses={"md:block hidden"}
                    buttonFunction={() => handleStatusChange(details)}
                  />
                )}

                <Button
                  buttonHasLink={true}
                  buttonLink={`/admin/vat-edit/${details?.id}`}
                  buttonType={"submit"}
                  buttonIcon={"fa-light fa-pen-to-square"}
                  buttonIconPosition={"left"}
                  buttonClasses={"!bg-red-600 !text-org-silver"}
                  buttonLabel={"Edit"}
                  buttonLabelClasses={"md:block hidden"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
