import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { classNames } from "../../../helpers/classNames";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { setIsLogin } from "../../../redux/slice/authSlice";
import { useDispatch } from "react-redux";
import { setProfileDetails } from "../../../redux/slice/profileSlice,";

const LogoutModal = ({
  title,
  secondaryTitle = "",
  isStatic = false,
  isOpen = false,
  showClose = false,
  onCancel = () => {},
  size = "md",
  footerClasses = "",
  footer = null,
  children,
  modalBodyOverflow = true,
  containerClass = "",
  setOpen,
}) => {
  const modalSize = {
    xs: "sm:max-w-xs",
    sm: "sm:max-w-sm",
    md: "sm:max-w-md",
    lg: "sm:max-w-lg",
    xl: "sm:max-w-xl",
    xl2: "sm:max-w-2xl",
    xl3: "sm:max-w-3xl",
    xl4: "sm:max-w-4xl",
    xl5: "sm:max-w-5xl",
    xl6: "sm:max-w-6xl",
    xl7: "sm:max-w-7xl",
    xl8: "sm:max-w-8xl",
    xl9: "sm:max-w-9xl",
    full: "max-w-full",
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-30 w-full"
          onClose={
            !isStatic
              ? onCancel
              : () => {
                  setOpen(false);
                }
          }
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto flex items-center justify-center">
            <div className="flex items-center justify-center text-center m-auto w-full md:p-0 p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  className={classNames(
                    `relative w-full bg-white rounded-3xl h-auto text-left shadow-2xl transform transition-all sm:my-8 p-0`,
                    modalSize[size],
                    containerClass
                  )}
                >
                  <div className="relative flex justify-between w-full flex-grow flex-shrink">
                    {title && secondaryTitle && (
                      <div className="py-4 px-6 space-y-2 w-full">
                        {title && (
                          <Dialog.Title
                            as="h2"
                            className="text-xl xl:text-2xl font-bold text-cyprus-500"
                          >
                            {title}
                          </Dialog.Title>
                        )}
                        {secondaryTitle && (
                          <div className="text-slate-600 text-sm">
                            {secondaryTitle}
                          </div>
                        )}
                      </div>
                    )}
                    {showClose && (
                      <button
                        type="button"
                        className="w-10 h-10 min-w-[40px] bg-slate-100 rounded-lg ml-auto mt-2 mr-2 text-xl text-slate-500 group hover:bg-slate-200"
                        onClick={() => setOpen(false)}
                      >
                        <i
                          className={classNames(
                            "fa-light fa-fw fa-xmark transition-all duration-200 group-hover:rotate-90"
                          )}
                        ></i>
                      </button>
                    )}
                  </div>

                  <div
                    className={classNames(
                      "md:py-6 py-4 md:px-6 px-4",
                      modalBodyOverflow
                        ? "max-h-[calc(100vh-10rem)] overflow-auto scroll-smooth scrollbar"
                        : ""
                    )}
                  >
                    <div className="flex flex-col justify-center items-center gap-2">
                      <div className="flex justify-start w-full items-center">
                        <i className="fa-regular fa-exclamation-triangle text-red-600 rounded-full mr-2 text-3xl"></i>
                        <div className="text-xl font-bold pl-1">Logout!</div>
                      </div>
                      <hr className="w-full border-1 border-slate-400"></hr>
                      <div className=" pt-6 md:text-xl text-base text-gray-700 font-bold">
                        Are You Sure You Want To Logout ?
                      </div>
                      <div className="flex items-center gap-4 mt-5">
                        <button
                          className="bg-red-600 text-base rounded-lg py-2 px-6 text-white"
                          onClick={() => {
                            setOpen(false);
                          }}
                        >
                          <i class="fa-solid fa-xmark pr-2"></i>
                          Cancel
                        </button>
                        <button
                          className="border border-solid border-red-600 text-base rounded-lg py-2 px-6 text-red-600 "
                          onClick={() => {
                            localStorage.removeItem("auth_token");
                            localStorage.removeItem("refreshToken");
                            localStorage.removeItem("role");
                            setOpen(false);
                            toast.success("Logout Successfull!");
                            dispatch(setIsLogin(false));
                            dispatch(setProfileDetails({}));
                            setTimeout(function () {
                              navigate("/");
                            }, 1000);
                          }}
                        >
                          <i class="fa-solid fa-arrow-right-from-bracket pr-2"></i>
                          Logout
                        </button>
                      </div>
                    </div>
                  </div>
                  {footer && (
                    <div
                      className={classNames(
                        "py-4 px-6 border-t border-slate-200",
                        footerClasses
                      )}
                    >
                      {footer}
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {/* <ToastContainer /> */}
    </>
  );
};

export default LogoutModal;
