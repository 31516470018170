import React from "react";

const PageDetailsText = ({ data, scrollHandler = () => {} }) => {
  return (
    <>
      <div>
        <div className="text-red-600 py-2 font-bold text-2xl md:pt-0 pt-5 md:text-4xl xl:pt-0">
          {data?.name}
        </div>
        {data?.description ? (
          <>
            <div
              className="text-lg md:pt-5 pt-1 text-black leading-7 cursor-pointer"
              dangerouslySetInnerHTML={{
                __html: data?.description?.substring(0, 45) + "...",
              }}
            ></div>
            <div
              onClick={() => scrollHandler("description")}
              className="text-blue-500 underline cursor-pointer"
            >
              Read more
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default PageDetailsText;
