import React from 'react'
import MainBanner from "../components/sections/MainBanner";
import ContactInfo from "../components/sections/ContactInfo";
import SmallBanner from '../components/sections/SmallBanner';

const ContactUs = () => {
  return (
    <>
     {/* <MainBanner/> */}
    <SmallBanner/>
      <ContactInfo/>
    </>
  )
}

export default ContactUs;