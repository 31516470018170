import React, { useCallback, useEffect, useState } from "react";
import SidebarMenuBlocks from "../components/navigation/SidebarMenuBlocks";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Logo from "../assets/images/logo(white).png";
import { settingsDetails } from "../services/sitesettingService";

const DefaultSidebar = ({ isMenuOpen, settings }) => {
  //   const { user } = useSelector((state) => state.user);
  const [sideBar, setSidebar] = useState([]);
  const [detailsData, setDetailsData] = useState({});

  const adminSidebarMenu = [
    {
      _id: "1",
      menuTitle: "Statistics & Analytics",
      menuData: [
        {
          _id: 1,
          menuLabel: "Dashboard",
          menuLink: "/admin/dashboard",
          menuIcon: "fa-grid-2",
          menuIconColor: "text-red-500",
        },
      ],
    },
    {
      _id: "2",
      menuTitle: "User Management",
      menuData: [
        {
          _id: 1,
          menuLabel: "Admins",
          menuLink: "/admin/admin-list",
          menuIcon: "fa-solid fa-user-secret",
          menuIconColor: "text-red-500",
        },
        {
          _id: 2,
          menuLabel: "Users",
          menuLink: "/admin/user-list",
          menuIcon: "fa-solid fa-users",
          menuIconColor: "text-red-500",
        },
      ],
    },
    {
      _id: "3",
      menuTitle: "Product Managements",
      menuData: [
        {
          _id: 1,
          menuLabel: "Products",
          menuLink: "/admin/product-list",
          menuIcon: "fa-brands fa-product-hunt",
          menuIconColor: "text-red-500",
        },
        {
          _id: 2,
          menuLabel: "Reviews",
          menuLink: "/admin/review-list",
          menuIcon: "fa-solid fa-star-sharp-half-stroke",
          menuIconColor: "text-red-500",
        },
        {
          _id: 3,
          menuLabel: "Orders",
          menuLink: "/admin/order-list",
          menuIcon: "fa-brands fa-first-order",
          menuIconColor: "text-red-500",
        },
        {
          _id: 4,
          menuLabel: "Vats",
          menuLink: "/admin/vat-list",
          menuIcon: "fa-brands fa-v",
          menuIconColor: "text-red-500",
        },
      ],
    },
    {
      _id: "4",
      menuTitle: "Category Managements",
      menuData: [
        {
          _id: 1,
          menuLabel: "Categories",
          menuLink: "/admin/category-list",
          menuIcon: "fa-solid fa-list",
          menuIconColor: "text-red-500",
        },
      ],
    },
    {
      _id: "5",
      menuTitle: "Page Managements",
      menuData: [
        {
          _id: 1,
          menuLabel: "Pages",
          menuLink: "/admin/pages-list",
          menuIcon: "fa-light fa-memo",
          menuIconColor: "text-red-500",
        },
        {
          _id: 2,
          menuLabel: "Testimonials",
          menuLink: "/admin/testimonial-list",
          menuIcon: "fa-regular fa-comment",
          menuIconColor: "text-red-500",
        },
        {
          _id: 3,
          menuLabel: "Enquiry",
          menuLink: "/admin/enquiry-list",
          menuIcon: "fa-regular fa-clipboard-question",
          menuIconColor: "text-red-500",
        },
      ],
    },
    {
      _id: "6",
      menuTitle: "Faq Managements",
      menuData: [
        {
          _id: 1,
          menuLabel: "Faqs",
          menuLink: "/admin/faq-list",
          menuIcon: "fas fa-question",
          menuIconColor: "text-red-500",
        },
      ],
    },
    {
      _id: "7",
      menuTitle: "Content Management",
      menuData: [
        {
          _id: 1,
          menuLabel: "Email Template",
          menuLink: "/admin/emailtemplate-list",
          menuIcon: "fa-solid fa-envelope",
          menuIconColor: "text-rose-500",
        },
        {
          _id: 2,
          menuLabel: "Site Settings",
          menuLink: "/admin/settings",
          menuIcon: "fa-gear",
          menuIconColor: "text-red-500",
        },
      ],
    },
  ];

  //   useEffect(() => {
  //     if (getRole(user?.role, roleCode?.admin)) {
  //       setSidebar([...adminSidebarMenu]);
  //     } else if (getRole(user?.role, roleCode?.company_administrator)) {
  //       setSidebar([...administratorSidebarMenu]);
  //     } else if (getRole(user?.role, roleCode?.company_manager)) {
  //       setSidebar([...managerSidebarMenu]);
  //     }
  //   }, [user]);
  //   !siteSetting ? "animate-pulse bg-gray-300 " :

  const getDetails = useCallback(() => {
    setDetailsData({ loading: true, data: {} });
    settingsDetails().then((res) => {
      if (res?.status === 200) {
        setDetailsData({ loading: true, data: res?.docs });
      }
    });
  }, []);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  return (
    <>
      <div className="relative w-full bg-victoria divide-y divide-slate-700 pt-16 lg:pt-0">
        <div
          className={
            `relative flex items-center gap-4 h-16 transition-all duration-100 ${null} ` +
            (isMenuOpen ? "px-3" : "px-4")
          }>
          <Link className="w-10 h-10 min-w-[40px] " to={"/"} replace>
            <img
              className="w-full h-full object-contain "
              src={settings?.data?.logoUrl || detailsData?.data?.logoUrl}
              alt={"Logo"}
            />
          </Link>

          {!isMenuOpen && (
            <Link className="w-full" to={"/"} replace>
              <div className="text-lg text-white font-bold leading-tight">
                {settings?.data?.title || detailsData?.data?.title}
              </div>
            </Link>
          )}
        </div>
        <div
          className={
            "relative overflow-auto scroll-smooth scrollbar scrollbar-1 h-[calc(100%-4rem)] transition-all duration-100 divide-y " +
            (isMenuOpen ? " divide-slate-800" : "divide-transparent")
          }>
          {Array.isArray(sideBar) &&
            adminSidebarMenu.map((item, index) => (
              <SidebarMenuBlocks
                key={item._id}
                menuTitle={item.menuTitle}
                menuData={item.menuData}
                isMenuOpen={isMenuOpen}
              />
            ))}
        </div>
      </div>
    </>
  );
};

export default DefaultSidebar;
