import React from "react";
import Button from "../components/form/Button";
import Image from "../components/elements/Image";
import img from "../assets/images/404/Untitled-1-01.png";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="relative w-full h-screen bg-red-50">
        <div className="h-full w-full flex justify-center items-center">
          <div className="relative text-center space-y-4">
            <div className="relative text-[21rem] font-bold text-gray-300">
              404
            </div>
            <div className="absolute top-36 right-7">
              <Image src={img} alt="" effect={"blur"} />
            </div>
            <div className="text-xl">
              <span className="text-4xl text-red-600 font-bold">Sorry!</span> We
              could not find this place
            </div>
            <div className="flex justify-center !mt-10">
              <Button
                buttonClasses={"bg-red-600 hover:!bg-black !px-5"}
                buttonLabel="Back To Home Page"
                buttonLabelClasses="  !text-base  "
                buttonIcon={"fa-solid fa-backward"}
                buttonIconPosition={"left"}
                buttonEffect={"filled"}
                buttonHasLink={false}
                buttonFunction={() => navigate("/")}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageNotFound;
