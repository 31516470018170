import React from "react";
import { classNames } from "../../helpers/classNames";

const PrivacyPolicyLoader = () => {
  return (
    <div>
      <div className="flex-grow">
        <div className="space-y-4">
          <div className="rounded overflow-hidden h-2 w-full bg-[#d4d4d4]">
            <div
              className={classNames(
                "w-full h-full relative before bg-gradient-to-r from-transparent via-gray-100/90 to-transparent -translate-x-full animate-[shimmer_2s_infinite]",
                "via-[#a3a3a3]/60"
              )}
            ></div>
          </div>
          <div className="rounded overflow-hidden h-2 w-full bg-[#d4d4d4]">
            <div
              className={classNames(
                "w-full h-full relative before bg-gradient-to-r from-transparent via-gray-100/90 to-transparent -translate-x-full animate-[shimmer_2s_infinite]",
                "via-[#a3a3a3]/60"
              )}
            ></div>
          </div>
          <div className="rounded overflow-hidden h-2 w-1/2 bg-[#d4d4d4]">
            <div
              className={classNames(
                "w-full h-full relative before bg-gradient-to-r from-transparent via-gray-100/90 to-transparent -translate-x-full animate-[shimmer_2s_infinite]",
                "via-[#a3a3a3]/60"
              )}
            ></div>
          </div>
          <div className="rounded overflow-hidden h-2 w-40 bg-[#d4d4d4]">
            <div
              className={classNames(
                "w-full h-full relative before bg-gradient-to-r from-transparent via-gray-100/90 to-transparent -translate-x-full animate-[shimmer_2s_infinite]",
                "via-[#a3a3a3]/60"
              )}
            ></div>
          </div>
        </div>
        <div className="space-y-4">
          <div className="rounded overflow-hidden h-2 w-full bg-[#d4d4d4]">
            <div
              className={classNames(
                "w-full h-full relative before bg-gradient-to-r from-transparent via-gray-100/90 to-transparent -translate-x-full animate-[shimmer_2s_infinite]",
                "via-[#a3a3a3]/60"
              )}
            ></div>
          </div>
          <div className="rounded overflow-hidden h-2 w-full bg-[#d4d4d4]">
            <div
              className={classNames(
                "w-full h-full relative before bg-gradient-to-r from-transparent via-gray-100/90 to-transparent -translate-x-full animate-[shimmer_2s_infinite]",
                "via-[#a3a3a3]/60"
              )}
            ></div>
          </div>
          <div className="rounded overflow-hidden h-2 w-1/2 bg-[#d4d4d4]">
            <div
              className={classNames(
                "w-full h-full relative before bg-gradient-to-r from-transparent via-gray-100/90 to-transparent -translate-x-full animate-[shimmer_2s_infinite]",
                "via-[#a3a3a3]/60"
              )}
            ></div>
          </div>
          <div className="rounded overflow-hidden h-2 w-40 bg-[#d4d4d4]">
            <div
              className={classNames(
                "w-full h-full relative before bg-gradient-to-r from-transparent via-gray-100/90 to-transparent -translate-x-full animate-[shimmer_2s_infinite]",
                "via-[#a3a3a3]/60"
              )}
            ></div>
          </div>
        </div>
        <div className="space-y-4">
          <div className="rounded overflow-hidden h-2 w-full bg-[#d4d4d4]">
            <div
              className={classNames(
                "w-full h-full relative before bg-gradient-to-r from-transparent via-gray-100/90 to-transparent -translate-x-full animate-[shimmer_2s_infinite]",
                "via-[#a3a3a3]/60"
              )}
            ></div>
          </div>
          <div className="rounded overflow-hidden h-2 w-full bg-[#d4d4d4]">
            <div
              className={classNames(
                "w-full h-full relative before bg-gradient-to-r from-transparent via-gray-100/90 to-transparent -translate-x-full animate-[shimmer_2s_infinite]",
                "via-[#a3a3a3]/60"
              )}
            ></div>
          </div>
          <div className="rounded overflow-hidden h-2 w-1/2 bg-[#d4d4d4]">
            <div
              className={classNames(
                "w-full h-full relative before bg-gradient-to-r from-transparent via-gray-100/90 to-transparent -translate-x-full animate-[shimmer_2s_infinite]",
                "via-[#a3a3a3]/60"
              )}
            ></div>
          </div>
          <div className="rounded overflow-hidden h-2 w-40 bg-[#d4d4d4]">
            <div
              className={classNames(
                "w-full h-full relative before bg-gradient-to-r from-transparent via-gray-100/90 to-transparent -translate-x-full animate-[shimmer_2s_infinite]",
                "via-[#a3a3a3]/60"
              )}
            ></div>
          </div>
        </div>
        <div className="space-y-4">
          <div className="rounded overflow-hidden h-2 w-full bg-[#d4d4d4]">
            <div
              className={classNames(
                "w-full h-full relative before bg-gradient-to-r from-transparent via-gray-100/90 to-transparent -translate-x-full animate-[shimmer_2s_infinite]",
                "via-[#a3a3a3]/60"
              )}
            ></div>
          </div>
          <div className="rounded overflow-hidden h-2 w-full bg-[#d4d4d4]">
            <div
              className={classNames(
                "w-full h-full relative before bg-gradient-to-r from-transparent via-gray-100/90 to-transparent -translate-x-full animate-[shimmer_2s_infinite]",
                "via-[#a3a3a3]/60"
              )}
            ></div>
          </div>
          <div className="rounded overflow-hidden h-2 w-1/2 bg-[#d4d4d4]">
            <div
              className={classNames(
                "w-full h-full relative before bg-gradient-to-r from-transparent via-gray-100/90 to-transparent -translate-x-full animate-[shimmer_2s_infinite]",
                "via-[#a3a3a3]/60"
              )}
            ></div>
          </div>
          <div className="rounded overflow-hidden h-2 w-40 bg-[#d4d4d4]">
            <div
              className={classNames(
                "w-full h-full relative before bg-gradient-to-r from-transparent via-gray-100/90 to-transparent -translate-x-full animate-[shimmer_2s_infinite]",
                "via-[#a3a3a3]/60"
              )}
            ></div>
          </div>
        </div>
        <div className="space-y-4">
          <div className="rounded overflow-hidden h-2 w-full bg-[#d4d4d4]">
            <div
              className={classNames(
                "w-full h-full relative before bg-gradient-to-r from-transparent via-gray-100/90 to-transparent -translate-x-full animate-[shimmer_2s_infinite]",
                "via-[#a3a3a3]/60"
              )}
            ></div>
          </div>
          <div className="rounded overflow-hidden h-2 w-full bg-[#d4d4d4]">
            <div
              className={classNames(
                "w-full h-full relative before bg-gradient-to-r from-transparent via-gray-100/90 to-transparent -translate-x-full animate-[shimmer_2s_infinite]",
                "via-[#a3a3a3]/60"
              )}
            ></div>
          </div>
          <div className="rounded overflow-hidden h-2 w-1/2 bg-[#d4d4d4]">
            <div
              className={classNames(
                "w-full h-full relative before bg-gradient-to-r from-transparent via-gray-100/90 to-transparent -translate-x-full animate-[shimmer_2s_infinite]",
                "via-[#a3a3a3]/60"
              )}
            ></div>
          </div>
          <div className="rounded overflow-hidden h-2 w-40 bg-[#d4d4d4]">
            <div
              className={classNames(
                "w-full h-full relative before bg-gradient-to-r from-transparent via-gray-100/90 to-transparent -translate-x-full animate-[shimmer_2s_infinite]",
                "via-[#a3a3a3]/60"
              )}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyLoader;
