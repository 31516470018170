import React, { useEffect } from "react";
import Image from "../elements/Image";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

const SwiperProduct = ({ data, fancybox, fancyboxAttribute }) => {
  const fancyboxParameter = fancybox
    ? { "data-fancybox": fancyboxAttribute }
    : {};
  useEffect(() => {
    Fancybox.bind("[data-fancybox='gallery']");
  }, []);
  return (
    <>
      <div className="w-full overflow-hidden flex">
        <a
          href={fancybox ? data?.imageUrl : "javascript:void(0)"}
          {...fancyboxParameter}
        >
          <Image
            src={data?.imageUrl}
            alt="testimonialimg"
            className="w-full h-full !object-contain"
            effect="blur"
          />
        </a>
      </div>
    </>
  );
};

export default SwiperProduct;
