import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { SendOTP, VerifyOTP } from "../../../services/loginService";
import Logo from "../../../assets/images/logo.png";
import { formValidate } from "../../../helpers/formValidate";
import Input from "../../../components/form/Input";
import Button from "../../../components/form/Button";
import useForm from "../../../hooks/useForm";
import Background from "../../../assets/images/loginBackground.jpg";
import { setIsLogin } from "../../../redux/slice/authSlice";
import { useDispatch } from "react-redux";

const validation = {
  // email: { required: true, message: "Email is required" },
  // password: { required: true, message: "Password is required" },
};

const AdminVerifyOtp = ({ email, onClose = () => {} }) => {
  const dispatch = useDispatch();
  const { values, handleChange, handleSubmit, errors, setFieldsValue } = useForm({}, validation);
  const backgroundStyle = {
    // backgroundColor: "#f1f5f9",
    backgroundImage: `url(${Background})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState({
    color: "red-500",
    message: null,
  });
  const [loaded, setLoaded] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [timer, setTimer] = useState(30);
  const [isResendAllowed, setIsResendAllowed] = useState(false);

  useEffect(() => {
    if (timer <= 0) {
      setIsResendAllowed(true);
      return;
    }

    const intervalId = setInterval(() => {
      setTimer((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timer]);

  const handleOtpChange = (element, index) => {
    if (isNaN(element.value)) return false;
    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    if (element.nextSibling && element.value) {
      element.nextSibling.focus();
    }
  };

  const onSubmit = async (e) => {
    setLoaded(true);
    try {
      const res = await VerifyOTP({
        email: email,
        otp: otp.join(""),
      });
      if (res?.data?.status === 200) {
        setErrorMessage({
          color: "text-green-500",
          message: res?.data?.message,
        });
        toast.info(res.data.message);
        setTimeout(() => {
          navigate("/admin/forgotpassword");
        }, 1000);
      } else if (res?.data?.status === 400) {
        setErrorMessage({
          color: "text-red-500",
          message: res?.data?.message || res?.data?.errors,
        });
        toast.info(res?.data?.message || res?.data?.errors);
      } else {
        setErrorMessage({
          color: "text-red-500",
          message: res?.data?.message || res?.data?.errors,
        });
        toast.info(res?.data?.message || res?.data?.errors);
      }
      setLoaded(false);
    } catch (error) {
      toast.info("Something went wrong!!!");
      setErrorMessage({
        color: "text-red-500",
        message: error?.response?.data?.message || error.message || "Something went wrong!!!",
      });
      toast.error(error?.response?.data?.message || error.message);
      setLoaded(false);
    } finally {
      setLoaded(false);
    }
  };

  const resendOtp = async () => {
    setTimer(30);
    setIsResendAllowed(false);
    try {
      const res = await SendOTP({
        email: email,
      });
      if (res?.data?.status === 200) {
        setErrorMessage({
          color: "text-green-500",
          message: res?.data?.message,
        });
        toast.info(res.data.message);
      } else if (res?.data?.status === 400) {
        setErrorMessage({
          color: "text-red-500",
          message: res?.data?.message || res?.data?.errors,
        });
        toast.info(res?.data?.message || res?.data?.errors);
      } else {
        setErrorMessage({
          color: "text-red-500",
          message: res?.data?.message || res?.data?.errors,
        });
        toast.info(res?.data?.message || res?.data?.errors);
      }
      setLoaded(false);
    } catch (error) {
      console.error("Error signing in:", error);
      toast.info("Something went wrong!!!");
      setErrorMessage({
        color: "text-red-500",
        message: error?.response?.data?.message || error.message || "Something went wrong!!!",
      });
      toast.error(error?.response?.data?.message || error.message);
      setLoaded(false);
    } finally {
      setLoaded(false);
    }
  };

  return (
    <>
      <div className="flex justify-end">
        <Button
          buttonClasses="!bg-white !h-4 !text-2xl !text-black hover:!text-red-600 !text-center  gap-3 transition duration-200 rounded-md mt-0 sm:mt-0"
          buttonIcon="fa-regular fa-xmark"
          buttonIconPosition="right"
          buttonEffect="filled"
          buttonFunction={onClose}
        />
      </div>
      <div className="flex min-h-full flex-1 flex-col justify-center px-2 py-4 lg:px-2">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img className="mx-auto h-20 w-auto" src={Logo} alt="Your Company" />
          {/* <h4 className={`mt-1 text-center text-md font-bold leading-9 tracking-tight ${errorMessage?.color}`}>
            {errorMessage?.message}
          </h4> */}
        </div>

        <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-sm">
          <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
            <h2 className="text-center text-xl font-bold mb-4">Enter OTP</h2>
            <div className="flex justify-center gap-2">
              {otp.map((data, index) => (
                <input
                  key={index}
                  type="text"
                  maxLength="1"
                  value={data}
                  onChange={(e) => handleOtpChange(e.target, index)}
                  className="w-12 h-12 text-center border border-gray-400 rounded-md"
                  onFocus={(e) => e.target.select()}
                />
              ))}
            </div>
            <div className="mt-5">
              <Button
                buttonClasses={
                  "w-full text-white hover:bg-secondaryColor focus-visible:outline justify-center rounded-md"
                }
                buttonHasLink={false}
                buttonType={"submit"}
                buttonLabel={"Verify OTP"}
                buttonIconPosition={"left"}
                buttonIcon={"fa-light fa-right-to-bracket"}
              />
            </div>
            <div className="mt-4 text-center">
              <p className="text-sm text-gray-600">
                {isResendAllowed ? (
                  <button onClick={resendOtp} className="text-blue-500 hover:underline cursor-pointer">
                    Resend OTP
                  </button>
                ) : (
                  `Resend OTP in ${timer}s`
                )}
              </p>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AdminVerifyOtp;
