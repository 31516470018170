import React from "react";
import Indentification from "../elements/Indentification";
import { initialsValue } from "../../helpers";
import user from "../../assets/images/user.jpg";

const UploadProfileImage = ({
  value,
  name,
  inputName = "image",
  accept = "image",
  removeImage = () => {},
  onChange = () => {},
}) => {
  function handleChange(e) {
    if (e.target.files[0]?.type.split("/")[0] === accept) {
      onChange(e);
    }
  }
  return (
    <div className="relative">
      <div className=" flex-shrink-0 w-36 h-36 overflow-hidden rounded-full relative flex">
        <div className=" aspect-square rounded-full relative overflow-hidden w-full h-full">
          {value ? (
            <>
              <img
                src={value instanceof File ? URL.createObjectURL(value) : value}
                alt="image"
                width={500}
                height={500}
                className="w-full h-full object-cover object-center"
                onError={(e) => {
                  e.target.src = user;
                }}
              />
            </>
          ) : (
            name && (
              <Indentification
                initial={initialsValue(name)}
                alt={name}
                fill={false}
                className={" !rounded-md"}
                size={"max"}
                initialClass={"!text-7xl"}
              />
            )
          )}

          <div className="absolute top-0 left-0 bg-white w-full h-full z-10 opacity-0">
            <input
              className="w-full h-full cursor-pointer"
              type="file"
              name={inputName}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      {value && (
        <button
          // onClick={removeImage}
          type="button"
          className="w-8 h-8 rounded-full bg-white border border-slate-500 shadow-lg absolute bottom-0 right-1  text-black text-sm"
        >
        <i className="fa-light fa-camera"></i>
        </button>
      )}
    </div>
  );
};

export default UploadProfileImage;
