import React from 'react'
import MainBanner from "../components/sections/MainBanner"
import PrivacyPolicySection from "../components/sections/PrivacyPolicySection"

const PrivacyPolicy = () => {
  return (
    <>
      <MainBanner/>
      <PrivacyPolicySection/>
    </>
  )
}

export default PrivacyPolicy