import React, { useState } from "react";
import MainBanner from "../components/sections/MainBanner";
import LabelProduct from "../components/sections/LabelProduct";
import { useParams } from "react-router-dom";
import AllLabelProductitems from "../components/sections/AllLabelProductitems";
import RedWineLabelsItem from "../components/sections/RedWineLabelsItem";
import WhiteWineLabelItems from "../components/sections/WhiteWineLabelItems";
import EnPremierProduct from "../components/sections/EnPremierProduct";
import CruSelectProduct from "../components/sections/CruSelectProduct";
import CruInternationalProducts from "../components/sections/CruInternationalProducts";
import OrchardBreezinProduct from "../components/sections/OrchardBreezinProduct";
import CruSpecialistProduct from "../components/sections/CruSpecialistProduct";
import { useDispatch } from "react-redux";
import { addToCartProduct } from "../services/frontService/ProductService";
import { toast } from "react-toastify";
import { setCartDetails } from "../redux/slice/cartSlice";

const RjsLabel = () => {
  const dispatch = useDispatch();
  const { slug } = useParams();
  const [loaded, setLoaded] = useState(false);

  const addToCart = async (data) => {
    setLoaded(true);
    try {
      const res = await addToCartProduct({
        product_id: data?.id,
        quantity: 1,
      });
      if (res?.data?.status === 200 || res?.data?.status === 201) {
        toast.info(res?.data?.message);
        dispatch(setCartDetails(res?.data?.data));
      } else {
        toast.info(
          res?.data?.message || res?.data?.errors || "Something went wrong"
        );
      }
      setLoaded(false);
    } catch (err) {
      setLoaded(false);
      // console.error(err);
      toast.error("Somthing went wrong !!!");
    }
  };

  return (
    <>
      <MainBanner />
      {slug === "en-primeur" && <EnPremierProduct slug={slug} addToCart={addToCart}/>}
      {slug === "cru-select" && <CruSelectProduct slug={slug} addToCart={addToCart}/>}
      {slug === "cru-international" && <CruInternationalProducts slug={slug} addToCart={addToCart}/>}
      {slug === "orchard-breezin" && <OrchardBreezinProduct slug={slug} addToCart={addToCart}/>}
      {slug === "cru-specialty" && <CruSpecialistProduct slug={slug}  addToCart={addToCart}/>}
    </>
  );
};

export default RjsLabel;
