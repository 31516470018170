import React, { useCallback, useEffect, useState } from "react";
import Button from "../form/Button";
import DetailsImageSection from "../sections/DetailsImageSection";
import ImageDetailsText from "./ImageDetailsText";
import {
  getProductDetails,
  addWishlistProduct,
  addToCartProduct,
} from "../../services/frontService/ProductService";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setCartDetails } from "../../redux/slice/cartSlice";
import { useSelector } from "react-redux";
import { loginModalOpen } from "../../redux/slice/loginSlice";
import ProductDetailsLoader from "../loader/ProductDetailsLoader";

const QuickViewModal = ({ isOpen, setIsOpen, onClose = () => {} }) => {
  const slug = isOpen?.data?.alias;
  const [details, setDetails] = useState({ loading: true, data: {} });
  const isLogin = useSelector((state) => state?.auth?.isLogin);
  const [loaded, setLoaded] = useState(false);
  const [isSolid, setIsSolid] = useState(false);
  const dispatch = useDispatch();

  const getDetails = useCallback(() => {
    if (slug) {
      setDetails({ loading: true, data: {} });
      getProductDetails({ slug })
        .then((res) => {
          if (res?.status === 200) {
            setDetails({ loading: false, data: res?.docs });
          } else {
            setDetails({ loading: true, data: {} });
          }
        })
        .catch(() => {
          setDetails({ loading: true, data: {} });
        });
    }
  }, [slug]);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  const handleAddToCart = async (data, quantity) => {
    if (isLogin) {
      setLoaded(true);
      try {
        const res = await addToCartProduct({
          product_id: data?.id,
          quantity: quantity || 1,
        });
        if (res?.data?.status === 200 || res?.data?.status === 201) {
          toast.info(res?.data?.message);
          dispatch(setCartDetails(res?.data?.data));
        } else {
          toast.info(
            res?.data?.message || res?.data?.errors || "Something went wrong"
          );
        }
        setLoaded(false);
      } catch (err) {
        setLoaded(false);
        // console.error(err);
        toast.error("Somthing went wrong !!!");
      }
    } else {
      dispatch(loginModalOpen({ isOpen: true }));
      setIsOpen({ open: false, data: {} });
    }
  };

  const handleWishlist = async (data) => {
    if (isLogin) {
      setLoaded(true);
      try {
        const res = await addWishlistProduct({
          product_id: data?.id,
        });

        if (res?.data?.status === 200 || res?.data?.status === 201) {
          toast.info(res?.data?.message);
          setIsSolid((pre) => !pre);
        } else {
          toast.info(
            res?.data?.message || res?.data?.errors || "Something went wrong"
          );
        }

        setLoaded(false);
      } catch (err) {
        setLoaded(false);
        // console.error(err);
        toast.error("Somthing went wrong !!!");
      }
    } else {
      dispatch(loginModalOpen({ isOpen: true }));
      setIsOpen({ open: false, data: {} });
    }
  };
  return (
    <>
      {details?.loading ? (
        <>
          <ProductDetailsLoader isSide={false}></ProductDetailsLoader>
        </>
      ) : (
        <>
          <div className="relative w-full md:p-12 p-3 !pt-0 !pb-8">
            <div className="flex w-full items-center space-x-3">
              <div className="w-full text-xl text-grey-700 font-bold pb-2">
                {details?.data?.title}
              </div>
              <div className="flex w-full justify-end text-end text-2xl absolute -top-6 right-2">
                <Button
                  buttonClasses="!bg-white !h-8 !text-3xl !text-black hover:!text-black !text-xl hover:!bg-gray-200 hover:!border-gray-200 !text-center border border-gray-200 gap-3 transition duration-200 rounded-none leading-none !rounded-md"
                  buttonIcon="fa-regular fa-xmark"
                  buttonIconPosition="right"
                  buttonEffect="filled"
                  buttonFunction={onClose}
                />
              </div>
            </div>
            <hr className="w-full border-1 border-slate-500 pb-6" />
            <div className="md:flex block space-x-2">
              <div className="md:w-2/5 w-full">
                <DetailsImageSection quickView={true} details={details?.data} />
              </div>
              <div className="w-full">
                <ImageDetailsText
                  quickView={true}
                  details={details}
                  handleAddToCart={handleAddToCart}
                  handleWishlist={handleWishlist}
                  isSolid={isSolid}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default QuickViewModal;
