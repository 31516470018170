import React, { useCallback, useEffect, useState } from "react";
import { getPageData } from "../../services/frontService/PageService";
import PrivacyPolicyLoader from "../loader/PrivacyPolicyLoader";

const PrivacyPolicySection = () => {
  const [homeData, setHomeData] = useState({});
  const [loading, setLoading] = useState(false);

  const loadPageData = useCallback(() => {
    setLoading(true);
    getPageData({
      slug: "privacy-policy",
    }).then((res) => {
      if (res?.status === 200) {
        setHomeData(res?.data);
        setLoading(false);
      } else {
        setLoading(true);
      }
    });
  }, []);

  useEffect(() => {
    loadPageData();
  }, [loadPageData]);
  return (
    <>
      <div className="relative sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto">
        <div className="relative w-full pt-24 ">
          <div className="relative">
            <h2 className="text-3xl font-bold mb-6 text-red-600 border-b border-slate-300 pb-2">
              Privacy Policy
            </h2>
            {loading ? (
              <>
               <PrivacyPolicyLoader></PrivacyPolicyLoader>
              </>
            ) : (
              <div
                className="relative w-full text-base text-slate-700 flex flex-col"
                dangerouslySetInnerHTML={{
                  __html: homeData?.description || "",
                }}
              ></div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicySection;
