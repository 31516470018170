import React, { useState } from "react";
import Button from "../form/Button";
import FavouriteImage from "./FavouriteImage";
import Modal from "../elements/Modal/Modal";
import LeaveFeedback from "../sections/LeaveFeedback";
import moment from "moment";
import { country } from "../../helpers";
import { useNavigate } from "react-router-dom";
import Image from "../elements/Image";
import image2 from "../../assets/images/cards/card-inside-2.webp";

const OrderDetailsItems = ({ data }) => {
  const navigate = useNavigate();
  const productInfo = JSON.parse(data?.product_info);

  return (
    <>
      <div className="relative md:flex w-full bg-slate-50 rounded-md shadow py-6 border border-slate-200 md:mt-5 mt-5 md:px-10 px-6">
        <div className="md:w-40  w-full aspect-square overflow-hidden">
          <div>
            <Image
              src={data?.orderProdImage || image2}
              className="w-full h-full object-cover"
              alt="product image"
            />
          </div>
        </div>

        <div className="w-full flex flex-col md:flex-row">
          <div className="w-full md:w-2/5 md:pl-8 pl-0">
            <div className="w-full md:space-y-1 space-y-0">
              <div className="md:!text-2xl text-lg font-bold">
                #{data?.order_id}
              </div>
              <div className="flex space-x-2 pt-1 items-center">
                <div className="md:text-base text-sm font-bold">Collected:</div>
                <div className="text-base text-gray-500">
                  {moment(data?.created_at).format("MM-DD-YYYY")}
                </div>
              </div>
            </div>
          </div>
          <div className="w-full  flex flex-col md:pl-8 pl-0 mt-4 md:mt-0">
            <div className="flex md:justify-end justify-start md:text-2xl text-lg text-red-600 font-bold">
              {country + " " + (data?.total_price || "")}
            </div>
            <div className="flex md:justify-end justify-start md:text-xl text-base text-gray-600 font-semibold mt-2">
              Status:{" "}
              <span className="pl-2 text-green-600">
                {data?.status === 1
                  ? "Processing"
                  : data?.status === 2
                  ? "Accepted"
                  : data?.status === 3
                  ? "Out For Delivery"
                  : data?.status === 4
                  ? "Shipped"
                  : "Cancelled"}
              </span>
            </div>
            <div className="flex w-full gap-3 items-end !md:justify-end justify-end mt-4">
              <div className="flex flex-wrap md:gap-3 gap-2 justify-end">
                {/* <Button
                  buttonClasses="!px-4 !py-1 !h-10 !text-white hover:!text-white !w-full md:!w-auto !text-xl hover:!bg-black hover:!border-black !text-center bg-gray-600 gap-3 transition duration-200 rounded-none leading-none !rounded-md"
                  buttonLabel="Add to Cart Again"
                  buttonLabelClasses="md:!text-base text-sm"
                  buttonEffect="filled"
                  buttonHasLink
                  buttonLink="/cart"
                /> */}
                <Button
                  buttonClasses="!px-4 !py-1 !h-10 !text-white hover:!text-white !w-full md:!w-auto !text-xl !bg-red-600 hover:!border-black !text-center hover:!bg-black gap-3 transition duration-200 rounded-none leading-none !rounded-md"
                  buttonLabel="View Details"
                  buttonLabelClasses="md:!text-base text-sm"
                  buttonEffect="filled"
                  buttonHasLink={false}
                  buttonFunction={() =>
                    navigate(`/order-details/${data?.order_id}`)
                  }
                />
              </div>
            </div>
          </div>
        </div>

        {/* <Modal size="xl4" modalTitle="" isOpen={isOpen}>
          <LeaveFeedback onClose={() => setIsOpen(false)} />
        </Modal> */}
      </div>
    </>
  );
};

export default OrderDetailsItems;
