import React from "react";
import Image from "../elements/Image";
import Review from "./Review";

const SingleDescriptionRating = ({ data }) => {
  return (
    <>
      <div>
        <div className="relative flex space-x-4 py-4">
          {/* <div className="w-16 h-16 rounded-full overflow-hidden">
    <Image src={data.image} alt="" effect="blur" className="w-full h-full object-cover" />
  </div> */}
          <div className="w-full">
            <div className="space-y-1">
              <div className="flex items-center">
                <div className="md:text-xl text-xl font-bold text-black">{data?.productInfo?.title}</div>
              </div>
              <div className="flex justify-end">
                <div className="md:text-l text-l font-bold text-gray-700">{data?.reviewUserInfo?.name}</div>
              </div>
              <div className="flex items-end space-x-2">
                <Review
                  rating={data?.rating}
                  totalReviews={data?.rating}
                  isReviewShow={true}
                  classes={"md:flex block"}
                />
              </div>
            </div>
            <div className="md:text-base text-sm pt-4 capitalize">{data?.review}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleDescriptionRating;
