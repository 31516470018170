import React, { useCallback, useEffect, useMemo, useState } from "react";
import Image from "../elements/Image";
// import Button from '../../form/Button';
import lable from "../../assets/images/labels/lable-card-1.webp";
import lable_2 from "../../assets/images/labels/lable-card-2.webp";
import lable_3 from "../../assets/images/labels/lable-card-3.webp";
import lable_4 from "../../assets/images/labels/lable-card-4.webp";
import lable_5 from "../../assets/images/labels/lable-card-5.webp";
import lable_6 from "../../assets/images/labels/lable-card-6.webp";
import lable_7 from "../../assets/images/labels/lable-card-12.webp";
import lable_8 from "../../assets/images/labels/lable-card-8.webp";
import ProductAction from "./ProductAction";
import SingleProduct from "../Items/SingleProduct";
import Button from "../form/Button";
import Modal from "../elements/Modal/Modal";
import ChangeProfileModal from "./ChangeProfileModal";
import QuickViewModal from "./QuickViewModal";
import { toast } from "react-toastify";
import { productListData } from "../../services/frontService/ProductService";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { loginModalOpen } from "../../redux/slice/loginSlice";
import { useNavigate } from "react-router-dom";
import { getPageData } from "../../services/frontService/PageService";
import ProductLoader from "../loader/ProductLoader";
import LOGO from "../../assets/images/logo.png";

const FeaturedProduct = ({ addToCart = () => {} }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState({ data: {}, open: false });
  const dispatch = useDispatch();
  const isLogin = useSelector((state) => state?.auth?.isLogin);
  const [list, setList] = useState({
    loading: true,
    data: [],
    pageCount: 0,
    totalItem: 0,
  });
  const [homeData, setHomeData] = useState({});

  const actions = useMemo(() => {
    return [
      // {
      //   _id: 1,
      //   icon: "fa-light fa-heart",
      //   isLink: false,
      //   isWishlist: true,
      //   isSolid: isSolid,
      //   click: (data) => {
      //     if (isLogin) {
      //       addWishList(data);
      //     } else {
      //       dispatch(loginModalOpen({ isOpen: true }));
      //     }
      //   },
      // },
      {
        _id: 2,
        icon: "fa-light fa-bag-shopping",
        isLink: false,
        click: (data) => {
          if (isLogin) {
            addToCart(data);
          } else {
            dispatch(loginModalOpen({ isOpen: true }));
          }
        },
      },
      {
        _id: 3,
        icon: "fa-light fa-arrow-right",
        isLink: false,
        click: (data) => {
          navigate(`/productdetails/${data?.alias}`);
        },
      },
      {
        _id: 4,
        icon: "fa-light fa-eye",
        isLink: false,
        click: (data) => setIsOpen({ open: true, data: data }),
      },
    ];
  }, [isLogin]);

  //  Product list >>>>>>>>>
  const loadList = useCallback(() => {
    setList((pre) => ({
      ...pre,
      data: [],
      loading: true,
    }));
    productListData({
      // perPage: 200,
      parent_category_slug: "labels",
      status: 1,
      featured: 1,
    }).then((res) => {
      if (res && res?.success) {
        setList({
          loading: false,
          data: res?.docs,
          pageCount: res?.docs?.metadata?.totalPages,
          totalItem: res?.docs?.metadata?.totalDocs,
        });
      } else {
        setList((pre) => ({ ...pre, data: [], loading: true }));
        toast.error(res?.message);
      }
    });
  }, []);

  useEffect(() => {
    loadList();
  }, [loadList,isLogin]);

  const loadPageData = useCallback(() => {
    getPageData({
      slug: "our-labels",
    }).then((res) => {
      if (res?.status === 200) {
        setHomeData(res?.data);
      }
    });
  }, []);

  useEffect(() => {
    loadPageData();
  }, [loadPageData]);

  const featuredProductData = {
    subheading: "Great Designs At The Best Price",
    heading: homeData?.name || "OUR LABELS",
    description:
      homeData?.description ||
      "HJL Designs is a manufacturer and wholesaler of high quality vinyl labels for the craft wine making industry. With a mix of modern and traditional designs, our ever-expanding lineup of 9.5 cm x 7 cm labels fit any bottle size, are easy to remove, and  do not leave any residue behind.",
    subdata: list && list?.data?.length > 0 ? list?.data : [],
  };
  const loaders = Array(8).fill(null);

  return (
    <>
      <section className="sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto md:px-0 px-5">
        <div className=" relative w-full items-end">
          <div className=" relative text-center xl:pt-24 pt-10 xl:px-40 px-0 w-full">
            <div className="md:text-xl text-sm text-gray-700">
              {featuredProductData?.subheading}
            </div>
            <h2 className="great-design xl:text-6xl md:text-5xl text-2xl font-bold pt-2 pb-4 drop-shadow-md uppercase">
              <span className="relative inline-block !leading-none before:content-[''] before:absolute before:top-2/4 before:-translate-y-1/2 md:before:-left-36 before:-left-20 md:before:w-32 before:w-16 before:border-t-2 before:border-black after:content-[''] after:absolute after:top-2/4 after:-translate-y-1/2 md:after:-right-36 after:-right-20 md:after:w-32 after:w-16 after:border-t-2 after:border-black">
                {featuredProductData?.heading}
              </span>
            </h2>
            <div
              className="md:text-lg text-sm pb-3 text-gray-800"
              dangerouslySetInnerHTML={{
                __html: featuredProductData?.description || "",
              }}
            >
              {/* {featuredProductData?.description} */}
            </div>
          </div>
          <div className="w-full text-end flex xl:justify-end justify-center">
            <div className="xl:mt-0 mt-4">
              <Button
                buttonClasses={
                  "md:!px-12 px-6 md:!p-8 p-3 !h-10 !text-4xl !text-white !text-xl hover:!bg-black !text-center bg-red-600 gap-3 hover:scale-110 transition duration-300"
                }
                buttonLabel="Explore Our labels"
                buttonLabelClasses=""
                buttonIcon={"fa-regular fa-arrow-right"}
                buttonIconPosition={"right"}
                buttonEffect={"filled"}
                buttonHasLink={"true"}
                buttonLink={"/label/all-wine-labels"}
              />
            </div>
          </div>
        </div>
        {list.loading ? (
          <>
            <div className="grid xl:grid-cols-4 md:grid-cols-3 grid-cols-2 xl:gap-8 md:gap-8 gap-5 md:pt-14 pt-10">
              {loaders?.map((_, index) => (
                <ProductLoader key={index} />
              ))}
            </div>
          </>
        ) : (
          featuredProductData &&
          Array.isArray(featuredProductData.subdata) &&
          featuredProductData?.subdata?.length > 0 && (
            <div className="grid xl:grid-cols-4 md:grid-cols-3 grid-cols-2 xl:gap-8 md:gap-8 gap-5 md:pt-14 pt-10">
              {featuredProductData?.subdata?.map((item, index) => (
                <SingleProduct
                  key={index}
                  data={item}
                  lable={lable}
                  actions={actions}
                />
              ))}
            </div>
          )
        )}

        <Modal
          size="xl4"
          modalTitle=""
          open={isOpen?.open}
          onClose={() => setIsOpen({ open: false })}
        >
          <QuickViewModal
            onClose={() => setIsOpen({ open: false })}
            setIsOpen={setIsOpen}
            isOpen={isOpen}
          />
        </Modal>
      </section>
    </>
  );
};

export default FeaturedProduct;
