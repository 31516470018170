import React from 'react';
import Tabs from '../../elements/Tabs';
import MyCurrentOrdersDetails from '../MyCurrentOrdersDetails';
import MyAllOrdersDetails from '../MyAllOrdersDetails';

const MyOrderList = ({myorderList}) => {

  const Processing = myorderList?.filter((myorderList) => myorderList?.status === 1);

  const Accepted = myorderList?.filter((myorderList) => myorderList?.status === 2);

  const OutForDelivery = myorderList?.filter((myorderList) => myorderList?.status === 3);

  const Shipped = myorderList?.filter((myorderList) => myorderList?.status === 4);

  const Cancelled = myorderList?.filter((myorderList) => myorderList?.status === 5);

    const tabData = [
        {
          _id: 1,
          name: "Processing",
          component: <MyCurrentOrdersDetails data={Processing} title={"Processing"}/>,
        },
        {
          _id: 2,
          name: "Accepted",
          component: <MyAllOrdersDetails data={Accepted} title={"Accepted"}/>,
        },
        {
          _id: 3,
          name: "Out For Delivery",
          component: <MyAllOrdersDetails data={OutForDelivery} title={"Out For Delivery"} />,
        },
        {
          _id: 4,
          name: "Shipped",
          component: <MyAllOrdersDetails data={Shipped} title={"Shipped"}/>,
        },
       
          {
            _id: 5,
            name: "Cancelled",
            component: <MyAllOrdersDetails  data={Cancelled} title={"Cancelled"}/>,
          },
      ];
    return (
        <>
           <div className="relative">
          <Tabs tabs={tabData} navTabClass={""} />
        </div>
        </>
    );
};

export default MyOrderList;