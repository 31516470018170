import React from "react";
import Image from "../elements/Image";

const MainBannerItems = ({ data }) => {
  return (
    <>
      <div className="!w-full relative">
        <img
          src={data?.imageUrl}
          alt=""
          className="object-cover w-full h-full"
        />
      </div>
    </>
  );
};

export default MainBannerItems;
