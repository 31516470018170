import { apiRequest } from "../../util/apiUtils";

export const productListData = async (params) => {
  try {
    const res = await apiRequest("get", "front/product/publicList", {
      queries: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const pageWiseProductListData = async (params) => {
  try {
    const res = await apiRequest("get", "front/product/list", {
      queries: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const getProductDetails = async (params) => {
  try {
    const res = await apiRequest(
      "get",
      `front/product/details/${params?.slug}`,
      {}
    );
    return res?.data;
  } catch (err) {
    return err;
  }
};
export const addWishlistProduct = async (params) => {
  try {
    const res = await apiRequest(
      "post",
      "front/product/wishList",
      {
        body: { ...params },
      }
      // "multipart/form-data"
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const cartListData = async (params) => {
  try {
    const res = await apiRequest("get", "front/cart/list", {
      queries: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const addToCartProduct = async (params) => {
  try {
    const res = await apiRequest(
      "post",
      "front/cart/addToCart",
      {
        body: { ...params },
      }
      // "multipart/form-data"
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const deleteCart = async (params) => {
  try {
    const res = await apiRequest("post", "front/cart/removeCartItem", {
      body: { ...params },
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const addCartQuantity = async (params) => {
  try {
    const res = await apiRequest(
      "post",
      "front/cart/manageCartItemQuantity",
      {
        body: { ...params },
      }
      // "multipart/form-data"
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const checkoutDetailsData = async (params) => {
  try {
    const res = await apiRequest("post", "front/checkout/details", {});
    return res?.data;
  } catch (error) {
    return error;
  }
};


export const newPlaseOrder = async (params) => {
  try {
    const res = await apiRequest(
      "post",
      "front/order/plaseOrder",
      {
        body: { ...params },
      }
      // "multipart/form-data"
    );
    return res;
  } catch (error) {
    return error;
  }
};

