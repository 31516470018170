import React, { useState } from "react";
import Button from "../../form/Button";
import Modal from "../../elements/Modal/Modal";
import { AddEditUserBillingAddressModal } from "./AddEditUserBillingAddressModal";

const UserBillingAddressTable = ({ data, id, setDetailsData = () => {}, getDetails = () => {} }) => {
  const [isBillingOpen, setIsBillingOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handelAddBillingOpenModal = () => {
    setIsBillingOpen(true);
  };

  const handelEditOpenModal = () => {
    setIsOpen(true);
  };

  return (
    <>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200 !bg-white !shadow-md">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-sm font-bold text-gray-800 uppercase tracking-wider">
                Address 1
              </th>
              <th className="px-6 py-3 text-left text-sm font-bold text-gray-800 uppercase tracking-wider">
                Address 2
              </th>
              <th className="px-6 py-3 text-left text-sm font-bold text-gray-800 uppercase tracking-wider">City</th>
              <th className="px-6 py-3 text-left text-sm font-bold text-gray-800 uppercase tracking-wider">State</th>
              <th className="px-6 py-3 text-left text-sm font-bold text-gray-800 uppercase tracking-wider">Zip code</th>
              <th className="px-6 py-3 text-left text-sm font-bold text-gray-800 uppercase tracking-wider">
                {data ? "Edit" : "Add"}
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            <tr>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{data?.address}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{data?.address2}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{data?.city}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{data?.stateInfo?.name}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{data?.pin}</td>

              {data ? (
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  <Button
                    buttonClasses={
                      "!bg-white !p-1 !text-red-600 !text-xl hover:!text-slate-900 h-8 flex items-center justify-center transition-all duration-200 !rounded-full"
                    }
                    buttonLabelClasses={""}
                    buttonIcon={"fa-solid fa-pen-to-square"}
                    buttonIconPosition={"left"}
                    buttonHasLink={false}
                    buttonLink={""}
                    buttonFunction={handelEditOpenModal}
                  />
                </td>
              ) : (
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  <Button
                    buttonHasLink={false}
                    buttonIcon={"fa-regular fa-plus"}
                    buttonIconPosition={"left"}
                    buttonClasses={
                      "!bg-white !p-1 !text-red-600 !text-xl hover:!text-slate-900 h-8 flex items-center justify-center transition-all duration-200 !rounded-full"
                    }
                    buttonLabelClasses={"font-medium !text-sm"}
                    buttonFunction={handelAddBillingOpenModal}
                  />
                </td>
              )}
            </tr>
          </tbody>
        </table>
        <Modal open={isOpen} title={`Billing Address`} size="xl2">
          <AddEditUserBillingAddressModal
            onClose={() => setIsOpen(false)}
            id={id}
            data={data}
            setBillingDetails={setDetailsData}
            getBillingDetails={getDetails}
          />
        </Modal>
        <Modal open={isBillingOpen} title={`Billing Address`} size="xl2">
          <AddEditUserBillingAddressModal
            onClose={() => setIsBillingOpen(false)}
            id={id}
            getBillingDetails={getDetails}
          />
        </Modal>
      </div>
    </>
  );
};

export default UserBillingAddressTable;
