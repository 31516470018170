import React, { useRef } from "react";
import Button from "../form/Button";
import { country } from "../../helpers";
import { useSelector } from "react-redux";
import html2pdf from "html2pdf.js";
import Invoice from "./Invoice";

const ViewDetailsPrice = ({ details }) => {
  const pdfRef = useRef();
  const settings = useSelector((state) => state?.settings?.data);
  const profile = useSelector((state) =>state?.profile?.profileData);
  const downloadPdf = () => {
    const element = pdfRef.current;
    const opt = {
      margin: 0.2,
      filename: "Invoice.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };
    if (element) {
      html2pdf(element, opt);
    }
  };

  return (
    <>
      <div className="relative xl:w-2/6 w-full md:px-auto">
        <div className="sticky top-32 w-full inline-block">
          <div className=" bg-gray-50 shadow-md border rounded-md">
            <div className="px-6 py-8">
              <div className="relative flex pb-4 text-black items-center px-2">
                <div className="text-xl font-bold capitalize">
                  Price Details
                </div>
              </div>
              <div className="relative pt-4 border-t border-slate-800 px-2 ">
                <div className="flex text-base text-slate-700 font-normal items-center capitalize text-slate">
                  <div className="relative">Sub Total value</div>
                  <div className="ml-auto">{details?.sub_total || 0.0}</div>
                </div>
              </div>
              <div className="relative pt-4  px-2 ">
                <div className="flex text-base text-slate-700 font-normal items-center capitalize text-slate">
                  <div className="relative">VAT charge</div>
                  <div className="ml-auto">
                    {country + " " + (details?.vat_charge || 0.0)}
                  </div>
                </div>
              </div>
              <div className="relative pt-4  px-2 ">
                <div className="flex text-base text-slate-700 font-normal items-center capitalize text-slate">
                  <div className="relative">Delivery charge</div>
                  <div className="ml-auto">
                    {country + " " + (details?.delivery_charge || 0.0)}
                  </div>
                </div>
              </div>
              <div className="relative border-t border-slate-800 mt-4 pt-2 flex justify-between px-2 ">
                <div className="relative font-semibold text-base text-black">
                  Total
                </div>
                <div className="relative font-semibold text-base text-black">
                  {country + " " + (details?.total_price || 0.0)}
                </div>
              </div>
            </div>
          </div>

          <div className="relative">
            <Button
              buttonClasses={
                "!px-5 !h-12 !rounded-md !mt-3 !text-white !text-xl hover:!bg-black hover:!border-black !text-center bg-red-600 gap-3 transition duration-300 w-full rounded-none border border-red-600"
              }
              buttonLabel="Download Invoice"
              buttonLabelClasses="  !text-xl "
              buttonEffect={"filled"}
              buttonIcon={"fa-solid fa-download"}
              buttonIconPosition={"left"}
              buttonFunction={() => downloadPdf()}
            />
          </div>
          <div style={{ display: "none" }}>
            <Invoice ref={pdfRef} data={details} settings={settings} profile={profile}/>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewDetailsPrice;
