import React, { useCallback, useEffect, useState } from "react";
import ViewOrderDetailsProductsList from "../components/sections/ViewOrderDetailsProductsList";
import ViewDetailsPrice from "../components/Items/ViewDetailsPrice";
import DetailsBreadcrumb from "../components/Items/DetailsBreadcrumb";
import { getOrderDetails } from "../services/frontService/ProfileService";
import { useParams } from "react-router-dom";
import moment from "moment";
import { country } from "../helpers";
import PageLoader from "../components/common/PageLoader";

const items = [
  { label: "Home", href: "/" },
  { label: "Order Details", href: "/vieworderdetails" },
];

const ViewOrderDetails = () => {
  const { slug } = useParams();
  const [orderDetails, setOrderDetails] = useState();
  const [loading, setLoading] = useState(true);
  const getDetails = useCallback(() => {
    setLoading(true);
    getOrderDetails({ id: slug }).then((res) => {
      if (res?.status === 200) {
        setOrderDetails(res?.docs);
        setLoading(false);
      } else {
        setOrderDetails(res?.docs);
        setLoading(false);
      }
    });
  }, []);

  useEffect(() => {
    getDetails();
  }, [getDetails]);
  return (
    <>
      <div className="relative xl:pt-40 md:pt-32 pt-24 w-full sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto md:px-auto px-5">
        <div className="pb-5">
          <DetailsBreadcrumb items={items} />
        </div>
        {loading ? (
          <PageLoader />
        ) : (
          <>
            <div className="md:text-3xl text-2xl uppercase text-black mb-10 text-medium pb-2">
              Order
              <span className="text-red-600 font-extrabold"> details</span>
              <hr className="w-full border-1 border-slate-500 mt-2"></hr>
            </div>

            <div className="w-full md:flex block bg-slate-50 border p-5 rounded-xl shadow-md">
              <div className="w-full md:space-y-2 space-y-2 ">
                {/* <div className="md:!text-2xl text-xl font-bold">sdcdxxc </div> */}
                <div className="flex space-x-4 items-center">
                  <div className="md:text-lg text-sm font-semibold">Order Id :</div>
                  <div className="md:text-base text-xs  text-gray-500">
                    {orderDetails?.order_id} ({orderDetails?.orderDetailsInfo?.length} Items)
                  </div>
                </div>
                <div className="flex space-x-4 items-center">
                  <div className="md:text-lg text-sm font-semibold">Order Date :</div>
                  <div className="md:text-base text-xs text-gray-500">
                    {moment(orderDetails?.created_at).format("MMM DD,YYYY")}
                  </div>
                </div>
                {/* <div className="flex space-x-4 pt-0 items-center">
                  <div className="md:text-lg text-sm font-semibold">
                    GST/HST Charge :
                  </div>
                  <div className="md:text-base text-xs text-gray-500 uppercase">
                     {country + " " + (orderDetails?.gst_charge || 0.0)}
                  </div>
                </div> */}
                <div className="flex space-x-4 pt-0 items-center">
                  <div className="md:text-lg text-sm font-semibold">VAT Charge :</div>
                  <div className="md:text-base text-xs text-gray-500 uppercase">
                    {country + " " + (orderDetails?.vat_charge || 0.0)}
                  </div>
                </div>
                <div className="flex space-x-4 pt-0 items-center">
                  <div className="md:text-lg text-sm font-semibold">Delivery Charge :</div>
                  <div className="md:text-base text-xs text-gray-500 uppercase">
                    {country + " " + (orderDetails?.delivery_charge || 0.0)}
                  </div>
                </div>
              </div>
              <div className="flex justify-end w-full md:mt-0 mt-4">
                <div className="md:space-y-2 space-y-1">
                  <div className="md:text-xl text-sm font-bold uppercase">Total Amount</div>
                  <div className="md:text-xl text-sm font-semibold text-end text-red-600">
                    {country + " " + (orderDetails?.total_price || 0.0)}
                  </div>
                </div>
              </div>
            </div>

            <div className="relative flex xl:flex-row flex-col gap-12 pt-10">
              <ViewOrderDetailsProductsList details={orderDetails} />
              <ViewDetailsPrice details={orderDetails} />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ViewOrderDetails;
