import React, { useState } from "react";
import MainBanner from "../components/sections/MainBanner";
import FeaturedProduct from "../components/sections/FeaturedProduct";
import RjsLables from "../components/sections/RjsLables";
import OurCards from "../components/sections/OurCards";
import Testimonial from "../components/sections/Testimonial";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { loginModalOpen } from "../redux/slice/loginSlice";
import { addToCartProduct } from "../services/frontService/ProductService";
import { toast } from "react-toastify";
import { setCartDetails } from "../redux/slice/cartSlice";

const Home = () => {
  const dispatch = useDispatch();
  const isLogin = useSelector((state) => state?.auth?.isLogin);
  const [loaded, setLoaded] = useState(false);

  const addToCart = async (data) => {
    setLoaded(true);
    try {
      const res = await addToCartProduct({
        product_id: data?.id,
        quantity: 1,
      });
      if (res?.data?.status === 200 || res?.data?.status === 201) {
        toast.info(res?.data?.message);
        dispatch(setCartDetails(res?.data?.data));
      } else {
        toast.info(
          res?.data?.message || res?.data?.errors || "Something went wrong"
        );
      }
      setLoaded(false);
    } catch (err) {
      setLoaded(false);
      // console.error(err);
      toast.error("Somthing went wrong !!!");
    }
  };

  return (
    <>
      <MainBanner />
      <FeaturedProduct addToCart={addToCart} />
      <RjsLables addToCart={addToCart} />
      <OurCards addToCart={addToCart} />
      <Testimonial />
    </>
  );
};

export default Home;
