import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import LOGO from "../../../src/assets/images/logo.png";
import invoice from "../../../src/assets/images/invoice.jpg";

const Invoice = React.forwardRef(({ data, settings, profile }, ref) => {
  const [logoBase64, setLogoBase64] = useState("");

  // useEffect(() => {
  //   if (settings?.logoUrl) {
  //     fetch(settings?.logoUrl)
  //       .then((response) => response.blob())
  //       .then((blob) => {
  //         const reader = new FileReader();
  //         reader.onloadend = () => {
  //           setLogoBase64(reader.result);
  //         };
  //         reader.readAsDataURL(blob);
  //       })
  //       .catch((error) =>
  //         console.error("Error converting image to base64:", error)
  //       );
  //   }
  // }, [settings]);

  return (
    <div ref={ref} className="p-4">
      <div className="flex lg:justify-between border-b pb-2 border-gray-500">
        <div>
          {/* {logoBase64 ? (
            <img
              src={logoBase64 || LOGO}
              alt="logo"
              className="h-24 w-36 object-contain indent"
            />
          ) : (
            <p>Loading logo...</p>
          )} */}
          <img src={LOGO} alt="logo" className="h-24 w-36 object-contain" />
        </div>
        <div className="mt-4 text-right">
          <p className="mb-1 font-semibold text-4xl">
            <img
              src={invoice}
              alt="invoice"
              className="h-12 w-64 object-contain"
            />
          </p>
          <p className="text-sm font-normal">
            Order ID : {" "}
            <span className="font-semibold text-xl">
              {data?.order_id || "N/A"}
            </span>
          </p>
          <p className="text-sm font-normal">
            Date :  {" "}
            <span className="text-md">
              {data?.created_at
                ? dayjs(data?.created_at).format("DD/MM/YYYY h:mm A")
                : "N/A"}
            </span>
          </p>
        </div>
      </div>
      <div className="flex justify-between mt-3 border-b pb-2 border-gray-500">
        <div className="text-left">
          <p className="font-semibold text-xl">{profile?.name || ""}</p>
          <p className="text-md font-normal">{profile?.email || ""}</p>
          <p className="text-md font-normal">{profile?.phone || ""}</p>
        </div>

        <div className="text-right">
          <p className="text-md font-normal">
            {profile?.userBillingAdderssInfo?.address || ""}
          </p>
          <p className="text-md font-normal">
            {`${profile?.userBillingAdderssInfo?.address2 || ""}, ${
              profile?.userBillingAdderssInfo?.city || ""
            }`}
          </p>
          <p className="text-md font-normal">
            {profile?.userBillingAdderssInfo?.stateName || ""}
          </p>
          <p className="text-md font-normal">
            {profile?.userBillingAdderssInfo?.pin || ""}
          </p>
        </div>
      </div>

      <div className="-mx-4 flow-root sm:mx-0">
        <table className="min-w-full">
          <thead className="border-b border-gray-500 text-gray-900">
            <tr>
              <th
                scope="col"
                className="py-2 pl-2 pr-2 text-left text-md font-bold text-gray-900 sm:pl-0"
              >
                Items Name
              </th>
              <th
                scope="col"
                className="hidden px-2 py-2 text-center text-md font-bold text-gray-900 sm:table-cell"
              >
                Quantity
              </th>
              <th
                scope="col"
                className="hidden px-2 py-2 text-right text-md font-bold text-gray-900 sm:table-cell"
              >
                Price
              </th>
              <th
                scope="col"
                className="py-2 pl-2 pr-2 text-right text-md font-bold text-gray-900 sm:pr-0"
              >
                Amount
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.orderDetailsInfo?.map((item) => (
              <tr key={item?.order_id}>
                <td className="max-w-0 py-3 pl-2 pr-2 text-sm sm:pl-0">
                  <div className="font-bold text-gray-900">
                    {item?.product_name || ""}
                  </div>
                </td>

                <td className="hidden px-2 py-3 text-center text-sm text-gray-700 sm:table-cell">
                  {item?.quantity || ""}
                </td>
                <td className="hidden px-2 py-3 text-right text-sm text-gray-700 sm:table-cell">
                  {item?.actual_price || ""}
                </td>
                <td className="py-3 pl-2 pr-2 text-right text-sm text-gray-700 sm:pr-0">
                  {item?.total_price || ""}
                </td>
              </tr>
            ))}
            <tr className="border-t border-gray-500">
              <th
                scope="row"
                colspan="4"
                className="hidden pl-2 pr-2 pt-2 text-right text-md font-normal text-gray-700 sm:table-cell sm:pl-0"
              >
                <div className="flex justify-end">
                  <div className="border-b border-gray-500 w-[270px] pb-2 space-y-2">
                    <div className="flex gap-2 items-center">
                      <div className="flex-grow">Sub Total</div>
                      <div className="flex-grow-0 text-right text-md font-semibold text-gray-900 w-[120px]">
                        {data?.sub_total || ""}
                      </div>
                    </div>
                    <div className="flex gap-2 items-center">
                      <div className="flex-grow">VAT Charges</div>
                      <div className="flex-grow-0 text-right text-md text-gray-900 w-[120px]">
                        {data?.vat_charge || ""}
                      </div>
                    </div>
                    <div className="flex gap-2 items-center">
                      <div className="flex-grow"> Delivery Charges</div>
                      <div className="flex-grow-0 text-right text-md text-gray-900 w-[120px]">
                        {data?.delivery_charge || ""}
                      </div>
                    </div>
                  </div>
                </div>
              </th>
            </tr>
            <tr>
              <th
                scope="row"
                colspan="4"
                className="hidden pl-2 pr-2  text-right text-xl font-semibold text-gray-900 sm:table-cell sm:pl-0"
              >
                <div className="flex justify-end">
                  <div className="flex gap-2 items-center">
                    <div className="flex-grow">Grand Total</div>
                    <div className="flex-grow-0 text-right w-[120px]">
                      CAD {" "}{data?.total_price || ""}
                    </div>
                  </div>
                </div>
              </th>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
});

export default Invoice;
