import React, { useCallback, useEffect, useRef, useState } from "react";
import Button from "../../../components/form/Button";
import Image from "../../../components/elements/Image";
import img from "../../../assets/images/cards/card-inside-2.webp";
import { orderDetails } from "../../../services/orderService";
import { useParams } from "react-router-dom";
import logo from "../../../assets/images/logo.png";
import html2pdf from "html2pdf.js";
import moment from "moment";
import { settingsDetails } from "../../../services/sitesettingService";
import PageLoader from "../../../components/common/PageLoader";

const OrderDetails = () => {
  const { id } = useParams();
  const [detailsData, setDetailsData] = useState({});
  const [imageLoaded, setImageLoaded] = useState(false);
  const pdfRef = useRef();
  const [logoData, setLogoData] = useState({});

  const getDetails = useCallback(() => {
    setDetailsData({ loading: true, data: {} });
    orderDetails(id).then((res) => {
      if (res?.status === 200) {
        setDetailsData({
          loading: false,
          data: res?.docs,
          name: JSON.parse(res?.docs?.user_info)?.name,
          email: JSON.parse(res?.docs?.user_info)?.email,
          contact_no: JSON.parse(res?.docs?.user_info)?.contact_no,
          location: JSON.parse(res?.docs?.user_info)?.address_info
            ?.shipping_address?.location,
          state: JSON.parse(res?.docs?.user_info)?.address_info
            ?.shipping_address?.state,
          city: JSON.parse(res?.docs?.user_info)?.address_info?.shipping_address
            ?.city,
          pincode: JSON.parse(res?.docs?.user_info)?.address_info
            ?.shipping_address?.pincode,
        });
      }
      setDetailsData({
        loading: false,
        data: res?.docs,
        name: JSON.parse(res?.docs?.user_info)?.name,
        email: JSON.parse(res?.docs?.user_info)?.email,
        contact_no: JSON.parse(res?.docs?.user_info)?.contact_no,
        location: JSON.parse(res?.docs?.user_info)?.address_info
          ?.shipping_address?.location,
        state: JSON.parse(res?.docs?.user_info)?.address_info?.shipping_address
          ?.state,
        city: JSON.parse(res?.docs?.user_info)?.address_info?.shipping_address
          ?.city,
        pincode: JSON.parse(res?.docs?.user_info)?.address_info
          ?.shipping_address?.pincode,
      });
    });
  }, []);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  const getSettingDetails = useCallback(() => {
    setLogoData({ loading: true, data: {} });
    settingsDetails().then((res) => {
      if (res?.status === 200) {
        setLogoData({ loading: true, data: res?.docs?.logoUrl });
      }
    });
  }, []);

  useEffect(() => {
    getSettingDetails();
  }, [getSettingDetails]);

  const downloadPdf = () => {
    const element = pdfRef.current;
    var opt = {
      margin: 0.4,
      filename: "Order_Invoice.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };
    if (element) {
      html2pdf(element, opt);
    }
  };

  return (
    <>
      {detailsData?.loading ? (
        <PageLoader />
      ) : (
        <div>
          <div className="md:flex block space-x-6 mt-5">
            <Button
              buttonClasses={
                "!bg-white !p-1 !text-gray-600 !text-2xl hover:!text-slate-900 h-10 !px-2 flex items-center justify-center transition-all duration-200 !border !border-gray-300 !w-14"
              }
              buttonLabelClasses={""}
              buttonIcon={"fa-solid fa-arrow-left"}
              buttonIconPosition={"left"}
              buttonHasLink={true}
              buttonLink={"/admin/order-list"}
            />
          </div>

          <div className="md:flex block w-full bg-white border p-5 rounded-xl shadow-md mt-8">
            <div className="w-full">
              <div className="md:text-2xl text-xl text-black font-bold">
                Order#
                {detailsData?.data?.order_id
                  ? detailsData?.data?.order_id
                  : null}
              </div>
              <div className="w-32 rounded-md mt-3 text-base bg-blue-100 text-blue-600 flex justify-center items-center">
                {detailsData?.data?.status === 1
                  ? "Processing"
                  : detailsData?.data?.status === 2
                  ? "Accepted"
                  : detailsData?.data?.status === 3
                  ? "Out for delivery"
                  : detailsData?.data?.status === 4
                  ? "Ready for ship"
                  : detailsData?.data?.status === 5
                  ? "Cancel"
                  : ""}
              </div>
            </div>
            <div className="w-full flex justify-end space-x-4 md:mt-2 mt-4">
              <Button
                buttonClasses={
                  "!bg-white !p-1 !text-black !text-2xl hover:!text-slate-900 h-10 !px-4 flex items-center justify-center transition-all duration-200 !border !border-gray-300"
                }
                buttonLabel={"Download"}
                buttonLabelClasses={""}
                buttonIcon={"fa-solid fa-file-pdf"}
                buttonIconPosition={"left"}
                buttonHasLink={false}
                buttonFunction={downloadPdf}
              />
            </div>
          </div>

          <div className="flex flex-col mt-8 gap-6 md:flex-row xl:flex-nowrap md:flex-wrap">
            <div className="w-full rounded-md bg-white shadow-md p-6 text-gray-600 font-bold space-y-4 xl:w-1/3 md:w-5/12">
              <h3 className="text-xl text-black uppercase">
                Customer and Order
              </h3>
              {/* <div className="flex"> */}
              <div className="space-y-2">
                <div className="flex 3xl:space-x-2">
                  <div className="text-gray-800 3xl:w-5/12 w-4/6">Name :</div>
                  <div className="text-gray-600 font-medium w-full">
                    {detailsData?.name ? detailsData.name : "-"}
                  </div>
                </div>
                <div className="flex 3xl:space-x-2">
                  <div className="text-gray-800 3xl:w-5/12 w-4/6">Email :</div>
                  <div className="text-gray-600 font-medium w-full break-words">
                    {detailsData?.email ? detailsData.email : "-"}
                  </div>
                </div>
                <div className="flex 3xl:space-x-2">
                  <div className="text-gray-800 3xl:w-5/12 w-4/6">Phone :</div>
                  <div className="text-gray-600 font-medium w-full">
                    {detailsData?.contact_no ? detailsData.contact_no : "-"}
                  </div>
                </div>
                <div className="flex 3xl:space-x-2">
                  <div className="text-gray-800 3xl:w-5/12 w-4/6">PO :</div>
                  <div className="text-gray-600 font-medium w-full">
                    {detailsData?.pincode ? detailsData.pincode : "-"}
                  </div>
                </div>
                {/* <div className="text-gray-800">Name :</div>
                <div className="text-gray-800">Email :</div>
                <div className="text-gray-800">Phone :</div>
                <div className="text-gray-800">PO :</div> */}
              </div>
              {/* <div className="pl-6 space-y-2">
                <div className="text-gray-600 font-medium">
                  {detailsData?.name ? detailsData.name : "-"}
                </div>
                <div className="text-gray-600 font-medium">
                  {detailsData?.email ? detailsData.email : "-"}
                </div>
                <div className="text-gray-600 font-medium">
                  {detailsData?.contact_no ? detailsData.contact_no : "-"}
                </div>
                <div className="text-gray-600 font-medium">
                  {detailsData?.pincode ? detailsData.pincode : "-"}
                </div>
              </div> */}
              {/* </div> */}
            </div>

            <div className="w-full rounded-md bg-white shadow-md p-6 text-gray-600 font-bold xl:w-1/3  md:w-6/12 space-y-4">
              <h3 className="text-xl text-black uppercase">Shipping Address</h3>

              <div className=" space-y-2">
                <div className="flex 3xl:space-x-2">
                  <div className="text-gray-800 3xl:w-5/12 w-4/6">
                    Address :
                  </div>
                  <div className="text-gray-600 font-medium w-full">
                    {detailsData.location || ""}
                  </div>
                </div>
                <div className="flex 3xl:space-x-2">
                  <div className="text-gray-800 3xl:w-5/12 w-4/6">City :</div>
                  <div className="text-gray-600 font-medium w-full">
                    {detailsData.city || ""}
                  </div>
                </div>
                <div className="flex 3xl:space-x-2">
                  <div className="text-gray-800 3xl:w-5/12 w-4/6">State :</div>
                  <div className="text-gray-600 font-medium w-full">
                    {detailsData.state || ""}
                  </div>
                </div>
                <div className="flex 3xl:space-x-2">
                  <div className="text-gray-800 3xl:w-5/12 w-4/6">
                    Postal code :
                  </div>
                  <div className="text-gray-600 font-medium w-full">
                    {detailsData?.pincode || ""}
                  </div>
                </div>

                <div className="pl-6 space-y-2"></div>
              </div>
            </div>

            <div className="w-full rounded-md bg-white shadow-md p-6 text-gray-600 font-bold xl:w-1/3 md:w-6/12 space-y-4">
              <h3 className="text-xl text-black uppercase">Billing Address</h3>

              <div className=" space-y-2">
                <div className="flex 3xl:space-x-2">
                  <div className="text-gray-800 3xl:w-5/12 w-4/6">
                    Address :
                  </div>
                  <div className="text-gray-600 font-medium w-full">
                    {detailsData.location || ""}
                  </div>
                </div>
                <div className="flex 3xl:space-x-4">
                  <div className="text-gray-800 3xl:w-5/12 w-4/6">City :</div>
                  <div className="text-gray-600 font-medium w-full">
                    {detailsData.city || ""}
                  </div>
                </div>
                <div className="flex 3xl:space-x-4">
                  <div className="text-gray-800 3xl:w-5/12 w-4/6">State :</div>
                  <div className="text-gray-600 font-medium w-full">
                    {detailsData.state || ""}
                  </div>
                </div>
                <div className="flex 3xl:space-x-4">
                  <div className="text-gray-800 3xl:w-5/12 w-4/6">
                    Postal code :
                  </div>
                  <div className="text-gray-600 font-medium w-full">
                    {detailsData?.pincode || ""}
                  </div>
                </div>

                <div className="pl-6 space-y-2"></div>
              </div>
            </div>
          </div>

          <div className="overflow-x-auto shadow-md rounded-lg mt-10">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50 border-b border-gray-400">
                <tr className="border-b border-gray-400 bg-white">
                  <th
                    colspan="6"
                    className="px-6 py-3 text-left text-2xl text-gray-600 font-bold tracking-wider boder-b border-gray-800 capitalize"
                  >
                    Items <span className="font-bold text-red-600">Order</span>
                  </th>
                </tr>

                <tr>
                  <th className="px-6 py-3 text-left text-base text-gray-600 font-bold uppercase tracking-wider">
                    Items Name
                  </th>
                  <th className="px-6 py-3 text-left text-base text-gray-600 font-bold uppercase tracking-wider">
                    Order Id
                  </th>
                  <th className="px-6 py-3  text-center text-base text-gray-600 font-bold uppercase tracking-wider">
                    Quantity
                  </th>
                  <th className="px-6 py-3  text-center text-base text-gray-600 font-bold uppercase tracking-wider">
                    Actual Price
                  </th>
                  <th className="px-6 py-3  text-center text-base text-gray-600 font-bold uppercase tracking-wider">
                    Total Price
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200 ">
                {detailsData?.data?.orderDetailsInfo?.map((item) => (
                  <>
                    <tr>
                      <td className="md:px-6 md:py-4 px-4 py-2 whitespace-nowrap text-sm font-semibold text-gray-900">
                        <div className="flex items-center">
                          <div className="w-14 h-14 overflow-hidden rounded-lg">
                            <Image
                              src={item?.productImageUrl}
                              // src={img}
                              alt=""
                              effect="blur"
                              className="w-full h-full object-cover"
                            />
                          </div>
                          <div className="md:px-6 md:py-4 px-4 py-2 whitespace-nowrap md:text-lg text-base text-gray-600 font-bold">
                            {item?.product_name || ""}
                          </div>
                        </div>
                      </td>
                      <td className="md:px-6 md:py-4 px-4 py-2 whitespace-nowrap text-base text-gray-700 font-semibold xl:text-start text-center">
                        #{item?.order_id || ""}
                      </td>
                      <td className="md:px-6 md:py-4 px-4 py-2 whitespace-nowrap text-base text-gray-700 font-semibold text-center">
                        {item?.quantity || ""}
                      </td>
                      <td className="md:px-6 md:py-4 px-4 py-2 whitespace-nowrap text-base text-gray-700 font-semibold text-center">
                        {item?.actual_price || ""}
                      </td>
                      <td className="md:px-6 md:py-4 px-4 py-2 whitespace-nowrap text-base text-gray-700 font-semibold text-center">
                        {item?.total_price || ""}
                      </td>
                    </tr>
                  </>
                ))}

                <tr className="!border-t-1 !border-slate-400 ">
                  <td colSpan="3"></td>
                  <td className="md:px-6 md:pt-4 px-4 pt-2 whitespace-nowrap text-lg text-gray-700 font-bold text-end">
                    Sub Total
                  </td>

                  <td className="md:px-6 md:pt-4 px-4 pt-2 whitespace-nowrap text-lg text-gray-500 font-semibold text-center">
                    {" "}
                    {detailsData?.data?.total_price || ""}
                  </td>
                </tr>
                <tr className="!border-0">
                  <td colSpan="3"></td>
                  <td className="md:px-6 md:py-2 px-4 py-2 whitespace-nowrap text-lg text-gray-700 font-bold text-end">
                    vat Charges
                  </td>

                  <td className="md:px-6 md:py-2 px-4 py-2 whitespace-nowrap text-lg text-gray-500 font-semibold text-center">
                    {" "}
                    {detailsData?.data?.total_price || ""}
                  </td>
                </tr>
                <tr className="!border-0">
                  <td colSpan="3"></td>
                  <td className="md:px-6 md:pb-4 px-4 pb-2 whitespace-nowrap text-lg text-gray-700 font-bold text-end">
                    Delivery Charges
                  </td>

                  <td className="md:px-6 md:pb-4 px-4 pb-2 whitespace-nowrap text-lg text-gray-500 font-semibold text-center">
                    {" "}
                    {detailsData?.data?.delivery_charge || ""}
                  </td>
                </tr>
                <tr className=" !border-t-2 !border-slate-400">
                  <td colSpan="3"></td>

                  <td className="md:pe-4 ps-0 md:py-4 pe-4 py-2 whitespace-nowrap text-2xl text-gray-700 font-bold text-end">
                    Grand Total
                  </td>

                  <td className="md:px-6 md:py-4 px-4 py-2 whitespace-nowrap text-2xl text-gray-500 font-semibold text-center !border-slate-400  !border-t-2">
                    {" "}
                    {detailsData?.data?.total_price || ""}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="hidden">
            <div ref={pdfRef}>
              <div
                className="max-w-3xl mx-auto p-6 bg-white rounded shadow-sm my-6"
                id="invoice"
              >
                <div className="grid grid-cols-2 items-center">
                  <div className="w-24 h-24 overflow-hidden">
                    {/* <Image
                    src={logoData?.data}
                    alt=""
                    effect="blur"
                    className="w-full h-full object-cover filter invert"
                  /> */}
                    <img src={logo} alt="Logo" />
                  </div>

                  <div className="text-right">
                    <p className="text-black font-bold text-lg">
                      {detailsData?.name ? detailsData.name : ""}
                    </p>
                    <p className="text-gray-500 text-lg">
                      {detailsData?.email ? detailsData.email : ""}
                    </p>
                    <p className="text-gray-500 text-lg mt-1">
                      {detailsData?.contact_no ? detailsData.contact_no : ""}
                    </p>
                  </div>
                </div>

                <div className="grid grid-cols-2 items-center mt-8">
                  <div>
                    <p className="font-bold text-black text-lg">Bill To :</p>
                    <p className="text-gray-500 text-lg">
                      {detailsData.location || ""}
                      <br />
                      {detailsData.city || ""}
                      {detailsData.state || ""}
                    </p>
                    <p className="text-gray-500 text-lg">
                      {detailsData?.pincode || ""}
                    </p>
                  </div>

                  <div className="text-right text-lg">
                    <p className="text-black font-bold ">
                      Order Id:
                      <span className="text-gray-500">
                        #
                        {detailsData?.data?.order_id
                          ? detailsData?.data?.order_id
                          : ""}
                      </span>
                    </p>
                    <p className="text-black font-bold">
                      Order Date:{" "}
                      <span className="text-gray-500">
                        {moment(detailsData?.data?.created_at).format(
                          "DD/MM/YYYY"
                        ) || ""}
                      </span>
                      <br />
                    </p>
                  </div>
                </div>

                <div className="-mx-4 mt-8 flow-root sm:mx-0">
                  <table className="min-w-full">
                    <thead className="border-b border-gray-400 text-gray-900">
                      <tr>
                        <th
                          scope="col"
                          className="py-2 pl-2 pr-2 text-left text-lg font-bold text-gray-900 sm:pl-0"
                        >
                          Items Name
                        </th>
                        <th
                          scope="col"
                          className="hidden px-2 py-2 text-center text-lg font-bold text-gray-900 sm:table-cell"
                        >
                          Quantity
                        </th>
                        <th
                          scope="col"
                          className="hidden px-2 py-2 text-right text-lg font-bold text-gray-900 sm:table-cell"
                        >
                          Price
                        </th>
                        <th
                          scope="col"
                          className="py-2 pl-2 pr-2 text-right text-lg font-bold text-gray-900 sm:pr-0"
                        >
                          Amount
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {detailsData?.data?.orderDetailsInfo?.map((item) => (
                        <tr
                          className="border-b border-gray-400"
                          key={item?.order_id}
                        >
                          <td className="max-w-0 py-3 pl-2 pr-2 text-sm sm:pl-0">
                            <div className="font-bold text-gray-900">
                              {item?.product_name || ""}
                            </div>
                          </td>
                          <td className="hidden px-2 py-3 text-center text-sm text-gray-500 sm:table-cell">
                            {item?.quantity || ""}
                          </td>
                          <td className="hidden px-2 py-3 text-right text-sm text-gray-500 sm:table-cell">
                            {item?.actual_price || ""}
                          </td>
                          <td className="py-3 pl-2 pr-2 text-right text-sm text-gray-500 sm:pr-0">
                            {item?.total_price || ""}
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <th
                          scope="row"
                          colspan="3"
                          className="hidden pl-2 pr-2 pt-4 text-right text-sm font-normal text-gray-500 sm:table-cell sm:pl-0"
                        >
                          Sub Total
                        </th>
                        <th
                          scope="row"
                          className="pl-4 pr-2 pt-4 text-left text-sm font-normal text-gray-500 sm:hidden"
                        >
                          Sub Total
                        </th>
                        <td className="pl-2 pr-2 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-0">
                          {detailsData?.data?.total_price || ""}
                        </td>
                      </tr>

                      <tr>
                        <th
                          scope="row"
                          colspan="3"
                          className="hidden pl-2 pr-2 pt-2 text-right text-sm font-normal text-gray-500 sm:table-cell sm:pl-0"
                        >
                          VAT Charges
                        </th>
                        <th
                          scope="row"
                          className="pl-4 pr-2 pt-2 text-left text-sm font-normal text-gray-500 sm:hidden"
                        >
                          VAT Charges
                        </th>
                        <td className="pl-2 pr-2 pt-2 text-right text-sm text-gray-900 sm:pr-0">
                          {detailsData?.data?.vat_charge || ""}
                        </td>
                      </tr>
                      <tr>
                        <th
                          scope="row"
                          colspan="3"
                          className="hidden pl-2 pr-2 pt-2 text-right text-sm font-normal text-gray-500 sm:table-cell sm:pl-0"
                        >
                          Delivery Charges
                        </th>
                        <th
                          scope="row"
                          className="pl-4 pr-2 pt-2 text-left text-sm font-normal text-gray-500 sm:hidden"
                        >
                          Delivery Charges
                        </th>
                        <td className="pl-2 pr-2 pt-2 text-right text-sm text-gray-900 sm:pr-0">
                          {detailsData?.data?.delivery_charge || ""}
                        </td>
                      </tr>
                      <tr>
                        <th
                          scope="row"
                          colspan="3"
                          className="hidden pl-2 pr-2 pt-2 text-right text-lg font-bold text-gray-900 sm:table-cell sm:pl-0 "
                        >
                          GRAND TOTAL
                        </th>
                        <th
                          scope="row"
                          className="pl-4 pr-2 pt-2 text-left text-lg font-bold text-gray-900 sm:hidden "
                        >
                          GRAND TOTAL
                        </th>
                        <td className="pl-2 pr-2 pt-2 text-right text-lg text-gray-900 sm:pr-0 font-bold">
                          {detailsData?.data?.total_price || ""}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OrderDetails;
