import React, { useCallback, useEffect, useState } from "react";
import Image from "../elements/Image";
import img from "../../assets/images/cards/card-inside-3.webp";
import profileImg from "../../assets/images/userProfile.jpg";
import Button from "../form/Button";
import LogoutModal from "../elements/logoutModal/LogoutModal";
import {
  editProfile,
  getProfileDetails,
} from "../../services/frontService/ProfileService";
import { useDispatch } from "react-redux";
import { setProfileDetails } from "../../redux/slice/profileSlice,";
import { useNavigate } from "react-router-dom";
import Indentification from "../elements/Indentification";
import { initialsValue } from "../../helpers";
import { useSelector } from "react-redux";
import UploadProfileImage from "../form/UploadProfileImage";
import { toast } from "react-toastify";

const ProfileSidebar = ({ selectedOption, setSelectedOption = () => {} }) => {
  const [isOpen, setOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [profileDetailsData, setProfileDetailsData] = useState({});
  const profile = useSelector((state) => state.profile.profileData);
  const handleClick = (option) => {
    setSelectedOption(option);
    navigate(`/${option}`);
  };

  const getBackgroundClass = (option) => {
    return selectedOption === option
      ? "bg-red-600 text-white"
      : "bg-slate-100 text-black hover:!border-red-600 hover:shadow-md hover:text-red-600";
  };
  const getDetails = useCallback(() => {
    setProfileDetailsData({ loading: true, data: {} });
    getProfileDetails().then((res) => {
      if (res?.status === 200) {
        setProfileDetailsData({
          loading: true,
          data: {
            ...res?.data,
            role: "USER",
          },
        });
        setImage(res?.data?.image_name_url);
        dispatch(setProfileDetails({ ...res?.data, role: "USER" }));
      } else {
        setProfileDetailsData({});
        dispatch(setProfileDetails({}));
      }
    });
  }, []);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  const [image, setImage] = useState();

  const removeImage = () => {
    // try {
    //   if (values?.image && values?.image instanceof File) {
    //     socket.emit("updateProfileEvent", { remove: true });
    //   } else {
    //     deleteUserImage().then((res) => {
    //       if (res?.status === 200) {
    //         socket.emit("updateProfileEvent", {
    //           res: { ...res, data: { ...res?.data, _id: session?.user?._id } },
    //           remove: true,
    //         });
    //       } else {
    //         toast.error(res?.message || res?.error);
    //       }
    //     });
    //   }
    // } catch (error) {
    //   toast.error(error);
    // }
  };

  const handleImageChange = async (value) => {
    setImage(value);
    try {
      const res = await editProfile({
        image_name: value,
        email: profileDetailsData?.data?.email,
        phone: profileDetailsData?.data?.phone,
        name: profileDetailsData?.data?.name,
      });

      if (res?.data?.status === 200 || res?.data?.status === 201) {
        dispatch(setProfileDetails({ ...res?.data?.data, role: "USER" }));
        toast.success("Image changed successfully");
      } else {
        toast.info(
          res?.data?.message || res?.data?.errors || "Something went wrong"
        );
        setImage(profileDetailsData?.data?.image_name_url);
      }
    } catch (err) {
      toast.error("Somthing went wrong !!!");
      setImage(profileDetailsData?.data?.image_name_url);
    }
  };

  return (
    <>
      <div className="mt-10">
        <div className="relative flex items-end  justify-center">
          <div className="">
            <UploadProfileImage
              name={profileDetailsData?.data?.name}
              value={image}
              accept={"image"}
              removeImage={removeImage}
              onChange={(e) => handleImageChange(e.target.files[0])}
            />
          </div>
        </div>
        <div className="text-center pt-4 pb-2">
          <div className="text-xl font-bold text-red-600">Hello!</div>
          <div className="text-black text-2xl font-semibold mt-1">
            {profile?.name}
          </div>

          <div className="text-sm font-semibold pt-1">
            Welcome to Your Profile
          </div>
        </div>
      </div>
      <div className="mt-8">
        <div className="flex flex-col gap-3">
          <div
            className={`md:text-xl text-lg px-4 py-1 rounded-md border-s-4 ${getBackgroundClass(
              "user-profile"
            )} cursor-pointer`}
            onClick={() => handleClick("user-profile")}
          >
            <i className="fa-regular fa-circle-info"></i>
            <span className="pl-3">My Info</span>
          </div>
          <div
            className={`md:text-xl text-lg px-4 py-1 rounded-md border-s-4 ${getBackgroundClass(
              "user-orders"
            )} cursor-pointer`}
            onClick={() => handleClick("user-orders")}
          >
            <i className="fa-regular fa-bag-shopping"></i>
            <span className="pl-3">My Orders</span>
          </div>
          <div
            className={`md:text-xl text-lg px-4 py-1 rounded-md border-s-4 ${getBackgroundClass(
              "user-wishlists"
            )} cursor-pointer`}
            onClick={() => handleClick("user-wishlists")}
          >
            <i className="fa-regular fa-heart"></i>
            <span className="pl-3">Wishlist</span>
          </div>
          <div
            className={`md:text-xl text-lg px-4 py-1 rounded-md border-s-4 ${getBackgroundClass(
              "user-address"
            )} cursor-pointer`}
            onClick={() => handleClick("user-address")}
          >
            <i className="fa-regular fa-address-card"></i>
            <span className="pl-3">My Address</span>
          </div>

          <div
            className={`md:text-xl text-lg px-4 py-1 rounded-md border-s-4 ${getBackgroundClass(
              "Logout"
            )} cursor-pointer`}
            onClick={() => setOpen(true)}
          >
            <i className="fa-regular fa-right-from-bracket"></i>
            <span className="pl-3">Logout</span>
          </div>
        </div>
      </div>
      <LogoutModal isOpen={isOpen} setOpen={setOpen} />
    </>
  );
};

export default ProfileSidebar;
