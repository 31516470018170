import React from "react";
import { classNames } from "../../helpers/classNames";

const ProductDescription = ({
  quickView,
  details,
  scrollHandler = () => {},
}) => {
  return (
    <>
      <section className="sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto">
        <div className="text-start w-full">
          <div
            className={classNames(
              "text-red-600   py-2  font-bold",
              quickView
                ? "text-2xl md:pt-0 pt-5"
                : "text-2xl md:text-4xl xl:pt-0 pt-10"
            )}
          >
            {details?.data?.title || ""}
          </div>
          <h2 className="md:text-xl text-sm text-gray-800 font-bold">
            {details?.data?.productCategoryInfo?.parent_category || ""}
          </h2>

          <div
            className={classNames(
              " text-grey-600",
              quickView ? "text-lg" : "md:text-2xl text-base"
            )}
          >
            {details?.data?.productCategoryInfo?.category_name || ""}
          </div>
          <div
            className="md:text-md text-md text-red-500 font-bold"
          > 
            {"PACK OF " + " "+details?.data?.minimum_package_quantity || ""}
          </div>
          <h2
            className="md:text-lg text-sm text-gray-600"
            onClick={() => scrollHandler("description")}
          >
            {details?.data?.short_description || ""}
          </h2>
        </div>
      </section>
    </>
  );
};

export default ProductDescription;
