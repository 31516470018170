import React, { useCallback, useEffect, useState } from "react";
import RecentOrderTable from "../../../components/Items/AdminPanelItems/RecentOrderTable";
import { getOrderList } from "../../../services/orderService";

// const data = [
//   { id: "1", name: "John Doe", date: "8/7/24", Price: "CAD 30", isLink: true, link: "/admin/order-details" },
//   { id: "2", name: "John Doe", date: "8/7/24", Price: "CAD 20", isLink: true, link: "/admin/order-details" },
//   { id: "3", name: "John Doe", date: "8/7/24", Price: "CAD 10", isLink: true, link: "/admin/order-details" },
//   { id: "4", name: "John Doe", date: "8/7/24", Price: "CAD 00", isLink: true, link: "/admin/order-details" },
// ];

const RecentOrders = () => {
  const [list, setList] = useState({ data: [] });
  const loadList = useCallback(() => {
    setList((pre) => ({
      ...pre,
      data: [],
      loading: true,
    }));
    getOrderList({
      perPage: 10,
    }).then((res) => {
      if (res && res?.docs?.success) {
        setList({
          loading: false,
          data: res.docs?.data,
        });
      }
    });
  }, []);

  useEffect(() => {
    loadList();
  }, [loadList]);

  return (
    <>
      <div className="md:p-0 p-0 pt-0 md:pt-2">
        <RecentOrderTable data={list?.data} />
      </div>
    </>
  );
};

export default RecentOrders;
