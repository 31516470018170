import React, { useState } from "react";
import Image from "../../elements/Image";
import Review from "../Review";
import { Link } from "react-router-dom";
import Modal from "../../elements/Modal/Modal";
import OrderStatusChange from "../../../pages/adminPannel/order/OrderStatusChange";
import { UserDetailsOrderStatusChange } from "./UserDetailsOrderStatusChange";

const RecentProductItems = ({
  data,
  orderList,
  setOrderList = () => {},
  getOrderDetails = () => {},
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [statusDetails, setStatusDetails] = useState({});

  const getProgress = (status) => {
    switch (status) {
      case status:
        return status;
      default:
        return 0;
    }
  };

  const num = parseInt(data?.orderStatusInfo?.processing_parcentage);

  const progress = getProgress(num);

  //User Details Order list
  return (
    <>
      <div className="relative flex md:gap-6 gap-4 md:py-6 py-8">
        <div className="relative flex  w-full space-Y-10">
          <div className="relative w-full">
            <Link to={`/admin/order-details/${data?.order_id}`}>
              <div className="md:text-xl text-base font-bold capitalize text-slate-900 leading-none">
                #{data.order_id}
              </div>
            </Link>
            <div className="flex space-x-2 py-1 items-center cursor-pointer">
              {/* <div className="md:text-base text-sm">Status :</div> */}
              <div
                className="text-red-600 font-bold text-xl"
                onClick={() => {
                  setIsOpen(true);
                  setStatusDetails(data);
                }}
              >
                {data?.status === 1
                  ? "Processing"
                  : data?.status === 2
                  ? "Accepted"
                  : data?.status === 3
                  ? "Out for delivery"
                  : data?.status === 4
                  ? "Ready for ship"
                  : data?.status === 5
                  ? "Cancel"
                  : ""}
              </div>
            </div>
            <Link to={`/admin/order-details/${data?.order_id}`}>
              <div
                className=" font-bold capitalize text-slate-700"
                dangerouslySetInnerHTML={{
                  __html: data?.orderStatusInfo?.title || "N/A",
                }}
              ></div>
              <div className="md:text-xl text-base md:py-2 py-1 text-red-600 font-bold absolute md:top-0 top-16 right-0">
                CAD {data?.total_price}
              </div>
              <div className="md:text-xl text-base md:py-12 py-6 text-red-600 font-bold absolute md:top-0 top-16 right-0">
                VAT {data.vat_charge}
              </div>
            </Link>
            <Link to={`/admin/order-details/${data?.order_id}`}>
              <div className="relative mt-4">
                <div className="relative w-full bg-gray-200 rounded-full h-2.5">
                  <div
                    className="absolute top-0 left-0 h-2.5 bg-red-600 rounded-full"
                    style={{ width: `${progress}%` }}
                  ></div>
                </div>
                <div className="text-xs text-slate-600 mt-1">
                  {progress}% Complete
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>

      <Modal
        open={isOpen}
        title={`Order Maintainence for Order: ${statusDetails?.order_id}`}
        size="xl2"
      >
        <UserDetailsOrderStatusChange
          onClose={() => setIsOpen(false)}
          statusDetails={statusDetails}
          setStatusDetails={setStatusDetails}
          list={orderList}
          setList={setOrderList}
          getOrderDetails={getOrderDetails}
        />
      </Modal>
    </>
  );
};

export default RecentProductItems;
