import React from "react";
import { Route, Routes, BrowserRouter, Switch } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import DefaultLayout from "./layout/DefaultLayout";
import Home from "./pages/Home";
import Lable from "./pages/Lable";
import Cards from "./pages/Cards";
import Testimonial from "./pages/Testimonial";
import AboutUs from "./pages/AboutUs";

import DetailsImageSection from "./components/sections/DetailsImageSection";
import ProductDetails from "./pages/ProductDetails";

import ShippingPolicy from "./pages/ShippingPolicy";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ContactUs from "./pages/ContactUs";
import Cart from "./pages/Cart";
import Profile from "./pages/Profile";
import Checkout from "./pages/Checkout";
import FavouritesAll from "./pages/FavouritesAll";
import ViewOrderDetails from "./pages/ViewOrderDetails";
import PaymentInformation from "./pages/PaymentInformation";
import InnerLayout from "./layout/InnerLayout";
import Dashboard from "./pages/adminPannel/dashboard/Dashboard";
import UserList from "./pages/adminPannel/user/UserList";
import AdminLogin from "./pages/adminPannel/login/AdminLogin";
import AddEditUser from "./pages/adminPannel/user/AddEditUser";
import FreeAuthRoute from "./routes/FreeAuthRoute";
import ProtectedRoute from "./routes/ProtectedRoute";
import PageNotFound from "./pages/PageNotFound";
import AdminAddEdit from "./pages/adminPannel/admin/AdminAddEdit";
import AdminList from "./pages/adminPannel/admin/AdminList";
import TestimonialList from "./pages/adminPannel/testimonial/TestimonialList";
import TestimonialAddEdit from "./pages/adminPannel/testimonial/TestimonialAddEdit";
import OrderDetails from "./pages/adminPannel/order/OrderDetails";
import CategoryList from "./pages/adminPannel/category/CategoryList";
import CategoryAddEdit from "./pages/adminPannel/category/CategoryAddEdit";
import RecentProductDetails from "./pages/adminPannel/dashboard/RecentProductDetails";
import FaqList from "./pages/adminPannel/faq/FaqList";
import FaqAddEdit from "./pages/adminPannel/faq/FaqAddEdit";
import RjsLabel from "./pages/RjsLabel";
import List from "./pages/adminPannel/product/List";
import { AddEditProduct } from "./pages/adminPannel/product/AddEditProduct";
import ReviewList from "./pages/adminPannel/review/reviewList";
import OrderList from "./pages/adminPannel/order/OrderList";
import EditSiteSettings from "./pages/adminPannel/siteSettings/EditSiteSettings";
import Login from "./pages/Login";
import UserDetails from "./pages/adminPannel/dashboard/UserDetails";
import PagesList from "./pages/adminPannel/pages/PagesList";
import EnquiryList from "./pages/adminPannel/enquiry/EnquiryList";
import MyInfo from "./pages/MyInfo";
import MyWishlists from "./pages/MyWishLists";
import MyOrder from "./pages/MyOrder";
import MyAdress from "./pages/MyAdress";
import { AddEditPage } from "./pages/adminPannel/pages/AddEditPage";
import Faq from "./pages/Faq";
import { OrderReportList } from "./pages/adminPannel/order/OrderReportList";
import { VatList } from "./pages/adminPannel/vat/VatList";
import { AddEditVat } from "./pages/adminPannel/vat/AddEditVat";
import { AdminProfile } from "./pages/adminPannel/profile/AdminProfile";
import EmailTemplateList from "./pages/adminPannel/emailtemplate/EmailTemplateList";
import { AddEditEmailTemplate } from "./pages/adminPannel/emailtemplate/AddEditEmailTemplate";
import AdminSendOtp from "./pages/adminPannel/sendotp/AdminSendOtp";
import AdminForgotPassword from "./pages/adminPannel/forgotpassword/AdminForgotPassword";
import { AdminDetailsModal } from "./pages/adminPannel/admin/AdminDetailsModal";
import { PageDetails } from "./pages/adminPannel/pages/PageDetails";

const CreateRoutes = () => {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route path="/login">
            <Route
              path="/login"
              exact
              element={
                <FreeAuthRoute title={"User Login"}>
                  <Login />
                </FreeAuthRoute>
              }
            />
          </Route>
          <Route path="/" element={<DefaultLayout />}>
            <Route index element={<Home />} />
          </Route>
          <Route path="/label/:slug" element={<DefaultLayout />}>
            <Route index element={<Lable />} />
          </Route>
          <Route path="/rjs-label/:slug" element={<DefaultLayout />}>
            <Route index element={<RjsLabel />} />
          </Route>
          <Route path="/cards" element={<DefaultLayout />}>
            <Route index element={<Cards />} />
          </Route>
          <Route path="/testimonial" element={<DefaultLayout />}>
            <Route index element={<Testimonial />} />
          </Route>
          <Route path="/aboutus" element={<DefaultLayout />}>
            <Route index element={<AboutUs />} />
          </Route>

          <Route path="/shippingpolicy" element={<DefaultLayout />}>
            <Route index element={<ShippingPolicy />} />
          </Route>
          <Route path="/privacypolicy" element={<DefaultLayout />}>
            <Route index element={<PrivacyPolicy />} />
          </Route>
          <Route path="/faq" element={<DefaultLayout />}>
            <Route index element={<Faq />} />
          </Route>
          <Route path="/productdetails/:slug" element={<DefaultLayout />}>
            <Route index element={<ProductDetails />} />
          </Route>
          <Route path="/contactus" element={<DefaultLayout />}>
            <Route index element={<ContactUs />} />
          </Route>
          <Route
            path="/cart"
            element={
              // <ProtectedRoute title={"User  Cart"} getAccess={["USER"]}>
              <DefaultLayout />
              // </ProtectedRoute>
            }>
            <Route
              index
              element={
                <ProtectedRoute title={"User  Cart"} getAccess={["USER"]}>
                  <Cart />
                </ProtectedRoute>
              }
            />
          </Route>
          {/* <Route path="/profile" element={<DefaultLayout />}>
            <Route index element={<Profile />} />
          </Route> */}
          <Route
            path="/checkout"
            element={
              // <ProtectedRoute title={"User Checkout"} getAccess={["USER"]}>
              <DefaultLayout />
              // </ProtectedRoute>
            }>
            <Route
              index
              element={
                <ProtectedRoute title={"User Checkout"} getAccess={["USER"]}>
                  <Checkout />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/favouritesall" element={<DefaultLayout />}>
            <Route index element={<FavouritesAll />} />
          </Route>

          <Route path="/paymentinformation" element={<DefaultLayout />}>
            <Route index element={<PaymentInformation />} />
          </Route>
          <Route path="*">
            <Route path="*" element={<PageNotFound />} />
          </Route>

          <Route
            path="/user-profile"
            element={
              // <ProtectedRoute title={"User Profile"} getAccess={["USER"]}>
              <DefaultLayout />
              // </ProtectedRoute>
            }>
            <Route
              index
              element={
                <ProtectedRoute title={"User Profile"} getAccess={["USER"]}>
                  <MyInfo />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/user-wishlists"
            element={
              // <ProtectedRoute title={"User Wishlists"} getAccess={["USER"]}>
              <DefaultLayout />
              // </ProtectedRoute>
            }>
            <Route
              index
              element={
                <ProtectedRoute title={"User Wishlists"} getAccess={["USER"]}>
                  <MyWishlists />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/user-orders"
            element={
              // <ProtectedRoute title={"User Orders"} getAccess={["USER"]}>
              <DefaultLayout />
              // </ProtectedRoute>
            }>
            <Route
              index
              element={
                <ProtectedRoute title={"User Orders"} getAccess={["USER"]}>
                  <MyOrder />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/user-address"
            element={
              // <ProtectedRoute title={"User Address"} getAccess={["USER"]}>
              <DefaultLayout />
              // </ProtectedRoute>
            }>
            <Route
              index
              element={
                <ProtectedRoute title={"User Address"} getAccess={["USER"]}>
                  <MyAdress />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/order-details/:slug"
            element={
              // <ProtectedRoute title={"Order Details"} getAccess={["USER"]}>
              <DefaultLayout />
              // </ProtectedRoute>
            }>
            <Route
              index
              element={
                <ProtectedRoute title={"Order Details"} getAccess={["USER"]}>
                  <ViewOrderDetails />
                </ProtectedRoute>
              }
            />
          </Route>

          {/* Admin Routing */}
          <Route path="/admin">
            <Route
              path="/admin"
              exact
              element={
                <FreeAuthRoute title={"Admin Login"}>
                  <AdminLogin />
                </FreeAuthRoute>
              }
            />
          </Route>
          <Route path="/admin/sendotp">
            <Route
              path="/admin/sendotp"
              exact
              element={
                <FreeAuthRoute title={"Admin Send OTP"}>
                  <AdminSendOtp />
                </FreeAuthRoute>
              }
            />
          </Route>
          <Route path="/admin/forgotpassword">
            <Route
              path="/admin/forgotpassword"
              exact
              element={
                <FreeAuthRoute title={"Admin Forgot Password"}>
                  <AdminForgotPassword />
                </FreeAuthRoute>
              }
            />
          </Route>
          <Route
            path="/admin/profile"
            element={
              <ProtectedRoute title={"Admin Dashboard"} getAccess={["ADMIN"]}>
                <InnerLayout />
              </ProtectedRoute>
            }>
            <Route
              path="/admin/profile"
              exact
              element={
                <ProtectedRoute title={"Admin Profile"} getAccess={["ADMIN"]}>
                  <AdminProfile />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/admin/dashboard"
            element={
              <ProtectedRoute title={"Admin Dashboard"} getAccess={["ADMIN"]}>
                <InnerLayout />
              </ProtectedRoute>
            }>
            <Route
              path="/admin/dashboard"
              exact
              element={
                <ProtectedRoute title={"Admin Dashboard"} getAccess={["ADMIN"]}>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/admin/admin-list"
            element={
              <ProtectedRoute title={"Admin List"} getAccess={["ADMIN"]}>
                <InnerLayout />
              </ProtectedRoute>
            }>
            <Route
              path="/admin/admin-list"
              exact
              element={
                <ProtectedRoute title={"Admin List"} getAccess={["ADMIN"]}>
                  <AdminList />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/admin/admin-add"
            element={
              <ProtectedRoute title={"Admin Add"} getAccess={["ADMIN"]}>
                <InnerLayout />
              </ProtectedRoute>
            }>
            <Route
              path="/admin/admin-add"
              exact
              element={
                <ProtectedRoute title={"Admin Add"} getAccess={["ADMIN"]}>
                  <AdminAddEdit />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/admin/admin-edit/:id"
            element={
              <ProtectedRoute title={"Admin Edit"} getAccess={["ADMIN"]}>
                <InnerLayout />
              </ProtectedRoute>
            }>
            <Route
              path="/admin/admin-edit/:id"
              exact
              element={
                <ProtectedRoute title={"Admin Edit"} getAccess={["ADMIN"]}>
                  <AdminAddEdit />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route
            path="/admin/user-list"
            element={
              <ProtectedRoute title={"User List"} getAccess={["ADMIN"]}>
                <InnerLayout />
              </ProtectedRoute>
            }>
            <Route
              path="/admin/user-list"
              exact
              element={
                <ProtectedRoute title={"User List"} getAccess={["ADMIN"]}>
                  <UserList />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/admin/user-add"
            element={
              <ProtectedRoute title={"User Add"} getAccess={["ADMIN"]}>
                <InnerLayout />
              </ProtectedRoute>
            }>
            <Route
              path="/admin/user-add"
              exact
              element={
                <ProtectedRoute title={"User Add"} getAccess={["ADMIN"]}>
                  <AddEditUser />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/admin/user-edit/:id"
            element={
              <ProtectedRoute title={"User Edit"} getAccess={["ADMIN"]}>
                <InnerLayout />
              </ProtectedRoute>
            }>
            <Route
              path="/admin/user-edit/:id"
              exact
              element={
                <ProtectedRoute title={"User Edit"} getAccess={["ADMIN"]}>
                  <AddEditUser />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/admin/testimonial-list"
            element={
              <ProtectedRoute title={"Testimonial List"} getAccess={["ADMIN"]}>
                <InnerLayout />
              </ProtectedRoute>
            }>
            <Route
              path="/admin/testimonial-list"
              exact
              element={
                <ProtectedRoute title={"Testimonial List"} getAccess={["ADMIN"]}>
                  <TestimonialList />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/admin/testimonial-add"
            element={
              <ProtectedRoute title={"Testimonial Add"} getAccess={["ADMIN"]}>
                <InnerLayout />
              </ProtectedRoute>
            }>
            <Route
              path="/admin/testimonial-add"
              exact
              element={
                <ProtectedRoute title={"Testimonial Add"} getAccess={["ADMIN"]}>
                  <TestimonialAddEdit />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/admin/testimonial-edit/:id"
            element={
              <ProtectedRoute title={"Testimonial Edit"} getAccess={["ADMIN"]}>
                <InnerLayout />
              </ProtectedRoute>
            }>
            <Route
              path="/admin/testimonial-edit/:id"
              exact
              element={
                <ProtectedRoute title={"Testimonial Edit"} getAccess={["ADMIN"]}>
                  <TestimonialAddEdit />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/admin/category-list"
            element={
              <ProtectedRoute title={"Category List"} getAccess={["ADMIN"]}>
                <InnerLayout />
              </ProtectedRoute>
            }>
            <Route
              path="/admin/category-list"
              exact
              element={
                <ProtectedRoute title={"Category List"} getAccess={["ADMIN"]}>
                  <CategoryList />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/admin/category-add"
            element={
              <ProtectedRoute title={"Category Add"} getAccess={["ADMIN"]}>
                <InnerLayout />
              </ProtectedRoute>
            }>
            <Route
              path="/admin/category-add"
              exact
              element={
                <ProtectedRoute title={"Category Add"} getAccess={["ADMIN"]}>
                  <CategoryAddEdit />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/admin/category-edit/:id"
            element={
              <ProtectedRoute title={"Category Edit"} getAccess={["ADMIN"]}>
                <InnerLayout />
              </ProtectedRoute>
            }>
            <Route
              path="/admin/category-edit/:id"
              exact
              element={
                <ProtectedRoute title={"Category Edit"} getAccess={["ADMIN"]}>
                  <CategoryAddEdit />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/admin/product-list"
            element={
              <ProtectedRoute title={"Product List"} getAccess={["ADMIN"]}>
                <InnerLayout />
              </ProtectedRoute>
            }>
            <Route
              path="/admin/product-list"
              exact
              element={
                <ProtectedRoute title={"Product List"} getAccess={["ADMIN"]}>
                  <List />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/admin/product-add"
            element={
              <ProtectedRoute title={"Product Add"} getAccess={["ADMIN"]}>
                <InnerLayout />
              </ProtectedRoute>
            }>
            <Route
              path="/admin/product-add"
              exact
              element={
                <ProtectedRoute title={"Product Add"} getAccess={["ADMIN"]}>
                  <AddEditProduct />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/admin/product-edit/:id"
            element={
              <ProtectedRoute title={"Product Edit"} getAccess={["ADMIN"]}>
                <InnerLayout />
              </ProtectedRoute>
            }>
            <Route
              path="/admin/product-edit/:id"
              exact
              element={
                <ProtectedRoute title={"Product Edit"} getAccess={["ADMIN"]}>
                  <AddEditProduct />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/admin/review-list"
            element={
              <ProtectedRoute title={"Review List"} getAccess={["ADMIN"]}>
                <InnerLayout />
              </ProtectedRoute>
            }>
            <Route
              path="/admin/review-list"
              exact
              element={
                <ProtectedRoute title={"Review List"} getAccess={["ADMIN"]}>
                  <ReviewList />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/admin/faq-list"
            element={
              <ProtectedRoute title={"Faq List"} getAccess={["ADMIN"]}>
                <InnerLayout />
              </ProtectedRoute>
            }>
            <Route
              path="/admin/faq-list"
              exact
              element={
                <ProtectedRoute title={"Faq List"} getAccess={["ADMIN"]}>
                  <FaqList />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/admin/faq-add"
            element={
              <ProtectedRoute title={"Faq Add"} getAccess={["ADMIN"]}>
                <InnerLayout />
              </ProtectedRoute>
            }>
            <Route
              path="/admin/faq-add"
              exact
              element={
                <ProtectedRoute title={"Faq Add"} getAccess={["ADMIN"]}>
                  <FaqAddEdit />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/admin/faq-edit/:id"
            element={
              <ProtectedRoute title={"Faq Edit"} getAccess={["ADMIN"]}>
                <InnerLayout />
              </ProtectedRoute>
            }>
            <Route
              path="/admin/faq-edit/:id"
              exact
              element={
                <ProtectedRoute title={"Faq Edit"} getAccess={["ADMIN"]}>
                  <FaqAddEdit />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/admin/order-list"
            element={
              <ProtectedRoute title={"Order List"} getAccess={["ADMIN"]}>
                <InnerLayout />
              </ProtectedRoute>
            }>
            <Route
              path="/admin/order-list"
              exact
              element={
                <ProtectedRoute title={"Order List"} getAccess={["ADMIN"]}>
                  <OrderList />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/admin/order-details/:id"
            element={
              <ProtectedRoute title={"Order Details"} getAccess={["ADMIN"]}>
                <InnerLayout />
              </ProtectedRoute>
            }>
            <Route
              path="/admin/order-details/:id"
              exact
              element={
                <ProtectedRoute title={"Order Details"} getAccess={["ADMIN"]}>
                  <OrderDetails />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/admin/order/report-list"
            element={
              <ProtectedRoute title={"Report List"} getAccess={["ADMIN"]}>
                <InnerLayout />
              </ProtectedRoute>
            }>
            <Route
              path="/admin/order/report-list"
              exact
              element={
                <ProtectedRoute title={"Report List"} getAccess={["ADMIN"]}>
                  <OrderReportList />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/admin/vat-list"
            element={
              <ProtectedRoute title={"Vat List"} getAccess={["ADMIN"]}>
                <InnerLayout />
              </ProtectedRoute>
            }>
            <Route
              path="/admin/vat-list"
              exact
              element={
                <ProtectedRoute title={"Vat List"} getAccess={["ADMIN"]}>
                  <VatList />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/admin/vat-add"
            element={
              <ProtectedRoute title={"Vat Add"} getAccess={["ADMIN"]}>
                <InnerLayout />
              </ProtectedRoute>
            }>
            <Route
              path="/admin/vat-add"
              exact
              element={
                <ProtectedRoute title={"Vat Add"} getAccess={["ADMIN"]}>
                  <AddEditVat />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/admin/vat-edit/:id"
            element={
              <ProtectedRoute title={"Vat Edit"} getAccess={["ADMIN"]}>
                <InnerLayout />
              </ProtectedRoute>
            }>
            <Route
              path="/admin/vat-edit/:id"
              exact
              element={
                <ProtectedRoute title={"Vat Edit"} getAccess={["ADMIN"]}>
                  <AddEditVat />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/admin/settings"
            element={
              <ProtectedRoute title={"Site Settings"} getAccess={["ADMIN"]}>
                <InnerLayout />
              </ProtectedRoute>
            }>
            <Route
              path="/admin/settings"
              exact
              element={
                <ProtectedRoute title={"Site Settings"} getAccess={["ADMIN"]}>
                  <EditSiteSettings />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/admin/product-details/:id"
            element={
              <ProtectedRoute title={"Product Details"} getAccess={["ADMIN"]}>
                <InnerLayout />
              </ProtectedRoute>
            }>
            <Route
              path="/admin/product-details/:id"
              exact
              element={
                <ProtectedRoute title={"Product Details"} getAccess={["ADMIN"]}>
                  <RecentProductDetails />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/admin/user-details/:id"
            element={
              <ProtectedRoute title={"User Details"} getAccess={["ADMIN"]}>
                <InnerLayout />
              </ProtectedRoute>
            }>
            <Route
              path="/admin/user-details/:id"
              exact
              element={
                <ProtectedRoute title={"User Details"} getAccess={["ADMIN"]}>
                  <UserDetails />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/admin/pages-list"
            element={
              <ProtectedRoute title={"pages List"} getAccess={["ADMIN"]}>
                <InnerLayout />
              </ProtectedRoute>
            }>
            <Route
              path="/admin/pages-list"
              exact
              element={
                <ProtectedRoute title={"Pages List"} getAccess={["ADMIN"]}>
                  <PagesList />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/admin/pages-edit/:id"
            element={
              <ProtectedRoute title={"Pages Edit"} getAccess={["ADMIN"]}>
                <InnerLayout />
              </ProtectedRoute>
            }>
            <Route
              path="/admin/pages-edit/:id"
              exact
              element={
                <ProtectedRoute title={"Pages Edit"} getAccess={["ADMIN"]}>
                  <AddEditPage />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/admin/pages-details/:id"
            element={
              <ProtectedRoute title={"Pages Details"} getAccess={["ADMIN"]}>
                <InnerLayout />
              </ProtectedRoute>
            }>
            <Route
              path="/admin/pages-details/:id"
              exact
              element={
                <ProtectedRoute title={"Pages Details"} getAccess={["ADMIN"]}>
                  <PageDetails />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/admin/enquiry-list"
            element={
              <ProtectedRoute title={"Enquiry List"} getAccess={["ADMIN"]}>
                <InnerLayout />
              </ProtectedRoute>
            }>
            <Route
              path="/admin/enquiry-list"
              exact
              element={
                <ProtectedRoute title={"Enquiry List"} getAccess={["ADMIN"]}>
                  <EnquiryList />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/admin/emailtemplate-list"
            element={
              <ProtectedRoute title={"Email Template List"} getAccess={["ADMIN"]}>
                <InnerLayout />
              </ProtectedRoute>
            }>
            <Route
              path="/admin/emailtemplate-list"
              exact
              element={
                <ProtectedRoute title={"Email Template List"} getAccess={["ADMIN"]}>
                  <EmailTemplateList />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/admin/emailtemplate-edit/:id"
            element={
              <ProtectedRoute title={"Email Template Edit"} getAccess={["ADMIN"]}>
                <InnerLayout />
              </ProtectedRoute>
            }>
            <Route
              path="/admin/emailtemplate-edit/:id"
              exact
              element={
                <ProtectedRoute title={"Email Template Edit"} getAccess={["ADMIN"]}>
                  <AddEditEmailTemplate />
                </ProtectedRoute>
              }
            />
          </Route>
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default CreateRoutes;
