import React, { useState } from "react";
import DetailsBreadcrumb from "../Items/DetailsBreadcrumb";
import ProductDescription from "../Items/ProductDescription";
import Review from "../Items/Review";
import PriceBuynow from "../Items/PriceBuynow";
import ProductQuantity from "../Items/ProductQuantity";
import { classNames } from "../../helpers/classNames";

const ImageDetailsText = ({
  quickView,
  details,
  handleAddToCart = () => {},
  handleWishlist = () => {},
  isSolid,
  setIsSolid = () => {},
  scrollHandler = () => {},
}) => {
  return (
    <>
      <div className="w-full review">
        {/* <div className="xl:mt-auto md:mt-10 mt-6 "><DetailsBreadcrumb items={items} /></div> */}
        <ProductDescription
          quickView={quickView}
          details={details}
          scrollHandler={scrollHandler}
        />
        <div
          className={classNames(
            "flex items-center space-x-3 ",
            quickView ? "my-3" : "md:my-8 my-4 "
          )}
          onClick={() => scrollHandler("review")}
        >
          {/* <div className="text-2xl text-red-600">
            {details?.data?.averageRating || 0}
          </div> */}
          <Review
            rating={details?.data?.averageRating}
            totalReviews={details?.data?.totalReview || "0"}
          />
        </div>
        <PriceBuynow
          quickView={quickView}
          details={details}
          handleAddToCart={handleAddToCart}
          handleWishlist={handleWishlist}
          isSolid={isSolid}
          setIsSolid={setIsSolid}
        />
        {/* <ProductQuantity/> */}
      </div>
    </>
  );
};

export default ImageDetailsText;
