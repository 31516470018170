import { apiRequest } from "../../util/apiUtils";


export const siteSettingDetails = async (params) => {
  try {
    const res = await apiRequest("get", "common/siteSettings", {
      queries: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};