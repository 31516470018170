import React from "react";
import Datepicker from "react-tailwindcss-datepicker";

const DatePickerInput = ({
  label,
  labelClasses,
  value,
  onChange,
  useRange = true,
  asSingle = false,
  showShortcuts = false,
}) => {
  const handleDateChange = (newDate) => {
    onChange(newDate);
  };

  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);
  const yesterdayDate = yesterday.toISOString().split("T")[0];

  return (
    <div className="relative space-y-1 w-full">
      {label && (
        <div className={`${labelClasses} text-sm font-medium text-slate-500 mb-1`}>
          <span>{label}</span>
        </div>
      )}
      <Datepicker
        primaryColor={"red"}
        value={value}
        asSingle={asSingle}
        inputClassName="w-full h-10 rounded-md bg-slate-50 border border-slate-200 px-3 text-sm text-slate-600 !ring-0 !outline-0 focus:border-victoria focus:bg-carnation-50 transition-all duration-200" 
        useRange={useRange}
        displayFormat="MM-DD-YYYY"
        onChange={handleDateChange}
        showShortcuts={showShortcuts}
        popoverDirection="down"
        containerClassName={"relative datepicker"}
        maxDate={yesterdayDate}
        
      />
    </div>
  );
};

export default DatePickerInput;
