import React, { useState, useRef, useEffect, Fragment, useMemo } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { usePopper } from "react-popper";
import { bottom, top, left, right } from "@popperjs/core";
import { classNames } from "../../helpers/classNames";
import Search from "./Search";

const SelectDropdownSearch = ({
  xPlacement,
  dropdownData = [],
  selectName,
  dropdownClass,
  dropdownButtonClass,
  dropdownButtonStyle, // Add this prop for custom styles
  value,
  label,
  labelClasses,
  onSearch = () => {},
  onChange = () => {},
  errorText,
  errorType,
  isSearch,
  buttonArrowIconClasses,
  buttonArrowIcon = "fa-regular fa-chevron-down",
  tabTextColor, // Add this prop to accept custom tab text color
  placeholder, // Add this prop for the placeholder
  ...props
}) => {
  const [selected, setSelected] = useState(value ? value : dropdownData[0]);
  const popperElRef = useRef(null);
  const [targetElement, setTargetElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const placements = {
    bottom: bottom,
    bottomLeft: "bottom-start",
    bottomRight: "bottom-end",
    top: top,
    topLeft: "top-start",
    topRight: "top-end",
    right: right,
    left: left,
    "": "",
  };

  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: placements[xPlacement],
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 2],
        },
      },
    ],
  });

  useEffect(() => {
    if (value && Object.keys(value).length > 0) {
      const index = dropdownData.findIndex(
        (item) => item._id === value._id || item.value === value || item.value === value?._id
      );
      if (index !== -1) {
        setSelected(dropdownData[index]);
      }
    } else {
      setSelected(null);
    }
  }, [dropdownData, value]);

  const onSearchHandler = (e) => {
    onSearch(e);
  };

  const handleChange = (e) => {
    onChange(e);
    setSelected(e);
  };
  return (
    <>
      <div className="relative space-y-1 w-full">
        {label && <div className={classNames("text-sm font-medium text-slate-500 mb-1", labelClasses)}>{label}</div>}
        <Listbox
          value={selected}
          onChange={(e) => {
            onChange({ target: { name: selectName, value: e } });
            setSelected(e);
          }}
          as={"div"}
          className="relative">
          {({ open }) => (
            <>
              <Listbox.Button ref={setTargetElement} className="flex items-center w-full">
                <div
                  className={classNames(
                    "relative w-full h-10 flex items-center justify-between focus:ring-0 transition-all duration-200 gap-1 pl-3 pr-6 rounded-md border text-sm font-medium bg-slate-50  border-slate-200 px-3  text-slate-600 !ring-0 !outline-0 focus:border-victoria focus:bg-carnation-50",
                    dropdownButtonClass
                  )}
                  style={dropdownButtonStyle}>
                  <div
                    className={classNames(
                      selected?.name ? "text-sm text-slate-600 " : "text-sm font-normal text-slate-500 "
                    )}>
                    {selected?.name ? selected.name : placeholder}
                  </div>
                  {buttonArrowIcon && (
                    <div
                      className={classNames(
                        "absolute top-1/2 right-1 -translate-y-1/2 transition-all duration-200 text-xs",
                        open ? "-rotate-180" : "",
                        buttonArrowIconClasses,
                        tabTextColor ? "" : "text-white"
                      )}
                      style={tabTextColor ? { color: tabTextColor } : {}}>
                      <i className={classNames("fa-fw", buttonArrowIcon)}></i>
                    </div>
                  )}
                </div>
              </Listbox.Button>
              <Transition
                as={Fragment}
                className="z-50"
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
                beforeEnter={() => setPopperElement(popperElRef.current)}
                afterLeave={() => setPopperElement(null)}
                ref={popperElRef}
                style={styles.popper}
                {...attributes.popper}>
                <Listbox.Options
                  className={classNames(
                    "absolute mt-1 w-full origin-top-right rounded-md bg-white shadow-lg focus:outline-none py-1",
                    dropdownClass
                  )}>
                  {isSearch && (
                    <div className="py-1 px-2 border-b border-slate-100">
                      <Search placeholder={"Search"} onSearch={(e) => onSearchHandler(e)} />
                    </div>
                  )}
                  <div className="py-1 max-h-80 overflow-auto scrollbar">
                    {dropdownData?.map((item, index) => (
                      <Listbox.Option
                        key={index}
                        className={({ active }) =>
                          classNames(
                            "relative group flex w-full items-center px-3 py-1.5 pl-8 text-sm gap-2 transition-all duration-200 cursor-pointer",
                            active
                              ? "bg-slate-200 text-black"
                              : value?._id == item?._id
                              ? "! bg-green-100 text-slate-800"
                              : "text-black",
                            item.isDisabled ? "!bg-slate-100 !cursor-not-allowed" : "",
                            item.class
                          )
                        }
                        value={item}
                        disabled={item.isDisabled}>
                        {({ selected }) => (
                          <>
                            {item.icon && <i className={classNames("fa-fw", item.icon)}></i>}
                            <div>{item.name}</div>
                            {selected && (
                              <div className="absolute inset-y-0 left-0 flex items-center pl-2 text-red-500 text-base">
                                <i className="fa-regular fa-fw fa-check"></i>
                              </div>
                            )}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </div>
                </Listbox.Options>
              </Transition>
            </>
          )}
        </Listbox>
      </div>
      {errorType && errorType === "danger" && (
        <div className="text-xs text-red-600 mt-1">
          <i className="fa-regular fa-fw fa-square-exclamation text-sm mr-1"></i>
          {errorText}
        </div>
      )}
      {errorType && errorType === "warning" && (
        <div className="text-xs text-amber-600 mt-1">
          <i className="fa-regular fa-fw fa-triangle-exclamation text-sm mr-1"></i>
          {errorText}
        </div>
      )}
      {errorType && errorType === "success" && (
        <div className="text-xs text-green-600 mt-1">
          <i className="fa-regular fa-fw fa-circle-check text-sm mr-1"></i>
          {errorText}
        </div>
      )}
      {errorType && errorType === "info" && (
        <div className="text-xs text-sky-600 mt-1">
          <i className="fa-regular fa-fw fa-circle-info text-sm mr-1"></i>
          {errorText}
        </div>
      )}
    </>
  );
};

export default SelectDropdownSearch;
