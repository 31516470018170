import React, { useState } from "react";
import { classNames } from "../../../helpers/classNames";
import Button from "../../form/Button";
import Modal from "../../elements/Modal/Modal";
import BillingAddressModal from "../BillingAddressModal";

const MyBillingAddress = ({ billingAdderss }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className="w-full">
        <div>
          <div className="flex items-center pt-10 md:pb-3 pb-1 space-x-2">
            <div className="uppercase text-black md:text-2xl text-xl font-medium rounded-md">
              Billing{" "}
              <span className="text-red-600 font-extrabold">Address</span>
            </div>
            <div className="text-2xl">
              <i className="fa-solid fa-circle-question"></i>
            </div>
          </div>
          <hr className="w-full border-1 border-slate-500 pb-8" />
          <div className="flex space-x-5">
            <div
              className={classNames(
                "relative overflow-hidden bg-slate-50 border border-red-600 !shadow-lg rounded-md p-3 z-0"
              )}
            >
              <div className="flex items-center">
                <div className="flex-1">
                  <div className="flex flex-wrap items-center md:space-x-2 space-x-0">
                    <div className="text-xs font-bold">Address :</div>
                    <div className="md:text-sm text-xs">
                      {billingAdderss?.address || "N/A"}
                    </div>
                  </div>
                  <div className="flex flex-wrap items-center md:space-x-2 space-x-0">
                    <div className="text-xs font-bold">Address Line 2:</div>
                    <div className="md:text-sm text-xs">
                      {billingAdderss?.address2 || "N/A"}
                    </div>
                  </div>
                  <div className="flex flex-wrap items-center md:space-x-2 space-x-0">
                    <div className="text-xs font-bold">City :</div>
                    <div className="md:text-sm text-xs">
                      {billingAdderss?.city || "N/A"}
                    </div>
                  </div>
                  <div className="flex flex-wrap items-center md:space-x-2 space-x-0">
                    <div className="text-xs font-bold">State :</div>
                    <div className="md:text-sm text-xs">
                      {billingAdderss?.stateName || "N/A"}
                    </div>
                  </div>
                  <div className="flex flex-wrap items-center md:space-x-2 space-x-0">
                    <div className="text-xs font-bold">Postalcode :</div>
                    <div className="md:text-sm text-xs">
                      {billingAdderss?.pin || "N/A"}
                    </div>
                  </div>
                </div>
                <div className="ml-4 mt-12">
                  <Button
                    buttonClasses={"!bg-transparent !text-red-600 "}
                    buttonLabel=""
                    buttonLabelClasses="!text-base"
                    buttonIcon={"fa-regular fa-pen"}
                    buttonIconPosition={"left"}
                    buttonEffect={"filled"}
                    buttonFunction={() => setIsOpen(true)}
                  />
                </div>
              </div>
            </div>
          </div>
          <Modal size="xl4" title="Billing Address" open={isOpen}>
            <BillingAddressModal
              onClose={() => setIsOpen(false)}
              details={billingAdderss}
            />
          </Modal>
        </div>
      </div>
    </>
  );
};

export default MyBillingAddress;
