import React from "react";

import SingleMyWishlist from "../Items/SingleMyWishlist";


const MyWishListDetails = ({wishlist , addWishList=()=> {}}) => {
 
  return (
    <>
     <div className="relative w-full bg-white md:px-8 px-4 rounded-md">
  {wishlist?.length > 0 && (
    <>
      <div className="relative divide-y divide-slate-200">
        {wishlist.map((item, index) => (
          <SingleMyWishlist key={index} data={item} addWishList={addWishList}/>
        ))}
      </div>
      
    </>
  ) }
</div>

    </>
  );
};

export default MyWishListDetails;
