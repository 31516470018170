import React, { useState } from "react";
import Button from "../form/Button";
import Image from "../elements/Image";
import DeleteModal from "../common/DeleteModal";
import { checkoutDetailsData, deleteCart } from "../../services/frontService/ProductService";
import { setCartDetails } from "../../redux/slice/cartSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { setCheckoutDetails } from "../../redux/slice/checkoutSlice";

const SingleCartHover = ({ data }) => {
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  const [deleteModal, setDeleteModal] = useState({ isOpen: false, data: {} });

  const deleteCartData = async () => {
    setLoaded(true);
    try {
      const res = await deleteCart({
        id: deleteModal?.data?.id,
      });
      if (res?.data?.status === 200 || res?.data?.status === 201) {
        toast.info(res?.data?.message);
        dispatch(setCartDetails(res?.data?.data));
        setDeleteModal({ isOpen: false, data: {} });
        checkoutDetailsData().then((res) => {
          if (res?.status === 200) {
            dispatch(setCheckoutDetails(res?.data));
          } else {
            dispatch(setCheckoutDetails({}));
          }
        });
      } else {
        toast.info(
          res?.data?.message || res?.data?.errors || "Something went wrong"
        );
      }
      setLoaded(false);
    } catch (err) {
      setLoaded(false);
      toast.error("Somthing went wrong !!!");
    }
  };

  const imageData =
    data &&
    Array.isArray(data?.productInfo?.productImages) &&
    data?.productInfo?.productImages.length > 0 &&
    data?.productInfo?.productImages[0];

  return (
    <>
      <div className="flex justify-center ">
        <div className="w-14 aspect-square overflow-hidden ml-0">
          <Image
            src={imageData?.imageUrl}
            alt="cart image"
            className="w-full h-full object-cover"
            effect={"blur"}
          />
        </div>
        <div className="md:pl-6 pl-4 ml-0">
          <div className="md:text-sm text-xs font-bold">
            {data?.productInfo?.title || ""}
          </div>
          <div className="flex space-x-2 pt-1 md:text-sm text-xs font-normal">
            <div>
              {data?.quantity || 0} <span>x</span>
            </div>
            <div>
              {data?.actual_price || 0} <span>=</span>
            </div>
            <div>{data?.total_price || 0}</div>
          </div>
        </div>
        <div className="md:pl-8 pl-3 pt-2 ml-auto">
          <Button
            buttonClasses={
              "!bg-white !px-1 !rounded-full !text-red-600 h-8 flex items-center justify-center transition-all duration-200 "
            }
            buttonLabelClasses={""}
            buttonIcon={"fa-light fa-trash"}
            buttonIconPosition={"left"}
            buttonFunction={() => setDeleteModal({ isOpen: true, data: data })}
          />
        </div>
      </div>
      <DeleteModal
        open={deleteModal?.isOpen}
        close={() => setDeleteModal({ isOpen: false })}
        loading={loaded}
        deleteHandler={deleteCartData}
      />
    </>
  );
};

export default SingleCartHover;
