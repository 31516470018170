import React, { useEffect, useState } from "react";
import ProfileSidebar from "../components/sections/ProfileSidebar";
import MyWishlist from "../components/sections/userProfile/MyWishlist";
import { useLocation } from "react-router-dom";

const MyWishlists = () => {
  const location = useLocation();
  const [selectedOption, setSelectedOption] = useState("");

  useEffect(() => {
    if (location.pathname === "/user-wishlists") {
      setSelectedOption("user-wishlists");
    }
  }, [location.pathname]);
  return (
    <>
      <div className="sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto">
        <div className="xl:flex md:block md:mt-36 mt-24 gap-10">
          <div className="xl:w-1/5 w-full md:px-0 px-5">
            <ProfileSidebar
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
            />
          </div>
          <div className="xl:w-4/5 w-full">
            <MyWishlist />
          </div>
        </div>
      </div>
    </>
  );
};

export default MyWishlists;
