import React from 'react'; 
import MainBanner from "../components/sections/MainBanner"; 
import AboutUsSection from "../components/sections/AboutUsSection";
import SmallBanner from '../components/sections/SmallBanner';


const AboutUs = () => {
    return (
        <>
          <SmallBanner/>
          <AboutUsSection/>
        </>
    );
};

export default AboutUs;