import React from "react";

const Skeleton = ({ className, type = "text", rounded = false }) => {
  const baseClasses = "bg-gray-300 animate-pulse";

  const skeletonClasses = {
    text: "h-6 w-full",
    circle: "h-10 w-10 rounded-full",
    square: "h-10 w-10",
    rectangle: "h-12 w-full",
  };

  const shapeClasses = rounded ? "rounded-full" : "rounded";
  return (
    <div
      className={`${baseClasses} ${skeletonClasses[type]} ${shapeClasses} ${className}`}
    ></div>
  );
};

export default Skeleton;
