import React, { useCallback, useEffect, useState } from "react";
import Button from "../../../components/form/Button";
import Input from "../../../components/form/Input";
import { formValidate } from "../../../helpers/formValidate";
import useForm from "../../../hooks/useForm";
import { Validation } from "../../../helpers/Validation";
import { toast } from "react-toastify";
// import {  useParams } from "react-router-dom";
import { setTitle } from "../../../helpers/MetaTag";
import {
  editProfile,
  getProfileDetails,
} from "../../../services/frontService/ProfileService";
import { setProfileDetails } from "../../../redux/slice/profileSlice,";
import { useDispatch } from "react-redux";
import ChangePassword from "./ChangePassword";

const MyInfo = () => {
  setTitle("HJL DESIGNS");
  // const { id } = useParams();
  const dispatch = useDispatch();
  const validation = {
    name: { required: true, message: "Please enter your name !!!" },
    email: { required: true, message: "Please enter your email !!!" },
    phone: { required: true, message: "Please enter phone number !!!" },
  };
  const { values, handleChange, handleSubmit, errors, setFieldsValue } =
    useForm({}, validation);

  const [loaded, setLoaded] = useState(false);

  const [profileDetailsData, setProfileDetailsData] = useState({
    loading: false,
    data: {},
  });

  const getDetails = useCallback(() => {
    setProfileDetailsData({ loading: true, data: {} });
    getProfileDetails().then((res) => {
      if (res?.status === 200) {
        setProfileDetailsData({
          loading: true,
          data: {
            ...res?.data,
            role: "USER",
          },
        });

        dispatch(setProfileDetails({ ...res?.data, role: "USER" }));
        setFieldsValue({
          name: res?.data?.name || "",
          email: res?.data?.email || "",
          phone: res?.data?.phone || "",
        });
      } else {
        setProfileDetailsData({});
        dispatch(setProfileDetails({}));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getDetails();
  }, [getDetails]);

  const onSubmit = async (values) => {
    setLoaded(true);
    try {
      if (!Validation("name", values.name)) {
        toast.error("Name cannot be empty or contain only spaces.");
        setLoaded(false);
        return;
      }
      if (!Validation("email", values.email)) {
        toast.error("Invalid email format. Please enter a valid email.");
        setLoaded(false);
        return;
      }

      const res = await editProfile({
        name: values?.name,
        email: values?.email,
        phone: values?.phone,
      });

      if (res?.data?.status === 200 || res?.data?.status === 201) {
        dispatch(setProfileDetails({ ...res?.data?.data, role: "USER" }));
        toast.info(res?.data?.message);
      } else {
        toast.info(
          res?.data?.message || res?.data?.errors || "Something went wrong"
        );
      }

      setLoaded(false);
    } catch (err) {
      setLoaded(false);
      toast.error("Somthing went wrong !!!");
    }
  };

  return (
    <div>
      <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
        <div className="py-2 md:px-6 px-0">
          <div className="grid grid-cols-12 gap-4">
            <div className="relative md:col-span-6 col-span-12">
              <Input
                isInputGroup={false}
                inputGroupIcon={"fa-regular fa-user"}
                inputGroupPosition={"left"}
                label={"Name"}
                labelClasses={"!text-base pb-1"}
                inputType={"text"}
                value={values.name}
                inputName={"name"}
                onChange={handleChange}
                inputPlaceholder={"Enter Name"}
                inputClasses={"!py-6"}
                {...formValidate(errors, "name")}
              />
            </div>
            <div className="relative md:col-span-6 col-span-12">
              <Input
                isInputGroup={false}
                inputGroupIcon={"fa-solid fa-envelope"}
                inputGroupPosition={"left"}
                label={"Email"}
                labelClasses={"!text-base pb-1"}
                inputType={"text"}
                value={values.email}
                inputName={"email"}
                onChange={handleChange}
                isDisabled
                inputPlaceholder={"Enter Email"}
                inputClasses={"!py-6"}
                {...formValidate(errors, "email")}
              />
            </div>
            <div className="relative md:col-span-6 col-span-12">
              <Input
                isInputGroup={false}
                inputGroupIcon={"fa-solid fa-phone"}
                inputGroupPosition={"left"}
                label={"Phone Number"}
                labelClasses={"!text-base pb-1"}
                inputType={"text"}
                value={values.phone}
                inputName={"phone"}
                onChange={handleChange}
                inputPlaceholder={"Enter Phone Number"}
                inputClasses={"!py-6"}
                {...formValidate(errors, "phone")}
              />
            </div>
          </div>
          <div className="mt-8 pt-4 border-t border-slate-200">
            <div className="flex justify-end space-x-3 mt-4">
              <Button
                buttonHasLink={false}
                buttonType={"submit"}
                buttonIcon={"fa-light fa-floppy-disk"}
                buttonIconPosition={"left"}
                buttonClasses={"!bg-primary !text-org-silver"}
                buttonLabel={"Save"}
                isDisable={loaded}
              />
            </div>
          </div>
        </div>
      </form>

      <ChangePassword />
    </div>
  );
};

export default MyInfo;
