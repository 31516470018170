import React from "react";
import { Navigate } from "react-router-dom";

const FreeAuthRoute = ({ auth, children, title = "" }) => {
  const token = localStorage.getItem("auth_token");
  const role = localStorage.getItem("role");

  if (token && role === "USER") {
    return <Navigate to="/" />;
  } else if (token && role === "ADMIN") {
    return <Navigate to="/admin/dashboard" />;
  } else {
    return <>{children}</>;
  }
};

export default FreeAuthRoute;

// import React, { useState, useEffect } from "react";
// import { Navigate } from "react-router-dom";

// const FreeAuthRoute = ({ children }) => {
//   const [isAuthenticated, setIsAuthenticated] = useState(false);

//   useEffect(() => {
//     const token = localStorage.getItem("auth_token");
//     if (token) {
//       setIsAuthenticated(true);
//     }
//   }, []);

//   return isAuthenticated ? <Navigate to="/" /> : children;
// };

// export default FreeAuthRoute;

