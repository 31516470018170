import React, { useEffect, useState } from "react";
import Button from "../form/Button";
import { classNames } from "../../helpers/classNames";
import { country } from "../../helpers";
import { useDispatch } from "react-redux";
import { addCartQuantity } from "../../services/frontService/ProductService";
import { toast } from "react-toastify";
import { setCartDetails } from "../../redux/slice/cartSlice";
import HideableInput from "../form/HideableInput";

const PriceBuynow = ({
  quickView,
  details,
  handleAddToCart = () => {},
  handleWishlist = () => {},
  isSolid,
  setIsSolid = () => {},
}) => {
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(details?.data?.quantity || 1);
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const handleDecrement = async () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const handleIncrement = async () => {
    setQuantity(quantity + 1);
  };

  useEffect(() => {
    if (details?.data) {
      setIsSolid(details?.data?.is_wishlist === 1 ? true : false);
    }
  }, [details?.data]);

  const handleInputChange = (e) => {
    const value = parseInt(e, 10);
    if (!isNaN(value)) {
      setQuantity(value);
    }
  };


  return (
    <>
      <div>
        {/* <div className="flex space-x-3">
        <div className="line-through text-lg text-slate-400 uppercase">Cad 30</div>
        <div className="text-xl text-black font-bold uppercase">cad 15</div>
        <div className="text-[11px] text-black px-2 py-1 bg-slate-100 rounded-md">15 % off</div>
      </div> */}
      </div>
      <div
        className={classNames(
          " text-red-600 font-extrabold md:mt-6 mt-3",
          quickView ? "text-2xl" : "text-2xl md:text-4xl"
        )}
      >
        {country + " " + (details?.data?.price || "")}
      </div>
      <div
        className={classNames(
          "w-full items-center md:my-6 my-3 ",
          quickView
            ? "!my-1 xl:!flex block xl:!space-x-2 space-x-0 "
            : "md:my-6 my-3 md:flex block md:!space-x-2"
        )}
      >
        <div className="relative flex items-center space-x-2 md:pt-auto pt-2">
          <div>
            <Button
              buttonClasses={
                "!bg-white md:h-10 h-8 md:w-auto w-8 px-3 font-medium border border-slate-900 !text-black hover:!text-slate-100 hover:!bg-slate-900 flex items-center justify-center transition-all duration-200"
              }
              buttonLabelClasses={""}
              buttonIcon={"fa-solid fa-minus"}
              buttonIconPosition={"left"}
              buttonHasLink={false}
              buttonFunction={handleDecrement}
              isDisable={loading ? loading : quantity > 1 ? false : true}
            />
          </div>
          <div
            className="text-2xl md:p-3 p-1 border-b-2 border-red-600"
            onClick={() => setIsEditing(true)}
          >
            {isEditing ? (
              <HideableInput
                value={quantity}
                onChange={handleInputChange}
                type={"transparent"}
                rename={isEditing}
                setRename={setIsEditing}
                inputClasses={
                  "!text-2xl !bg-transparent !border !border-transparent !w-16 !text-org-theme"
                }
              />
            ) : (
              <div>{quantity}</div>
            )}
          </div>
          <div>
            <Button
              buttonClasses={
                "!bg-white md:h-10 h-8 md:w-auto w-8 px-3 font-medium border border-slate-900 !text-black hover:!text-slate-100 hover:!bg-slate-900 flex items-center justify-center transition-all duration-200"
              }
              buttonLabelClasses={""}
              buttonIcon={"fa-solid fa-plus"}
              buttonIconPosition={"left"}
              buttonHasLink={false}
              buttonFunction={handleIncrement}
              isDisable={loading}
            />
          </div>
        </div>
        <div
          className={classNames(
            "flex items-center ",
            quickView ? "mt-4" : "md:mt-auto mt-8"
          )}
        >
          <div
            className={classNames(
              "",
              quickView ? "xl:pl-10 pl-0" : "md:pl-10 pl-0"
            )}
          >
            <Button
              buttonClasses={classNames(
                " !h-10 !text-4xl !text-white !text-xl hover:!bg-black !text-center !bg-red-600 gap-3 hover:scale-110 transition duration-300",
                quickView ? "!px-4 !p-7" : "md:!px-8 md:!p-8 !px-6 !p-7"
              )}
              buttonLabel="Add To Cart"
              buttonLabelClasses={classNames(
                quickView ? "text-base" : "md:!text-xl !text-lg"
              )}
              buttonIcon={"fa-solid fa-cart-shopping"}
              buttonIconPosition={"left"}
              buttonEffect={"filled"}
              buttonFunction={() => handleAddToCart(details?.data, quantity)}
            />
          </div>
          {!quickView && (
            <div className="ml-0 md:text-center md:flex items-center">
              <Button
                buttonClasses={`!bg-transparent !h-10 !text-3xl !text-black md:!text-4xl text-2xl !text-center  hover:scale-110 transition duration-300 text-center ${
                  isSolid ? "!text-red-600" : ""
                }`}
                buttonIcon={isSolid ? "fa-solid fa-heart" : "fa-light fa-heart"}
                buttonIconPosition={"right"}
                buttonEffect={"filled"}
                buttonFunction={() => handleWishlist(details?.data)}
              />
            </div>
          )}
        </div>
      </div>
      {/* <div className="flex space-x-5 mt-10">
        
         <div>
          <Button
            buttonClasses={
              "!px-5 !h-10 !text-3xl !text-white !text-xl hover:!bg-black !text-center bg-red-600 gap-3 hover:scale-110 transition duration-300"
            }
            buttonLabel="Buy Now"
            buttonLabelClasses=""
            buttonIcon={"fa-light fa-share-all"}
            buttonIconPosition={"left"}
            buttonEffect={"filled"}
          />
        </div> 
      </div> */}
    </>
  );
};

export default PriceBuynow;
