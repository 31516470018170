import React, { useState } from "react";

import Image from "../elements/Image";
import card1 from "../../assets/images/visacard.png";
import card2 from "../../assets/images/mastercard.png";
import card3 from "../../assets/images/ameriacnexpress.png";
import card4 from "../../assets/images/disovernetworkcard.png";
import Input from "../form/Input";
import Select from "../form/Select";
import Button from "../form/Button";
import { country } from "../../helpers";

const PaymentMethodData = {
  heading: "Payment Information",
  subheading: "Credit <span class='text-red-600 font-extrabold'>Card</span>",
  title: "Amount being paid now:",
  price: "Cad 115.5",
};

const PaymentMethod = ({
  onPlaceOrder = () => {},
  loaded,
  setLoaded = () => {},
  myCartList,
  myCheckoutData,
}) => {
  const [month, setMonth] = useState([
    { _id: 1, name: "01" },
    { _id: 2, name: "02" },
    { _id: 2, name: "03" },
    { _id: 3, name: "04" },
    { _id: 4, name: "05" },
    { _id: 5, name: "06" },
    { _id: 6, name: "07" },
    { _id: 7, name: "08" },
    { _id: 8, name: "09" },
    { _id: 9, name: "10" },
    { _id: 10, name: "11" },
    { _id: 11, name: "12" },
  ]);

  const [year, setYear] = useState([
    { _id: 1, name: "2015" },
    { _id: 2, name: "2016" },
    { _id: 2, name: "2017" },
    { _id: 3, name: "2018" },
    { _id: 4, name: "2019" },
    { _id: 5, name: "2020" },
    { _id: 6, name: "2021" },
    { _id: 7, name: "2022" },
    { _id: 8, name: "2023" },
    { _id: 9, name: "2024" },
    { _id: 10, name: "2025" },
    { _id: 11, name: "2026" },
  ]);
  const orderSummaryData = {
    cartSummary:
      myCheckoutData &&
      myCheckoutData?.cartSummary &&
      myCheckoutData?.cartSummary,
    orderSummary:
      myCheckoutData &&
      myCheckoutData?.orderSummary &&
      myCheckoutData?.orderSummary,
  };
  return (
    <>
      <div className="xl:w-3/5 md:px-0 px-5">
        {/* <div className="bg-red-600 p-2 pl-5 uppercase text-white text-xl font-semibold rounded-md">
          {PaymentMethodData.heading}
        </div> */}
        <div
          className=" uppercase text-black md:text-2xl text-xl font-medium rounded-md pt-7 md:pb-3 pb-1"
          dangerouslySetInnerHTML={{ __html: PaymentMethodData.subheading }}
        ></div>
        <hr className="w-full border-1 border-slate-500"></hr>

        <div className="flex">
          {/* <div className="w-2/4">
            <div className="!w-full bg-red-600 px-14 py-3 text-xl text-white mt-6 rounded-md">
              Credit Card
            </div>
          </div> */}
          <div className="w-full">
            <div className="flex items-center pt-6">
              <div className="md:text-xl text-base font-semibold text-gray-700 leading-none items-center">
                {PaymentMethodData.title}
              </div>
              <div className="md:text-2xl text-xl font-bold text-red-600 uppercase leading-none pl-2">
              {country +
                  " " +
                  (orderSummaryData?.orderSummary?.totalOrderValue || 0)}
              </div>
            </div>
            <div className="flex space-x-2 pt-5">
              <div className="w-20 h-12 overflow-hidden border border-gray-300 rounded-md">
                <Image
                  src={card1}
                  alt=""
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="w-20 h-12 overflow-hidden border border-gray-300 rounded-md">
                <Image
                  src={card2}
                  alt=""
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="w-20 h-12 overflow-hidden border border-gray-300 rounded-md">
                <Image
                  src={card3}
                  alt=""
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="w-20 h-12 overflow-hidden border border-gray-300 rounded-md">
                <Image
                  src={card4}
                  alt=""
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
            <div className="pt-10">
              <Input
                label={"Card Holder's Name"}
                inputName={"name"}
                inputClasses={
                  "!bg-prontopsy-dodgerBlue !border !border-gray-300 !h-12 placeholder:!text-slate-400 text-gray-600 bg-white !text-xl"
                }
                labelClasses={"md:!text-lg text-sm !font-bold !text-gray-700"}
              />
            </div>
            <div className="pt-3">
              <Input
                label={"Card Number"}
                inputName={"name"}
                inputClasses={
                  " !border !border-gray-300 !h-12 placeholder:!text-slate-400 text-gray-600 bg-white"
                }
                labelClasses={"md:!text-lg text-sm !font-bold !text-gray-700"}
              />
            </div>
            <div className="md:flex block">
              <div className="md:w-2/4 w-full">
                <div className="md:!text-lg text-sm !font-bold !text-gray-700 pt-3">
                  Expiration date
                </div>
                <div className="flex">
                  <div className="pt-1 w-2/4">
                    <Select
                      xPlacement={"bottomLeft"}
                      dropdownButtonClass={
                        "!border !border-gray-300 !h-12 placeholder:!text-slate-400 text-gray-600 bg-white"
                      }
                      dropdownClass={"w-full  px-12"}
                      //   label={"Expiration"}
                      selectedValue={{ _id: 1, name: "01" }}
                      dropdownData={month}
                    />
                  </div>
                  <div className="pt-1 pl-3 w-2/4">
                    <Select
                      xPlacement={"bottomLeft"}
                      dropdownButtonClass={
                        "!border !border-gray-300 !h-12 placeholder:!text-slate-400 text-gray-600 bg-white"
                      }
                      dropdownClass={"w-full  px-12"}
                      //   label={" Date"}
                      selectedValue={{ _id: 1, name: "2024" }}
                      dropdownData={year}
                    />
                  </div>
                </div>
              </div>
              <div className="pt-3 md:pl-5 pl-0">
                <Input
                  label={"CVV Code"}
                  inputName={"name"}
                  inputClasses={
                    " !border !border-gray-300 !h-12 placeholder:!text-slate-400 text-gray-600 bg-white"
                  }
                  labelClasses={"md:!text-lg text-sm !font-bold !text-gray-700"}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap justify-end md:space-x-7 space-x-0 md:pt-16 pt-10">
          <div className=" md:pt-8 pt-2 md:w-auto w-full">
            <Button
              buttonClasses={"bg-gray-600 hover:!bg-black !px-5"}
              buttonLabel="Back to Address"
              buttonLabelClasses="  !text-lg  "
              buttonEffect={"filled"}
              buttonHasLink={true}
              buttonLink={"/checkout"}
            />
          </div>
          {myCartList?.length > 0 && (
            <>
              {" "}
              <div className=" md:pt-8 pt-2 md:w-auto w-full">
                <Button
                  buttonClasses={"!w-full bg-red-600 hover:!bg-black !px-5"}
                  buttonLabel="Place Order"
                  buttonLabelClasses="  !text-lg  "
                  buttonEffect={"filled"}
                  buttonType={"button"}
                  buttonFunction={onPlaceOrder}
                  isDisable={loaded}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default PaymentMethod;
