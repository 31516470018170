import React from "react";
import Tabs from "../../elements/Tabs";
import ProductDescriptionItem from "../../Items/ProductDescriptionItem";
import ProductDescriptionRating from "../../Items/ProductDescriptionRating";
import UserAddress from "../../Items/AdminPanelItems/UserAddress";
import UserOrders from "../../Items/AdminPanelItems/UserOrders";
import ProductDescriptionRatingAdmin from "../../Items/ProductDescriptionRatingAdmin";

const UserDetailsTabs = ({
  data,
  orderList,
  setOrderList = () => {},
  reviewList,
  setDetailsData = () => {},
  getDetails = () => {},
  activeTab,
  setActiveTab = () => {},
  getOrderDetails = () => {},
}) => {
  const tabData = [
    {
      _id: 1,
      name: "Address",
      component: (
        <UserAddress
          data={data}
          setDetailsData={setDetailsData}
          getDetails={getDetails}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      ),
    },
    {
      _id: 2,
      name: "Orders",
      component: (
        <UserOrders
          orderList={orderList}
          setOrderList={setOrderList}
          getOrderDetails={getOrderDetails}
        />
      ),
    },
    {
      _id: 3,
      name: "Reviews",
      component: <ProductDescriptionRatingAdmin reviewList={reviewList} />,
    },
  ];
  return (
    <div className="w-full">
      <div className="relative pt-6">
        <Tabs tabs={tabData} navTabClass={""} />
      </div>
    </div>
  );
};

export default UserDetailsTabs;
