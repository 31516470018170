import React, { useState } from "react";
import nodatafound from "../../../assets/images/nodatafound.png";
import Image from "../../../components/elements/Image";
import Button from "../../../components/form/Button";
import Modal from "../../../components/elements/Modal/Modal";
import { AddBannerModal } from "./AddBannerModal";
import DeleteModal from "../../../components/common/DeleteModal";
import { deleteBanner } from "../../../services/pagesService";
import { toast } from "react-toastify";

const PageGalleryItem = ({ data, setData = () => {}, getDetails = () => {} }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    id: null,
  });
  const [deleteComplete, setDeleteComplete] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [bannerDetails, setBannerDetails] = useState({});

  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  // const handelAddOpenModal = () => {
  //   setIsOpen(true);
  // };
  const handelEditOpenModal = (item) => {
    setIsOpen(true);
  };

  const handelDeleteModal = (item) => {
    setDeleteModal({
      isOpen: true,
      id: item?.id,
    });
  };

  const onDelete = async () => {
    if (deleteModal?.id) {
      setDeleteComplete(true);
      try {
        const res = await deleteBanner({ id: deleteModal?.id });
        if (res?.status === 200) {
          toast.success(res?.message);
          setData((prevState) => ({
            ...prevState,
            data: {
              ...prevState.data,
              bannerList: prevState.data.bannerList.filter((item) => item.id !== deleteModal.id),
            },
          }));
          setDeleteModal({ isOpen: false, id: null });
        } else {
          toast.error(res?.message || "Failed to delete banner.");
        }
      } catch (error) {
        toast.error("An error occurred while deleting the banner.");
      } finally {
        setDeleteComplete(false);
      }
    }
  };

  return (
    <>
      {data?.data?.bannerList?.length > 0 ? (
        <div>
          <div className="py-2 md:px-6 px-0">
            <div className="w-full bg-white rounded-md shadow  py-5 px-4">
              <div className="flex items-center justify-between">
                <span className="lg:text-2xl md:text-lg text-base font-bold text-red-600 mb-2">Banner Images</span>
              </div>
              <div className=" h-[500px] overflow-auto mt-12">
                <div className="grid 3xl:grid-cols-7 xl:grid-cols-6 md:grid-cols-3 grid-cols-2 gap-2 divide-x divide-slate-200 md:px-10 px-0">
                  {data?.data?.bannerList?.map((item, index) => (
                    <div key={index} className="relative md:w-36 md:h-36 w-28 h-28 overflow-hidden group flex-shrink-0">
                      <Image
                        src={item?.imageUrl}
                        alt={item?.name}
                        className="w-full h-full !object-cover"
                        effect={""}
                      />

                      <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-90 text-white text-center text-sm py-1">
                        {item?.title}
                      </div>

                      <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 gap-2">
                        <Button
                          buttonHasLink={false}
                          buttonIcon={"fa-light fa-pen"}
                          buttonIconPosition={"left"}
                          buttonClasses={"!bg-primary !text-org-silver !h-8 !rounded-md"}
                          buttonFunction={() => {
                            handelEditOpenModal(item);
                            setBannerDetails(item);
                          }}
                        />
                        <Button
                          buttonHasLink={false}
                          buttonIcon={"fa fa-info-circle"}
                          buttonIconPosition={"left"}
                          buttonClasses={"!bg-primary !text-org-silver !h-8 !rounded-md"}
                          buttonFunction={() => openModal(item?.imageUrl)}
                        />
                        <Button
                          buttonHasLink={false}
                          buttonIcon={"fa-light fa-trash"}
                          buttonIconPosition={"left"}
                          buttonClasses={"!bg-primary !text-org-silver !h-8 !rounded-md"}
                          buttonFunction={() => {
                            handelDeleteModal(item);
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {isModalOpen && (
            <div
              className="fixed inset-0 bg-black bg-opacity-40 flex justify-center items-center z-40"
              onClick={closeModal}>
              <div className="relative">
                <Image src={selectedImage} alt="Selected" className="w-full h-full object-cover " effect={"blur"} />
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="xl:w-full w-full bg-white rounded-md md:px-10 px-5 py-5 shadow-md">
          <div className="w-full h-auto overflow-hidden flex justify-center items-center">
            <div>
              <Image src={nodatafound} alt="No Data Found" effect="blur" className="w-full h-full object-cover" />
            </div>
          </div>
        </div>
      )}
      <Modal open={isOpen} title={`Manage Banner`} size="xl5">
        <AddBannerModal
          onClose={() => setIsOpen(false)}
          setLoaded={setLoaded}
          loaded={loaded}
          bannerId={bannerDetails?.id}
          setDetails={setData}
          getBannerDetails={getDetails}
        />
      </Modal>
      <DeleteModal
        open={deleteModal?.isOpen}
        close={() => setDeleteModal({ isOpen: false, id: null })}
        loading={deleteComplete}
        deleteHandler={onDelete}
      />
    </>
  );
};

export default PageGalleryItem;
