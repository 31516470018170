import { apiRequest } from "../util/apiUtils";

export const SignInService = async (params, setLoaded = () => {}, setResData = () => {}) => {
  setLoaded(false);
  try {
    const res = await apiRequest("post", "admin/authentication/login", {
      body: {
        email: params?.email,
        password: params?.password,
      },
    });
    const data = res.data;
    setResData(data);
    setLoaded(true);
    return res;
  } catch (error) {
    setResData(error);
    setLoaded(true);
    return error;
  }
};

export const SendOTP = async (params, setLoaded = () => {}, setResData = () => {}) => {
  setLoaded(false);
  try {
    const res = await apiRequest("post", "admin/authentication/sendOTP", {
      body: {
        email: params?.email,
      },
    });
    const data = res.data;
    setResData(data);
    setLoaded(true);
    return res;
  } catch (error) {
    setResData(error);
    setLoaded(true);
    return error;
  }
};

export const VerifyOTP = async (params, setLoaded = () => {}, setResData = () => {}) => {
  setLoaded(false);
  try {
    const res = await apiRequest("post", "admin/authentication/verifyOTP", {
      body: {
        email: params?.email,
        otp: params?.otp,
      },
    });
    const data = res.data;
    setResData(data);
    setLoaded(true);
    return res;
  } catch (error) {
    setResData(error);
    setLoaded(true);
    return error;
  }
};

export const ForgotPassword = async (params, setLoaded = () => {}, setResData = () => {}) => {
  setLoaded(false);
  try {
    const res = await apiRequest("post", "admin/authentication/forgotPassword", {
      body: {
        email: params?.email,
        password: params?.password,
        password_confirmation: params?.password_confirmation,
      },
    });
    const data = res.data;
    setResData(data);
    setLoaded(true);
    return res;
  } catch (error) {
    setResData(error);
    setLoaded(true);
    return error;
  }
};
