import React, { useState } from "react";
import Button from "../form/Button";
import Review from "./Review";
import Image from "../elements/Image";
import Modal from "../elements/Modal/Modal";
import LeaveFeedback from "../sections/LeaveFeedback";
import image1 from "../../assets/images/cards/card-inside-4.webp";
import { country } from "../../helpers";

const SingleViewDetailsProductList = ({ data, details }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div className="relative flex md:gap-10 gap-6 md:py-6 py-4">
        <div className="relative">
          <div className="md:w-32 md:h-32 w-24 h-24 overflow-hidden rounded-md">
            <Image
              src={data?.productImageUrl || image1}
              className={"w-full h-full object-fill"}
              alt="product image"
            />
          </div>
        </div>
        <div className="relative flex  w-full space-Y-10">
          <div className="relative w-full">
            <div className="md:text-xl text-base font-bold capitalize text-slate-900 leading-none">
              {data?.product_name}
            </div>
            <div className="relative md:text-base text-sm text-slate-600 mt-2">
              {data.title}
              {/* <span className="text-base ">{data.subtitle}</span> */}
            </div>
            <div>
              <Review
                rating={data?.averageRating}
                totalReviews={data?.totalReview}
                isReviewShow={true}
                classes={"md:flex block"}
              />
            </div>
            <div className="flex space-x-2 py-1 items-center">
              <div className="md:text-base text-sm">Quantity :</div>
              <div>{data?.quantity}</div>
            </div>
            <div className="md:text-xl text-base md:py-2 py-1 text-black font-bold absolute md:top-0 top-6 right-0">
              {data.price}
            </div>
            <div className="relative flex items-center gap-4">
              {/* <div className="relative text-xl  font-medium text-slate-900">Product name</div> */}

              <div className="w-full flex md:justify-end justify-start">
                <div className="text-center">
                  {/* <div className="flex space-x-2 items-center text-black md:!text-base text-xs !font-bold uppercase md:py-0 py-1">
            <div>Cad 20</div>
            <div className="text-xs font-semibold">X</div>
            <div>3</div>

          </div> */}
                  <div className="flex space-x-2 items-center text-black md:!text-base text-xs !font-bold uppercase md:py-0 py-1">
                    <div> {country + " " + (data?.total_price || 0.0)}</div>
                  </div>
                  {details?.status === 4 && data?.reviewExists === 0 && (
                    <div className="md:mt-auto mt-2">
                      <Button
                        buttonClasses={
                          "md:!px-4 !px-3 !py-1 md:!h-10 !h-7 !h-8 !text-white hover:!text-white !text-xl hover:!bg-black hover:!border-black !text-center bg-red-600 gap-3  transition duration-200 rounded-none leading-none !rounded-md"
                        }
                        buttonLabel="Leave Feedback"
                        buttonLabelClasses="md:!text-lg !text-sm"
                        buttonEffect={"filled"}
                        buttonFunction={() => setIsOpen(true)}
                      />
                      <Modal size="xl" modalTitle="" open={isOpen}>
                        <LeaveFeedback
                          onClose={() => setIsOpen(false)}
                          data={data}
                        />
                      </Modal>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* <div className="absolute md:top-0 right-0 top-12">
          <div className=" text-slate-700 flex justify-end">
        <Button
                    buttonClasses={
                      "!p-1 !text-red-600 !text-2xl hover:!text-slate-900 h-8 flex items-center justify-center transition-all duration-200 !rounded-full"
                    }
                    buttonLabelClasses={""}
                    buttonIcon={"fa-regular fa-trash"}
                    buttonIconPosition={"left"}
                    buttonHasLink={data?.isLink}
                    buttonLink={data?.link}
                    buttonFunction={data?.click}
                  />
        </div>
        
        </div> */}
        </div>
      </div>
    </>
  );
};

export default SingleViewDetailsProductList;
