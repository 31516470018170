import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { SendOTP } from "../../../services/loginService";
import Logo from "../../../assets/images/logo.png";
import { formValidate } from "../../../helpers/formValidate";
import Input from "../../../components/form/Input";
import Button from "../../../components/form/Button";
import useForm from "../../../hooks/useForm";
import Background from "../../../assets/images/loginBackground.jpg";
import { setIsLogin } from "../../../redux/slice/authSlice";
import { useDispatch } from "react-redux";
import Modal from "../../../components/elements/Modal/Modal";
import AdminVerifyOtp from "../verifyOtp/AdminVerifyOtp";
const validation = {
  email: { required: true, message: "Email is required" },
};

const AdminSendOtp = () => {
  const dispatch = useDispatch();
  const { values, handleChange, handleSubmit, errors, setFieldsValue } = useForm({}, validation);
  const backgroundStyle = {
    // backgroundColor: "#f1f5f9",
    backgroundImage: `url(${Background})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState({
    color: "red-500",
    message: null,
  });
  const [loaded, setLoaded] = useState(false);
  const [email, setEmail] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const handelOpenModal = () => {
    setIsOpen(true);
  };

  const onSubmit = async (values) => {
    setLoaded(true);
    try {
      const res = await SendOTP({
        email: values?.email,
      });
      if (res?.data?.status === 200) {
        setEmail(res?.data?.data?.email);
        setErrorMessage({
          color: "text-green-500",
          message: res?.data?.message,
        });
        toast.info(res.data.message);
      } else if (res?.data?.status === 400) {
        setErrorMessage({
          color: "text-red-500",
          message: res?.data?.message || res?.data?.errors,
        });
        toast.info(res?.data?.message || res?.data?.errors);
      } else {
        setErrorMessage({
          color: "text-red-500",
          message: res?.data?.message || res?.data?.errors,
        });
        toast.info(res?.data?.message || res?.data?.errors);
      }
      setLoaded(false);
    } catch (error) {
      toast.info("Something went wrong!!!");
      setErrorMessage({
        color: "text-red-500",
        message: error?.response?.data?.message || error.message || "Something went wrong!!!",
      });
      toast.error(error?.response?.data?.message || error.message);
      setLoaded(false);
    } finally {
      setLoaded(false);
    }
  };

  return (
    <div
      className="w-full min-h-screen flex items-center justify-center py-3 px-5"
      // style={backgroundStyle}
    >
      <div className="w-full max-w-md xl:max-w-md  bg-slate-50 shadow-md backdrop-blur-sm border border-slate-200 py-10 rounded-2xl">
        <div className="flex min-h-full flex-1 flex-col justify-center px-2 py-4 lg:px-2">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <img className="mx-auto h-20 w-auto" src={Logo} alt="Your Company" />
            <h2 className="mt-4 text-center text-xl font-bold leading-9 tracking-tight">
              Use you email address to recover your password.
            </h2>
            {/* <h4 className={`mt-1 text-center text-md font-bold leading-9 tracking-tight ${errorMessage?.color}`}>
              {errorMessage?.message}
            </h4> */}
          </div>

          <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-sm">
            <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
              <div className="mt-4">
                <Input
                  label={"Email"}
                  labelClasses={"!text-base !text-gray-700"}
                  inputType={"text"}
                  inputClasses={"!text-black !border border-slate-400"}
                  inputPlaceholder={"Enter your email"}
                  value={values?.email}
                  inputName={"email"}
                  onChange={handleChange}
                  {...formValidate(errors, "email")}
                />
              </div>

              <div className="mt-5">
                <Button
                  buttonClasses={
                    "w-full text-white hover:bg-secondaryColor focus-visible:outline justify-center rounded-md"
                  }
                  buttonHasLink={false}
                  buttonType={"submit"}
                  buttonLabel={"Reset Password"}
                  buttonIconPosition={"left"}
                  buttonIcon={"fa-sharp fa-regular fa-arrow-rotate-right"}
                  buttonFunction={handelOpenModal}
                />
              </div>
              <div className="mt-8 text-end">
                <Link to="/admin" className="text-sm font-bold  hover:text-">
                  <div className="flex justify-center items-center gap-1">
                    <span className="flex text-black">Already remembered your password?</span>
                  </div>
                  <div className="flex justify-center items-center mt-1">
                    {/* <i className="fa fa-arrow-left"></i> */}
                    <span className="text-red-600 ">Go to Login</span>
                  </div>
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Modal open={isOpen} size="xl">
        <AdminVerifyOtp onClose={() => setIsOpen(false)} email={email} />
      </Modal>
    </div>
  );
};

export default AdminSendOtp;
