import { apiRequest } from "../../util/apiUtils";

export const getProfileDetails = async (params) => {
  try {
    const res = await apiRequest("get", `front/profile/userProfile`, {});
    return res?.data;
  } catch (err) {
    return err;
  }
};

export const getUserWishlist = async (params) => {
  try {
    const res = await apiRequest("get", `front/profile/userWishList`, {});
    return res?.data;
  } catch (err) {
    return err;
  }
};
export const getUserOrders = async (params) => {
  try {
    const res = await apiRequest("get", `front/order/orderLIst`, {});
    return res?.data;
  } catch (err) {
    return err;
  }
};
export const getOrderDetails = async (params) => {
  try {
    const res = await apiRequest("get", `front/order/orderDetails`, {
      queries: {
        order_id: params?.id,
      },
    });
    return res?.data;
  } catch (err) {
    return err;
  }
};
export const editProfile = async (params) => {
  try {
    const res = await apiRequest(
      "post",
      "front/profile/userProfileUpdate",
      {
        body: params,
      },
      "multipart/form-data"
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const myShippingAddressEdit = async (params) => {
  try {
    const res = await apiRequest(
      params.id ? "post" : "post",
      params.id
        ? "front/profile/shippingAddressEdit"
        : "front/profile/shippingAddressEdit",
      {
        body: { ...params },
      }
      // "multipart/form-data"
    );
    return res;
  } catch (error) {
    return error;
  }
};
export const changePassword = async (params) => {
  try {
    const res = await apiRequest("post", "front/profile/changePassword", {
      body: params,
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const myBillingAddressEdit = async (params) => {
  try {
    const res = await apiRequest(
      params.id ? "post" : "post",
      params.id
        ? "front/profile/billingAddressEdit"
        : "front/profile/billingAddressEdit",
      {
        body: { ...params },
      }
      // "multipart/form-data"
    );
    return res;
  } catch (error) {
    return error;
  }
};
