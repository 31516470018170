import React, { useCallback, useEffect, useMemo, useState } from "react";
import CardImg from "./CardImg";
import Button from "../form/Button";
import SingleProduct from "../Items/SingleProduct";
import cardimg from "../../assets/images/cards/card-inside.webp";
import cardimg_2 from "../../assets/images/cards/card-inside-2.webp";
import cardimg_3 from "../../assets/images/cards/card-inside-3.webp";
import cardimg_4 from "../../assets/images/cards/card-inside-4.webp";
import cardimg_5 from "../../assets/images/cards/card-inside-5.webp";
import cardimg_6 from "../../assets/images/cards/card-inside-6.webp";
import Modal from "../elements/Modal/Modal";
import QuickViewModal from "./QuickViewModal";
import { productListData } from "../../services/frontService/ProductService";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { loginModalOpen } from "../../redux/slice/loginSlice";
import { useNavigate } from "react-router-dom";
import { getPageData } from "../../services/frontService/PageService";
import ProductLoader from "../loader/ProductLoader";

const OurCards = ({ addToCart = () => {} }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState({ data: {}, open: false });
  const isLogin = useSelector((state) => state?.auth?.isLogin);
  const [list, setList] = useState({
    loading: true,
    data: [],
    pageCount: 0,
    totalItem: 0,
  });

  const [homeData, setHomeData] = useState({});

  const loadPageData = useCallback(() => {
    getPageData({
      slug: "our-cards",
    }).then((res) => {
      if (res?.status === 200) {
        setHomeData(res?.data);
      }
    });
  }, []);

  useEffect(() => {
    loadPageData();
  }, [loadPageData]);

  const actions = useMemo(() => {
    return [
      // {
      //   _id: 1,
      //   icon: "fa-light fa-heart",
      //   isLink: false,
      //   isWishlist: true,
      //   isSolid: isSolid,
      //   click: (data) => {
      //     if (isLogin) {
      //       addWishList(data);
      //     } else {
      //       dispatch(loginModalOpen({ isOpen: true }));
      //     }
      //   },
      // },
      {
        _id: 2,
        icon: "fa-light fa-bag-shopping",
        isLink: false,
        click: (data) => {
          if (isLogin) {
            addToCart(data);
          } else {
            dispatch(loginModalOpen({ isOpen: true }));
          }
        },
      },
      {
        _id: 3,
        icon: "fa-light fa-arrow-right",
        isLink: false,
        click: (data) => {
          navigate(`/productdetails/${data?.alias}`);
        },
      },
      {
        _id: 4,
        icon: "fa-light fa-eye",
        isLink: false,
        click: (data) => setIsOpen({ open: true, data: data }),
      },
    ];
  }, [isLogin]);

  //  Product list >>>>>>>>>
  const loadList = useCallback(() => {
    setList((pre) => ({
      ...pre,
      data: [],
      loading: true,
    }));
    productListData({
      // perPage: 200,
      parent_category_slug: "cards",
      // status: 1,
      featured: 1,
    }).then((res) => {
      if (res && res?.success) {
        setList({
          loading: false,
          data: res?.docs,
          pageCount: res?.docs?.metadata?.totalPages,
          totalItem: res?.docs?.metadata?.totalDocs,
        });
      } else {
        setList((pre) => ({ ...pre, data: [], loading: true }));
        toast.error(res?.message);
      }
    });
  }, []);

  useEffect(() => {
    loadList();
  }, [loadList, isLogin]);

  const featuredProductData = {
    subheading: "Great Designs At The Best Price",
    heading: homeData?.name || "OUR CARDS",
    description:
      homeData?.description ||
      "HJL Designs is now carrying RJS designed labels for all RJS products. Like our non-branded labels, they are printed on high quality, easily removable nylon at a size of 9.5 cm x 7cm without leaving any residue behind.",
    subdata: list && list?.data?.length > 0 ? list?.data : [],
  };
  const loaders = Array(8).fill(null);
  return (
    <section className="sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto md:px-0 px-5">
      <div className=" relative w-full items-end">
        <div className="text-center xl:pt-24 pt-16 xl:px-40 px-0 w-full">
          <div className="md:text-xl text-sm text-gray-700">
            {featuredProductData.subheading}
          </div>
          <h2 className="great-design xl:text-6xl md:text-5xl text-2xl font-bold pt-2 pb-4 drop-shadow-md uppercase">
            <span className="relative inline-block !leading-none before:content-[''] before:absolute before:top-2/4 before:-translate-y-1/2 md:before:-left-36 before:-left-20 md:before:w-32 before:w-16 before:border-t-2 before:border-black after:content-[''] after:absolute after:top-2/4 after:-translate-y-1/2 md:after:-right-36 after:-right-20 md:after:w-32 after:w-16 after:border-t-2 after:border-black">
              {featuredProductData.heading}
            </span>
          </h2>
          <div
            className="md:text-lg text-sm pb-3 text-gray-800"
            dangerouslySetInnerHTML={{
              __html: featuredProductData?.description || "",
            }}
          >
            {/* {featuredProductData.description} */}
          </div>
        </div>

        <div className="w-full text-end flex xl:justify-end justify-center">
          <div className="xl:mt-0 mt-4 ">
            <Button
              buttonClasses={
                "md:!px-12 px-6 md:!p-8 p-3 !h-10 !text-4xl !text-white !text-xl hover:!bg-black !text-center bg-red-600 gap-3 hover:scale-110 transition duration-300"
              }
              buttonLabel="Explore Our Cards"
              buttonLabelClasses=""
              buttonIcon={"fa-regular fa-arrow-right"}
              buttonIconPosition={"right"}
              buttonEffect={"filled"}
              buttonHasLink={"true"}
              buttonLink={"/cards"}
            />
          </div>
        </div>
      </div>
      {/* <div className="flex justify-center mt-6">
        <div className=" bg-slate-100 flex justify-center px-28 py-8 rounded-md shadow-md">
          <div className="pr-5 font-bold text-2xl text-red-600">
            <i class="fa-sharp fa-solid fa-circle-exclamation"></i>
          </div>
          <div className=" text-2xl font-bold text-black">
            No <span className=" text-red-600  font-semibold">Data</span>
          </div>
        </div>
      </div> */}
      {/* <div className="flex justify-center"> */}
      {list?.loading ? (
        <>
          <div className="grid xl:grid-cols-4 md:grid-cols-3 grid-cols-2 xl:gap-8 md:gap-8 gap-5 md:pt-14 pt-10">
            {loaders?.map((_, index) => (
              <ProductLoader key={index} />
            ))}
          </div>
        </>
      ) : (
        featuredProductData &&
        Array.isArray(featuredProductData.subdata) &&
        featuredProductData?.subdata?.length > 0 && (
          <div className="grid xl:grid-cols-4 md:grid-cols-3 grid-cols-2 xl:gap-8 md:gap-8 gap-5 md:pt-14 pt-10">
            {featuredProductData?.subdata?.map((item, index) => (
              <SingleProduct
                key={index}
                data={item}
                lable={cardimg_2}
                actions={actions}
              />
            ))}
          </div>
        )
      )}
      {/* </div> */}
      <Modal
        size="xl4"
        modalTitle=""
        open={isOpen?.open}
        onClose={() => setIsOpen({ open: false })}
      >
        <QuickViewModal
          onClose={() => setIsOpen({ open: false })}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
        />
      </Modal>
    </section>
  );
};

export default OurCards;
