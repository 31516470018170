import React, { useCallback, useEffect, useState } from "react";
import testimonialimg from "../../assets/images/testimonial/testimonial-img-1.webp";
import testimonialimg_2 from "../../assets/images/testimonial/testimonial-img-2.webp";
import testimonialimg_3 from "../../assets/images/testimonial/testimonial-img-3.webp";
import TestimonialItems from "../Items/TestimonialItems";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { Autoplay } from "swiper/modules";
import { Pagination, Navigation } from "swiper/modules";
import { testimonialListData } from "../../services/frontService/TestimonialService";
import { toast } from "react-toastify";
import { getPageData } from "../../services/frontService/PageService";
import TestimonialBoxLoader from "../loader/TestimonialBoxLoader";

const Testimonial = () => {
  const [list, setList] = useState({
    loading: true,
    data: [],
    pageCount: 0,
    totalItem: 0,
  });

  const [homeData, setHomeData] = useState({});

  const loadPageData = useCallback(() => {
    getPageData({
      slug: "testimonials",
    }).then((res) => {
      if (res?.status === 200) {
        setHomeData(res?.data);
      }
    });
  }, []);

  useEffect(() => {
    loadPageData();
  }, [loadPageData]);

  //  Product list >>>>>>>>>
  const loadList = useCallback(() => {
    setList((pre) => ({
      ...pre,
      data: [],
      loading: true,
    }));
    testimonialListData({
      perPage: 20,
      // status: 1,
      // featured: 1,
    }).then((res) => {
      if (res && res?.docs?.success) {
        setList({
          loading: false,
          data: res?.docs?.data,
          pageCount: res?.docs?.metadata?.totalPages,
          totalItem: res?.docs?.metadata?.totalDocs,
        });
      } else {
        setList((pre) => ({ ...pre, data: [], loading: true }));
        toast.error(res?.message);
      }
    });
  }, []);

  useEffect(() => {
    loadList();
  }, [loadList]);

  const swiperParams = {
    pagination: {
      clickable: true,
      dynamicBullets: true,
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      1280: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      1900: {
        slidesPerView: 3,
        spaceBetween: 40,
      },
    },
  };

  const testimonialItemstData = {
    subheading: "Great Designs At The Best Price",
    heading: homeData?.name || "TESTIMONIALS",
    description:
      homeData?.description ||
      "HJL Designs is now carrying RJS designed labels for all RJS products. Like our non-branded labels, they are printed on high quality, easily removable nylon at a size of 9.5 cm x 7cm without leaving any residue behind.",

    subdata: list && list?.data?.length > 0 ? list?.data : [],
  };

  const loaders = Array(3).fill(null);

  return (
    <section className="sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto md:px-0 px-5">
      <div className="text-center md:pt-24 pt-16 xl:px-32 px-0">
        <div className="md:text-xl text-sm text-gray-700">
          {testimonialItemstData?.subheading}
        </div>
        <h2 className="great-design xl:text-6xl md:text-5xl text-2xl font-bold pt-2 pb-4 drop-shadow-md uppercase">
          <span className="relative inline-block !leading-none before:content-[''] before:absolute before:top-2/4 before:-translate-y-1/2 md:before:-left-36 before:-left-20 md:before:w-32 before:w-16 before:border-t-2 before:border-black after:content-[''] after:absolute after:top-2/4 after:-translate-y-1/2 md:after:-right-36 after:-right-20 md:after:w-32 after:w-16 after:border-t-2 after:border-black">
            {testimonialItemstData?.heading}
          </span>
        </h2>
        <div
          className="md:text-lg text-sm pb-3 text-gray-800"
          dangerouslySetInnerHTML={{
            __html: testimonialItemstData?.description || "",
          }}
        >
          {/* {testimonialItemstData.description} */}
        </div>
      </div>

      <div className="flex py-9 xl:pt-20 md:pt-12 pt-10 h-full">
        {list?.loading ? (
          <div className="grid xl:grid-cols-3 md:grid-cols-3 grid-cols-2  gap-8">
            {loaders?.map((_, index) => (
              <TestimonialBoxLoader key={index} />
            ))}
          </div>
        ) : (
          testimonialItemstData?.subdata?.length > 0 && (
            <div className="relative w-full">
              {
                <>
                  <Swiper
                    {...swiperParams}
                    navigation={{
                      nextEl: ".swiper-button-next",
                      prevEl: ".swiper-button-prev",
                    }}
                    modules={[Navigation]}
                    className="relative"
                  >
                    {testimonialItemstData?.subdata?.map((item, index) => (
                      <SwiperSlide key={index}>
                        <TestimonialItems data={item} />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  <div className="swiper-button-next !text-red-600 md:!-right-10 !-right-0 !z-[9]"></div>
                  <div className="swiper-button-prev !text-red-600 md:!-left-10 !-left-0 !z-[9]"></div>
                </>
              }
            </div>
          )
        )}
      </div>
    </section>
  );
};

export default Testimonial;
