import React, { useState } from "react";
import { setTitle } from "../../../helpers/MetaTag";
import AllProductList from "./AllProductList";
import Labels from "./Labels";
import Cards from "./Cards";
import RJSLabels from "./RJSLabels";
import Masks from "./Masks";
import Tabs from "../../../components/elements/Tabs";

const List = () => {
  setTitle("HJL");

  const [activeTab, setActiveTab] = useState(1);
  const [taballData, setTabAllData] = useState("");
  const tabData = [
    {
      _id: 1,
      name: "All Products",
      component: <AllProductList />,
    },
    {
      _id: 2,
      name: "Labels",
      slug: "labels",
      component: <Labels taballData={taballData} />,
    },
    {
      _id: 3,
      name: "RJS Labels",
      slug: "rjs-labels",
      component: <RJSLabels taballData={taballData} />,
    },
    {
      _id: 4,
      name: "Cards",
      slug: "cards",
      component: <Cards taballData={taballData} />,
    },
    {
      _id: 5,
      name: "Masks",
      slug: "masks",
      component: <Masks taballData={taballData} />,
    },
  ];

  return (
    <>
      <div className="w-full py-4 md:px-6 px-0">
        <div className="font-bold text-3xl m-1 text-secondary"></div>
        <div className="relative w-full">
          <Tabs
            tabs={tabData}
            selectedTabId={activeTab}
            onTab={(tab) => {
              setActiveTab(tab?._id);
              setTabAllData(tab?.slug);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default List;
