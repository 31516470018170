import React from 'react';

const SearchBar = () => {
    return (
        <>
        <div>
        <input
                  type="text"
                  className="w-full rounded-lg"
                  placeholder="Search..."
                />
        </div>
            
        </>
    );
};

export default SearchBar;