import React from "react";
import lable from "../../src/assets/images/labels/lable-card-5.webp";
import lable_2 from "../../src/assets/images/labels/lable-card-2.webp";
import lable_3 from "../../src/assets/images/labels/lable-card-1.webp";
import lable_4 from "../../src/assets/images/labels/lable-card-6.webp";
import lable_5 from "../../src/assets/images/labels/lable-card-3.webp";
import lable_6 from "../../src/assets/images/labels/lable-card-4.webp";
import lable_7 from "../../src/assets/images/labels/lable-card-8.webp";
import lable_8 from "../../src/assets/images/labels/lable-card-5.webp";
import SingleProduct from "../components/Items/SingleProduct";

const featuredProductData = {
  subheading: "Great Designs At The Best Price",
  heading: "OUR LABELS",
  description:
    "HJL Designs is a manufacturer and wholesaler of high quality vinyl labels for the craft wine making industry. With a mix of modern and traditional designs, our ever-expanding lineup of 9.5 cm x 7 cm labels fit any bottle size, are easy to remove, and  do not leave any residue behind.",
  subdata: [
    {
      _id: 1,
      name: "Pinot Gris (die cut)",
      image: lable,
      isLink: true,
      link: "/productdetails",
      actions: [
        {
          _id: 1,
          icon: "fa-light fa-heart",
          isLink: false,
          click: () => {},
        },
        {
          _id: 2,
          icon: "fa-light fa-bag-shopping",
          isLink: true,
          link: "/cart",
        },
        {
          _id: 3,
          icon: "fa-light fa-arrow-right",
          isLink: true,
          link: "/productdetails",
        },
      ],
    },
    {
      _id: 2,
      name: "California White (Die Cut)",
      image: lable_2,
      actions: [
        {
          _id: 1,
          icon: "fa-light fa-heart",
          isLink: false,
          click: () => {},
        },
        {
          _id: 2,
          icon: "fa-light fa-bag-shopping",
          isLink: true,
          link: "/cart",
        },
        {
          _id: 3,
          icon: "fa-light fa-arrow-right",
          isLink: true,
          link: "/productdetails",
        },
      ],
    },
    {
      _id: 3,
      name: "California White (Die Cut)",
      image: lable_3,
      actions: [
        {
          _id: 1,
          icon: "fa-light fa-heart",
          isLink: false,
          click: () => {},
        },
        {
          _id: 2,
          icon: "fa-light fa-bag-shopping",
          isLink: true,
          link: "/cart",
        },
        {
          _id: 3,
          icon: "fa-light fa-arrow-right",
          isLink: true,
          link: "/productdetails",
        },
      ],
    },
    {
      _id: 4,
      name: "California White (Die Cut)",
      image: lable_4,
      actions: [
        {
          _id: 1,
          icon: "fa-light fa-heart",
          isLink: false,
          click: () => {},
        },
        {
          _id: 2,
          icon: "fa-light fa-bag-shopping",
          isLink: true,
          link: "/cart",
        },
        {
          _id: 3,
          icon: "fa-light fa-arrow-right",
          isLink: true,
          link: "/productdetails",
        },
      ],
    },
    {
      _id: 5,
      name: "California White (Die Cut)",
      image: lable_5,
      actions: [
        {
          _id: 1,
          icon: "fa-light fa-heart",
          isLink: false,
          click: () => {},
        },
        {
          _id: 2,
          icon: "fa-light fa-bag-shopping",
          isLink: true,
          link: "/cart",
        },
        {
          _id: 3,
          icon: "fa-light fa-arrow-right",
          isLink: true,
          link: "/productdetails",
        },
      ],
    },
    {
      _id: 6,
      name: "California White (Die Cut)",
      image: lable_6,
      actions: [
        {
          _id: 1,
          icon: "fa-light fa-heart",
          isLink: false,
          click: () => {},
        },
        {
          _id: 2,
          icon: "fa-light fa-bag-shopping",
          isLink: true,
          link: "/cart",
        },
        {
          _id: 3,
          icon: "fa-light fa-arrow-right",
          isLink: true,
          link: "/productdetails",
        },
      ],
    },
    {
      _id: 7,
      name: "California White (Die Cut)",
      image: lable_7,
      actions: [
        {
          _id: 1,
          icon: "fa-light fa-heart",
          isLink: false,
          click: () => {},
        },
        {
          _id: 2,
          icon: "fa-light fa-bag-shopping",
          isLink: true,
          link: "/cart",
        },
        {
          _id: 3,
          icon: "fa-light fa-arrow-right",
          isLink: true,
          link: "/productdetails",
        },
      ],
    },
    {
      _id: 8,
      name: "California White (Die Cut)",
      image: lable_8,
      actions: [
        {
          _id: 1,
          icon: "fa-light fa-heart",
          isLink: false,
          click: () => {},
        },
        {
          _id: 2,
          icon: "fa-light fa-bag-shopping",
          isLink: true,
          link: "/cart",
        },
        {
          _id: 3,
          icon: "fa-light fa-arrow-right",
          isLink: true,
          link: "/productdetails",
        },
      ],
    },
  ],
};

const FavouritesAll = () => {
  return (
    <>
      <div className="relative xl:pt-40 md:pt-32 pt-24 w-full sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto md:px-auto px-5">
        <div className="pb-14">
        <div className="md:text-3xl text-2xl pb-2 font-medium">
          Your All <span className="text-red-600 font-extrabold">wishlist Is Here</span>
        </div>
        <hr className="w-full border-1 border-slate-500 "></hr>
        </div>
        <div>
          {featuredProductData?.subdata?.length > 0 && (
            <div className="grid xl:grid-cols-4 md:grid-cols-3 grid-cols-2 xl:gap-12 md:gap-8 gap-5">
              {featuredProductData.subdata.map((item, index) => (
                <SingleProduct key={index} data={item} />
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default FavouritesAll;
