import { apiRequest } from "../../util/apiUtils";

export const loginService = async (params, setLoaded = () => {}, setResData = () => {}) => {
  setLoaded(false);
  try {
    const res = await apiRequest("post", "front/authentication/login", {
      body: {
        email: params?.email,
        password: params?.password,
      },
    });
    const data = res.data;
    setResData(data);
    setLoaded(true);
    return res;
  } catch (error) {
    setResData(error);
    setLoaded(true);
    return error;
  }
};

export const userVerifyTokan = async (params) => {
  try {
    const res = await apiRequest("get", "admin/authentication/verifyToken", {});
    return res;
  } catch (error) {
    return error;
  }
};
