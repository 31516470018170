import React from "react";

const PageHeader = ({ title, headerActions = null }) => {
  return (
    <>
      <section className="sticky top-0 z-[9] bg-slate-100 md:px-6 px-0 py-2 w-full mt-4">
        <div className="flex justify-between">
          <div className="lg:text-2xl md:text-lg text-base font-bold text-primary md:leading-10 leading-norma px-2">
            {title}
          </div>
          <div className="ml-auto flex justify-end">
            {headerActions && headerActions()}
          </div>
        </div>
      </section>
    </>
  );
};

export default PageHeader;
