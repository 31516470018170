import React from "react";

const ProductDetailsLoader = ({ isSide = true }) => {
  return (
    <div className="p-4 md:p-8">
      <div className="xl:flex block items-center">
        <div className="flex md:flex-row flex-col-reverse xl:w-1/2 w-full">
          {isSide && (
            <>
              <div className="md:block flex md:space-y-4 space-y-0 md:space-x-0 space-x-4 pr-5">
                <svg
                  className="w-20 h-20 text-gray-200 dark:text-gray-600"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 18"
                >
                  <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                </svg>
                <svg
                  className="w-20 h-20 text-gray-200 dark:text-gray-600"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 18"
                >
                  <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                </svg>
                <svg
                  className="w-20 h-20 text-gray-200 dark:text-gray-600"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 18"
                >
                  <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                </svg>
                <svg
                  className="w-20 h-20 text-gray-200 dark:text-gray-600"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 18"
                >
                  <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                </svg>
              </div>
            </>
          )}

          <div className="mb-6 xl:mb-0 w-full">
            <div
              role="status"
              className="flex items-center justify-center h-[430px] bg-gray-300 rounded-lg animate-pulse dark:bg-gray-700"
            >
              <svg
                className="w-12 h-12 text-gray-200 dark:text-gray-600"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 18"
              >
                <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
              </svg>
            </div>
          </div>
        </div>
        <div className="col-span-3 space-y-5 xl:w-3/5 w-full xl:pl-8 pl-0">
          <div className="w-full relative">
            <div className="flex items-center justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid"
                width="50"
                height="50"
              >
                <g>
                  <rect fill="#8CB1C5" height="40" width="15" y="30" x="17.5">
                    <animate
                      begin="-0.2s"
                      keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
                      values="18;30;30"
                      keyTimes="0;0.5;1"
                      calcMode="spline"
                      dur="1s"
                      repeatCount="indefinite"
                      attributeName="y"
                    />
                    <animate
                      begin="-0.2s"
                      keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
                      values="64;40;40"
                      keyTimes="0;0.5;1"
                      calcMode="spline"
                      dur="1s"
                      repeatCount="indefinite"
                      attributeName="height"
                    />
                  </rect>
                  <rect fill="#EAEFF3" height="40" width="15" y="30" x="42.5">
                    <animate
                      begin="-0.1s"
                      keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
                      values="20.999999999999996;30;30"
                      keyTimes="0;0.5;1"
                      calcMode="spline"
                      dur="1s"
                      repeatCount="indefinite"
                      attributeName="y"
                    />
                    <animate
                      begin="-0.1s"
                      keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
                      values="58.00000000000001;40;40"
                      keyTimes="0;0.5;1"
                      calcMode="spline"
                      dur="1s"
                      repeatCount="indefinite"
                      attributeName="height"
                    />
                  </rect>
                  <rect fill="#8CB1C5" height="40" width="15" y="30" x="67.5">
                    <animate
                      keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
                      values="20.999999999999996;30;30"
                      keyTimes="0;0.5;1"
                      calcMode="spline"
                      dur="1s"
                      repeatCount="indefinite"
                      attributeName="y"
                    />
                    <animate
                      keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
                      values="58.00000000000001;40;40"
                      keyTimes="0;0.5;1"
                      calcMode="spline"
                      dur="1s"
                      repeatCount="indefinite"
                      attributeName="height"
                    />
                  </rect>
                </g>
              </svg>
            </div>
          </div>

          <div className="space-y-2">
            <div className="h-6 bg-gray-200 rounded-full dark:bg-gray-700 w-2/3 mb-2"></div>
            <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-2"></div>
            <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-4/5 mb-2"></div>
            <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-3/4 mb-2"></div>
            <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-2/4"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetailsLoader;
