import React, { useState } from "react";
import RecentOrderTable from "./RecentOrderTable";
import UserBillingAddressTable from "./UserBillingAddressTable";
import UserShippingAddressTable from "./UserShippingAddressTable";
import Button from "../../form/Button";
import Modal from "../../elements/Modal/Modal";
import { AddEditUserBillingAddressModal } from "./AddEditUserBillingAddressModal";
import { AddEditUserShippingAddressModal } from "./AddEditUserShippingAddressModal";

const UserAddress = ({
  data,
  setDetailsData = () => {},
  getDetails = () => {},
  activeTab,
  setActiveTab = () => {},
}) => {
  const [isBillingOpen, setIsBillingOpen] = useState(false);
  const [isShippingOpen, setIsShippingOpen] = useState(false);

  const tabs = [
    {
      label: "Billing Address",
      content: (
        <div>
          <UserBillingAddressTable
            id={data?.id}
            data={data?.userBillingAdderssInfo}
            setDetailsData={setDetailsData}
            getDetails={getDetails}
          />
        </div>
      ),
    },
    {
      label: "Shipping Address",
      content: (
        <div>
          <UserShippingAddressTable
            id={data?.id}
            data={data?.userShippingAddressInfo}
            setDetailsData={setDetailsData}
            getDetails={getDetails}
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="">
        <div className="flex-none  border-gray-300">
          <ul className=" flex space-x-5 mb-6 mt-4">
            {tabs.map((tab, index) => (
              <li
                key={index}
                className={`cursor-pointer p-2 rounded-lg ${
                  activeTab === index
                    ? "bg-red-600 text-white"
                    : "bg-slate-100 border border-red-600 text-red-600 hover:!border-red-600 hover:shadow-md hover:text-red-600"
                }`}
                onClick={() => setActiveTab(index)}>
                {tab.label}
              </li>
            ))}
          </ul>
        </div>
        <div className="w-full">
          <div className="md:p-0 p-0 pt-0 md:pt-2 bg-white shadow-md">
            <div className="mt-2">{tabs[activeTab].content}</div>
          </div>
        </div>
      </div>
      {/* <div className="md:p-0 p-0 pt-0 md:pt-2 bg-white shadow-md">
        <UserAddressTable data={data?.data} />
      </div> */}
      <Modal open={isBillingOpen} title={`Billing Address`} size="xl2">
        <AddEditUserBillingAddressModal onClose={() => setIsBillingOpen(false)} />
      </Modal>
      <Modal open={isShippingOpen} title={`Shipping Address`} size="xl2">
        <AddEditUserShippingAddressModal onClose={() => setIsShippingOpen(false)} id={data?.id} />
      </Modal>
    </div>
  );
};

export default UserAddress;
