import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Table from "../../../components/elements/table/Table";
import { status_filter } from "../../../constant/Constant";
import Select from "../../../components/form/Select";
import Input from "../../../components/form/Input";
import { setTitle } from "../../../helpers/MetaTag";
import DeleteModal from "../../../components/common/DeleteModal";
import { changeEnquiryStatus, deleteEnquiry, getEnquiryList } from "../../../services/enquiryService";
import { useDebounce } from "../../../hooks/useDebounce";
import Button from "../../../components/form/Button";
import Modal from "../../../components/elements/Modal/Modal";
import { EnquiryDetailsModal } from "./EnquiryDetailsModal";

const EnquiryList = () => {
  setTitle("HJL");
  const navigate = useNavigate();
  const [limit, setLimit] = useState(10);
  const [status, setStatus] = useState("all");
  const [featured, setFeatured] = useState(" ");
  const [offset, setOffset] = useState(0);
  const [sort, setSort] = useState({ orderBy: "", ordering: "DESC" });
  const [currentPage, setCurrentPage] = useState(1);

  const [pagination, setPagination] = useState({
    hasNextPage: false,
    hasPrevPage: false,
  });
  const [statusFilterData, setStatusFilterData] = useState(status_filter || []);
  const [keyword, setKeyword] = useState("");
  const search = useDebounce(keyword, 200);
  const [list, setList] = useState({
    loading: true,
    data: [],
    pageCount: 0,
    totalItem: 0,
  });
  const [count, setCount] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]);
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    id: null,
  });
  const [deleteComplete, setDeleteComplete] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [details, setDetails] = useState({});

  const data = [
    { name: "All Items", value: "All" },
    { name: "5 Items", value: 5 },
    { name: "10 Items", value: 10 },
    { name: "20 Items", value: 20 },
    { name: "30 Items", value: 30 },
    { name: "50 Items", value: 50 },
    { name: "100 Items", value: 100 },
  ];

  //Page data limit function
  const handleChange = (value) => {
    if (value?.value !== "All") {
      setOffset(0);
      setLimit(value?.value);
      setSelectedItems([]);
    } else {
      setOffset(0);
      setLimit(count);
      setCurrentPage(1);
      setSelectedItems([]);
    }
  };

  //   loading the member list >>>>>>>>>
  const loadList = useCallback(() => {
    setList((pre) => ({
      ...pre,
      data: [],
      loading: true,
    }));
    getEnquiryList({
      ...sort,
      keyword: search,
      perPage: limit,
      page: currentPage,
    }).then((res) => {
      if (res && res?.docs?.success) {
        setList({
          loading: false,
          data: res.docs?.data,
          pageCount: res?.docs?.metadata?.totalPages,
          keyword: search,
          totalItem: res?.docs?.metadata?.totalDocs,
        });
        setCount(res?.docs?.metadata?.totalDocs);
        setPagination({
          hasNextPage: res?.docs?.metadata?.hasNextPage,
          hasPrevPage: res?.docs?.metadata?.hasPrevPage,
        });
      } else {
        setList((pre) => ({ ...pre, data: [], loading: false }));
        toast.error(res?.message);
      }
    });
  }, [limit, status, sort, featured, currentPage, count, search]);

  useEffect(() => {
    loadList();
  }, [loadList]);

  // Pagination Logic
  // useEffect(() => {
  //   if (list?.data?.length <= 0 && pagination?.hasNextPage) {
  //     setCurrentPage(currentPage);
  //   } else if (list?.data?.length <= 0 && !pagination?.hasPrevPage) {
  //     setCurrentPage(1);
  //   } else if (list?.data?.length <= 0) {
  //     setCurrentPage(currentPage - 1);
  //   }
  // }, [list?.data.length]);

  //Refresh Function

  const refreshData = () => {
    setLimit(10);
    setKeyword("");
    setSelectedItems([]);
  };

  const viewData = (item) => {
    setIsOpen(true);
    setDetails(item);
  };

  //Table Heading Data >>>>>>>>>

  const tableHeadData = [
    {
      _id: 1,
      name: "Name",
      align: "left",
      isSort: true,
      isFilter: false,
      isFilterSearch: false,
      onSort: (val) => {
        if (val === "DESC") {
          setSort({ orderBy: "name", ordering: "DESC" });
        } else {
          setSort({ orderBy: "name", ordering: "ASC" });
        }
      },
    },
    {
      _id: 2,
      name: "Email",
      align: "left",
      isSort: true,
      isFilter: false,
      isFilterSearch: false,
      onSort: (val) => {
        if (val === "DESC") {
          setSort({ orderBy: "email", ordering: "DESC" });
        } else {
          setSort({ orderBy: "email", ordering: "ASC" });
        }
      },
    },
    {
      _id: 3,
      name: "Phone",
      align: "left",
      isSort: false,
      isFilter: false,
      isFilterSearch: false,
    },
    {
      _id: 3,
      name: "Message",
      align: "left",
      isSort: false,
      isFilter: false,
      isFilterSearch: false,
    },
    {
      _id: 4,
      name: "Created At",
      align: "left",
      isFilter: false,
      isSort: false,
    },
    {
      _id: 5,
      name: "Action",
      align: "left",
      isFilter: false,
      isSort: false,
    },
  ];

  // Table Body Data >>>>>>>>>
  const tableData = list?.data?.map((item, index) => ({
    _id: item?.id,
    rowData: [
      {
        _id: 1,
        type: "user",
        align: "left",
        cellClass: "!min-w-[100px]",
        data: item?.name || "N/A",
        image: item?.imageUrl,
      },
      {
        _id: 2,
        type: "text",
        align: "left",
        cellClass: "!min-w-[100px]",
        data: item?.email || "N/A",
      },
      {
        _id: 3,
        type: "text",
        align: "left",
        cellClass: "!min-w-[100px]",
        data: item?.phone || "N/A",
      },
      {
        _id: 4,
        type: "text",
        align: "left",
        cellClass: "!min-w-[100px]",
        data: (
          <div
            dangerouslySetInnerHTML={{
              __html: item?.message?.substring(0, 25) + "...",
            }}
          />
        ),
      },

      {
        _id: 4,
        type: "text",
        align: "left",
        cellClass: "!min-w-[100px]",
        data: item?.created_at ? moment(item?.created_at).format("MM-DD-YYYY") : "N/A",
      },
      {
        _id: 5,
        type: "action",
        align: "left",
        cellClass: "w-[5%]",
        actionData: [
          {
            _id: 1,
            name: "View Details",
            icon: "fa-regular fa-eye",
            standout: false,
            onClick: () => viewData(item),
          },
          {
            _id: 2,
            name: "Delete",
            icon: "fa-regular fa-trash-can",
            standout: true,
            onClick: () => {
              setDeleteModal({
                isOpen: true,
                id: item?.id,
              });
            },
          },
        ],
      },
    ],
  }));

  // data delte api call
  // const onDelete = (item) => {
  //   setDeleteComplete(true);
  //   if (deleteModal?.id) {
  //     try {
  //       deleteEnquiry({ id: deleteModal?.id }).then((res) => {
  //         if (res?.status === 200) {
  //           toast?.success(res?.message);
  //           setList((prevList) => ({
  //             ...prevList,
  //             data: prevList.data.filter((item) => item.id !== deleteModal.id),
  //           }));
  //           setDeleteModal({
  //             isOpen: false,
  //             id: null,
  //           });
  //         } else {
  //           toast.error(res?.message || res?.error);
  //         }
  //       });
  //       setDeleteComplete(false);
  //     } catch (error) {
  //       setDeleteComplete(false);
  //       // toast.error(error);
  //     }
  //   }
  // };

  const onDelete = async () => {
    if (!deleteModal?.id) {
      toast.error("No enquiry ID specified for deletion");
      return;
    }

    setDeleteComplete(true);

    try {
      const res = await deleteEnquiry({ id: deleteModal.id });

      if (res?.status === 200) {
        toast.success(res?.message || "Enquiry deleted successfully");

        setList((prevList) => ({
          ...prevList,
          data: prevList.data.filter((item) => item.id !== deleteModal.id),
          totalItem: prevList.totalItem - 1,
        }));

        setDeleteModal({ isOpen: false, id: null });

        if (list.data.length === 1 && pagination.hasNextPage) {
          const nextPageItems = await getEnquiryList({
            ...sort,
            keyword: search,
            perPage: limit,
            page: currentPage,
          });

          if (nextPageItems?.docs?.data?.length > 0) {
            const firstNextPageItem = nextPageItems.docs.data[0];

            setList((prevList) => ({
              ...prevList,
              data: [...prevList.data, firstNextPageItem],
              totalItem: prevList.totalItem - 1,
            }));

            setList((prevList) => ({
              ...prevList,
              data: nextPageItems.docs.data.slice(1),
            }));
          }
        }

        if (list.data.length === 1 && currentPage > 1) {
          setCurrentPage((prevPage) => prevPage - 1);
        }

        loadList();
      } else {
        toast.error(res?.message || "Failed to delete enquiry");
      }
    } catch (error) {
      toast.error(error?.message || "An error occurred while deleting the enquiry");
    } finally {
      setDeleteComplete(false);
    }
  };

  return (
    <>
      <div className="w-full py-4 md:px-6 px-0">
        <div className="font-bold text-3xl m-1 text-secondary mb-5 border-b border-gray-300 pb-2">Enquiry</div>
        <div className="mb-6 md:flex block gap-3 md:space-y-0 space-y-2">
          <div className="md:w-40 w-full">
            <Select
              dropdownData={data}
              placeholder={"Select Limit"}
              dropdownButtonClass={"!h-10 !rounded-md "}
              selectedValue={limit}
              onChange={handleChange}
            />
          </div>
          <div className="w-80">
            <Input
              inputType={"text"}
              isInputGroup={true}
              inputGroupPosition={"left"}
              inputGroupIcon={"fa-regular fa-search"}
              inputPlaceholder={"Search"}
              inputClasses={"!pl-3"}
              value={keyword}
              onChange={(e) => {
                setKeyword(e.target.value);
                setCurrentPage(1);
                setSelectedItems([]);
              }}
            />
          </div>
          {/* {selectedItems.length > 0 && (
            <>
              <div className="relative">
                <Button
                  buttonHasLink={false}
                  buttonIcon={"fa-regular fa-badge-check"}
                  buttonIconPosition={"left"}
                  buttonClasses={"!bg-org-green !border-org-border !text-white"}
                  buttonLabel={"Active"}
                  buttonLabelClasses={"font-medium !text-sm"}
                  buttonFunction={handleActiveStatusToggle}
                />
              </div>
            </>
          )}
          {selectedItems.length > 0 && (
            <>
              <div className="relative">
                <Button
                  buttonHasLink={false}
                  buttonIcon={"fa-regular fa-circle-xmark"}
                  buttonIconPosition={"left"}
                  buttonClasses={"!bg-org-red !border-org-border !text-white"}
                  buttonLabel={"In Active"}
                  buttonLabelClasses={"font-medium !text-sm"}
                  buttonFunction={handleInactiveStatusToggle}
                />
              </div>
            </>
          )} */}

          <div className="ml-auto flex justify-end space-x-2">
            <Button
              buttonHasLink={false}
              buttonIcon={"fa-solid fa-arrows-rotate"}
              buttonIconPosition={"left"}
              buttonClasses={
                "!bg-secondary !h-10 !text-white !text-bold hover:!bg-secondary !border-org-theme  hover:!text-white"
              }
              buttonLabel={"Refresh"}
              buttonLabelClasses={"font-medium !text-md"}
              buttonFunction={refreshData}
            />
          </div>
        </div>
        <div className="md:-mx-8 -mx-6">
          <Table
            tableHeadData={tableHeadData}
            tableData={tableData}
            containerClasses={"px-6"}
            isLoder={list?.loading}
            pageCount={list?.pageCount}
            currentPage={currentPage}
            onPageChange={(val) => {
              setOffset(limit * val?.selected);
              setCurrentPage(val?.selected);
              setSelectedItems([]);
            }}
            isOrderingDisabled={true}
            originalData={[...list.data]}
            // handleOrdering={handleOrdering}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            isCheckbox={false}
            setOriginalData={(data) => setList((cur) => ({ ...cur, data }))}
          />
        </div>
      </div>
      <DeleteModal
        open={deleteModal?.isOpen}
        close={() => setDeleteModal({ isOpen: false })}
        loading={deleteComplete}
        deleteHandler={onDelete}
      />
      <Modal open={isOpen} size="xl4">
        <EnquiryDetailsModal onClose={() => setIsOpen(false)} details={details} />
      </Modal>
    </>
  );
};

export default EnquiryList;
