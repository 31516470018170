import React from "react";
import Image from "../elements/Image";
import nodatafound from "../../assets/images/nodatafound.png";

const ProductDescriptionItem = ({ data }) => {
  return (
    <>
      {data?.long_description ? (
        <div className="w-2xl bg-white rounded-md md:px-10 px-5 py-5 shadow-md flex">
          <div
            className="relative text-base"
            dangerouslySetInnerHTML={{
              __html: data?.long_description,
            }}
          />
        </div>
      ) : (
        <div className="flex flex-col items-center md:space-y-8 space-y-4 md:mt-16 mt-4">
          <div className="w-40 h-40 rounded-full bg-[#F0F9F4] overflow-hidden flex items-center justify-center">
            <div className="text-[#e73737] text-8xl">
              <i className="fa-regular fa fa-exclamation"></i>
            </div>
          </div>
          <div className="text-cape-cod text-3xl text-center">
            No Description Available.
          </div>
          <div className="text-concord text-base max-w-md text-center">
            Sorry, no data available at the moment. Please check back later or
            try adjusting your search criteria.
          </div>
        </div>
      )}
    </>
  );
};

export default ProductDescriptionItem;
