import React from "react";
import avatar from "../../../assets/images/avatar.png";
import Image from "../../../components/elements/Image";
import Button from "../../../components/form/Button";

export const EnquiryDetailsModal = ({ onClose = () => {}, details }) => {
  return (
    <div className="relative p-4 rounded-md shadow-md border border-slate-300">
      <div className="flex justify-end">
        <Button
          buttonClasses="!bg-white !h-8 !text-3xl !text-black hover:!text-black !text-xl hover:!bg-white hover:!text-red-600 hover:!border-gray-200 !text-center  gap-3 transition duration-200 rounded-md sm:mt-0"
          buttonIcon="fa-regular fa-xmark"
          buttonIconPosition="right"
          buttonEffect="filled"
          buttonFunction={onClose}
        />
      </div>
      <div className="flex  flex-col sm:flex-row md:items-center md:justify-between justify-center items-center mb-8">
        {/* <div className="flex flex-col sm:flex-row items-center justify-between mb-8"> */}
        <div className="flex-shrink-0 w-20 h-20 xl:w-24 xl:h-24 overflow-hidden rounded-md border border-gray-200">
          <Image
            src={avatar}
            alt="Avatar"
            className="w-full h-full object-cover"
            effect="blur"
          />
        </div>

        <div className="flex-1 mx-4 text-center sm:text-left text-red-600 font-bold text-2xl xl:text-3xl mt-4 sm:mt-0">
          {details?.name || "No Name"}
        </div>
        {/* </div> */}
      </div>

      <div className="flex flex-col xl:flex-row gap-8">
        <div className="flex-1 w-full xl:w-2/3">
          <div className="space-y-6">
            <div className="flex flex-col md:flex-row items-start gap-x-8">
              <div className="flex items-start mb-4 md:mb-0">
                <div className="flex-shrink-0">
                  <div className="inline-block p-3 text-xl text-red-600 bg-gray-100 rounded-md">
                    <i className="fa-solid fa-user"></i>
                  </div>
                </div>
                <div className="ml-4">
                  <p className="font-semibold">Name</p>
                  <p className="text-gray-600">
                    {details?.name || "Not provided"}
                  </p>
                </div>
              </div>

              <div className="flex items-start mb-4 md:mb-0">
                <div className="flex-shrink-0">
                  <div className="inline-block p-3 text-xl text-red-600 bg-gray-100 rounded-md">
                    <i className="fa-solid fa-envelope"></i>
                  </div>
                </div>
                <div className="ml-4">
                  <p className="font-semibold">Email Address</p>
                  <p className="text-gray-600">
                    {details?.email || "Not provided"}
                  </p>
                </div>
              </div>

              <div className="flex items-start mb-4 md:mb-0">
                <div className="flex-shrink-0">
                  <div className="inline-block p-3 text-xl text-red-600 bg-gray-100 rounded-md">
                    <i className="fa-solid fa-phone"></i>
                  </div>
                </div>
                <div className="ml-4">
                  <p className="font-semibold">Phone Number</p>
                  <p className="text-gray-600">
                    {details?.phone || "Not provided"}
                  </p>
                </div>
              </div>
            </div>

            <div className="flex items-start">
              <div className="flex-shrink-0">
                <div className="inline-block p-3 text-xl text-red-600 bg-gray-100 rounded-md">
                  <i className="fa-solid fa-message"></i>
                </div>
              </div>
              <div className="ml-4 flex-1">
                <p className="font-semibold">Message</p>
                <div className="h-32 overflow-auto p-2 bg-gray-100 rounded-md border border-gray-200">
                  <p className="text-gray-600">
                    {details?.message || "No message available"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
