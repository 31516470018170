import React from "react";
import CartsImtemsPrice from "../Items/CartsImtemsPrice";
import CartsItemsDetails from "../Items/CartsItemsDetails";
import DetailsBreadcrumb from "../Items/DetailsBreadcrumb";

const CartsSection = ({
  myCartList,
  myCartSummary,
  myOrderSummary,
  deleteCartData = () => {},
  loaded,
  setDeleteModal = () => {},
  deleteModal,
}) => {
  return (
    <>
      <div className="relative flex xl:flex-row flex-col gap-12 pt-3">
        {myCartList && Array.isArray(myCartList) && myCartList.length > 0 ? (
          <>
            <CartsItemsDetails
              myCartList={myCartList}
              deleteCartData={deleteCartData}
              loaded={loaded}
              setDeleteModal={setDeleteModal}
              deleteModal={deleteModal}
            />
            <CartsImtemsPrice myOrderSummary={myOrderSummary} myCartSummary={myCartSummary} myCartList={myCartList} />
          </>
        ) : (
          <>
            <div className="bg-white w-fill h-full flex flex-col justify-center items-center rounded-lg w-full pt-20 pb-10">
              <div className="text-red-600 text-8xl">
                <i class="fa-regular fa-cart-circle-exclamation"></i>
              </div>
              <div className="text-black font-semibold md:text-4xl text-3xl text-center px-6 py-1 rounded-full mt-8 mb-2">
                Your Cart Is Currently Empty
              </div>
              <div className="text-gray-600 text-base xl:w-2/5 w-full text-center pt-4">
                Before proceed to checkout you must add some products to your shopping cart you will find a lot of
                interesting products on our page
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CartsSection;
