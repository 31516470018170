import React, { useState } from "react";
import PaymentMethod from "../components/sections/PaymentMethod";
import OrderSummary from "../components/sections/OrderSummary";
import ShippingAddress from "../components/sections/ShippingAddress";
import DetailsBreadcrumb from "../components/Items/DetailsBreadcrumb";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { loginModalOpen } from "../redux/slice/loginSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  addWishlistProduct,
  newPlaseOrder,
} from "../services/frontService/ProductService";

const items = [
  { label: "Home", href: "/" },
  { label: "Shipping Address", href: "/checkout" },
];

const PaymentInformation = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = location;
  const myCheckoutData = useSelector((state) => state?.checkout?.checkoutData);
  const isLogin = useSelector((state) => state?.auth?.isLogin);
  const [loaded, setLoaded] = useState(false);
  const myCartList = useSelector(
    (state) => state?.cart?.cartListData?.cartList
  );

  const onPlaceOrder = async () => {
    if (isLogin) {
      setLoaded(true);
      try {
        const res = await newPlaseOrder({
          shippingLocation: state?.shippingAddress?.location,
          shippingState: state?.shippingAddress?.state,
          shippingCity: state?.shippingAddress?.city,
          shippingPin: state?.shippingAddress?.pin,
          billingAddress: state?.billingAddress?.address
            ? state?.billingAddress?.address
            : state?.billingAddress?.location,
          billingAddress2: state?.billingAddress?.address2
            ? state?.billingAddress?.address2
            : null,
          billingState: state?.billingAddress?.state,
          billingCity: state?.billingAddress?.city,
          billingPin: state?.billingAddress?.pin,
          cardHolderName: "Pilu Malo",
          cardNumber: "5492974492545090",
          validThrough: "10/28",
          cvvCode: "790",
          cardHolderAddress: "1/18 Poddarnagar, Jadavpur",
          cardHolderPostalcode: "700025",
        });

        if (res?.data?.status === 200 || res?.data?.status === 201) {
          toast.info(res?.data?.message);
          navigate("/user-orders");
        } else {
          toast.info(
            res?.data?.message || res?.data?.errors || "Something went wrong"
          );
        }

        setLoaded(false);
      } catch (err) {
        setLoaded(false);
        // console.error(err);
        toast.error("Somthing went wrong !!!");
      }
    } else {
      dispatch(loginModalOpen({ isOpen: true }));
    }
  };

  return (
    <>
      <div className="relativew-full sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto xl:mt-40 md:mt-40 mt-20">
        <div className="pb-5 md:mx-0 mx-5">
          <DetailsBreadcrumb items={items} />
        </div>
        <div className=" xl:flex block ">
          <PaymentMethod
            onPlaceOrder={onPlaceOrder}
            loaded={loaded}
            setLoaded={setLoaded}
            myCartList={myCartList}
            myCheckoutData={myCheckoutData}
          />
          <OrderSummary myCheckoutData={myCheckoutData} />
        </div>
      </div>
    </>
  );
};

export default PaymentInformation;
