import React, { useCallback, useEffect, useState } from "react";
import Image from "../components/elements/Image";
import img from "../assets/images/faq-img-01.png";
import AccordionItems from "../components/Items/AccordionItems";
import { faqListData } from "../services/frontService/FaqService";
import FaqLoader from "../components/loader/FaqLoader";

const Faq = () => {
  const [faqData, setFaqData] = useState([]);
  const [loading, setLoading] = useState(false);

  const loadFaqData = useCallback(() => {
    setLoading(true);
    faqListData().then((res) => {
      if (res?.status === 200) {
        setFaqData(res?.data);
        setLoading(false);
      } else {
        setLoading(true);
      }
    });
  }, []);

  useEffect(() => {
    loadFaqData();
  }, [loadFaqData]);

  return (
    <section className="sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto md:px-0 px-5 xl:pt-40 md:pt-28 pt-16">
      <div className="w-full text-center md:pt-8 pt-5 xl:pb-8 md:pb-5 pb-5">
        <div className="text-red-600 md:text-5xl text-3xl font-bold">
          Freequently Asked Question
        </div>
        <div className="md:text-base text-sm font-semibold py-3 text-gray-600">
          Have a Question? We are here to assist you
        </div>
      </div>
      <div className="xl:flex block xl:space-x-6 space-x-0 ">
        {loading ? (
          <>
            <div className="w-full">
              <FaqLoader></FaqLoader>
            </div>
          </>
        ) : (
          <div className="w-full">
            <AccordionItems faqData={faqData} />
          </div>
        )}
      </div>
    </section>
  );
};

export default Faq;
