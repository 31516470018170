import React, { useCallback, useEffect, useRef, useState } from "react";
import PageHeader from "../../../shared/PageHeader";
import Button from "../../../components/form/Button";
import Input from "../../../components/form/Input";
import SingleImageUpload from "../../../components/form/SingleImageUpload";
import { formValidate } from "../../../helpers/formValidate";
import useForm from "../../../hooks/useForm";
import { Validation } from "../../../helpers/Validation";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { setTitle } from "../../../helpers/MetaTag";
import DatePickerInput from "../../../components/form/DatePickerInput";
import { addUser, userDetails } from "../../../services/userService";
import TextareaAutoSize from "../../../components/form/TextareaAutoSize";
import { addTestimonial, testimonialDetails } from "../../../services/testimonialService";
import Select from "../../../components/form/Select";
import { addFaq, faqDetails } from "../../../services/faqService";
import SelectDropdownSearch from "../../../components/form/SelectDropdownSearch";
import { Editor } from "@tinymce/tinymce-react";
import { editEmailtemlete, emailtemplateDetails } from "../../../services/emailtemplateService";

export const AddEditEmailTemplate = () => {
  setTitle("HJL DESIGNS");
  const { id } = useParams();
  const validation = {
    from_mail: { required: true, message: "Please enter your from mail !!!" },
    from_name: { required: true, message: "Please enter your from name !!!" },
    subject: { required: true, message: "Please enter your subject !!!" },
  };
  const { values, handleChange, handleSubmit, errors, setFieldsValue } = useForm({}, validation);
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const [detailsData, setDetailsData] = useState({ loading: false, data: {} });
  const [content, setContent] = useState();
  const editorRef = useRef(null);
  const tinymceApiKey = "uemqg8efft8kgaho6qxmnav0yzbr7s6n00wmyfrh5b7gyxvl";

  const getDetails = useCallback(() => {
    if (id) {
      setDetailsData({ loading: true, data: {} });
      emailtemplateDetails({ id }).then((res) => {
        if (res?.status === 200) {
          setDetailsData({ loading: true, data: res?.docs });
          setFieldsValue({
            from_mail: res?.docs?.from_mail || "",
            from_name: res?.docs?.from_name || "",
            subject: res?.docs?.subject || "",
          });
          setContent(res?.docs?.content);
        }
      });
    }
  }, [id]);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  const onSubmit = async (values) => {
    setLoaded(true);
    try {
      const res = await editEmailtemlete({
        id: id,
        subject: values?.subject,
        from_name: values?.from_name,
        from_mail: values?.from_mail,
        content: content,
      });

      if (res?.data?.status === 200 || res?.data?.status === 201) {
        navigate("/admin/emailtemplate-list");
        toast.info(res?.data?.message);
      } else {
        toast.info(res?.data?.message || res?.data?.errors || "Something went wrong");
      }

      setLoaded(false);
    } catch (err) {
      setLoaded(false);
      // console.error(err);
      toast.error("Somthing went wrong !!!");
    }
  };

  return (
    <div>
      <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
        <PageHeader
          title={"Edit Email Template"}
          headerActions={() => (
            <div className="flex items-center space-x-3">
              <Button
                buttonHasLink={true}
                buttonLink={"/admin/emailtemplate-list"}
                buttonType={"button"}
                buttonIcon={"fa-regular fa-xmark"}
                buttonIconPosition={"left"}
                buttonLabel={"Cancel"}
                buttonLabelClasses={"md:block hidden"}
                buttonClasses={"!bg-white !text-primary"}
              />
              <Button
                buttonHasLink={false}
                buttonType={"submit"}
                buttonIcon={"fa-light fa-floppy-disk"}
                buttonIconPosition={"left"}
                buttonClasses={"!bg-primary !text-org-silver"}
                buttonLabel={"Save"}
                buttonLabelClasses={"md:block hidden"}
                isDisable={loaded}
              />
            </div>
          )}
        />
        <div className="py-2 md:px-6 px-0">
          <div className="w-full bg-white rounded-md shadow  py-5 px-4">
            <div className="grid grid-cols-5 gap-4 divide-x divide-slate-200 md:px-10 px-0">
              <div className="xl:col-span-6 col-span-5">
                <div className="space-y-3">
                  <div className="grid grid-cols-12 gap-4">
                    <div className="relative md:col-span-6 col-span-12">
                      <Input
                        isInputGroup={false}
                        inputGroupIcon={""}
                        inputGroupPosition={"left"}
                        label={"From Mail"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        value={values.from_mail}
                        inputName={"from_mail"}
                        onChange={handleChange}
                        inputPlaceholder={"From Mail"}
                        {...formValidate(errors, "from_mail")}
                      />
                    </div>
                    <div className="relative md:col-span-6 col-span-12">
                      <Input
                        isInputGroup={false}
                        inputGroupIcon={""}
                        inputGroupPosition={"left"}
                        label={"From Name"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        value={values.from_name}
                        inputName={"from_name"}
                        onChange={handleChange}
                        inputPlaceholder={"From Name"}
                        {...formValidate(errors, "from_name")}
                      />
                    </div>
                    <div className="relative md:col-span-12 col-span-12">
                      <Input
                        isInputGroup={false}
                        inputGroupIcon={""}
                        inputGroupPosition={"left"}
                        label={"Subject"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        value={values.subject}
                        inputName={"subject"}
                        onChange={handleChange}
                        inputPlaceholder={"Subject"}
                        {...formValidate(errors, "subject")}
                      />
                    </div>
                    {/* <div className="relative md:col-span-12 col-span-12">
                      <TextareaAutoSize
                        isInputGroup={true}
                        inputGroupIcon={"fa-solid fa-user-tie"}
                        inputGroupPosition={"left"}
                        label={"Answer"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        inputValue={values.answer}
                        inputName={"answer"}
                        onChange={handleChange}
                        inputPlaceholder={"Enter Answer"}
                        {...formValidate(errors, "answer")}
                      />
                    </div> */}
                    <div className="relative md:col-span-12 col-span-12">
                      <span className="test-base">Content</span>
                      <Editor
                        apiKey={tinymceApiKey}
                        onInit={(evt, editor) => (editorRef.current = editor)}
                        value={content}
                        init={{
                          height: 300,
                          menubar: false,
                          plugins: [
                            "code",
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code help wordcount",
                          ],
                          toolbar:
                            "code | undo redo | formatselect | " +
                            "bold italic backcolor | alignleft aligncenter " +
                            "alignright alignjustify | bullist numlist outdent indent | " +
                            "removeformat | help ",
                          content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                        }}
                        onEditorChange={(newText) => setContent(newText)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-8 pt-4 border-t border-slate-200">
              <div className="flex items-center space-x-3 justify-end">
                <Button
                  buttonHasLink={true}
                  buttonLink={"/admin/emailtemplate-list"}
                  buttonType={"button"}
                  buttonIcon={"fa-regular fa-xmark"}
                  buttonIconPosition={"left"}
                  buttonLabel={"Cancel"}
                  buttonClasses={"!bg-white !text-primary !border-primary !border-2"}
                />
                <Button
                  buttonHasLink={false}
                  buttonType={"submit"}
                  buttonIcon={"fa-light fa-floppy-disk"}
                  buttonIconPosition={"left"}
                  buttonClasses={"!bg-primary !text-org-silver"}
                  buttonLabel={"Save"}
                  isDisable={loaded}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
