import React, { useCallback, useEffect, useState } from "react";
import img from "../../../assets/images/cards/card-inside-2.webp";
import RecentUserItems from "../../Items/AdminPanelItems/RecentUserItems";
import { getUserList } from "../../../services/userService";

// const DashboardRecentUser = [
//   {
//     _id: 1,
//     image: img,
//     name: "Tania Das",
//     email: "tania@gmail.com",
//   },
//   {
//     _id: 2,
//     image: img,
//     name: "Binita Das",
//     email: "tania@gmail.com",
//   },
//   {
//     _id: 3,
//     image: img,
//     name: "Binita Das",
//     email: "tania@gmail.com",
//   },
//   {
//     _id: 4,
//     image: img,
//     name: "Binita Das",
//     email: "tania@gmail.com",
//   },
// ];

const RecentUsers = () => {
  const [list, setList] = useState({ data: [] });
  const loadList = useCallback(() => {
    setList((pre) => ({
      ...pre,
      data: [],
      loading: true,
    }));
    getUserList({
      perPage: 10,
      role: "user",
    }).then((res) => {
      if (res && res?.docs?.success) {
        setList({
          loading: false,
          data: res.docs?.data,
        });
      }
    });
  }, []);

  useEffect(() => {
    loadList();
  }, [loadList]);

  return (
    <>
      {list?.data?.length > 0 && (
        <div className="relative">
          {list?.data?.map((item, index) => (
            <RecentUserItems key={index} data={item} />
          ))}
        </div>
      )}
    </>
  );
};

export default RecentUsers;
