import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { pagesViewDetails } from "../../../services/pagesService";
import Image from "../../../components/elements/Image";
import logo from "../../../assets/images/logo.png";
import PageDetailsText from "./PageDetailsText";
import PageDetailsTab from "./PageDetailsTab";
import PageLoader from "../../../components/common/PageLoader";

export const PageDetails = () => {
  const { id } = useParams();
  const [detailsData, setDetailsData] = useState({});
  const [activeTab, setActiveTab] = useState(0);
  const cardRef = useRef(null);

  const getDetails = useCallback(() => {
    setDetailsData({ loading: true, data: {} });
    pagesViewDetails(id).then((res) => {
      if (res?.status === 200) {
        setDetailsData({
          loading: false,
          data: res?.docs,
        });
      }
      setDetailsData({
        loading: false,
        data: res?.docs,
      });
    });
  }, []);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  const scrollHandler = (type) => {
    if (cardRef.current) {
      const scrollElement = document.querySelector("body");
      switch (type) {
        case "gallery":
          if (scrollElement) {
            cardRef.current.scrollIntoView({
              behavior: "smooth",
              block: "center",
              inline: "center",
            });
          }
          setActiveTab(1);
          break;
        case "description":
          if (scrollElement) {
            cardRef.current.scrollIntoView({
              behavior: "smooth",
              block: "center",
              inline: "center",
            });
          }
          setActiveTab(0);
          break;
        default:
          break;
      }
    }
  };

  return (
    <div>
      {detailsData?.loading ? (
        <PageLoader />
      ) : (
        <div className="sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto pb-10">
          <div className="flex md:mt-20 mt-10  w-full justify-center">
            <div className="xl:w-6/12 lg:w-10/12 md:w-10/12 w-full  md:flex block bg-white p-6 rounded-lg justify-center items-center shadow-xl">
              <div className="w-2/5">
                <div
                  className="w-full aspect-square overflow-hidden "
                  onClick={() => scrollHandler("gallery")}
                >
                  <Image
                    src={
                      detailsData?.data?.imageUrl
                        ? detailsData?.data?.imageUrl
                        : logo
                    }
                    alt=""
                    effect="blur"
                    className="w-full h-full object-cover"
                  />
                </div>
              </div>
              <div className="w-full md:pl-14 pl-0">
                <PageDetailsText
                  data={detailsData?.data}
                  scrollHandler={scrollHandler}
                />
              </div>
            </div>
          </div>
          <div ref={cardRef}>
            <PageDetailsTab
              data={detailsData}
              setData={setDetailsData}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              getDetails={getDetails}
            />
          </div>
        </div>
      )}
    </div>
  );
};
