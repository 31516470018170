import React, { useCallback, useEffect, useState } from "react";
import PageHeader from "../../../shared/PageHeader";
import Button from "../../../components/form/Button";
import Input from "../../../components/form/Input";
import SingleImageUpload from "../../../components/form/SingleImageUpload";
import { formValidate } from "../../../helpers/formValidate";
import useForm from "../../../hooks/useForm";
import { Validation } from "../../../helpers/Validation";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { setTitle } from "../../../helpers/MetaTag";
import { getCategoryList, getParentCategoryList } from "../../../services/categoryService";
import SelectDropdownSearch from "../../../components/form/SelectDropdownSearch";
import Select from "../../../components/form/Select";
import TextEditor from "../../../components/form/TextEditor";
import { addProduct, getProductCategoryList, productDetails } from "../../../services/productService";
import MultipleImageUpload from "../../../components/form/MultipleImageUpload";

export const AddEditProduct = () => {
  setTitle("HJL DESIGNS");
  const { id } = useParams();
  const validation = {
    title: { required: true, message: "Please enter your product name !!!" },
    minimum_package_quantity: { required: true, message: "Please enter your product quantity !!!" },
    price: { required: true, message: "Please enter your product price !!!" },
  };
  const { values, handleChange, handleSubmit, errors, setFieldsValue } = useForm({}, validation);
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const [detailsData, setDetailsData] = useState({ loading: false, data: {} });
  const [categoryList, setCategoryList] = useState({ data: [] });
  const [categoryKeyword, setCategoryKeyword] = useState("");
  const [statusKeyword, setStatusKeyword] = useState("");
  const [imageUrl, setImageUrl] = useState();
  const [shortDescription, setShortDescription] = useState();
  const [longDescription, setLongDescription] = useState();
  const location = useLocation();
  let categoryName = new URLSearchParams(location.search).get("selectValue");
  const filterData = categoryList?.data?.filter((item) => item?.parent_category_slug === categoryName);

  const getDetails = useCallback(() => {
    if (id) {
      setDetailsData({ loading: true, data: {} });
      productDetails({ id }).then((res) => {
        if (res?.status === 200) {
          setDetailsData({ loading: true, data: res?.docs });
          setFieldsValue({
            title: res?.docs?.title || "",
            category_id:
              {
                name: res?.docs?.productCategoryInfo?.category_name
                  ? res?.docs?.productCategoryInfo?.category_name
                  : null,
                _id: res?.docs?.productCategoryInfo?.id ? res?.docs?.productCategoryInfo?.id : null,
              } || "",
            price: res?.docs?.price || "",
            minimum_package_quantity: res?.docs?.minimum_package_quantity || "",
            status:
              {
                name: res?.docs?.status ? res?.docs?.status : null,
                _id: res?.docs?.status ? res?.docs?.status : null,
              } || "",
          });
          setShortDescription(res?.docs?.short_description);
          setLongDescription(res?.docs?.long_description);
          setImageUrl(res?.docs?.productImages);
        }
      });
    }
  }, [id]);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  useEffect(() => {
    getProductCategoryList({ keyword: categoryKeyword, perPage: 999 }).then((res) => {
      if (res && res?.status === 200) {
        setCategoryList({
          data: res?.data,
        });
      }
    });
  }, []);

  const onSubmit = async (values) => {
    setLoaded(true);
    try {
      if (!id) {
        const res = await addProduct({
          category_id: values?.category_id?._id,
          minimum_package_quantity: parseInt(values?.minimum_package_quantity),
          status: values?.status?._id,
          price: parseInt(values?.price),
          title: values?.title,
          short_description: shortDescription,
          long_description: longDescription,
          // image: imageUrl,
        });

        if (res?.data?.status === 200 || res?.data?.status === 201) {
          navigate("/admin/product-list");
          toast.info(res?.data?.message);
        } else {
          toast.info(res?.data?.message || res?.data?.errors?.category_id[0] || "Something went wrong");
        }
      } else {
        const res = await addProduct({
          id: id,
          category_id: values?.category_id?._id,
          minimum_package_quantity: parseInt(values?.minimum_package_quantity),
          status: values?.status?._id,
          price: parseInt(values?.price),
          title: values?.title,
          short_description: shortDescription,
          long_description: longDescription,
          // image: imageUrl,
        });

        if (res?.data?.status === 200 || res?.data?.status === 201) {
          navigate("/admin/product-list");
          toast.info(res?.data?.message);
        } else {
          toast.info(res?.data?.message || res?.data?.errors?.category_id[0] || "Something went wrong");
        }
      }

      setLoaded(false);
    } catch (err) {
      setLoaded(false);
      toast.error("Somthing went wrong !!!");
    }
  };

  const data = [
    { name: "Active", _id: 1 },
    { name: "In-Active", _id: 2 },
    { name: "Comming Soon", _id: 3 },
  ];

  return (
    <div>
      <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
        <PageHeader
          title={detailsData?.data?.id ? "Edit Product" : "Add New Product"}
          headerActions={() => (
            <div className="flex items-center space-x-3">
              <Button
                buttonHasLink={true}
                buttonLink={"/admin/product-list"}
                buttonType={"button"}
                buttonIcon={"fa-regular fa-xmark"}
                buttonIconPosition={"left"}
                buttonLabel={"Cancel"}
                buttonLabelClasses={"md:block hidden"}
                buttonClasses={"!bg-white !text-primary"}
              />
              <Button
                buttonHasLink={false}
                buttonType={"submit"}
                buttonIcon={"fa-light fa-floppy-disk"}
                buttonIconPosition={"left"}
                buttonClasses={"!bg-primary !text-org-silver"}
                buttonLabel={"Save"}
                buttonLabelClasses={"md:block hidden"}
                isDisable={loaded}
              />
            </div>
          )}
        />
        <div className="py-2 md:px-6 px-0">
          <div className="w-full bg-white rounded-md shadow  py-5 px-4">
            <div className="grid grid-cols-5 gap-4 divide-x divide-slate-200 md:px-10 px-0">
              <div className="xl:col-span-6 col-span-12">
                <div className="space-y-3">
                  <div className="grid grid-cols-12 gap-4">
                    <div className="relative md:col-span-6 col-span-12">
                      <Input
                        isInputGroup={true}
                        inputGroupIcon={"fa-solid fa-list"}
                        inputGroupPosition={"left"}
                        label={"Title"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        value={values.title}
                        inputName={"title"}
                        onChange={handleChange}
                        inputPlaceholder={"Enter Product Name"}
                        {...formValidate(errors, "title")}
                      />
                    </div>
                    <div className="relative md:col-span-6 col-span-12">
                      {categoryName ? (
                        <SelectDropdownSearch
                          label={"Category"}
                          labelClasses={"!text-base"}
                          placeholder={"Select Category Name  "}
                          xPlacement={"bottom"}
                          buttonArrowIcon={""}
                          selectName={"category_id"}
                          isSearch={true}
                          dropdownButtonClass="!px-3  !border-gray-200 !w-full"
                          onChange={handleChange}
                          onSearch={(key) => setCategoryKeyword(key)}
                          value={values?.category_id}
                          dropdownData={
                            filterData &&
                            filterData?.map((item) => ({
                              name: item?.category_name,
                              _id: item?.id,
                            }))
                          }
                        />
                      ) : (
                        <SelectDropdownSearch
                          label={"Category"}
                          labelClasses={"!text-base"}
                          placeholder={"Select Category Name  "}
                          xPlacement={"bottom"}
                          buttonArrowIcon={""}
                          selectName={"category_id"}
                          isSearch={true}
                          dropdownButtonClass="!px-3  !border-gray-200 !w-full"
                          onChange={handleChange}
                          onSearch={(key) => setCategoryKeyword(key)}
                          value={values?.category_id}
                          dropdownData={
                            categoryList &&
                            categoryList?.data?.map((item) => ({
                              name: item?.category_name,
                              _id: item?.id,
                            }))
                          }
                        />
                      )}
                    </div>
                    <div className="relative md:col-span-4 col-span-12">
                      <SelectDropdownSearch
                        label={"Status  "}
                        labelClasses={"!text-base"}
                        placeholder={"Select Status"}
                        xPlacement={"bottom"}
                        buttonArrowIcon={""}
                        selectName={"status"}
                        isSearch={false}
                        dropdownButtonClass="!px-3  !border-gray-200 !w-full"
                        onChange={handleChange}
                        onSearch={(key) => setStatusKeyword(key)}
                        value={values?.status}
                        dropdownData={data}
                      />
                    </div>
                    <div className="relative md:col-span-4 col-span-12">
                      <Input
                        isInputGroup={true}
                        inputGroupIcon={"fa-solid fa-list"}
                        inputGroupPosition={"left"}
                        label={"Package Quantity"}
                        labelClasses={"!text-base"}
                        inputType={"number"}
                        value={values.minimum_package_quantity}
                        inputName={"minimum_package_quantity"}
                        onChange={handleChange}
                        inputPlaceholder={"Enter Minimum Package Quantity"}
                        {...formValidate(errors, "minimum_package_quantity")}
                      />
                    </div>
                    <div className="relative md:col-span-4 col-span-12">
                      <Input
                        isInputGroup={true}
                        inputGroupIcon={"fa-solid fa-dollar-sign"}
                        inputGroupPosition={"left"}
                        label={"Price(CAD)"}
                        labelClasses={"!text-base"}
                        inputType={"number"}
                        value={values.price}
                        inputName={"price"}
                        onChange={handleChange}
                        inputPlaceholder={"Enter Product Price"}
                        {...formValidate(errors, "price")}
                      />
                    </div>
                    <div className="relative md:col-span-12 col-span-12">
                      <TextEditor
                        label={"Short Description"}
                        labelClasses={"!text-base"}
                        value={shortDescription}
                        onChange={(e) => setShortDescription(e)}
                      />
                    </div>
                    <div className="relative md:col-span-12 col-span-12">
                      <TextEditor
                        label={"Long Description"}
                        labelClasses={"!text-base"}
                        value={longDescription}
                        onChange={(e) => setLongDescription(e)}
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="mt-8 pt-4 border-t border-slate-200">
                  <div className="relative">
                    <MultipleImageUpload
                      label={"Images"}
                      size={"md"}
                      images={imageUrl}
                      setImages={setImageUrl}
                      accept={["jpg", "png", "jpeg"]}
                    />
                  </div>
                </div> */}
              </div>
            </div>
            <div className="mt-8 pt-4 border-t border-slate-200">
              <div className="flex items-center space-x-3">
                <Button
                  buttonHasLink={true}
                  buttonLink={"/admin/product-list"}
                  buttonType={"button"}
                  buttonIcon={"fa-regular fa-xmark"}
                  buttonIconPosition={"left"}
                  buttonLabel={"Cancel"}
                  buttonClasses={"!bg-white !text-primary !border-primary !border-2"}
                />
                <Button
                  buttonHasLink={false}
                  buttonType={"submit"}
                  buttonIcon={"fa-light fa-floppy-disk"}
                  buttonIconPosition={"left"}
                  buttonClasses={"!bg-primary !text-org-silver"}
                  buttonLabel={"Save"}
                  isDisable={loaded}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
