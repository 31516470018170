import React from "react";
import Accordion from "../sections/Accordion";

const AccordionItems = ({faqData}) => {
  return (
    <>
      <div className="xl:p-6 xl:pt-0 p-0">
        <Accordion items={faqData} />
      </div>
    </>
  );
};

export default AccordionItems;
