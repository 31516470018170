import React from "react";
import MyInfo from "./MyInfo";

const MyProfileInput = () => {
  return (
    <>
      <div className="mt-10 w-full h-full bg-slate-100 rounded-md md:px-10 px-5 py-10 pt-5 shadow-md">
        <div className="text-black text-2xl font-bold pb-2">My Profile</div>
        <div>
          {" "}
          <MyInfo />
        </div>
      </div>
    </>
  );
};

export default MyProfileInput;
