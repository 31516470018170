import React from "react";
import Image from "../../elements/Image";
import { Link } from "react-router-dom";
import avatar from "../../../assets/images/avatar.png";

const RecentUserItems = ({ data }) => {
  return (
    <>
      <Link to={`/admin/user-details/${data?.id}`} className="relative flex items-center space-x-4 py-4 border-b">
        <div className="w-12 h-12 rounded-full overflow-hidden">
          <Image src={data?.image_name_url || avatar} alt="" classname="w-full h-full object-cover" effect={"blur"} />
        </div>
        <div className="w-9/12">
          <div className="space-y-1">
            <div className="md:text-xl text-lg font-bold text-black">{data.name}</div>
            {/* <div className="flex items-end space-x-2">
              <Review
                rating={data?.rating}
                totalReviews={"120"}
                isReviewShow={false}
                classes={"md:flex block"}
              />
              <div className="text-xs">{data.date}</div>
            </div> */}
          </div>
          <div className="md:text-sm text-sm">{data.email}</div>
        </div>
      </Link>
    </>
  );
};

export default RecentUserItems;
