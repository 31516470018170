import React from "react";
import Button from "../../../components/form/Button";
import Image from "../../../components/elements/Image";

export const TestimonialDetailsModal = ({ onClose = () => {}, details }) => {
  return (
    <div>
      <div className="flex justify-end">
        <Button
          buttonClasses="!bg-transparent !h-4 !w-8 !text-2xl !text-gray-500 hover:!text-red-500 rounded-full flex items-center justify-center transition duration-200"
          buttonIcon="fa-regular fa-xmark"
          buttonIconPosition="right"
          buttonEffect="filled"
          buttonFunction={onClose}
        />
      </div>
      <div className="max-w-xl px-5">
        <div className="flex items-center flex-col mb-6">
          <div className="flex-shrink-0 w-24 h-24 overflow-hidden rounded-full ">
            <Image
              src={details?.imageUrl}
              alt="Avatar"
              className="w-full h-full object-cover"
              effect="blur"
            />
          </div>
          <h1 className="text-2xl font-extrabold text-red-600 mt-3">
            {details?.name}
          </h1>
        </div>

        <div className="space-y-6 text-center mb-5">
          <div className="min-h-40 overflow-auto bg-red-50 border border-red-100 p-5 rounded-md shadow-md">
            <div className="text-4xl text-black flex justify-center">
              <i class="fa-solid fa-quote-left"></i>
            </div>
            <p
              className="text-lg leading-relaxed text-gray-700 mt-1"
              dangerouslySetInnerHTML={{ __html: details?.description }}
            ></p>
          </div>
        </div>
      </div>
    </div>
  );
};
