import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { formValidate } from "../../../helpers/formValidate";
import Input from "../../form/Input";
import Button from "../../form/Button";
import useForm from "../../../hooks/useForm";
import Background from "../../../assets/images/loginBackground.jpg";
import { loginService } from "../../../services/frontService/Login";
import { classNames } from "../../../helpers/classNames";
import { loginModalOpen } from "../../../redux/slice/loginSlice";
import { useDispatch } from "react-redux";
import { setIsLogin } from "../../../redux/slice/authSlice";
const validation = {
  email: { required: true, message: "Email is required" },
  password: { required: true, message: "Password is required" },
};

const LoginForm = ({ getProfile = () => {} }) => {
  const { values, handleChange, handleSubmit, errors, setFieldsValue } = useForm({}, validation);
  const dispatch = useDispatch();
  const backgroundStyle = {
    // backgroundColor: "#f1f5f9",
    backgroundImage: `url(${Background})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState({
    color: "red-500",
    message: null,
  });
  const [loaded, setLoaded] = useState(false);

  const onSubmit = async (values) => {
    setLoaded(true);
    try {
      const res = await loginService({
        email: values?.email,
        password: values?.password,
      });
      if (res?.data?.status === 200) {
        setErrorMessage({
          color: "text-green-500",
          message: res?.data?.message,
        });
        localStorage.setItem("auth_token", res?.data?.accessToken);
        localStorage.setItem("refreshToken", res?.data?.refreshToken);
        localStorage.setItem("role", "USER");
        toast.info(res?.data?.message);
        dispatch(loginModalOpen({ isOpen: false }));
        getProfile();
      } else if (res?.data?.status === 400) {
        setErrorMessage({
          color: "text-red-500",
          message: res?.data?.message || res?.data?.errors,
        });
        toast.info(res?.data?.message || res?.data?.errors);
      } else {
        setErrorMessage({
          color: "text-red-500",
          message: res?.data?.message || res?.data?.errors,
        });
        toast.info(res?.data?.message || res?.data?.errors);
      }
      setLoaded(false);
    } catch (error) {
      console.error("Error signing in:", error);
      toast.info("Something went wrong!!!");
      setErrorMessage({
        color: "text-red-500",
        message: error?.response?.data?.message || error.message || "Something went wrong!!!",
      });
      toast.error(error?.response?.data?.message || error.message);
      setLoaded(false);
    } finally {
      setLoaded(false);
    }
  };

  return (
    <div className={classNames("relative w-full flex-shrink-0 space-y-5 transition-all duration-200 ease-in-out")}>
      <div className="relative">
        <div className="text-2xl uppercase font-bold pb-2 text-red-600">Login</div>
        <div className="text-base text-slate-500">Welcome back! Your login brings hope and fuels our mission!</div>
        <div
          className={`mt-1 text-center text-md font-bold leading-9 tracking-tight rounded-full ${errorMessage?.color}`}>
          {errorMessage?.message}
        </div>
      </div>

      <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
        <div className="mt-5">
          <Input
            label={"Email"}
            labelClasses={"text-slate-400 !text-base !font-semibold"}
            inputType={"text"}
            inputClasses={"!text-black !border !border-slate-400"}
            inputPlaceholder={"Enter your email"}
            value={values?.email}
            inputName={"email"}
            onChange={handleChange}
            {...formValidate(errors, "email")}
          />
        </div>

        <div className="mt-5">
          <Input
            label={"Password"}
            labelClasses={"text-slate-400 !text-base !font-semibold"}
            inputType={"password"}
            inputClasses={" !text-black !border !border-slate-400"}
            inputPlaceholder={"Enter your password"}
            value={values?.password}
            inputName={"password"}
            onChange={handleChange}
            {...formValidate(errors, "password")}
          />
        </div>

        <div className="mt-8">
          <Button
            buttonClasses={"w-full text-white hover:bg-secondaryColor focus-visible:outline justify-center rounded-md"}
            buttonHasLink={false}
            buttonType={"submit"}
            buttonLabel={"Log in"}
            buttonIconPosition={"left"}
            buttonIcon={"fa-light fa-right-to-bracket"}
          />
        </div>
        {/* <div className="mt-4 text-end">
                <div className="text-sm">
                  <Link
                    to="/forgotPassword"
                    className="font-semibold text-victoria hover:text-"
                  >
                    Forgot password?
                  </Link>
                </div>
              </div> */}
      </form>
    </div>
  );
};

export default LoginForm;
