import React from "react";
import Image from "../elements/Image";
import { Link } from "react-router-dom";
import { classNames } from "../../helpers/classNames";

const TestimonialItems = ({ data, testimonial }) => {
  return (
    <>
      <Link
        to={"/testimonial"}
        className={classNames(
          "relative bg-white border hover:shadow-[0_5px_10px_0px_rgb(0,0,0,0.1)] transition duration-300 py-6 xl:px-8 px-4 rounded ",
          testimonial ? " flex-row block h-auto cursor-default" : "flex-col flex h-full"
        )}
      >
        <div
          className={classNames(
            "",
            testimonial
              ? "flex justify-start items-center pt-0 w-full"
              : "block justify-center items-center pt-2"
          )}
        >
          <div
            className={classNames(
              " aspect-square overflow-hidden border border-gray-400 rounded-full ",
              testimonial ? "mx-0 md:w-32 w-24" : " mx-auto w-24"
            )}
          >
            <Image
              src={data?.imageUrl}
              alt="testimonialimg"
              className="w-full h-full object-cover"
            />
          </div>

          <div className="w-full">
            <div
              className={classNames(
                "relative w-full",
                testimonial
                  ? " pt-0 text-start md:pl-6 pl-3"
                  : "pt-2 xl:pt-5 text-center pl-0"
              )}
            >
              <div
                className={classNames(
                  "relative inline-block after:contents-[''] after:absolute after:left-1/2 after:-translate-x-1/2 after:right-0 after:-bottom-1 after:h-0.5 ",
                  testimonial
                    ? "after:w-full after:bg-white"
                    : "after:w-3/4 after:bg-gray-200 "
                )}
              >
                <p
                  className={classNames(
                    "xl:text-2xl  text-xl   inline-block leading-6 font-bold relative",
                    testimonial ? "text-start" : " text-center"
                  )}
                >
                  {data?.name || ""}
                </p>
              </div>
              {/* <p
              className={classNames(
                "text-base leading-6 text-red-600 pt-2",
                testimonial ? "text-start" : " text-center"
              )}
            >
              {data?.profession || ""}
            </p> */}
              {/* <div className="relative w-28 h-0.5 bg-gray-100 mx-auto"></div> */}
            </div>

            <div
              className={classNames(
                " w-full",
                testimonial ? "xl:py-2 md:pl-6 pl-3" : "xl:py-6 py-3 pl-0"
              )}
            >
              <p
                className={classNames(
                  "text-2xl text-gray-800 leading-5 flex items-start",
                  testimonial ? "pt-0" : " pt-4"
                )}
              >
                <div className="text-red-600 xl:text-2xl md:text-xl text-sm">
                  <i className="fa-solid fa-quote-left"></i>
                </div>
                <div className="relative flex items-end">
                  <span
                    className="md:px-4 px-2 xl:text-base text-sm line-clamp-3"
                    dangerouslySetInnerHTML={{
                      __html: data?.description || "",
                    }}
                  ></span>
                  <div className=" xl:text-2xl md:text-xl text-sm text-red-600">
                    <i className="fa-solid fa-quote-right"></i>
                  </div>
                </div>
              </p>
            </div>
          </div>
        </div>
      </Link>

      {/* <Link
        to={"/testimonial"}
        className={classNames(
          "relative bg-white border hover:shadow-[0_5px_10px_0px_rgb(0,0,0,0.1)] transition duration-300 py-6 xl:px-8 px-4 rounded flex-col flex w-1/3"
        )}
      >
        <div
          className={classNames(
            "flex flex-col justify-center items-center pt-2 w-full"
          )}
        >
          <div
            className={classNames(
              " aspect-square overflow-hidden border border-gray-400 rounded-full  mx-auto w-24"
            )}
          >
            <Image
              src={data?.imageUrl}
              alt="testimonialimg"
              className="w-full h-full object-cover"
            />
          </div>
          <div
            className={classNames(
              "relative w-full pt-2 xl:pt-5 text-center pl-0"
            )}
          >
            <div
              className={classNames(
                "relative inline-block after:contents-[''] after:absolute after:left-1/2 after:-translate-x-1/2 after:right-0 after:-bottom-1 after:h-0.5 after:w-3/4 after:bg-gray-200"
              )}
            >
              <p
                className={classNames(
                  "xl:text-2xl  text-xl   inline-block leading-6 font-bold relative text-center"
                )}
              >
                {data?.name || ""}
              </p>
            </div>
          </div>
        </div>
        <div className={classNames(" w-full xl:py-6 py-3 pl-0")}>
          <p
            className={classNames(
              "text-2xl text-gray-800 leading-5 flex items-start pt-4"
            )}
          >
            <div className="text-red-600 xl:text-2xl md:text-xl text-sm">
              <i className="fa-solid fa-quote-left"></i>
            </div>
            <div className="relative flex items-end">
              <span
                className="px-4 xl:text-base text-sm line-clamp-3"
                dangerouslySetInnerHTML={{
                  __html: data?.description || "",
                }}
              ></span>
              <div className=" xl:text-2xl md:text-xl text-sm text-red-600">
                <i className="fa-solid fa-quote-right"></i>
              </div>
            </div>
          </p>
        </div>
      </Link> */}
    </>
  );
};

export default TestimonialItems;
