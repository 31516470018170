import React from "react";
import PageDescriptionItem from "./PageDescriptionItem";
import PageGalleryItem from "./PageGalleryItem";
import Tabs from "../../../components/elements/Tabs";

const PageDetailsTab = ({ data, activeTab, setActiveTab = () => {}, setData = () => {}, getDetails = () => {} }) => {
  const tabData = [
    {
      _id: 0,
      name: "Description",
      component: <PageDescriptionItem data={data?.data} />,
    },

    {
      _id: 1,
      name: "Gallery",
      component: <PageGalleryItem data={data} setData={setData} getDetails={getDetails} />,
    },
  ];

  const validTabIndex = activeTab >= 0 && activeTab < tabData.length ? activeTab : 0;

  return (
    <>
      <div className="relative md:pt-20 pt-10">
        <div className="App">
          <header className="App-header">
            <h1 className="md:text-3xl text-2xl font-medium mb-4 border-b border-slate-300 pb-4">
              {data?.data?.name} <span className="text-red-600 font-extrabold">{tabData[validTabIndex].name}</span>
            </h1>
            <div className="relative tab-container">
              <Tabs tabs={tabData} selectedTabId={validTabIndex} onTabChange={(tabId) => setActiveTab(tabId)} />
            </div>
          </header>
        </div>
      </div>
    </>
  );
};

export default PageDetailsTab;
