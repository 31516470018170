import React from "react";
import Skeleton from "./Skeleton";
import { classNames } from "../../helpers/classNames";

const TestimonialBoxLoader = ({ testimonial }) => {
  return (
    <div
      className={classNames(
        "relative bg-white border hover:shadow-[0_5px_10px_0px_rgb(0,0,0,0.1)] transition duration-300 py-6 xl:px-8 px-4 rounded ",
        testimonial ? " flex-row block h-auto" : "flex-col flex h-full"
      )}>
      <div
        className={classNames(
          "",
          testimonial ? "flex justify-start items-center pt-0 w-3/5" : "block justify-center items-center pt-2"
        )}>
        <div
          className={classNames(
            "aspect-square overflow-hidden border border-gray-400 rounded-full ",
            testimonial ? "mx-0 w-32" : " mx-auto w-24"
          )}>
          <Skeleton type="circle" className="w-full h-full" />
        </div>
        <div
          className={classNames(
            "relative w-full",
            testimonial ? "pt-0 text-start pl-6" : "pt-2 xl:pt-5 text-center pl-0"
          )}>
          <Skeleton type="text" className="w-3/4 mb-2" />
          <Skeleton type="text" className="w-1/2" />
        </div>
      </div>
      <div className={classNames("w-full", testimonial ? "xl:py-0 pl-32" : "xl:py-6 py-3 pl-0")}>
        <Skeleton type="rectangle" className="h-12 mb-2" />
        <Skeleton type="rectangle" className="h-6 w-3/4" />
      </div>
    </div>
  );
};

export default TestimonialBoxLoader;
