import React from "react";
import ShippingAddress from "../components/sections/ShippingAddress";
import OrderSummary from "../components/sections/OrderSummary";
import DetailsBreadcrumb from "../components/Items/DetailsBreadcrumb";
import { useSelector } from "react-redux";

const items = [
  { label: "Home", href: "/" },
  { label: "Checkout", href: "/checkout" },
];

const Checkout = () => {
  const myCheckoutData = useSelector((state) => state?.checkout?.checkoutData);
  const addressData = {
    billingAddress:
      myCheckoutData &&
      myCheckoutData?.billingAdderss &&
      myCheckoutData?.billingAdderss,
    shippingAddress:
      myCheckoutData &&
      myCheckoutData?.shippingAddress &&
      myCheckoutData?.shippingAddress,
  };

  return (
    <>
      <div className="relative  w-full sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto xl:mt-40 md:mt-40 mt-20">
        <div className="pb-5 md:mx-0 mx-5">
          <DetailsBreadcrumb items={items} />
        </div>
        <div className="xl:flex block">
          <ShippingAddress addressData={addressData} />
          <OrderSummary myCheckoutData={myCheckoutData} />
        </div>
      </div>
    </>
  );
};

export default Checkout;
