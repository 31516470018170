import React, { useEffect, useState } from "react";
import { classNames } from "../../helpers/classNames";

const Tabs = ({
  tabs,
  navTabClass,
  selectedTabId,
  onTabChange,
  onTab,
  ...props
}) => {
  const [selectedTab, setSelectedTab] = useState({});

  const handleTabSelection = (tab = {}) => {
    setSelectedTab(tab);
    if (onTabChange) {
      onTabChange(tab._id);
    }
    if (onTab) {
      onTab(tab);
    }
  };

  useEffect(() => {
    if (tabs.length) {
      setSelectedTab(tabs.find((tab) => tab._id === selectedTabId) || tabs[0]);
    }
  }, [tabs, selectedTabId]);

  return (
    <>
      <div className="relative">
        <div className={classNames("flex overflow-auto", navTabClass)}>
          <nav
            className={classNames(
              "flex mb-4 space-x-4 border-b border-slate-200"
            )}
          >
            {tabs.map((item, index) => (
              <button
                type="button"
                className={classNames(
                  "relative px-2 py-2 transition-all duration-200 border-b-2 border-red-600",
                  item._id === selectedTab?._id
                    ? "border-magenta-500"
                    : "border-transparent"
                )}
                key={index}
                onClick={() => handleTabSelection(item)}
              >
                <div
                  className={classNames(
                    "whitespace-nowrap text-lg font-semibold transition-all duration-200",
                    item._id === selectedTab?._id
                      ? "text-magenta-500"
                      : "text-black"
                  )}
                >
                  {item.name}
                </div>
              </button>
            ))}
          </nav>
        </div>
        <div className="relative">{selectedTab?.component}</div>
      </div>
    </>
  );
};

export default Tabs;
