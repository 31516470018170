import React from "react";
import RatingStars from "./RatingStars";

const Review = ({ rating, totalReviews, isReviewShow=true, classes, }) => {
  
  // const width = (rating / 5) * 100;
  // const fullStars = Math.floor(rating);
  // const halfStar = rating % 1 !== 0;
  // const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);
  return (
    <>
      
        
        <div  className={`${classes}`}>
          <div className="flex items-center">
            {/* {[...Array(fullStars)].map((_, index) => (
              <div className="w-5 h-5 text-yellow-500">
                <i className="fa-solid fa-star"></i>
              </div>
            ))}
            {halfStar && (
              <div className="w-5 h-5 text-yellow-500">
                <i className="fa-solid fa-star-half-stroke"></i>
              </div>
            )}

            {[...Array(emptyStars)].map((_, index) => (
              <div className="w-5 h-5 text-gray-400">
                <i className="fa-solid fa-star"></i>
              </div>
            ))} */}
            <RatingStars data={rating}/>

          </div>
          

          {isReviewShow && <span className="text-gray-600 text-sm md:pl-2 pl-0">
            ({totalReviews} reviews)
          </span>}
        </div>
      
    </>
  );
};

export default Review;
