import { apiRequest } from "../util/apiUtils";

export const getProductList = async (params) => {
  try {
    const res = await apiRequest("get", "admin/product/list", {
      queries: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};
export const changeProductFeaturedStatus = async (payload = {}) => {
  try {
    const res = await apiRequest("post", "admin/product/is_fetured", {
      body: payload,
    });
    return res;
  } catch (err) {
    return err;
    throw err;
  }
};
export const deleteProduct = async (payload = {}) => {
  try {
    const res = await apiRequest("post", "admin/product/delete", {
      body: payload,
    });
    return res;
  } catch (err) {
    return err;
    throw err;
  }
};
export const addProduct = async (params) => {
  try {
    const res = await apiRequest(
      params.id ? "post" : "post",
      params.id ? "admin/product/edit" : "admin/product/add",
      {
        body: { ...params },
      },
      "multipart/form-data"
    );
    return res;
  } catch (error) {
    return error;
  }
};
export const productDetails = async (params) => {
  try {
    const res = await apiRequest("get", `admin/product/details/${params?.id}`, {});
    return res?.data;
  } catch (err) {
    return err;
  }
};
export const productViewDetails = async (params) => {
  try {
    const res = await apiRequest("get", `admin/product/details/${params}`, {});
    return res?.data;
  } catch (err) {
    return err;
  }
};
export const getProductCategoryList = async (params) => {
  try {
    const res = await apiRequest("get", "common/categoryList", {
      queries: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};
