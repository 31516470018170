import React from 'react';

const CardFlipBook = () => {
    return (
        <>
            {/* <div className="h-[370px] relative">
                    <div className="-webkit-perspective:1000px;perspective:1000px z-10 absolute top-0 left-0 mso-hide:all;display:none;max-h-0 overflow-hidden text-center w-full ">
                        <div className="hover:transform hover:rotate-180">
                            <div className="z-10 w-[255px] h-[370px] p-0  transform rotate-x-0">
                                <img src="https://hjldesigns.com/resources/views/front/assets/img/card-front.png" />
                            </div>
                            <div className="z-[5] w-[255px] h-[370px] p-0 transform rotate-x-180 after:[''] after:absolute after:top-0 after:left-0 after:w-full after:h-full after:bg-gradient-to-l-[#f0f0f0 0%,#fff 30%,#f0f0f0 100%]"></div>
                        </div>
                        <div className="z-[-1] w-[255px] h-[370px] p-0 absolute top-0 left-0 border">
                            <img src="https://hjldesigns.com/resources/views/front/assets/img/card-inside.png" />
                        </div>
                    </div>
                </div> */}

<div className="card_container">
                    <div className="flip-container kinetic">
                        <div className="flipper">
                            <div className="front">
                                <img src="https://hjldesigns.com/resources/views/front/assets/img/card-front.png" alt="" />
                            </div>
                            <div className="back"></div>
                        </div>
                        <div className="inside">
                            <img src="https://hjldesigns.com/resources/views/front/assets/img/card-inside.png" alt=""/>
                        </div>
                    </div>
                </div>
        </>
    );
};

export default CardFlipBook;