import React from "react";
import Input from "../form/Input";
import Button from "../form/Button";
import Image from "../elements/Image";
import OrderSummeryProductDetails from "../Items/OrderSummeryProductDetails";
import { country } from "../../helpers";

const OrderSummary = ({ myCheckoutData }) => {
  const productSummery =
    myCheckoutData && myCheckoutData?.cartList?.length > 0
      ? myCheckoutData?.cartList
      : [];

  const orderSummaryData = {
    cartSummary:
      myCheckoutData &&
      myCheckoutData?.cartSummary &&
      myCheckoutData?.cartSummary,
    orderSummary:
      myCheckoutData &&
      myCheckoutData?.orderSummary &&
      myCheckoutData?.orderSummary,
  };

  return (
    <>
      <div className="xl:w-2/5 w-full xl:pl-16 pl-0  xl:block flex xl:justify-normal justify-center">
        <div className="sticky top-32 w-11/12 bg-gray-50 md:px-8 px-4 py-8 shadow-md">
          <div className="md:text-2xl text-xl uppercase font-bold text-slate-700 pb-1">
            Product Summary
          </div>
          <hr className="w-full border-1 border-slate-500 pb-8"></hr>
          <div className="max-h-96 overflow-auto">
            {productSummery?.length > 0 && (
              <div className="relative space-y-5">
                {productSummery?.map((item, index) => (
                  <OrderSummeryProductDetails key={index} data={item} />
                ))}
              </div>
            )}
          </div>

          <div>
            <div className="md:text-2xl text-xl uppercase pt-10 pb-1 font-bold text-slate-700">
              Order Summary
            </div>
            <hr className="w-full border-1 border-slate-500 pb-5"></hr>
            <div className="space-y-2 ">
              <div className="flex w-full">
                <div className="w-full text-base font-semibold text-gray-600">
                  Sub Total Value
                </div>
                <div className="w-full flex justify-end text-base font-semibold text-gray-600 uppercase">
                  {country +
                    " " +
                    (orderSummaryData?.cartSummary?.totalCartValue || 0)}
                </div>
              </div>
              <div className="flex w-full">
                <div className="w-full text-base font-semibold text-gray-600">
                VAT charge
                </div>
                <div className="w-full flex justify-end text-base font-semibold text-gray-600 uppercase">
                  {country +
                    " " +
                    (orderSummaryData?.orderSummary?.taxCharges || 0)}
                </div>
              </div>
              <div className="flex w-full">
                <div className="w-full text-base font-semibold text-gray-600">
                  Delivery charge
                </div>
                <div className="w-full flex justify-end text-base font-semibold text-gray-600 uppercase">
                  {country +
                    " " +
                    (orderSummaryData?.orderSummary?.deliveryCharges || "Free")}
                </div>
              </div>
            </div>
            <hr className="w-full border border-slate-500 mt-6  "></hr>
            <div className="flex w-full pt-3">
              <div className="md:text-2xl text-xl font-bold text-gray-500">
                Total
              </div>
              <div className="w-full flex justify-end md:text-2xl text-xl font-bold text-gray-500 uppercase">
                {country +
                  " " +
                  (orderSummaryData?.orderSummary?.totalOrderValue || 0)}
              </div>
            </div>
          </div>

          {/* <div>
            <div className="md:text-2xl text-xl text-black pt-12 pb-1">
              Gift Card / Discount Code
            </div>
            <div className="md:text-base text-sm text-gray-500 pb-3">
              Apply a promo code or discount (one per order)
            </div>

            <div className="relative flex w-full mt-3">
              <div className="relative w-full">
                <Input
                  inputPlaceholder={"Write Your Code"}
                  inputName={"name"}
                  inputClasses={
                    "!pr-36 !w-full !bg-prontopsy-dodgerBlue !border !border-gray-400 !h-12 placeholder:!text-slate-400 text-gray-600 bg-white"
                  }
                />
              </div>
              <div className="absolute right-0">
                <Button
                  buttonLabel={"Submit"}
                  buttonLabelClasses={"capitalize"}
                  buttonClasses={"!w-full !px-10 !bg-red-600 hover:!bg-black  "}
                  className="!text-red-600"
                  // buttonFunction={"handleSubmit"}
                  // isDisable={!loaded}
                />
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default OrderSummary;
