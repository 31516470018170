import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { apiRequest } from "../util/apiUtils";
import PageLoader from "../components/common/PageLoader";
import ProductDetailsLoader from "../components/loader/ProductDetailsLoader";

const ProtectedRoute = (props) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(false);
  const [auth, setAuth] = useState(false);
  let token_ = localStorage.getItem("auth_token");
  const role = localStorage.getItem("role");
  const accessRole = [
    ...(Array.isArray(props.getAccess) ? props.getAccess : []),
  ];

  useEffect(() => {
    const checkData = async () => {
      setIsLoader(true);
      try {
        const res = await apiRequest(
          "get",
          "admin/authentication/verifyToken",
          {}
        );
        if (res?.status === 401) {
          localStorage.removeItem("auth_token");
          navigate("/");
          setAuth(false);
        } else {
          setIsLoader(false);
          setAuth(true);
          localStorage.setItem(
            "role",
            res?.data?.data?.role === "admin"
              ? "ADMIN"
              : res?.data?.data?.role === "user"
              ? "USER"
              : ""
          );
        }
      } catch (error) {
        console.error("Error verifying token:", error);
        // Optionally handle different error statuses
        if (error?.response?.status === 401) {
          localStorage.removeItem("auth_token");
          navigate("/");
          setAuth(false);
        } else {
          // Handle other types of errors (e.g., network issues)
          console.error("Unexpected error:", error);
          localStorage.removeItem("auth_token");
          navigate("/");
          setAuth(false);
        }
      }
    };
   
    if (!token_) {
      setIsLoader(false);
      navigate("/");
      localStorage.removeItem("role");
    } else {
      checkData();
    }
    setIsLoader(false);
  }, [token_, dispatch]);

  useEffect(() => {
    async function checkPath() {
      let checkRole = accessRole.filter((item) => item === role);
      if (checkRole && checkRole.length === 0) {
        if (token_ && role === "ADMIN") {
          return navigate("/admin/dashboard");
        } else if (token_ && role === "USER") {
          return navigate("/user-profile");
        } else {
          return navigate("/");
        }
      }
    }
    checkPath();
  }, [dispatch, window.location.pathname, props.getAccess, token_]);

  if (isLoader) {
    return (
      <div className="mt-52">
        <ProductDetailsLoader />
      </div>
    );
  }

  return <>{auth && props.children}</>;
};
export default ProtectedRoute;
