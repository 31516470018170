import React from 'react';
import { classNames } from "../../helpers/classNames";

const Radio = ({
  radioName, 
  radioInputClass, 
  isChecked, 
  radioLabel, 
  radioLableClass,
  onChange,
  ...props
}) => {
  return (
    <>
      <div className="relative overflow-hidden flex items-center">
        <input type="radio" 
          name={radioName} 
          className={classNames("peer absolute top-0 left-0 w-5 h-5 opacity-0 z-10 cursor-pointer", radioInputClass)} 
          defaultChecked={isChecked}
          onChange={onChange}
        />
        <div className="w-5 h-5 min-w-[20px] mr-3 border border-slate-300 rounded-full flex items-center justify-center text-[8px] bg-white text-black/0 peer-checked:bg-red-600 peer-checked:border-carbg-red-600 peer-checked:text-white">
          <i className="fa-solid fa-fw fa-circle"></i>
        </div>
        <div className={classNames("text-sm text-slate-500 w-full", radioLableClass)}>{radioLabel}</div>
      </div>
    </>
  );
};

export default Radio;