import React from "react";
import Button from "../form/Button";
import { country } from "../../helpers";
import { useNavigate } from "react-router-dom";
import { checkoutDetailsData } from "../../services/frontService/ProductService";
import { useDispatch } from "react-redux";
import { setCheckoutDetails } from "../../redux/slice/checkoutSlice";

const CartsImtemsPrice = ({ myCartSummary, myOrderSummary, myCartList }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cartsitemsprice = {
    heading: "Summary",
    subTotal: "Sub Total Value",
    tax: "VAT charge",
    deliverycharge: "Delivery charge",
    total: "Total",
  };

  const handleBuyNow = () => {
    checkoutDetailsData().then((res) => {
      if (res?.status === 200) {
        dispatch(setCheckoutDetails(res?.data));
      } else {
        dispatch(setCheckoutDetails({}));
      }
    });
    navigate("/checkout");
  };

  return (
    <>
      <div className="relative xl:w-2/6 w-full md:px-auto pt-5">
        <div className="sticky top-32 w-full inline-block">
          <div className=" bg-gray-50 shadow-md border rounded-md">
            <div className="px-6 py-8">
              <div className="relative flex pb-4 text-black items-center px-2">
                <div className="text-xl font-bold capitalize">Summary</div>
                <div className="relative ml-auto">
                  <div className="text-xl font-medium">
                    {country + " " + (myCartSummary?.totalCartValue || "")}
                  </div>
                </div>
              </div>
              <div className="relative pt-4 border-t border-slate-800 px-2 ">
                <div className="flex text-base text-slate-700 font-normal items-center capitalize text-slate">
                  <div className="relative">{cartsitemsprice.subTotal}</div>
                  <div className="ml-auto">
                    {country + " " + (myCartSummary?.totalCartValue || 0.0)}
                  </div>
                </div>
              </div>
              <div className="relative pt-4  px-2 ">
                <div className="flex text-base text-slate-700 font-normal items-center capitalize text-slate">
                  <div className="relative">{cartsitemsprice.tax}</div>
                  <div className="ml-auto">
                    {" "}
                    {country + " " + (myOrderSummary?.taxCharges || 0.0)}
                  </div>
                </div>
              </div>
              <div className="relative pt-4  px-2 ">
                <div className="flex text-base text-slate-700 font-normal items-center capitalize text-slate">
                  <div className="relative">
                    {cartsitemsprice.deliverycharge}
                  </div>
                  <div className="ml-auto">
                    {country +
                      " " +
                      (myOrderSummary?.deliveryCharges || "Free")}
                  </div>
                </div>
              </div>
              <div className="relative border-t border-slate-800 mt-4 pt-2 flex justify-between px-2 ">
                <div className="relative font-semibold text-base text-black">
                  {" "}
                  {cartsitemsprice.total}
                </div>
                <div className="relative font-semibold text-base text-black">
                  {" "}
                  {country + " " + (myOrderSummary?.totalOrderValue || 0.0)}
                </div>
              </div>
            </div>
          </div>
          {myCartList?.length > 0 && (
            <>
              {" "}
              <div className="relative">
                <Button
                  buttonClasses={
                    "!px-5 !h-12 !rounded-md !mt-3 !text-white !text-xl hover:!bg-black hover:!border-black !text-center bg-red-600 gap-3  transition duration-300 w-full rounded-none border border-red-600"
                  }
                  buttonLabel="Buy Now"
                  buttonLabelClasses="  !text-xl "
                  buttonEffect={"filled"}
                  buttonHasLink={false}
                  buttonFunction={handleBuyNow}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CartsImtemsPrice;
