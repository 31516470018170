import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  redirect: "",
  cartListData: {},
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setCartDetails: (state, action) => {
      state.loading = false;
      state.cartListData = action.payload;
    },
  },
});

export const { setCartDetails } = cartSlice.actions;

export default cartSlice.reducer;

