import React, { useState } from "react";
import MyWishListDetails from "../MyWishListDetails";
import Pagination from "../../form/Pagination";

const MyWishlistComponent = ({ wishlist, addWishList = () => {} }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 2;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentItems = wishlist?.slice(startIndex, endIndex);

  return (
    <>
      <div className="sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto">
        <div>
          <MyWishListDetails
            wishlist={currentItems}
            addWishList={addWishList}
          />
          <div>
            <Pagination
              totalItems={wishlist?.length}
              itemsPerPage={itemsPerPage}
              onPageChange={handlePageChange}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MyWishlistComponent;
