import React, { useCallback, useEffect, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import Banner from "../../assets/images/banner/small banner.webp";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper/modules";
import MainBannerItems from "../Items/MainBannerItems";
import { getPageData } from "../../services/frontService/PageService";
import BannerLoader from "../loader/BannerLoader";

const SmallBanner = () => {
  const [homeData, setHomeData] = useState({});
  const [loading, setLoading] = useState(false);

  const loadPageData = useCallback(() => {
    setLoading(true);
    getPageData({
      slug: "about-us",
    }).then((res) => {
      if (res?.status === 200) {
        setHomeData(res?.data);
        setLoading(false);
      } else {
        setLoading(true);
      }
    });
  }, []);

  useEffect(() => {
    loadPageData();
  }, [loadPageData]);

  const MainBannerImg =
    homeData &&
    Array.isArray(homeData?.bannerList) &&
    homeData?.bannerList?.length > 0
      ? homeData?.bannerList
      : [];

  return (
    <>
      {loading ? (
        <>
          <div className="!w-full relative pt-32">
            <BannerLoader></BannerLoader>
          </div>
        </>
      ) : (
        MainBannerImg?.length > 0 && (
          <Swiper
            pagination={{
              dynamicBullets: true,
            }}
            navigation={true}
            modules={[Pagination, Navigation]}
            className="mainbannerslide relative !h-full !text-black xl:mt-28 md:mt-20 mt-14"
          >
            {MainBannerImg.map((item, index) => (
              <SwiperSlide key={index} className="relative !h-full">
                <MainBannerItems key={index} data={item} />
              </SwiperSlide>
            ))}
          </Swiper>
        )
      )}
    </>
  );
};

export default SmallBanner;
