import React, { useCallback, useEffect, useMemo, useState } from "react";
import SingleProduct from "../Items/SingleProduct";
import lable from "../../assets/images/all label/alllabel-1.webp";
import lable_2 from "../../assets/images/all label/alllabel-9.webp";
import lable_3 from "../../assets/images/all label/alllabel-2.webp";
import lable_4 from "../../assets/images/all label/alllabel-3.webp";
import lable_5 from "../../assets/images/all label/alllabel-4.webp";
import lable_6 from "../../assets/images/all label/alllabel-5.webp";
import lable_7 from "../../assets/images/all label/alllabel-6.webp";
import lable_8 from "../../assets/images/all label/alllabel-7.webp";
import Pagination from "../form/Pagination";
import {
  pageWiseProductListData,
  productListData,
} from "../../services/frontService/ProductService";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { loginModalOpen } from "../../redux/slice/loginSlice";
import Modal from "../elements/Modal/Modal";
import QuickViewModal from "./QuickViewModal";
import { getPageData } from "../../services/frontService/PageService";
import ProductLoader from "../loader/ProductLoader";

const AllLabelProductitems = ({ slug, addToCart = () => {} }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState({ data: {}, open: false });
  const isLogin = useSelector((state) => state?.auth?.isLogin);

  const [limit, setLimit] = useState({ name: "60 Items", value: 60 });
  const [status, setStatus] = useState("all");
  const [offset, setOffset] = useState(0);

  const [pagination, setPagination] = useState({
    hasNextPage: false,
    hasPrevPage: false,
  });
  const [list, setList] = useState({
    loading: true,
    data: [],
    pageCount: 0,
    totalItem: 0,
  });
  const [count, setCount] = useState(0);
  const [items] = useState([...Array(100).keys()]); // Example items array
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 60;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const [homeData, setHomeData] = useState({});

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = items.slice(indexOfFirstItem, indexOfLastItem);

  const loadPageData = useCallback(() => {
    getPageData({
      slug: "all-wine-labels",
    }).then((res) => {
      if (res?.status === 200) {
        setHomeData(res?.data);
      }
    });
  }, []);

  useEffect(() => {
    loadPageData();
  }, [loadPageData]);

  const actions = useMemo(() => {
    return [
      // {
      //   _id: 1,
      //   icon: "fa-light fa-heart",
      //   isLink: false,
      //   isWishlist: true,
      //   isSolid: isSolid,
      //   click: (data) => {
      //     if (isLogin) {
      //       addWishList(data);
      //     } else {
      //       dispatch(loginModalOpen({ isOpen: true }));
      //     }
      //   },
      // },
      {
        _id: 2,
        icon: "fa-light fa-bag-shopping",
        isLink: false,
        click: (data) => {
          if (isLogin) {
            addToCart(data);
          } else {
            dispatch(loginModalOpen({ isOpen: true }));
          }
        },
      },
      {
        _id: 3,
        icon: "fa-light fa-arrow-right",
        isLink: false,
        click: (data) => {
          navigate(`/productdetails/${data?.alias}`);
        },
      },
      {
        _id: 4,
        icon: "fa-light fa-eye",
        isLink: false,
        click: (data) => setIsOpen({ open: true, data: data }),
      },
    ];
  }, [isLogin]);

  //   loading the member list >>>>>>>>>
  const loadList = useCallback(() => {
    setList((pre) => ({
      ...pre,
      data: [],
      loading: true,
    }));
    pageWiseProductListData({
      perPage: limit?.value,
      page: currentPage,
      parent_category_slug: "labels",
    }).then((res) => {
      if (res && res?.docs?.success) {
        setList({
          loading: false,
          data: res?.docs?.data,
          pageCount: res?.docs?.metadata?.totalPages,
          totalItem: res?.docs?.metadata?.totalDocs,
        });
        setCount(res?.docs?.metadata?.totalDocs);
        setPagination({
          hasNextPage: res?.docs?.metadata?.hasNextPage,
          hasPrevPage: res?.docs?.metadata?.hasPrevPage,
        });
      } else {
        setList((pre) => ({ ...pre, data: [], loading: true }));
        toast.error(res?.message);
      }
    });
  }, [limit, status, currentPage]);

  useEffect(() => {
    loadList();
  }, [loadList, isLogin]);

  const labelItem = {
    subheading: "Great Designs At The Best Price",
    heading: homeData?.name || "All Wine Labels",
    description:
      homeData?.description ||
      "HJL Designs is a manufacturer and wholesaler of high quality vinyl labels for the craft wine making industry. With a mix of modern and traditional designs, our ever-expanding lineup of 9.5 cm x 7 cm labels fit any bottle size, are easy to remove, and  do not leave any residue behind.",
    subdata: list && list?.data?.length > 0 ? list?.data : [],
  };
  const loaders = Array(20).fill(null);
  return (
    <>
      <section className="sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto md:px-0 px-5">
        <div className="relative flex items-center justify-center md:pt-20 pt-10 xl:px-32 px-0">
          <div className="text-center w-full">
            <div className="md:text-xl text-sm text-gray-700">
              {labelItem?.subheading}
            </div>
            <h2 className="great-design xl:text-6xl md:text-5xl text-2xl font-bold pt-2 pb-4 drop-shadow-md uppercase">
              <span className="relative inline-block !leading-none before:content-[''] before:absolute before:top-2/4 before:-translate-y-1/2 md:before:-left-36 before:-left-12 md:before:w-32 before:w-10 before:border-t-2 before:border-black after:content-[''] after:absolute after:top-2/4 after:-translate-y-1/2 md:after:-right-36 after:-right-12 md:after:w-32 after:w-10 after:border-t-2 after:border-black">
                {labelItem?.heading}
              </span>
            </h2>
            <div
              className="md:text-lg text-sm pb-3 text-gray-800"
              dangerouslySetInnerHTML={{
                __html: labelItem?.description || "",
              }}
            >
              {/* {labelItem?.description} */}
            </div>
          </div>
          <div className="relative ml-auto"></div>
        </div>

        {list.loading ? (
          <>
            <div className="grid xl:grid-cols-4 md:grid-cols-3 grid-cols-2 xl:gap-8 md:gap-8 gap-5 md:pt-36 pt-28">
              {loaders?.map((_, index) => (
                <ProductLoader key={index} />
              ))}
            </div>
          </>
        ) : (
          labelItem?.subdata?.length > 0 && (
            <>
              <div className="grid xl:grid-cols-4 md:grid-cols-3 grid-cols-2 xl:gap-8 md:gap-8 gap-5 md:pt-20 pt-10">
                {labelItem?.subdata?.map((item, index) => (
                  <SingleProduct
                    key={index}
                    data={item}
                    lable={lable_3}
                    actions={actions}
                  />
                ))}
              </div>
              <Pagination
                totalItems={count}
                itemsPerPage={limit?.value}
                onPageChange={handlePageChange}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </>
          )
        )}
        <Modal
          size="xl4"
          modalTitle=""
          open={isOpen?.open}
          onClose={() => setIsOpen({ open: false })}
        >
          <QuickViewModal
            onClose={() => setIsOpen({ open: false })}
            setIsOpen={setIsOpen}
            isOpen={isOpen}
          />
        </Modal>
      </section>
    </>
  );
};

export default AllLabelProductitems;
