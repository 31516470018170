import { apiRequest } from "../util/apiUtils";

export const getOrderList = async (params) => {
  try {
    const res = await apiRequest("get", "admin/order/list", {
      queries: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};
export const orderDetails = async (params) => {
  try {
    const res = await apiRequest("get", `admin/order/details/${params}`, {});
    return res?.data;
  } catch (err) {
    return err;
  }
};
export const changeOrderStatus = async (payload = {}) => {
  try {
    const res = await apiRequest("post", "admin/order/status-change", {
      body: payload,
    });
    return res;
  } catch (err) {
    return err;
    throw err;
  }
};

export const deleteOrder = async (payload = {}) => {
  try {
    const res = await apiRequest("post", "admin/order/delete", {
      body: payload,
    });
    return res;
  } catch (err) {
    return err;
    throw err;
  }
};

export const getOrderReportList = async (params) => {
  try {
    const res = await apiRequest("get", "admin/order/reportList", {
      queries: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const deleteOrderReport = async (payload = {}) => {
  try {
    const res = await apiRequest("post", "admin/order/report/delete", {
      body: payload,
    });
    return res;
  } catch (err) {
    return err;
    throw err;
  }
};
