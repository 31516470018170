import React, { useState } from "react";
import MyBillingAddress from "./MyBillingAddress";
import { useSelector } from "react-redux";
import { classNames } from "../../../helpers/classNames";
import Modal from "../../elements/Modal/Modal";
import ShippingAddressModal from "../ShippingAddressModal";
import Button from "../../form/Button";

const MyAddress = () => {
  const [isOpen, setIsOpen] = useState(false);

  const address = useSelector((state) => state?.profile?.profileData);
  const shippingAddress = address?.userShippingAddressInfo;
  const billingAdderss = address?.userBillingAdderssInfo;

  return (
    <>
      <div className="mt-10 w-full h-full bg-slate-100 rounded-md px-10 py-10 pt-5 shadow-md">
        <div className="text-black text-2xl font-bold pb-2">My Address</div>
        <div className="w-full">
          <div>
            <div className="flex items-center pt-3 md:pb-3 pb-1 space-x-2">
              <div className="uppercase text-black md:text-2xl text-xl font-medium rounded-md">
                Shipping{" "}
                <span className="text-red-600 font-extrabold">Address</span>
              </div>
              <div className="text-2xl">
                <i className="fa-solid fa-circle-question"></i>
              </div>
            </div>
            <hr className="w-full border-1 border-slate-500 pb-8"></hr>
            <div className="">
              <div className="flex space-x-5">
                <div className="relative overflow-hidden bg-slate-50 border border-red-600 !shadow-lg rounded-md p-3 z-0">
                  <div className="flex items-center">
                    <div className="flex-1">
                      <div className="flex flex-wrap items-center md:space-x-2 space-x-0">
                        <div className="text-xs font-bold">Location :</div>
                        <div className="md:text-sm text-xs">
                          {shippingAddress?.location || "N/A"}
                        </div>
                      </div>
                      <div className="flex flex-wrap items-center md:space-x-2 space-x-0">
                        <div className="text-xs font-bold">City :</div>
                        <div className="md:text-sm text-xs">
                          {shippingAddress?.city || "N/A"}
                        </div>
                      </div>
                      <div className="flex flex-wrap items-center md:space-x-2 space-x-0">
                        <div className="text-xs font-bold">State :</div>
                        <div className="md:text-sm text-xs">
                          {shippingAddress?.stateName || "N/A"}
                        </div>
                      </div>
                      <div className="flex flex-wrap items-center md:space-x-2 space-x-0">
                        <div className="text-xs font-bold">Postalcode :</div>
                        <div className="md:text-sm text-xs">
                          {shippingAddress?.pin || "N/A"}
                        </div>
                      </div>
                    </div>
                    <div className="ml-4 mt-12">
                      <Button
                        buttonClasses={"!bg-transparent !text-red-600 "}
                        buttonLabel=""
                        buttonLabelClasses="!text-base"
                        buttonIcon={"fa-regular fa-pen"}
                        buttonIconPosition={"left"}
                        buttonEffect={"filled"}
                        buttonFunction={() => setIsOpen(true)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <MyBillingAddress billingAdderss={billingAdderss} />
        </div>
      </div>
      <Modal size="xl4" title="Shipping Address" open={isOpen}>
        <ShippingAddressModal
          onClose={() => setIsOpen(false)}
          details={shippingAddress}
        />
      </Modal>
    </>
  );
};

export default MyAddress;
