import { apiRequest } from "../../util/apiUtils";

export const addContactQuery = async (params) => {
  try {
    const res = await apiRequest(
      "post",
      "front/profile/addContact",
      {
        body: { ...params },
      },
      "multipart/form-data"
    );
    return res;
  } catch (error) {
    return error;
  }
};
