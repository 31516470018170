import React from "react";
import SingleCartItems from "./SingleCartItems";
import DetailsBreadcrumb from "./DetailsBreadcrumb";
import Button from "../form/Button";

const CartsItemsDetails = ({
  myCartList,
  deleteCartData = () => {},
  loaded,
  setDeleteModal = () => {},
  deleteModal,
}) => {
  return (
    <>
      <div className="relative xl:w-4/6 w-full bg-white">
        <div className="3xl:min-h-[450px] xl:[300px] max-h-[500px] overflow-auto">
          <div className="relative divide-y divide-slate-200">
            {myCartList?.map((item, index) => (
              <SingleCartItems
                key={index}
                data={item}
                deleteCartData={deleteCartData}
                loaded={loaded}
                setDeleteModal={setDeleteModal}
                deleteModal={deleteModal}
              />
            ))}
          </div>
        </div>
        <div className="relative w-full flex justify-end pt-6">
          <Button
            buttonClasses={
              "!px-5 !h-12 !rounded-md !mt-3 !text-white !text-xl hover:!bg-black hover:!border-black !text-center bg-red-600 gap-3  transition duration-300 rounded-none border border-red-600"
            }
            buttonLabel="Continue Shopping"
            buttonLabelClasses="  !text-lg "
            buttonIcon={"fa-sharp-duotone fa-solid fa-bag-shopping"}
            buttonIconPosition={"left"}
            buttonEffect={"filled"}
            buttonHasLink={true}
            buttonLink={"/cards"}
          />
        </div>
      </div>
    </>
  );
};

export default CartsItemsDetails;
