import React, { useCallback, useEffect, useState } from "react";
import { getPageData } from "../../services/frontService/PageService";
import PrivacyPolicyLoader from "../loader/PrivacyPolicyLoader";

const aboutUs = {
  subheading: "Great Designs At The Best Price",
  heading: "SHIPPING POLICY",
  description:
    "HJL Designs is a manufacturer and wholesaler of high quality vinyl labels for the craft wine making industry. With a mix of modern and traditional designs, our ever-expanding lineup of 9.5 cm x 7 cm labels fit any bottle size, are easy to remove, and  do not leave any residue behind.",
};

const ShippingPolicySection = () => {
  const [homeData, setHomeData] = useState({});
  const [loading, setLoading] = useState(false);

  const loadPageData = useCallback(() => {
    setLoading(true);
    getPageData({
      slug: "shipping-policy",
    }).then((res) => {
      if (res?.status === 200) {
        setHomeData(res?.data);
        setLoading(false);
      } else {
        setLoading(true);
      }
    });
  }, []);

  useEffect(() => {
    loadPageData();
  }, [loadPageData]);

  return (
    <>
      <div className="relative sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto">
        <div className="relative  w-full pt-24 ">
          <div className="relative">
            <h2 className="text-3xl font-bold mb-6 text-red-600 border-b border-slate-300 pb-2">
              Shipping Policy
            </h2>
            {loading ? (
              <>
                <PrivacyPolicyLoader></PrivacyPolicyLoader>
              </>
            ) : (
              <div
                className="relative w-full text-base text-slate-700 flex flex-col"
                dangerouslySetInnerHTML={{
                  __html: homeData?.description || "",
                }}
              >
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ShippingPolicySection;
