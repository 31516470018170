import React, { useEffect, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import Image from "../elements/Image";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "../../global.css";
// import required modules
import { FreeMode, Mousewheel, Navigation, Thumbs } from "swiper/modules";
import SwiperProduct from "../Items/SwiperProduct";
import LOGO from "../../assets/images/logo.png";
import Image1 from "../../assets/images/all label/alllabel-1.jpg";
import Image2 from "../../assets/images/labels/lable-card-19.webp";

export default function DetailsImageSection({ quickView = false, details }) {
  const deviceWidth = window.innerWidth;
  const swiperParams = {
    breakpoints: {
      0: {
        slidesPerView: 1.2,
        spaceBetween: 20,
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      1280: {
        slidesPerView: 6,
        spaceBetween: 20,
      },
    },
  };

  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const filteredProductImages = details?.productImages?.filter(
    (item) => item?.imageUrl !== null
  );

  // const filteredProductImages = [
  //   {
  //     default_status: 0,
  //     image: "public/uploads/1/bagpipers.jpg",
  //     imageUrl: LOGO,
  //   },
  //   {
  //     default_status: 1,
  //     image: "public/uploads/102/pinot-gris.jpg",
  //     imageUrl: Image1,
  //   },
  //   {
  //     default_status: 2,
  //     image: "../../assets/images/cru-international/cru-international-10.webp",
  //     imageUrl: Image2,
  //   },
  // ];
  return (
    <>
      <div className="md:flex md:flex-row-reverse block">
        {filteredProductImages?.length > 0 && (
          <Swiper
            style={{
              "--swiper-navigation-color": "#fff",
              "--swiper-pagination-color": "#fff",
            }}
            loop={true}
            spaceBetween={10}
            navigation={true}
            thumbs={{
              swiper:
                thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
            }}
            modules={[FreeMode, Navigation, Thumbs]}
            className="main-gallery"
          >
            {filteredProductImages?.map((item, index) => (
              <SwiperSlide key={index} className="relative !h-auto !flex">
                <SwiperProduct
                  data={item}
                  fancybox={true}
                  fancyboxAttribute="gallery"
                />
              </SwiperSlide>
            ))}
          </Swiper>
        )}
        {filteredProductImages?.length > 0 && !quickView && (
          <Swiper
            //  {...swiperParams}
            onSwiper={setThumbsSwiper}
            loop={true}
            spaceBetween={10}
            slidesPerView={4}
            direction={deviceWidth < 480 ? "horizontal" : "vertical"}
            mousewheel={true}
            freeMode={true}
            watchSlidesProgress={true}
            modules={[FreeMode, Navigation, Thumbs, Mousewheel]}
            className="thumb-gallery"
          >
            {filteredProductImages?.map((item, index) => (
              <SwiperSlide key={index}>
                <SwiperProduct data={item} />
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
    </>
  );
}
