import React from "react";
import avatar from "../../../assets/images/avatar.png";
import Image from "../../../components/elements/Image";
import Button from "../../../components/form/Button";
import StarRating from "../../../components/elements/StartRating";
import moment from "moment";
import { toast } from "react-toastify";
import { changeReviewStatus } from "../../../services/reviewService";

export const ReviewDetailsModal = ({
  onClose = () => {},
  details,
  setDetails = () => {},
  loadList = () => {},
}) => {
  const handleStatusChange = async (data) => {
    changeReviewStatus({
      id: data.id,
      is_approved: data.is_approved === 1 ? 2 : 1,
    }).then((res) => {
      if (res?.status === 200) {
        setDetails((pre) => ({
          ...pre,
          is_approved: pre.is_approved === 1 ? 2 : 1,
        }));
        loadList();
        toast.success("Status Change Successful");
      } else {
        toast.error("Somthing Went Wrong");
      }
    });
  };

  return (
    <div>
      <section className="bg-white antialiased dark:bg-gray-900">
        <div className="mx-auto max-w-screen-xl px-4 2xl:px-0">
          <div className="flex items-center justify-between gap-2 mb-4">
            <div className="gap-2">
              <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-1">
                Reviews Of{" "}
                <span className="font-bold text-red-600">
                  {details?.productInfo?.title}
                </span>
              </h2>

              <div className="mt-2 flex items-center gap-2 sm:mt-0">
                <div className="flex items-center gap-0.5">
                  <StarRating rating={details?.rating} />
                </div>
                <p className="text-sm font-medium leading-none text-gray-500 dark:text-gray-400">
                  ({details?.rating})
                </p>
              </div>
            </div>

            <Button
              buttonClasses="!bg-white !h-8 !text-2xl !text-black hover:!text-black hover:!bg-gray-200 hover:!border-gray-200 border border-gray-200 gap-2 transition duration-200 rounded-md"
              buttonIcon="fa-regular fa-xmark"
              buttonIconPosition="right"
              buttonEffect="filled"
              buttonFunction={onClose}
            />
          </div>
          {details?.is_approved === 1 ? (
            <div
              className="w-32 rounded-md mt-3 text-base font-bold bg-blue-100 text-blue-600 flex justify-center items-center cursor-pointer"
              onClick={() => handleStatusChange(details)}
            >
              Approved
            </div>
          ) : (
            <div
              className="w-32 rounded-md mt-3 text-base font-bold bg-red-100 text-red-600 flex justify-center items-center cursor-pointer"
              onClick={() => handleStatusChange(details)}
            >
              Not Approved
            </div>
          )}

          <div className="mt-6 divide-y divide-gray-200 dark:divide-gray-700">
            <div className="gap-3 pb-6 sm:flex sm:items-start">
              <div className="shrink-0 space-y-2 sm:w-48 md:w-72">
                <div className="flex items-center gap-0.5">
                  <StarRating rating={details?.rating} />
                </div>

                <div className="space-y-0.5">
                  <p className="text-base font-semibold text-gray-900 dark:text-white">
                    {details?.reviewUserInfo?.name}
                  </p>
                  <p className="text-sm font-normal text-gray-500 dark:text-gray-400">
                    {moment(details?.created_at).format(
                      "MMMM D, YYYY [at] HH:mm"
                    )}
                  </p>
                </div>

                <div className="inline-flex items-center gap-1">
                  <svg
                    className="h-5 w-5 text-primary-700 dark:text-primary-500"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12 2c-.791 0-1.55.314-2.11.874l-.893.893a.985.985 0 0 1-.696.288H7.04A2.984 2.984 0 0 0 4.055 7.04v1.262a.986.986 0 0 1-.288.696l-.893.893a2.984 2.984 0 0 0 0 4.22l.893.893a.985.985 0 0 1 .288.696v1.262a2.984 2.984 0 0 0 2.984 2.984h1.262c.261 0 .512.104.696.288l.893.893a2.984 2.984 0 0 0 4.22 0l.893-.893a.985.985 0 0 1 .696-.288h1.262a2.984 2.984 0 0 0 2.984-2.984V15.7c0-.261.104-.512.288-.696l.893-.893a2.984 2.984 0 0 0 0-4.22l-.893-.893a.985.985 0 0 1-.288-.696V7.04a2.984 2.984 0 0 0-2.984-2.984h-1.262a.985.985 0 0 1-.696-.288l-.893-.893A2.984 2.984 0 0 0 12 2Zm3.683 7.73a1 1 0 1 0-1.414-1.413l-4.253 4.253-1.277-1.277a1 1 0 0 0-1.415 1.414l1.985 1.984a1 1 0 0 0 1.414 0l4.96-4.96Z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <p className="text-sm font-medium text-gray-900 dark:text-white">
                    Verified purchase
                  </p>
                </div>
              </div>

              <div className="mt-4 min-w-0 flex-1 space-y-4 sm:mt-0">
                <div className="h-32 overflow-auto p-2 bg-white rounded-md border border-gray-200">
                  <p className="text-base font-normal text-black dark:text-black">
                    {details?.review || ""}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
