import React from "react";
import Image from "../elements/Image";
import img from "../../assets/images/cards/card-inside-6.webp";
import Review from "./Review";
import SingleDescriptionRating from "./SingleDescriptionRating";
import nodatafound from "../../assets/images/nodatafound.png";

const ProductDescriptionRatingAdmin = ({ reviewList }) => {
  return (
    <>
      {reviewList?.data?.length > 0 ? (
        <div className="xl:w-full w-full bg-white rounded-md md:px-10 px-5 py-5 shadow-md">
          <div className="relative divide-y divide-slate-200">
            {reviewList?.data?.map((item, index) => (
              <SingleDescriptionRating key={index} data={item} />
            ))}
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center md:space-y-8 space-y-4 md:mt-16 mt-2">
          <div className="w-40 h-40 rounded-full bg-[#F0F9F4] overflow-hidden flex items-center justify-center">
            <div className="text-[#e73737] text-8xl">
              <i className="fa-regular fa fa-exclamation"></i>
            </div>
          </div>
          <div className="text-cape-cod text-3xl">No Reviews Available.</div>
          <div className="text-concord text-base max-w-md text-center">
            It looks like there are no reviews for this item yet. Be the first
            to share your thoughts!
          </div>
        </div>
      )}
    </>
  );
};

export default ProductDescriptionRatingAdmin;
