import { apiRequest } from "../util/apiUtils";

export const getEnquiryList = async (params) => {
  try {
    const res = await apiRequest("get", "admin/contact-us/list", {
      queries: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const changeEnquiryStatus = async (payload = {}) => {
    try {
      const res = await apiRequest("post", "admin/contact-us/status-change", {
        body: payload,
      });
      return res;
    } catch (err) {
      return err;
    }
  };

  export const deleteEnquiry = async (payload = {}) => {
    try {
      const res = await apiRequest("post", "admin/contact-us/delete", {
        body: payload,
      });
      return res;
    } catch (err) {
      return err;
    }
  };