import { apiRequest } from "../util/apiUtils";

export const getVatList = async (params) => {
  try {
    const res = await apiRequest("get", "admin/vat/list", {
      queries: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const changeVatStatus = async (payload = {}) => {
  try {
    const res = await apiRequest("post", "admin/vat/status-change", {
      body: payload,
    });
    return res;
  } catch (err) {
    return err;
    throw err;
  }
};

export const addEditVat = async (params) => {
  try {
    const res = await apiRequest(
      params.id ? "post" : "post",
      params.id ? "admin/vat/edit" : "admin/vat/add",
      {
        body: { ...params },
      }
      // "multipart/form-data"
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const vatDetails = async (params) => {
  try {
    const res = await apiRequest("get", `admin/vat/details/${params?.id}`, {});
    return res?.data;
  } catch (err) {
    return err;
  }
};

export const deleteVat = async (payload = {}) => {
  try {
    const res = await apiRequest("post", "admin/vat/delete", {
      body: payload,
    });
    return res;
  } catch (err) {
    return err;
    throw err;
  }
};
