import React from "react";
import nodatafound from "../../../assets/images/nodatafound.png";
import Image from "../../../components/elements/Image";

const PageDescriptionItem = ({ data }) => {
  return (
    <>
      {data?.description ? (
        <div className="xl:w-full w-full bg-white rounded-md md:px-10 px-5 py-5 shadow-md">
          <div className="relative flex space-x-4 py-4 overflow-x-auto">
            <div className="pt-3">
              <div
                className="relative text-base"
                dangerouslySetInnerHTML={{
                  __html: data?.description,
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="w-2xl bg-white rounded-md md:px-10 px-5 py-5 shadow-md flex">
          <div className="w-full h-auto overflow-hidden flex justify-center items-center mx-auto">
            <div>
              <Image src={nodatafound} alt="No Data Found" effect="blur" className="w-full h-full object-cover" />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PageDescriptionItem;
