import React from "react";

import SingleViewDetailsProductList from "../Items/SingleViewDetailsProductList";

const ViewOrderDetailsProductsList = ({ details }) => {
  return (
    <>
      <div className="relative xl:w-4/6 w-full bg-white">
        {details?.orderDetailsInfo?.length > 0 ? (
          <div className="relative divide-y divide-slate-200">
            {details?.orderDetailsInfo?.map((item, index) => (
              <SingleViewDetailsProductList key={index} data={item} details={details} />
            ))}
          </div>
        ) : (
          <>
            <div>No data</div>
          </>
        )}
      </div>
    </>
  );
};

export default ViewOrderDetailsProductsList;
