import { configureStore } from "@reduxjs/toolkit";
import settingsSlice from "./slice/settingsSlice";
import profileSlice from "./slice/profileSlice,";
import loginSlice from "./slice/loginSlice";
import authSlice from "./slice/authSlice";
import cartSlice from "./slice/cartSlice";
import checkoutSlice from "./slice/checkoutSlice";

export const store = configureStore({
  reducer: {
    profile: profileSlice,
    settings: settingsSlice,
    loginModal: loginSlice,
    auth: authSlice,
    cart: cartSlice,
    checkout: checkoutSlice,
  },
});
