import React, { useState } from "react";
import Button from "../form/Button";
import Feedback from "../Items/Feedback";
import Input from "../form/Input";
import Textarea from "../form/Textarea";
import SubmitRating from "./SubmitRating";
import Image from "../elements/Image";
import productImage from "../../assets/images/cards/card-inside-6.webp";
import Review from "../Items/Review";
import { addReview } from "../../services/frontService/ReviewService";
import { toast } from "react-toastify";

const LeaveFeedback = ({ onClose, data }) => {
  const [rating, setRating] = useState();
  const [feedback, setFeedback] = useState("");

  const handleReviewSubmit = async () => {
    try {
      const res = await addReview({
        review: feedback,
        rating: rating,
        product_id: data?.product_id,
      });

      if (res?.data?.status === 200 || res?.data?.status === 201) {
        toast.info(res?.data?.message);
        onClose();
      } else {
        toast.info(res?.data?.message || res?.data?.errors || "Something went wrong");
        onClose();
      }
    } catch (error) {
      toast.error("An error occurred while submitting your review.");
    }
  };

  return (
    <div className="relative pb-10">
      <div className="flex w-full justify-end text-end text-2xl absolute md:-top-6 -top-9 right-2">
        <Button
          buttonClasses="!bg-white !h-8 !text-3xl !text-black hover:!text-black !text-xl hover:!bg-gray-200 hover:!border-gray-200 !text-center border border-gray-200 gap-3 transition duration-200 rounded-none leading-none !rounded-md"
          buttonIcon="fa-regular fa-xmark"
          buttonIconPosition="right"
          buttonEffect="filled"
          buttonFunction={onClose}
        />
      </div>
      <div>
        <div className="text-center">
          <div className="md:text-3xl text-2xl text-red-600 font-bold">Share Your Review</div>
          <div className="md:text-base text-sm text-grey-600 pt-2 pb-4 md:px-10 px-0">
            We highly value your feedback! Kindly take a moment to rate your experience and provide us with your
            valuable feedback.
          </div>
        </div>
        <div className="flex w-full pt-8 justify-center space-x-6">
          <div className="w-24 h-24 overflow-hidden rounded-md mb-3">
            <Image className="w-full h-full object-cover" src={data?.productImageUrl} alt="Product" effect="blur" />
          </div>
          <div className="space-y-1">
            <div className="text-lg font-bold text-red-600 leading-none line-clamp-1">{data?.product_name}</div>
            <Review rating={data?.averageRating} totalReviews={data?.totalReview} />
          </div>
        </div>
        <div className="w-full flex justify-center">
          <SubmitRating rating={rating} setRating={setRating} />
        </div>
        <div className="items-center md:divide-x mt-2 md:px-10 px-4">
          <div className="w-full">
            <Textarea
              inputPlaceholder="Leave Your Feedback"
              inputName="message"
              inputType="text"
              inputClasses="!h-28 py-3 !border-0 !bg-gray-100 !shadow md:text-xl text-base"
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
            />
          </div>
        </div>
        <div className="flex justify-center space-x-6 md:mt-8 mt-4">
          <Button
            buttonClasses="!bg-white !text-black !border !border-black hover:!bg-black hover:!text-white !px-10"
            buttonLabel="Leave"
            buttonFunction={onClose}
          />
          <Button
            buttonClasses="!text-black !text-white !bg-black hover:!bg-red-600 !px-10"
            buttonLabel="Submit"
            buttonFunction={() => {
              handleReviewSubmit();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default LeaveFeedback;
