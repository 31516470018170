import React, { useState } from "react";

const StarRating = ({ rating, onChange }) => {
  const [starRating, setStartRating] = useState(rating);

  const handleRatingChange = (newRating) => {
    setStartRating(newRating);
    onChange(newRating);
  };

  const stars = [];

  const fullStars = Math.floor(starRating);
  const hasHalfStar = starRating - fullStars >= 0.1;

  if (!onChange) {
    for (let i = 0; i < 5; i++) {
      if (i < fullStars) {
        stars.push(<i key={i} className="fa fa-star text-yellow-300 cursor-pointer"></i>);
      } else if (i === fullStars && hasHalfStar) {
        stars.push(<i key="half" className="fa fa-star-half-alt text-yellow-300 cursor-pointer"></i>);
      } else {
        stars.push(<i key={i} className="fa fa-star text-[#c0c0c0] cursor-pointer"></i>);
      }
    }
  } else {
    for (let i = 0; i < 5; i++) {
      if (i < fullStars) {
        stars.push(
          <i
            key={i}
            className="fa fa-star text-yellow-300 cursor-pointer"
            onClick={() => handleRatingChange(i + 1)}></i>
        );
      } else if (i === fullStars && hasHalfStar) {
        stars.push(
          <i
            key="half"
            className="fa fa-star-half-alt text-yellow-300 cursor-pointer"
            onClick={() => handleRatingChange(i + 0.5)}></i>
        );
      } else {
        stars.push(
          <i key={i} className="fa fa-star text-[#c0c0c0] cursor-pointer" onClick={() => handleRatingChange(i + 1)}></i>
        );
      }
    }
  }

  const boxSize = {
    sm: "w-16",
    md: "w-32",
    lg: "w-60",
    full: "w-full",
  };

  return (
    <div className="flex items-center gap-1">
      {stars}
      {/* {starRating} */}
    </div>
  );
};

export default StarRating;
