import React from 'react';

const DetailsBreadcrumb = ({ items }) => {
    return (
    <div className="flex rounded-md w-full justify-start">
      <ol className="inline-flex items-center space-x-1 md:space-x-3 ">
        {items.map((item, index) => (
          <li key={index} className="inline-flex items-center">
            {index !== items.length - 1 ? (
              <>
              <div className='flex items-center'>
                <a href={item.href} className="inline-flex items-center text-base font-medium text-gray-600 hover:text-red-600 leading-none">
                  {item.label}
                </a>
                <div className='"w-4 h-4 text-red-600 mx-2 leading-none'>
                <i className="fa-solid fa-angles-right"></i>
                </div>
                </div>
                {/* <svg
                  className="
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 111.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  ></path>
                </svg> */}
              </>
            ) : (
              <span className="text-base font-medium text-gray-600 border-b border-red-600">{item.label}</span>
            )}
          </li>
        ))}
      </ol>
    </div>
    );
};

export default DetailsBreadcrumb;