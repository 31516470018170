import React, { useCallback, useEffect, useState } from "react";
import Image from "../elements/Image";
import OurCards from "./OurCards";
import Testimonial from "./Testimonial";
import CardFlipBook from "./CardFlipBook";
import { getPageData } from "../../services/frontService/PageService";

const AboutUsSection = () => {
  const [homeData, setHomeData] = useState({});

  const loadPageData = useCallback(() => {
    getPageData({
      slug: "about-us",
    }).then((res) => {
      if (res?.status === 200) {
        setHomeData(res?.data);
      }
    });
  }, []);

  useEffect(() => {
    loadPageData();
  }, [loadPageData]);

  const aboutUs = {
    subheading: "Great Designs At The Best Price",
    heading: homeData?.name || "ABOUT US",
    description:
      homeData?.description ||
      "HJL Designs is a manufacturer and wholesaler of high quality vinyl labels for the craft wine making industry. With a mix of modern and traditional designs, our ever-expanding lineup of 9.5 cm x 7 cm labels fit any bottle size, are easy to remove, and  do not leave any residue behind.",
  };

  return (
    <>
      <div className="relative sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto md:px-auto px-5">
        <div className="text-center md:pt-20 pt-8 xl:px-40 px-0 w-full">
          <div className="md:text-lg text-sm text-gray-700">
            {aboutUs?.subheading}
          </div>
          <h2 className="great-design xl:text-6xl md:text-5xl text-2xl font-bold pt-2 pb-4 drop-shadow-md uppercase">
            <span className="relative inline-block !leading-none before:content-[''] before:absolute before:top-2/4 before:-translate-y-1/2 md:before:-left-36 before:-left-20 md:before:w-32 before:w-16 before:border-t-2 before:border-black after:content-[''] after:absolute after:top-2/4 after:-translate-y-1/2 md:after:-right-36 after:-right-20 md:after:w-32 after:w-16 after:border-t-2 after:border-black">
              {aboutUs?.heading}
            </span>
          </h2>
        </div>
        <div className="max-w-6xl mx-auto relative flex xl:flex-row flex-col md:mt-10 mt-5 gap-10 items-center">
          <div className="relative xl:w-1/3 w-full md:h-auto h-60 flex xl:justify-start justify-center  md:py-5 py-0 md:border-r border-0  xl:pr-10 pr-0">
            <div className="max-w-full flex-shrink-0">
              {/* <Image
                src={AboutImage}
                className={"w-full h-full object-fill"}
                alt=""
              /> */}
              <CardFlipBook />
            </div>
          </div>
          <div className="relative xl:w-3/4 md:full md:py-5 py-0 ">
            <div className="relative">
              <h2 className="md:text-4xl text-2xl font-bold mb-2 text-red-600 md:pb-5 pb-2">
                HJL Designs
              </h2>
              <div
                className="relative w-full md:text-base text-sm text-slate-700 flex flex-col gap-3"
                dangerouslySetInnerHTML={{
                  __html: aboutUs?.description || "",
                }}
              ></div>
            </div>
          </div>
        </div>

        {/* <OurCards/> */}
        {/* <Testimonial/> */}
      </div>
    </>
  );
};

export default AboutUsSection;
