import React, { useCallback, useEffect, useState } from "react";
import { getUserWishlist } from "../../../services/frontService/ProfileService";
import Button from "../../form/Button";
import MyWishlistComponent from "./MyWishListComponent";
import PageLoader from "../../common/PageLoader";
import { toast } from "react-toastify";
import { addWishlistProduct } from "../../../services/frontService/ProductService";

const MyWishlists = () => {
  const [wishlist, setWishlist] = useState([]);
  const [loading, setLoading] = useState(true);

  const getList = useCallback(() => {
    setLoading(true);
    getUserWishlist().then((res) => {
      if (res?.status === 200) {
        setWishlist(res?.data);
        setLoading(false);
      } else {
        setWishlist(res?.data);
        setLoading(false);
      }
    });
  }, []);

  useEffect(() => {
    getList();
  }, [getList]);

  const addWishList = async (data) => {
    try {
      const res = await addWishlistProduct({
        product_id: data?.product_id,
      });

      if (res?.data?.status === 200 || res?.data?.status === 201) {
        toast.info(res?.data?.message);
        setWishlist((prevList) =>
          prevList?.filter((item) => item.id !== data.id)
        );
      } else {
        toast.info(
          res?.data?.message || res?.data?.errors || "Something went wrong"
        );
      }
    } catch (err) {
      toast.error("Something went wrong !!!");
    }
  };

  return (
    <div className="mt-10 w-full h-full bg-slate-100 rounded-md md:px-10 px-5 py-5 shadow-md">
      {loading ? (
        <PageLoader />
      ) : wishlist?.length > 0 ? (
        <>
          <div className="text-black text-2xl font-bold pb-5">My Wishlist</div>
          <MyWishlistComponent wishlist={wishlist} addWishList={addWishList} />
        </>
      ) : (
        <div className="flex flex-col items-center space-y-8 mt-16">
          <div className="w-40 h-40 rounded-full bg-[#F0F9F4] overflow-hidden flex items-center justify-center">
            <div className="text-[#e73737] text-8xl">
              <i className="fa-regular fa-heart"></i>
            </div>
          </div>
          <div className="text-cape-cod text-3xl">Your wishlist is empty.</div>
          <div className="text-concord text-base max-w-md text-center">
            You don’t have any products in the wishlist yet. Explore our wide
            range of products and start shopping.
          </div>
          <Button
            buttonClasses="md:!px-4 !px-3 !py-1 md:!h-10 !h-7 !text-white hover:!text-white !text-xl hover:!bg-black hover:!border-black !text-center bg-red-600 gap-3 transition duration-200 rounded-md"
            buttonLabel="Continue Shopping"
            buttonLabelClasses="md:!text-lg !text-sm"
            buttonEffect="filled"
            buttonHasLink={true}
            buttonLink="/"
          />
        </div>
      )}
    </div>
  );
};

export default MyWishlists;
