import React from 'react'
import MainBanner from "../components/sections/MainBanner"
import ShippingPolicySection from "../components/sections/ShippingPolicySection"

const ShippingPolicy = () => {
  return (
    <>
      <MainBanner/>
      <ShippingPolicySection/>
    </>
  )
}

export default ShippingPolicy