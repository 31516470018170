import { apiRequest } from "../util/apiUtils";

export const getCategoryList = async (params) => {
  try {
    const res = await apiRequest("get", "admin/category/list", {
      queries: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const getParentCategoryList = async (params) => {
  try {
    const res = await apiRequest("get", "admin/category/parent-categoris", {
      queries: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const deleteCategory = async (params) => {
  try {
    const res = await apiRequest("delete", `admin/category/delete/${params?.id}`, {
      body: params,
    });
    return res;
  } catch (err) {
    return err;
    throw err;
  }
};

export const addCategory = async (params) => {
  try {
    const res = await apiRequest(
      params.id ? "post" : "post",
      params.id ? "admin/category/add" : "admin/category/add",
      {
        body: { ...params },
      }
      // "multipart/form-data"
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const categoryDetails = async (params) => {
  try {
    const res = await apiRequest("get", `admin/category/details/${params?.id}`, {});
    return res?.data;
  } catch (err) {
    return err;
  }
};
