import React, { useCallback, useEffect, useRef, useState } from "react";
import Button from "../../../components/form/Button";
import SingleImageUpload from "../../../components/form/SingleImageUpload";
import Input from "../../../components/form/Input";
import useForm from "../../../hooks/useForm";
import { formValidate } from "../../../helpers/formValidate";
import { Editor } from "@tinymce/tinymce-react";
import { toast } from "react-toastify";
import { bannerDetails, addEditBanner } from "../../../services/pagesService";
import { useNavigate, useParams } from "react-router-dom";

export const AddBannerModal = ({
  onClose = () => {},
  setLoaded = () => {},
  loaded,
  bannerId,
  setDetails = () => {},
  getBannerDetails = () => {},
}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [imageUrl, setImageUrl] = useState();
  const [description, setDescription] = useState();
  const [detailsData, setDetailsData] = useState({});

  const validation = {
    title: { required: true, message: "Please enter your title !!!" },
    link: { required: true, message: "Please enter your link !!!" },
  };
  const { values, handleChange, handleSubmit, errors, setFieldsValue } = useForm({}, validation);
  const editorRef = useRef(null);
  const tinymceApiKey = "uemqg8efft8kgaho6qxmnav0yzbr7s6n00wmyfrh5b7gyxvl";

  const getDetails = useCallback(() => {
    if (bannerId) {
      setDetailsData({ loading: true, data: {} });
      bannerDetails({ bannerId }).then((res) => {
        if (res?.status === 200) {
          setDetailsData({ loading: true, data: res?.docs });
          setFieldsValue({
            title: res?.docs?.title || "",
            link: res?.docs?.link || "",
          });
          setDescription(res?.docs?.description);
          setImageUrl(res?.docs?.imageUrl);
        }
      });
    }
  }, [bannerId]);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  const onSubmit = async (values) => {
    setLoaded(true);
    try {
      const res = await addEditBanner({
        id: bannerId,
        page_id: id,
        image: imageUrl,
        title: values?.title,
        link: values?.link,
        description: description,
        status: 1,
      });
      if (res?.data?.status === 200 || res?.data?.status === 201) {
        toast.info(res?.data?.message);
        onClose(true);
        getBannerDetails();
      } else {
        toast.info(res?.data?.message || res?.data?.errors || "Something went wrong");
      }

      setLoaded(false);
    } catch (err) {
      setLoaded(false);
      // console.error(err);
      toast.error("Somthing went wrong !!!");
    }
  };

  return (
    <>
      <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
        <div className="py-2 md:px-6 px-0">
          <div className="grid grid-cols-5 gap-4 divide-x divide-slate-200 md:px-10 px-0">
            <div className="xl:col-span-4 col-span-5">
              <div className="space-y-3">
                <div className="grid grid-cols-12 gap-4">
                  <div className="relative md:col-span-6 col-span-12">
                    <Input
                      isInputGroup={false}
                      label={"Title"}
                      labelClasses={"!text-base"}
                      inputType={"text"}
                      value={values.title}
                      inputName={"title"}
                      onChange={handleChange}
                      inputPlaceholder={"Enter Title"}
                      {...formValidate(errors, "title")}
                    />
                  </div>
                  <div className="relative md:col-span-6 col-span-12">
                    <Input
                      isInputGroup={false}
                      label={"Link"}
                      labelClasses={"!text-base"}
                      inputType={"text"}
                      value={values.link}
                      inputName={"link"}
                      onChange={handleChange}
                      inputPlaceholder={"Enter Link"}
                      {...formValidate(errors, "link")}
                    />
                  </div>
                  <div className="relative md:col-span-12 col-span-12">
                    <span className="test-base">Description</span>
                    <Editor
                      apiKey={tinymceApiKey}
                      onInit={(evt, editor) => (editorRef.current = editor)}
                      value={description}
                      init={{
                        height: 200,
                        menubar: false,
                        branding: false,
                        plugins: [
                          "code",
                          "advlist autolink lists link image charmap print preview anchor",
                          "searchreplace visualblocks code fullscreen",
                          "insertdatetime media table paste code help wordcount",
                        ],
                        toolbar:
                          "code | undo redo | formatselect | " +
                          "bold italic backcolor | alignleft aligncenter " +
                          "alignright alignjustify | bullist numlist outdent indent | " +
                          "removeformat | help ",
                        content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                      }}
                      onEditorChange={(newText) => setDescription(newText)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="xl:col-span-1 col-span-5 pl-4 gap-3">
              <div className="relative">
                <SingleImageUpload
                  label={"Image"}
                  size={"full"}
                  image={imageUrl}
                  setImage={setImageUrl}
                  accept={["jpg", "png", "jpeg", "webp"]}
                />
              </div>
            </div>
          </div>
          <div className="mt-8 pt-4 border-t border-slate-200">
            <div className="flex justify-end space-x-3">
              <Button
                buttonHasLink={false}
                buttonType={"button"}
                buttonIcon={"fa-regular fa-xmark"}
                buttonIconPosition={"left"}
                buttonLabel={"Cancel"}
                buttonClasses={"!bg-white !text-primary !border-primary !border-2"}
                buttonFunction={onClose}
              />
              <Button
                buttonHasLink={false}
                buttonType={"submit"}
                buttonIcon={"fa-light fa-floppy-disk"}
                buttonIconPosition={"left"}
                buttonClasses={"!bg-primary !text-org-silver"}
                buttonLabel={"Save"}
                isDisable={loaded}
              />
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
