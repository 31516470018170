import { apiRequest } from "../util/apiUtils";

export const editProfile = async (params) => {
  try {
    const res = await apiRequest(
      "post",
      "admin/user/edit",
      {
        body: { ...params },
      },
      "multipart/form-data"
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const profileDetails = async (params) => {
  try {
    const res = await apiRequest("get", `admin/user/details/${params}`, {});
    return res?.data;
  } catch (err) {
    return err;
  }
};
