import React from "react";
import RecentProductItems from "./RecentProductItems";
import { Link } from "react-router-dom";
import image1 from "../../../assets/images/cards/card-inside-5.webp";
import image2 from "../../../assets/images/cards/card-inside-4.webp";
import nodatafound from "../../../assets/images/nodatafound.png";
import Image from "../../elements/Image";

// const DashboardRecentProduct = {
//   data: [
//     {
//       _id: 1,
//       image: image1,
//       name: "Dancing in the moon card",
//       subtitle: "Packs of 1S Packs",
//       quantity: "30",
//       price: "CAD 2.25",
//       rating: {
//         value: 3.2,
//         reviews: 100,
//       },
//     },

//     {
//       _id: 2,
//       image: image2,
//       name: "Dancing in the moon card",
//       subtitle: "Packs of 1S Packs",
//       quantity: "30",
//       price: "CAD 2.25",
//       rating: {
//         value: 3.2,
//         reviews: 100,
//       },
//     },
//   ],
// };

const UserOrders = ({
  orderList,
  setOrderList = () => {},
  getOrderDetails = () => {},
}) => {
  return (
    <>
      {orderList?.data?.length > 0 ? (
        <div className="xl:w-full w-full md:px-10 px-5 rounded-md bg-white shadow-md">
          <div className="relative divide-y divide-slate-200">
            {orderList?.data?.map((item, index) => (
              <RecentProductItems
                key={index}
                data={item}
                orderList={orderList}
                setOrderList={setOrderList}
                getOrderDetails={getOrderDetails}
              />
            ))}
          </div>
        </div>
      ) : (
        <>
          <div className="flex flex-col items-center md:space-y-8 space-y-4 mt-13">
            <div className="w-40 h-40 rounded-full bg-[#F0F9F4] overflow-hidden flex items-center justify-center">
              <div className="text-[#e73737] text-8xl">
                <i className="fa-regular fa fa-exclamation"></i>
              </div>
            </div>
            <div className="text-cape-cod text-3xl">No Orders Available.</div>
            <div className="text-concord text-base max-w-md text-center">
              Order information not found. Please check the order number or try
              again later.
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default UserOrders;
