import React, { useState } from "react";

const Accordion = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <>
      <div className="w-full xl:mt-0 md:mt-6">
        {items &&
          Array.isArray(items) &&
          items?.length > 0 &&
          items?.map((item, index) => (
            <div key={index} className="space-y-4">
              <button
                onClick={() => toggleAccordion(index)}
                className="w-full flex justify-between items-center p-6 text-left text-gray-700 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 rounded-md shadow-md border border-white md:text-lg text-base"
              >
                <span>{item?.question || ""}</span>
                <span
                  className="transform transition-transform duration-300 ml-3"
                  style={{
                    transform:
                      activeIndex === index ? "rotate(180deg)" : "rotate(0deg)",
                  }}
                >
                  <div className="text-xl">
                    <i class="fa-solid fa-caret-down"></i>
                  </div>
                </span>
              </button>
              <div
                className={`transition-max-height duration-300 ease-in-out overflow-hidden ${
                  activeIndex === index ? "max-h-screen" : "max-h-0"
                }`}
              >
                <div className="px-4 py-4 text-gray-600 bg-white md:text-lg text-base">
                  {item?.answer || ""}
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default Accordion;
