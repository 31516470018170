import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { classNames } from "../../../helpers/classNames";
import { Link, useNavigate } from "react-router-dom";
import Image from "../Image";
import Login from "../../../pages/Login";
import Logo from "../../../assets/images/logo(white).png";
import Background from "../../../assets/images/loginBackground.jpg";
import LoginForm from "./LoginForm";
import Button from "../../form/Button";

const LoginModal = ({ onClose = () => {}, getProfile = () => {} }) => {
  return (
    <>
      <div className="flex justify-end">
        <Button
          buttonClasses="!bg-white !h-4 !text-2xl !text-black hover:!text-red-600 !text-center  gap-3 transition duration-200 rounded-md mt-0 sm:mt-0"
          buttonIcon="fa-regular fa-xmark"
          buttonIconPosition="right"
          buttonEffect="filled"
          buttonFunction={onClose}
        />
      </div>
      <div className="relative flex justify-between w-full flex-grow flex-shrink md:p-5 p-0 !pt-0">
        <div className="h-full flex justify-center items-center w-full">
          <div className="relative w-full space-y-8">
            <div className={`relative w-full bg-white rounded-lg overflow-hidden flex flex-wrap sm:flex-nowrap `}>
              <div className="w-1/2 hidden sm:block">
                <img src={Background} alt={"Image"} className={"w-full h-full object-cover"} />
              </div>

              <div className={`md:w-1/2 w-full md:pl-10 pl-0 pb-5 pt-0 space-y-8 overflow-hidden`}>
                {/* <div className="flex justify-center">
                  <Link to={`${"/"}`}>
                    <div className="relative h-16 flex w-44">
                      <Image
                        src={Logo}
                        alt={"siteSetting?.logo?.url"}
                        className={"w-full h-full object-contain filter invert"}
                      />
                    </div>
                  </Link>
                </div> */}
                <div className="flex justify-center">
                  <LoginForm getProfile={getProfile}></LoginForm>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginModal;
