import React, { useState } from "react";
import Image from "../elements/Image";
import Review from "./Review";
import { country } from "../../helpers";
import image2 from "../../assets/images/cards/card-inside-2.webp";

const SingleMyWishlist = ({ data, addWishList = () => {} }) => {
  return (
    <div className="relative flex md:gap-10 gap-6 md:py-6 py-4">
      <div className="relative">
        <div className="md:w-32 md:h-32 w-24 h-24 overflow-hidden rounded-md">
          <Image
            src={data?.wishListProductInfo?.productImageUrl}
            className="w-full h-full object-fill"
            alt="product image"
          />
        </div>
      </div>
      <div className="relative flex w-full space-y-10">
        <div className="relative w-full">
          <div className="md:text-xl text-base font-bold capitalize text-slate-900 leading-none">
            {data?.wishListProductInfo?.title}
          </div>
          <div className="relative md:text-base text-sm text-slate-600 mt-2">
            {data.title}
          </div>
          <div>
            <Review
              rating={data?.wishListProductInfo?.averageRating}
              totalReviews={data?.wishListProductInfo?.totalReview}
              isReviewShow={true}
              classes={"md:flex block"}
            />
          </div>
          <div className="flex space-x-2 py-1 items-center">
            <div className="md:text-base text-sm">Quantity :</div>
            <div>{data?.wishListProductInfo?.minimum_package_quantity}</div>
          </div>
        </div>
        <div className="relative w-full">
          <div
            className="text-red-600 text-2xl absolute top-0 right-0 cursor-pointer"
            onClick={() => addWishList(data)}
          >
            <i className="fa-solid fa-heart"></i>
          </div>
          <div className="md:text-xl text-base md:py-2 py-1 text-black font-bold absolute md:top-12 top-6 md:right-0 right-1">
            {country + " " + (data?.wishListProductInfo?.price || "")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleMyWishlist;
