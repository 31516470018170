import React, { useState } from "react";
import { toast } from "react-toastify";
import Modal from "../elements/Modal/Modal";
import Button from "../form/Button";

const DeleteModal = ({
  open = false,
  close = () => {},
  deleteHandler = () => {},
  title = "Delete Record",
  titleIcon = "fa-regular fa-circle-exclamation",
  loading = false,
  customMessage,
}) => {
  return (
    <Modal open={open}  title={title} size="sm">
      <div className="text-center my-3 text-slate-500">
        {customMessage ? (
          customMessage
        ) : (
          <>
            You are trying to delete the record,
            <br /> click &quot;Delete&quot; below.
          </>
        )}
      </div>
      <div className="flex gap-3 justify-center">
        <Button
          buttonIconPosition={"left"}
          buttonIcon={"fa-regular fa-close"}
          buttonLabel={"Cancel"}
          buttonFunction={() => close()}
          buttonClasses={
            "!bg-org-green !border-org-green !hover:bg-org-green/80"
          }
        />
        <Button
          buttonFunction={deleteHandler}
          buttonIconPosition={"left"}
          isDisable={loading}
          isLoaderDisabled={true}
          buttonIcon={
            loading
              ? "fa-duotone fa-spinner-third animate-spin"
              : "fa-regular fa-trash-can"
          }
          buttonLabel={"Delete"}
          buttonClasses={"!bg-org-red !border-org-red !hover:bg-org-red/80"}
        />
      </div>
    </Modal>
  );
};

export default DeleteModal;
