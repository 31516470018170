import React, { useCallback, useEffect, useState } from "react";
import PageHeader from "../../../shared/PageHeader";
import Button from "../../../components/form/Button";
import Input from "../../../components/form/Input";
import SingleImageUpload from "../../../components/form/SingleImageUpload";
import { formValidate } from "../../../helpers/formValidate";
import useForm from "../../../hooks/useForm";
import { Validation } from "../../../helpers/Validation";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
// import { addUser, userDetails } from "../../../services/userService";
import { setTitle } from "../../../helpers/MetaTag";
import DatePickerInput from "../../../components/form/DatePickerInput";
import { addUser, userDetails } from "../../../services/userService";

const AdminAddEdit = () => {
  setTitle("HJL DESIGNS");
  const { id } = useParams();
  const validation = {
    name: { required: true, message: "Please enter your name !!!" },
    email: { required: true, message: "Please enter your email !!!" },
    phone: { required: true, message: "Please enter phone number !!!" },
    password: {
      required: id ? false : true,
      message: "Please enter your password !!!",
    },
  };
  const { values, handleChange, handleSubmit, errors, setFieldsValue } = useForm({}, validation);
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [companyImage, setCompanyImage] = useState();
  const [detailsData, setDetailsData] = useState({ loading: false, data: {} });
  const [date, setDate] = useState({ startDate: null, endDate: null });

  const validatePassword = (password) => {
    const errors = [];
    if (password.length < 8) {
      errors.push("Password must be at least 8 characters long.");
    }
    if (!/(?=.*\d)/.test(password)) {
      errors.push("Password must contain at least one digit.");
    }
    if (!/(?=.*[a-z])/.test(password)) {
      errors.push("Password must contain at least one lowercase letter.");
    }
    if (!/(?=.*[A-Z])/.test(password)) {
      errors.push("Password must contain at least one uppercase letter.");
    }
    if (!/(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])/.test(password)) {
      errors.push("Password must contain at least one special character (!@#$%^&*()_+-=[]{};':\"\\|,.<>/?).");
    }

    return errors;
  };
  const getDetails = useCallback(() => {
    if (id) {
      setDetailsData({ loading: true, data: {} });
      userDetails({ id }).then((res) => {
        if (res?.status === 200) {
          setDetailsData({ loading: true, data: res?.docs });
          setFieldsValue({
            name: res?.docs?.name || "",
            email: res?.docs?.email || "",
            phone: res?.docs?.phone || "",
          });
          setImageUrl(res?.docs?.image_name_url);
          // setDate({
          //   startDate: res?.docs?.dateOfBirth,
          //   endDate: res?.docs?.dateOfBirth,
          // });
        }
      });
    }
  }, [id]);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  const onSubmit = async (values) => {
    setLoaded(true);
    try {
      if (!Validation("name", values.name)) {
        toast.error("Name cannot be empty or contain only spaces.");
        setLoaded(false);
        return;
      }
      if (!Validation("email", values.email)) {
        toast.error("Invalid email format. Please enter a valid email.");
        setLoaded(false);
        return;
      }
      if (values.password) {
        const passwordErrors = validatePassword(values.password);
        if (passwordErrors.length > 0) {
          toast.error(passwordErrors.join(" "));
          setLoaded(false);
          return;
        }
      }
      if (values.password && !Validation("confirmPassword", values.confirmpassword, values.password)) {
        toast.error("Confirm password and password doesn`t match");
        setLoaded(false);
        return;
      }
      const res = await addUser({
        id: id,
        image_name: imageUrl,
        name: values?.name,
        email: values?.email,
        phone: values?.phone,
        role: "admin",
        password: values?.password || "",
        password_confirmation: values?.confirmpassword || "",
        // dateOfBirth: date?.startDate,
        status: 1,
      });

      if (res?.data?.status === 200 || res?.data?.status === 201) {
        navigate("/admin/admin-list");
        toast.info(res?.data?.message);
      } else {
        toast.info(res?.data?.message || res?.data?.errors || "Something went wrong");
      }

      setLoaded(false);
    } catch (err) {
      setLoaded(false);
      // console.error(err);
      toast.error("Somthing went wrong !!!");
    }
  };

  return (
    <div>
      <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
        <PageHeader
          title={detailsData?.data?.id ? "Edit Admin" : "Add New Admin"}
          headerActions={() => (
            <div className="flex items-center space-x-3">
              <Button
                buttonHasLink={true}
                buttonLink={"/admin/admin-list"}
                buttonType={"button"}
                buttonIcon={"fa-regular fa-xmark"}
                buttonIconPosition={"left"}
                buttonLabel={"Cancel"}
                buttonLabelClasses={"md:block hidden"}
                buttonClasses={"!bg-white !text-primary"}
              />
              <Button
                buttonHasLink={false}
                buttonType={"submit"}
                buttonIcon={"fa-light fa-floppy-disk"}
                buttonIconPosition={"left"}
                buttonClasses={"!bg-primary !text-org-silver"}
                buttonLabel={"Save"}
                buttonLabelClasses={"md:block hidden"}
                isDisable={loaded}
              />
            </div>
          )}
        />
        <div className="py-2 md:px-6 px-0">
          <div className="w-full bg-white rounded-md shadow  py-5 px-4">
            <div className="grid grid-cols-5 gap-4 divide-x divide-slate-200 md:px-10 px-0">
              <div className="xl:col-span-4 col-span-5">
                <div className="space-y-3">
                  <div className="grid grid-cols-12 gap-4">
                    <div className="relative md:col-span-6 col-span-12">
                      <Input
                        isInputGroup={true}
                        inputGroupIcon={"fa-regular fa-user"}
                        inputGroupPosition={"left"}
                        label={"Name"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        value={values.name}
                        inputName={"name"}
                        onChange={handleChange}
                        inputPlaceholder={"Enter Name"}
                        {...formValidate(errors, "name")}
                      />
                    </div>
                    <div className="relative md:col-span-6 col-span-12">
                      <Input
                        isInputGroup={true}
                        inputGroupIcon={"fa-solid fa-envelope"}
                        inputGroupPosition={"left"}
                        label={"Email"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        value={values.email}
                        inputName={"email"}
                        onChange={handleChange}
                        inputPlaceholder={"Enter Email"}
                        {...formValidate(errors, "email")}
                      />
                    </div>
                    <div className="relative md:col-span-6 col-span-12">
                      <Input
                        isInputGroup={true}
                        inputGroupIcon={"fa-solid fa-phone"}
                        inputGroupPosition={"left"}
                        label={"Phone Number"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        value={values.phone}
                        inputName={"phone"}
                        onChange={handleChange}
                        inputPlaceholder={"Enter Phone Number"}
                        {...formValidate(errors, "phone")}
                      />
                    </div>
                    {/* <div className="relative md:col-span-6 col-span-12">
                      <DatePickerInput
                        label="Date Of Birth"
                        labelClasses={"!text-slate-500 !text-base"}
                        asSingle={true}
                        useRange={false}
                        value={date}
                        onChange={(val) => setDate(val)}
                      />
                    </div> */}
                    <div className="relative md:col-span-6 col-span-12">
                      <Input
                        isInputGroup={true}
                        inputGroupIcon={"fa-solid fa-key"}
                        inputGroupPosition={"left"}
                        label={"Password"}
                        inputPlaceholder={"Enter  Password"}
                        labelClasses={"!text-base"}
                        inputType={"password"}
                        value={values.password}
                        inputName={"password"}
                        onChange={handleChange}
                        {...formValidate(errors, "password")}
                      />
                    </div>
                    <div className="relative md:col-span-6 col-span-12">
                      <Input
                        isInputGroup={true}
                        inputGroupIcon={"fa-solid fa-key"}
                        inputGroupPosition={"left"}
                        label={"Confirm Password"}
                        inputPlaceholder={"Enter Confirm Password"}
                        labelClasses={"!text-base"}
                        inputType={"password"}
                        value={values.confirmpassword}
                        inputName={"confirmpassword"}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="xl:col-span-1 col-span-5 pl-4 gap-3">
                <div className="relative">
                  <SingleImageUpload
                    label={"Admin Image"}
                    size={"full"}
                    image={imageUrl}
                    setImage={setImageUrl}
                    accept={["jpg", "png", "jpeg"]}
                  />
                </div>
              </div>
            </div>
            <div className="mt-8 pt-4 border-t border-slate-200">
              <div className="flex items-center space-x-3">
                <Button
                  buttonHasLink={true}
                  buttonLink={"/admin/admin-list"}
                  buttonType={"button"}
                  buttonIcon={"fa-regular fa-xmark"}
                  buttonIconPosition={"left"}
                  buttonLabel={"Cancel"}
                  buttonClasses={"!bg-white !text-primary !border-primary !border-2"}
                />
                <Button
                  buttonHasLink={false}
                  buttonType={"submit"}
                  buttonIcon={"fa-light fa-floppy-disk"}
                  buttonIconPosition={"left"}
                  buttonClasses={"!bg-primary !text-org-silver"}
                  buttonLabel={"Save"}
                  isDisable={loaded}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AdminAddEdit;
