import React from "react";
import Skeleton from "./Skeleton";

const ProductLoader = () => {
  return (
    <div className="rounded border w-full h-full flex flex-col overflow-hidden hover:shadow-[0_5px_10px_0px_rgb(0,0,0,0.3)] hover:scale-110 transition duration-300">
      <div className="relative 2xl:pl-12 xl:pl-8 xl:pr-16 md:pl-4 md:pr-4 pl-3 pr-3 md:py-6 py-3">
        <Skeleton type="rectangle" className="w-full h-60" />
        <div className="absolute top-1/2 -translate-y-2/4 right-0 space-y-2 xl:bg-transparent bg-white xl:shadow-none shadow-[0_5px_10px_0px_rgb(0,0,0,0.3)] rounded-lg">
          <div className="xl:pr-3 md:p-2 p-1 mt-0 !pb-0">
            <Skeleton type="circle" />
          </div>
          <div className="xl:pr-3 md:p-2 p-1 mt-0 !pb-0">
            <Skeleton type="circle" />
          </div>
          <div className="xl:pr-3 md:p-2 p-1 mt-0 !pb-0">
            <Skeleton type="circle" />
          </div>
          <div className="xl:pr-3 md:p-2 p-1 mt-0 !pb-0">
            <Skeleton type="circle" />
          </div>
        </div>
      </div>
      <div className="xl:px-2 xlpx-3 px-1 pb-4 text-center mt-auto">
        <Skeleton type="text" className="w-3/4 mx-auto" />
      </div>
    </div>
  );
};

export default ProductLoader;
