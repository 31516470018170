import React, { useCallback, useEffect, useState } from "react";
import Button from "../../../components/form/Button";
import Input from "../../../components/form/Input";
import { formValidate } from "../../../helpers/formValidate";
import useForm from "../../../hooks/useForm";
import { Validation } from "../../../helpers/Validation";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { addBillingAddress, getStateList, userDetails } from "../../../services/userService";
import SelectDropdownSearch from "../../../components/form/SelectDropdownSearch";

export const AddEditUserBillingAddressModal = ({
  id,
  onClose = () => {},
  // loaded,
  // setLoaded = () => {},
  setBillingDetails = () => {},
  getBillingDetails = () => {},
}) => {
  const validation = {
    address: { required: true, message: "Please enter your address !!!" },
    address2: {
      required: true,
      message: "Please enter your address line two !!!",
    },
    city: { required: true, message: "Please enter city !!!" },
    pin: { required: true, message: "Please enter pin number !!!" },
    state: { required: true, message: "Please enter state !!!" },
  };
  const navigate = useNavigate();
  const { values, handleChange, handleSubmit, errors, setFieldsValue } = useForm({}, validation);
  const [stateKeyword, setStateKeyword] = useState("");
  const [stateList, setStateList] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const [detailsData, setDetailsData] = useState({ loading: false, data: {} });

  const onSubmit = async (values) => {
    setLoaded(true);
    try {
      const res = await addBillingAddress({
        id: detailsData?.data?.userBillingAdderssInfo?.id,
        user_id: id,
        address: values?.address,
        address2: values?.address2,
        city: values?.city || "",
        pin: values?.pin || "",
        state: values?.state?._id,
      });
      if (res?.data?.status === 200 || res?.data?.status === 201) {
        // setBillingDetails(res?.data);
        onClose(true);
        getBillingDetails();
        toast.info(res?.data?.message);
      } else {
        toast.info(res?.data?.message || res?.data?.errors || "Something went wrong");
      }

      setLoaded(false);
    } catch (err) {
      setLoaded(false);
      // console.error(err);
      toast.error("Somthing went wrong !!!");
    }
  };

  const getDetails = useCallback(() => {
    if (id) {
      setDetailsData({ loading: true, data: {} });
      userDetails({ id }).then((res) => {
        if (res?.status === 200) {
          setDetailsData({ loading: true, data: res?.docs });
          setFieldsValue({
            address: res?.docs?.userBillingAdderssInfo?.address || "",
            address2: res?.docs?.userBillingAdderssInfo?.address2 || "",
            city: res?.docs?.userBillingAdderssInfo?.city || "",
            state:
              {
                name: res?.docs?.userBillingAdderssInfo?.stateInfo
                  ? res?.docs?.userBillingAdderssInfo?.stateInfo
                  : null,
                _id: res?.docs?.userBillingAdderssInfo?.stateInfo?.id
                  ? res?.docs?.userBillingAdderssInfo?.stateInfo?.id
                  : null,
              } || "",
            pin: res?.docs?.userBillingAdderssInfo?.pin || "",
          });
        }
      });
    }
  }, [id]);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  // loading the State list >>>>>>>>>
  const loadStateList = useCallback(() => {
    getStateList({
      keyword: stateKeyword,
      perPage: 9999999999,
    }).then((res) => {
      if (res && res?.success) {
        setStateList(res?.data);
      }
    });
  }, [stateKeyword]);

  useEffect(() => {
    loadStateList();
  }, [loadStateList]);

  return (
    <div>
      <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
        <div className="py-2 px-0">
          <div className="grid grid-cols-5 gap-4 divide-x divide-slate-200 md:px-5 px-8 ">
            <div className="xl:col-span-12 col-span-5">
              <div className="space-y-3">
                <div className="grid grid-cols-12 gap-4">
                  <div className="relative md:col-span-12 col-span-12">
                    <Input
                      isInputGroup={true}
                      label={"Address line 1"}
                      labelClasses={"!text-base !text-slate-600"}
                      inputClasses={" !border-slate-400"}
                      inputType={"text"}
                      value={values.address}
                      inputName={"address"}
                      onChange={handleChange}
                      inputPlaceholder={"Enter Address"}
                      {...formValidate(errors, "address")}
                    />
                  </div>
                  <div className="relative md:col-span-6 col-span-12">
                    <Input
                      isInputGroup={true}
                      label={"Address line 2"}
                      labelClasses={"!text-base !text-slate-600"}
                      inputClasses={" !border-slate-400"}
                      inputType={"text"}
                      value={values.address2}
                      inputName={"address2"}
                      onChange={handleChange}
                      inputPlaceholder={"Enter address line 2"}
                      {...formValidate(errors, "address2")}
                    />
                  </div>
                  <div className="relative md:col-span-6 col-span-12">
                    <div className="relative col-span-6">
                      <SelectDropdownSearch
                        label={"Province "}
                        labelClasses={"!text-base !text-slate-600"}
                        placeholder={"Select a province "}
                        dropdownClass={""}
                        xPlacement={"bottom"}
                        buttonArrowIcon={""}
                        selectName={"state"}
                        isSearch={true}
                        dropdownButtonClass="!px-3 !border-slate-400 !w-full"
                        onChange={handleChange}
                        onSearch={(key) => setStateKeyword(key)}
                        value={values?.state}
                        dropdownData={stateList?.map((item) => ({
                          name: item?.name,
                          _id: item?.id,
                        }))}
                        //   {...formValidate(errors, "state")}
                      />
                    </div>
                  </div>
                  <div className="relative md:col-span-6 col-span-12">
                    <Input
                      isInputGroup={true}
                      label={"City"}
                      inputPlaceholder={"Enter  City"}
                      inputClasses={"!border-slate-400"}
                      labelClasses={"!text-base !text-slate-600"}
                      inputType={"city"}
                      value={values.city}
                      inputName={"city"}
                      onChange={handleChange}
                      {...formValidate(errors, "city")}
                    />
                  </div>
                  <div className="relative md:col-span-6 col-span-12">
                    <Input
                      isInputGroup={true}
                      label={"Postal Code"}
                      inputClasses={"!border-slate-400"}
                      inputPlaceholder={"Enter Postal Code"}
                      labelClasses={"!text-base !text-slate-600"}
                      inputType={"text"}
                      value={values.pin}
                      inputName={"pin"}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-8 pt-4 border-t border-slate-200">
            <div className="flex justify-end space-x-3">
              <Button
                buttonHasLink={false}
                buttonLink={""}
                buttonFunction={onClose}
                buttonType={"button"}
                buttonIcon={"fa-regular fa-xmark"}
                buttonIconPosition={"left"}
                buttonLabel={"Cancel"}
                buttonClasses={"!bg-white !text-primary !border-primary !border-2"}
              />
              <Button
                buttonHasLink={false}
                buttonType={"submit"}
                buttonIcon={"fa-light fa-floppy-disk"}
                buttonIconPosition={"left"}
                buttonClasses={"!bg-primary !text-org-silver"}
                buttonLabel={"Save"}
                isDisable={loaded}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
