import React, { useCallback, useEffect, useState } from "react";
import LabelProduct from "../components/sections/LabelProduct";
import SmallBanner from "../components/sections/SmallBanner";
import { useDispatch } from "react-redux";
import { addToCartProduct } from "../services/frontService/ProductService";
import { setCartDetails } from "../redux/slice/cartSlice";
import { toast } from "react-toastify";

const Cards = () => {
  const slug = "cards";
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);

  const addToCart = async (data) => {
    setLoaded(true);
    try {
      const res = await addToCartProduct({
        product_id: data?.id,
        quantity: 1,
      });
      if (res?.data?.status === 200 || res?.data?.status === 201) {
        toast.info(res?.data?.message);
        dispatch(setCartDetails(res?.data?.data));
      } else {
        toast.info(
          res?.data?.message || res?.data?.errors || "Something went wrong"
        );
      }
      setLoaded(false);
    } catch (err) {
      setLoaded(false);
      // console.error(err);
      toast.error("Somthing went wrong !!!");
    }
  };

  return (
    <>
      <SmallBanner />
      <LabelProduct slug={slug} addToCart={addToCart} />
    </>
  );
};

export default Cards;
