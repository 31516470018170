import React from "react";
import Button from "../form/Button";

const Productactions = ({ data, actions }) => {
  return (
    <>
      {actions?.length > 0 && (
        <div className="xl:pr-3 md:p-2 p-1 !pt-0 !mt-0">
          {actions?.map((item, index) => (
            <div key={index}>
              <Button
                buttonClasses={
                  "!p-2 !w-10 !h-10 !justify-center !bg-transparent md:!px-2 !px-1 font-medium !text-black hover:!text-slate-100 hover:!bg-slate-900 !rounded-full md:h-10 h-8 flex items-center justify-center transition-all duration-200 !text-xl"
                }
                buttonLabelClasses={""}
                buttonIcon={item.icon}
                buttonIconPosition={"left"}
                buttonHasLink={item.isLink}
                buttonLink={item.link}
                buttonFunction={() =>
                  typeof item.click === "function" && item.click(data)
                }
              />
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default Productactions;
