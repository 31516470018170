import { apiRequest } from "../util/apiUtils";

export const getReviewList = async (params) => {
  try {
    const res = await apiRequest("get", "admin/review/list", {
      queries: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};
export const changeReviewStatus = async (payload = {}) => {
  try {
    const res = await apiRequest("post", "admin/review/edit", {
      body: payload,
    });
    return res;
  } catch (err) {
    return err;
    throw err;
  }
};
export const deleteReview = async (payload = {}) => {
  try {
    const res = await apiRequest("post", "admin/review/delete", {
      body: payload,
    });
    return res;
  } catch (err) {
    return err;
    throw err;
  }
};
