import React, { useCallback, useEffect, useState } from "react";
import PageHeader from "../../../shared/PageHeader";
import Button from "../../../components/form/Button";
import Input from "../../../components/form/Input";
import SingleImageUpload from "../../../components/form/SingleImageUpload";
import { formValidate } from "../../../helpers/formValidate";
import useForm from "../../../hooks/useForm";
import { Validation } from "../../../helpers/Validation";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
// import { addUser, userDetails } from "../../../services/userService";
import { setTitle } from "../../../helpers/MetaTag";
import DatePickerInput from "../../../components/form/DatePickerInput";
import { addUser, userDetails } from "../../../services/userService";
import TextareaAutoSize from "../../../components/form/TextareaAutoSize";
import { addTestimonial, testimonialDetails } from "../../../services/testimonialService";
import Select from "../../../components/form/Select";
import { addFaq, faqDetails } from "../../../services/faqService";
import SelectDropdownSearch from "../../../components/form/SelectDropdownSearch";

const FaqAddEdit = () => {
  setTitle("HJL DESIGNS");
  const { id } = useParams();
  const validation = {
    question: { required: true, message: "Please enter your question !!!" },
    answer: { required: true, message: "Please enter your answer !!!" },
  };
  const { values, handleChange, handleSubmit, errors, setFieldsValue } = useForm({}, validation);
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [companyImage, setCompanyImage] = useState();
  const [detailsData, setDetailsData] = useState({ loading: false, data: {} });
  const [date, setDate] = useState({ startDate: null, endDate: null });
  const [statusKeyword, setStatusKeyword] = useState("");

  const getDetails = useCallback(() => {
    if (id) {
      setDetailsData({ loading: true, data: {} });
      faqDetails({ id }).then((res) => {
        if (res?.status === 200) {
          setDetailsData({ loading: true, data: res?.docs });
          setFieldsValue({
            question: res?.docs?.question || "",
            answer: res?.docs?.answer || "",
            status:
              {
                name: res?.docs?.status ? res?.docs?.status : null,
                _id: res?.docs?.status ? res?.docs?.status : null,
              } || "",
          });
        }
      });
    }
  }, [id]);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  const onSubmit = async (values) => {
    setLoaded(true);
    try {
      const res = await addFaq({
        id: id,
        question: values?.question,
        answer: values?.answer,
        status: values?.status?._id,
      });

      if (res?.data?.status === 200 || res?.data?.status === 201) {
        navigate("/admin/faq-list");
        toast.info(res?.data?.message);
      } else {
        toast.info(res?.data?.message || res?.data?.errors || "Something went wrong");
      }

      setLoaded(false);
    } catch (err) {
      setLoaded(false);
      // console.error(err);
      toast.error("Somthing went wrong !!!");
    }
  };

  const data = [
    { name: "Active", _id: 1 },
    { name: "In-Active", _id: 2 },
  ];

  return (
    <div>
      <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
        <PageHeader
          title={detailsData?.data?.id ? "Edit Faq" : "Add New Faq"}
          headerActions={() => (
            <div className="flex items-center space-x-3">
              <Button
                buttonHasLink={true}
                buttonLink={"/admin/faq-list"}
                buttonType={"button"}
                buttonIcon={"fa-regular fa-xmark"}
                buttonIconPosition={"left"}
                buttonLabel={"Cancel"}
                buttonLabelClasses={"md:block hidden"}
                buttonClasses={"!bg-white !text-primary"}
              />
              <Button
                buttonHasLink={false}
                buttonType={"submit"}
                buttonIcon={"fa-light fa-floppy-disk"}
                buttonIconPosition={"left"}
                buttonClasses={"!bg-primary !text-org-silver"}
                buttonLabel={"Save"}
                buttonLabelClasses={"md:block hidden"}
                isDisable={loaded}
              />
            </div>
          )}
        />
        <div className="py-2 md:px-6 px-0">
          <div className="w-full bg-white rounded-md shadow  py-5 px-4">
            <div className="grid grid-cols-5 gap-4 divide-x divide-slate-200 md:px-10 px-0">
              <div className="xl:col-span-6 col-span-5">
                <div className="space-y-3">
                  <div className="grid grid-cols-12 gap-4">
                    <div className="relative md:col-span-6 col-span-12">
                      <Input
                        isInputGroup={true}
                        inputGroupIcon={"fas fa-question"}
                        inputGroupPosition={"left"}
                        label={"Question"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        value={values.question}
                        inputName={"question"}
                        onChange={handleChange}
                        inputPlaceholder={"Enter Question"}
                        {...formValidate(errors, "question")}
                      />
                    </div>
                    <div className="relative md:col-span-6 col-span-12">
                      {/* <Select
                        placeholder={"Select Status"}
                        dropdownButtonClass={"!h-10 !rounded-md "}
                        label={"Status"}
                        labelClasses={"!text-base"}
                        selectedValue={status}
                        onChange={handleStatusChange}
                        dropdownData={data}
                      /> */}
                      <SelectDropdownSearch
                        label={"Status  "}
                        placeholder={"Select Status"}
                        xPlacement={"bottom"}
                        buttonArrowIcon={""}
                        selectName={"status"}
                        isSearch={false}
                        dropdownButtonClass="!px-3  !border-gray-200 !w-full"
                        onChange={handleChange}
                        onSearch={(key) => setStatusKeyword(key)}
                        value={values?.status}
                        dropdownData={data}
                      />
                    </div>
                    <div className="relative md:col-span-12 col-span-12">
                      <TextareaAutoSize
                        isInputGroup={true}
                        inputGroupIcon={"fa-solid fa-user-tie"}
                        inputGroupPosition={"left"}
                        label={"Answer"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        inputValue={values.answer}
                        inputName={"answer"}
                        onChange={handleChange}
                        inputPlaceholder={"Enter Answer"}
                        {...formValidate(errors, "answer")}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-8 pt-4 border-t border-slate-200">
              <div className="flex items-center space-x-3">
                <Button
                  buttonHasLink={true}
                  buttonLink={"/admin/faq-list"}
                  buttonType={"button"}
                  buttonIcon={"fa-regular fa-xmark"}
                  buttonIconPosition={"left"}
                  buttonLabel={"Cancel"}
                  buttonClasses={"!bg-white !text-primary !border-primary !border-2"}
                />
                <Button
                  buttonHasLink={false}
                  buttonType={"submit"}
                  buttonIcon={"fa-light fa-floppy-disk"}
                  buttonIconPosition={"left"}
                  buttonClasses={"!bg-primary !text-org-silver"}
                  buttonLabel={"Save"}
                  isDisable={loaded}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default FaqAddEdit;
