import React, { useCallback, useEffect, useMemo, useState } from "react";
import cruinternational from "../../assets/images/cru-international/cru-international-3.webp";
import cruinternational_2 from "../../assets/images/cru-international/cru-international-4.webp";
import cruinternational_3 from "../../assets/images/cru-international/cru-international-5.webp";
import cruinternational_4 from "../../assets/images/cru-international/cru-international-6.webp";
import cruinternational_5 from "../../assets/images/cru-international/cru-international-7.webp";
import cruinternational_6 from "../../assets/images/cru-international/cru-international-8.webp";
import cruinternational_7 from "../../assets/images/cru-international/cru-international-9.webp";
import cruinternational_8 from "../../assets/images/cru-international/cru-international-10.webp";
import cruinternational_9 from "../../assets/images/cru-international/cru-international-11.webp";
import cruinternational_10 from "../../assets/images/cru-international/cru-international-12.webp";
import cruinternational_11 from "../../assets/images/cru-international/cru-international-13.webp";
import cruinternational_12 from "../../assets/images/cru-international/cru-international-14.webp";
import cruinternational_13 from "../../assets/images/cru-international/cru-international-15.webp";
import cruinternational_14 from "../../assets/images/cru-international/cru-international-16.webp";
import cruinternational_15 from "../../assets/images/cru-international/cru-international-17.webp";
import cruinternational_16 from "../../assets/images/cru-international/cru-international-18.webp";
import cruinternational_17 from "../../assets/images/cru-international/cru-international-19.webp";
import cruinternational_18 from "../../assets/images/cru-international/cru-international-20.webp";
import cruinternational_19 from "../../assets/images/cru-international/cru-international-21.webp";
import cruinternational_20 from "../../assets/images/cru-international/cru-international-22.webp";
import cruinternational_21 from "../../assets/images/cru-international/cru-international-23.webp";
import cruinternational_22 from "../../assets/images/cru-international/cru-international-24.webp";
import cruinternational_23 from "../../assets/images/cru-international/cru-international-25.webp";
import cruinternational_24 from "../../assets/images/cru-international/cru-international-26.webp";
import cruinternational_25 from "../../assets/images/cru-international/cru-international-27.webp";
import cruinternational_26 from "../../assets/images/cru-international/cru-international-28.webp";
import cruinternational_27 from "../../assets/images/cru-international/cru-international-29.webp";
import cruinternational_28 from "../../assets/images/cru-international/cru-international-21.webp";
import cruinternational_29 from "../../assets/images/cru-international/cru-international-25.webp";
import SingleProduct from "../Items/SingleProduct";
import { pageWiseProductListData } from "../../services/frontService/ProductService";
import { toast } from "react-toastify";
import Pagination from "../form/Pagination";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { loginModalOpen } from "../../redux/slice/loginSlice";
import Modal from "../elements/Modal/Modal";
import QuickViewModal from "./QuickViewModal";
import { getPageData } from "../../services/frontService/PageService";
import ProductLoader from "../loader/ProductLoader";

const CruInternationalProducts = ({ slug, addToCart = () => {} }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState({ data: {}, open: false });
  const isLogin = useSelector((state) => state?.auth?.isLogin);
  const [limit, setLimit] = useState({ name: "60 Items", value: 60 });
  const [status, setStatus] = useState("all");
  const [offset, setOffset] = useState(0);

  const [pagination, setPagination] = useState({
    hasNextPage: false,
    hasPrevPage: false,
  });
  const [list, setList] = useState({
    loading: true,
    data: [],
    pageCount: 0,
    totalItem: 0,
  });
  const [count, setCount] = useState(0);
  const [items] = useState([...Array(100).keys()]); // Example items array
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 60;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = items.slice(indexOfFirstItem, indexOfLastItem);

  const [homeData, setHomeData] = useState({});

  const loadPageData = useCallback(() => {
    getPageData({
      slug: "cru-international",
    }).then((res) => {
      if (res?.status === 200) {
        setHomeData(res?.data);
      }
    });
  }, []);

  useEffect(() => {
    loadPageData();
  }, [loadPageData]);

  const actions = useMemo(() => {
    return [
      // {
      //   _id: 1,
      //   icon: "fa-light fa-heart",
      //   isLink: false,
      //   isWishlist: true,
      //   isSolid: isSolid,
      //   click: (data) => {
      //     if (isLogin) {
      //       addWishList(data);
      //     } else {
      //       dispatch(loginModalOpen({ isOpen: true }));
      //     }
      //   },
      // },
      {
        _id: 2,
        icon: "fa-light fa-bag-shopping",
        isLink: false,
        click: (data) => {
          if (isLogin) {
            addToCart(data);
          } else {
            dispatch(loginModalOpen({ isOpen: true }));
          }
        },
      },
      {
        _id: 3,
        icon: "fa-light fa-arrow-right",
        isLink: false,
        click: (data) => {
          navigate(`/productdetails/${data?.alias}`);
        },
      },
      {
        _id: 4,
        icon: "fa-light fa-eye",
        isLink: false,
        click: (data) => setIsOpen({ open: true, data: data }),
      },
    ];
  }, [isLogin]);

  //   loading the member list >>>>>>>>>
  const loadList = useCallback(() => {
    setList((pre) => ({
      ...pre,
      data: [],
      loading: true,
    }));
    pageWiseProductListData({
      perPage: limit?.value,
      page: currentPage,
      cat_slug: slug,
    }).then((res) => {
      if (res && res?.docs?.success) {
        setList({
          loading: false,
          data: res?.docs?.data,
          pageCount: res?.docs?.metadata?.totalPages,
          totalItem: res?.docs?.metadata?.totalDocs,
        });
        setCount(res?.docs?.metadata?.totalDocs);
        setPagination({
          hasNextPage: res?.docs?.metadata?.hasNextPage,
          hasPrevPage: res?.docs?.metadata?.hasPrevPage,
        });
      } else {
        setList((pre) => ({ ...pre, data: [], loading: true }));
        toast.error(res?.message);
      }
    });
  }, [limit, status, currentPage, isLogin]);

  useEffect(() => {
    loadList();
  }, [loadList]);

  const featuredProductData = {
    subheading: "Great Designs At The Best Price",
    heading: homeData?.name || "CRU INTERNATIONAL",
    description:
      homeData?.description ||
      "HJL Designs is a manufacturer and wholesaler of high quality vinyl labels for the craft wine making industry. With a mix of modern and traditional designs, our ever-expanding lineup of 9.5 cm x 7 cm labels fit any bottle size, are easy to remove, and  do not leave any residue behind.",
    subdata: list && list?.data?.length > 0 ? list?.data : [],
  };
  const loaders = Array(20).fill(null);

  return (
    <>
      <section className="sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto md:px-0 px-5">
        <div className="text-center md:pt-20 pt-8 xl:px-32 px-0">
          <h2 className="great-design xl:text-6xl md:text-5xl text-2xl font-bold pt-2 pb-4 drop-shadow-md uppercase">
            <span className="relative inline-block !leading-none before:content-[''] before:absolute before:top-2/4 before:-translate-y-1/2 md:before:-left-28 before:-left-12 md:before:w-24 before:w-10 before:border-t-2 before:border-black after:content-[''] after:absolute after:top-2/4 after:-translate-y-1/2 md:after:-right-28 after:-right-12 md:after:w-24 after:w-10 after:border-t-2 after:border-black">
              {featuredProductData?.heading}
            </span>
          </h2>
        </div>

        {list?.loading ? (
          <>
            <div className="grid xl:grid-cols-4 md:grid-cols-3 grid-cols-2 xl:gap-8 md:gap-8 gap-5 md:pt-36 pt-28">
              {loaders?.map((_, index) => (
                <ProductLoader key={index} />
              ))}
            </div>
          </>
        ) : (
          featuredProductData?.subdata?.length > 0 && (
            <>
              <div className="grid md:grid-cols-4 grid-cols-2 xl:gap-8 gap-8 xl:pt-20 pt-8">
                {featuredProductData.subdata.map((item, index) => (
                  <SingleProduct
                    key={index}
                    data={item}
                    lable={cruinternational_21}
                    actions={actions}
                  />
                ))}
              </div>
              <Pagination
                totalItems={count}
                itemsPerPage={limit?.value}
                onPageChange={handlePageChange}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </>
          )
        )}
        <Modal
          size="xl4"
          modalTitle=""
          open={isOpen?.open}
          onClose={() => setIsOpen({ open: false })}
        >
          <QuickViewModal
            onClose={() => setIsOpen({ open: false })}
            setIsOpen={setIsOpen}
            isOpen={isOpen}
          />
        </Modal>
      </section>
    </>
  );
};

export default CruInternationalProducts;
