import React from "react";
import Button from "../../../components/form/Button";
import { toast } from "react-toastify";
import { changeFaqStatus } from "../../../services/faqService";

export const FaqDetailsModal = ({
  onClose = () => {},
  details,
  setDetails = () => {},
  loadList = () => {},
}) => {
  const handleStatusChange = async (data) => {
    changeFaqStatus({
      id: data.id,
      status: data.status === 1 ? 2 : 1,
    }).then((res) => {
      if (res?.status === 200) {
        setDetails((pre) => ({
          ...pre,
          status: pre.status === 1 ? 2 : 1,
        }));
        loadList();
        toast.success("Status Change Successful");
      } else {
        toast.error("Somthing Went Wrong");
      }
    });
  };
  return (
    <div>
      <div className="flex justify-end">
        <Button
          buttonClasses="!bg-white !h-8 !text-3xl !text-black hover:!text-black !text-xl hover:!bg-gray-200 hover:!border-gray-200 !text-center border border-gray-200 gap-3 transition duration-200 rounded-md"
          buttonIcon="fa-regular fa-xmark"
          buttonIconPosition="right"
          buttonEffect="filled"
          buttonFunction={onClose}
        />
      </div>

      <div className="max-w-4xl mx-auto px-4 py-8">
        <div className="md:flex block items-center justify-between md:mb-6 mb-3">
          <h1 className="text-3xl font-bold text-red-600">
            Frequently Asked Questions
          </h1>
          {details?.status === 1 ? (
            <div
              className="w-28 rounded-md mt-3 text-base font-bold bg-blue-100 text-blue-600 flex justify-center items-center cursor-pointer"
              onClick={() => handleStatusChange(details)}
            >
              Active
            </div>
          ) : (
            <div
              className="w-28 rounded-md mt-3 text-base font-bold bg-red-100 text-red-600 flex justify-center items-center cursor-pointer"
              onClick={() => handleStatusChange(details)}
            >
              In-Active
            </div>
          )}
        </div>
        <div className="space-y-4">
          <div className="bg-white shadow-md rounded-lg p-4">
            <h2 className="text-xl font-semibold mb-4 capitalize">
              Q: {details?.question}
            </h2>
            <div className="h-32 overflow-auto p-2 bg-white rounded-md border border-gray-100">
              <p className="text-gray-700 capitalize">{details?.answer}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
