import React, { useState } from "react";
import Image from "../elements/Image";
import img from "../../assets/images/cards/card-inside-4.webp";
import nodatafound from "../../assets/images/nodatafound.png";
import Button from "../form/Button";

const ProductGalleryItem = ({ data }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  return (
    <>
      {data?.productImages?.length > 0 ? (
        <div>
          <div className="xl:w-full w-full bg-white rounded-md md:px-10 px-5 py-5 shadow-md">
            <div className="relative flex space-x-4 py-4 overflow-x-auto">
              <div className="flex space-x-4 w-full">
                {data?.productImages?.map((item, index) => (
                  <div key={index} className="space-y-1 flex-shrink-0">
                    <div className="w-36 h-36 overflow-hidden cursor-pointer relative group">
                      <Image src={item?.imageUrl} alt="" className="w-full h-full object-cover" effect={"blur"} />
                      <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 gap-4">
                        <Button
                          buttonHasLink={false}
                          buttonIcon={"fa fa-info-circle"}
                          buttonIconPosition={"left"}
                          buttonClasses={"!bg-primary !text-org-silver"}
                          buttonFunction={() => openModal(item?.imageUrl)}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {isModalOpen && (
            <div
              className="fixed inset-0 bg-black bg-opacity-40 flex justify-center items-center z-40"
              onClick={closeModal}>
              <div className="relative">
                {/* <button
                  onClick={closeModal}
                  className="absolute top-2 right-2 text-white text-2xl bg-black rounded-full p-1">
                  &times;
                </button> */}
                <Image src={selectedImage} alt="Selected" className="w-full h-full object-cover " effect={"blur"} />
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="flex flex-col items-center space-y-8 mt-16">
          <div className="w-40 h-40 rounded-full bg-[#F0F9F4] overflow-hidden flex items-center justify-center">
            <div className="text-[#e73737] text-8xl">
              <i className="fa-regular fa fa-exclamation"></i>
            </div>
          </div>
          <div className="text-cape-cod text-3xl">No Gallery Available.</div>
          <div className="text-concord text-base max-w-md text-center">
            Oops! No data available at the moment. Please try again later.
          </div>
        </div>
      )}
    </>
  );
};

export default ProductGalleryItem;
