import React, { useCallback, useEffect, useState } from "react";
import MyOrderList from "./MyOrderList";
import { getUserOrders } from "../../../services/frontService/ProfileService";
import Button from "../../form/Button";
import PageLoader from "../../common/PageLoader";
import ImageTextSkeletonLoader from "../../elements/loader/ImageTextSkeletonLoader";
import MyOrderLoader from "../../loader/MyOrderLoder";

const MyOrders = () => {
  const [myorderList, setMyOrderList] = useState([]);
  const [loading, setLoading] = useState(true);

  const orderList = useCallback(() => {
    setLoading(true);
    getUserOrders().then((res) => {
      if (res?.status === 200) {
        setMyOrderList(res?.docs?.data);
        setLoading(false);
      } else {
        setMyOrderList(res?.docs?.data);
        setLoading(false);
      }
    });
  }, []);

  useEffect(() => {
    orderList();
  }, [orderList]);



  return (
    <>
      <div className="mt-10 w-full h-full bg-slate-100 rounded-md md:px-10 px-5 py-10 pt-5 shadow-md">
        {loading ? (
          <>
            <MyOrderLoader />
            <MyOrderLoader />
          </>
        ) : (
          <div>
            {Array?.isArray(myorderList) && myorderList?.length > 0 ? (
              <>
                <div className="text-black text-2xl font-bold pb-2">
                  My Orders
                </div>
                <div>
                  <MyOrderList
                    myorderList={
                      Array?.isArray(myorderList) &&
                      myorderList?.length > 0 &&
                      myorderList
                    }
                  />
                </div>
              </>
            ) : (
              <div className="flex flex-col items-center space-y-8 mt-16">
                <div className="w-40 h-40 rounded-full bg-[#F0F9F4] overflow-hidden flex items-center justify-center ">
                  <div className="text-[#e73737] text-8xl">
                    <i className="fa-regular fa-bag-shopping"></i>
                  </div>
                </div>
                <div className="text-cape-cod text-3xl">
                  You have no orders yet.
                </div>
                <div className="text-concord text-base max-w-md text-center">
                  You don’t have any products in the orderlist yet. Explore our
                  wide range of products and start shopping
                </div>
                <Button
                  buttonClasses={
                    "md:!px-4 !px-3 !py-1 md:!h-10 !h-7 !h-8 !text-white hover:!text-white !text-xl hover:!bg-black hover:!border-black !text-center bg-red-600 gap-3  transition duration-200 rounded-none leading-none !rounded-md"
                  }
                  buttonLabel="Continue Shopping"
                  buttonLabelClasses="md:!text-lg !text-sm"
                  buttonEffect={"filled"}
                  buttonHasLink={true}
                  buttonLink={"/"}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default MyOrders;
