import React, { useCallback, useEffect, useState } from "react";
import Button from "../../../components/form/Button";
import Input from "../../../components/form/Input";
import SingleImageUpload from "../../../components/form/SingleImageUpload";
import { formValidate } from "../../../helpers/formValidate";
import useForm from "../../../hooks/useForm";
import { useNavigate, useParams } from "react-router-dom";
import DatePickerInput from "../../../components/form/DatePickerInput";
import { userDetails } from "../../../services/userService";
import SelectDropdownSearch from "../../../components/form/SelectDropdownSearch";

const GenarelField = ({
  loaded,
  setLoaded = () => {},
  imageUrl,
  setImageUrl = () => {},
  date,
  setDate = () => {},
  onSubmit = () => {},
  setType = () => {},
}) => {
  const { id } = useParams();
  const validation = {
    name: { required: true, message: "Please enter your name !!!" },
    email: { required: true, message: "Please enter your email !!!" },
    phoneNumber: { required: true, message: "Please enter phone number !!!" },
    password: {
      required: id ? false : true,
      message: "Please enter your password !!!",
    },
  };
  const { values, handleChange, handleSubmit, errors, setFieldsValue } =
    useForm({}, validation);
  const navigate = useNavigate();

  const [detailsData, setDetailsData] = useState({ loading: false, data: {} });
  const [statusKeyword, setStatusKeyword] = useState("");

  const getDetails = useCallback(() => {
    if (id) {
      setDetailsData({ loading: true, data: {} });
      userDetails({ id }).then((res) => {
        if (res?.status === 200) {
          setDetailsData({ loading: true, data: res?.docs });
          setFieldsValue({
            name: res?.docs?.name || "",
            email: res?.docs?.email || "",
            phoneNumber: res?.docs?.phone || "",
            // status:
            //   {
            //     name: res?.docs?.active ? res?.docs?.active : null,
            //     _id: res?.docs?.active ? res?.docs?.active : null,
            //   } || "",
          });
          setImageUrl(res?.docs?.image_name_url);
          // setDate({
          //   startDate: res?.docs?.bday,
          //   endDate: res?.docs?.bday,
          // });
        }
      });
    }
  }, [id]);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  const data = [
    { name: "Active", _id: 1 },
    { name: "In-Active", _id: 2 },
  ];

  return (
    <div>
      <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
        <div className="py-2 md:px-0 px-0">
          <div className="w-full bg-white rounded-md shadow  py-5 px-4">
            <div className="grid grid-cols-5 gap-4 divide-x divide-slate-200 md:px-10 px-0">
              <div className="xl:col-span-4 col-span-5">
                <div className="space-y-3">
                  <div className="grid grid-cols-12 gap-4">
                    <div className="relative md:col-span-6 col-span-12">
                      <Input
                        isInputGroup={true}
                        inputGroupIcon={"fa-regular fa-user"}
                        inputGroupPosition={"left"}
                        label={"Name"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        value={values.name}
                        inputName={"name"}
                        onChange={handleChange}
                        inputPlaceholder={"Enter Name"}
                        {...formValidate(errors, "name")}
                      />
                    </div>
                    <div className="relative md:col-span-6 col-span-12">
                      <Input
                        isInputGroup={true}
                        inputGroupIcon={"fa-solid fa-envelope"}
                        inputGroupPosition={"left"}
                        label={"Email"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        value={values.email}
                        inputName={"email"}
                        onChange={handleChange}
                        inputPlaceholder={"Enter Email"}
                        {...formValidate(errors, "email")}
                      />
                    </div>
                    <div className="relative md:col-span-6 col-span-12">
                      <Input
                        isInputGroup={true}
                        inputGroupIcon={"fa-solid fa-phone"}
                        inputGroupPosition={"left"}
                        label={"Phone Number"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        value={values.phoneNumber}
                        inputName={"phoneNumber"}
                        onChange={handleChange}
                        inputPlaceholder={"Enter Phone Number"}
                        {...formValidate(errors, "phoneNumber")}
                      />
                    </div>
                    {/* <div className="relative md:col-span-6 col-span-12">
                      <DatePickerInput
                        label="Date Of Birth"
                        labelClasses={"!text-slate-500 !text-base"}
                        asSingle={true}
                        useRange={false}
                        value={date}
                        onChange={(val) => setDate(val)}
                      />
                    </div> */}
                    {/* <div className="relative md:col-span-6 col-span-12">
                      <SelectDropdownSearch
                        label={"Status  "}
                        labelClasses={"!text-base"}
                        placeholder={"Select Status"}
                        xPlacement={"bottom"}
                        buttonArrowIcon={""}
                        selectName={"status"}
                        isSearch={false}
                        dropdownButtonClass="!px-3  !border-gray-200 !w-full"
                        onChange={handleChange}
                        onSearch={(key) => setStatusKeyword(key)}
                        value={values?.status}
                        dropdownData={data}
                      />
                    </div> */}
                    <div className="relative md:col-span-6 col-span-12">
                      <Input
                        isInputGroup={true}
                        inputGroupIcon={"fa-solid fa-key"}
                        inputGroupPosition={"left"}
                        label={"Password"}
                        inputPlaceholder={"Enter  Password"}
                        labelClasses={"!text-base"}
                        inputType={"password"}
                        value={values.password}
                        inputName={"password"}
                        onChange={handleChange}
                        {...formValidate(errors, "password")}
                      />
                    </div>
                    <div className="relative md:col-span-6 col-span-12">
                      <Input
                        isInputGroup={true}
                        inputGroupIcon={"fa-solid fa-key"}
                        inputGroupPosition={"left"}
                        label={"Confirm Password"}
                        inputPlaceholder={"Enter Confirm Password"}
                        labelClasses={"!text-base"}
                        inputType={"password"}
                        value={values.confirmpassword}
                        inputName={"confirmpassword"}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="xl:col-span-1 col-span-5 pl-4 gap-3">
                <div className="relative">
                  <SingleImageUpload
                    label={"User Image"}
                    size={"full"}
                    image={imageUrl}
                    setImage={setImageUrl}
                    accept={["jpg", "png", "jpeg"]}
                  />
                </div>
              </div>
            </div>
            <div className="mt-8 pt-4 border-t border-slate-200">
              <div className="flex items-center space-x-3 justify-end">
                <Button
                  buttonHasLink={true}
                  buttonLink={"/admin/user-list"}
                  buttonType={"button"}
                  buttonIcon={"fa-regular fa-xmark"}
                  buttonIconPosition={"left"}
                  buttonLabel={"Cancel"}
                  buttonClasses={
                    "!bg-white !text-primary !border-primary !border-2"
                  }
                />
                <Button
                  buttonHasLink={false}
                  buttonType={"submit"}
                  buttonIcon={"fa-light fa-floppy-disk"}
                  buttonIconPosition={"left"}
                  buttonClasses={"!bg-primary !text-org-silver"}
                  buttonLabel={"Save"}
                  isDisable={loaded}
                />
                {/* <Button
                  buttonHasLink={false}
                  buttonType={"submit"}
                  buttonIcon={"fa-light fa-arrow-right"}
                  buttonIconPosition={"left"}
                  buttonClasses={"!bg-primary !text-org-silver"}
                  buttonLabel={"Save And Continue"}
                  isDisable={loaded}
                  buttonFunction={() => setType("continue")}
                /> */}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default GenarelField;
