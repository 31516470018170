import React, { useState } from "react";

const SubmitRating = ({ onSubmit, rating, setRating = () => {} }) => {
  const [hover, setHover] = useState(0);

  return (
    <>
      {/* <div className='w-64 pt-2 border shadow rounded-full'> */}
      <div className="flex flex-col items-center">
        <div className="flex space-x-1 mb-4">
          {[...Array(5)].map((_, index) => {
            const ratingValue = index + 1;
            return (
              <svg
                key={index}
                className={`w-10 h-10 cursor-pointer ${
                  ratingValue <= (hover || rating)
                    ? "text-yellow-500"
                    : "text-gray-300"
                }`}
                onClick={() => setRating(ratingValue)}
                onMouseEnter={() => setHover(ratingValue)}
                onMouseLeave={() => setHover(rating)}
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M12 .288l2.833 8.718h9.167l-7.5 5.442 2.833 8.718-7.5-5.442-7.5 5.442 2.833-8.718-7.5-5.442h9.167z" />
              </svg>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default SubmitRating;
