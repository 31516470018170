import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { SignInService } from "../services/loginService";
import Logo from "../assets/images/logo(white).png";
import { formValidate } from "../helpers/formValidate";
import Input from "../components/form/Input";
import Button from "../components/form/Button";
import useForm from "../hooks/useForm";
import Background from "../assets/images/loginBackground.jpg";
import { loginService } from "../services/frontService/Login";
const validation = {
  email: { required: true, message: "Email is required" },
  password: { required: true, message: "Password is required" },
};

const Login = () => {
  const { values, handleChange, handleSubmit, errors, setFieldsValue } = useForm({}, validation);
  const backgroundStyle = {
    // backgroundColor: "#f1f5f9",
    backgroundImage: `url(${Background})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState({
    color: "red-500",
    message: null,
  });
  const [loaded, setLoaded] = useState(false);

  const onSubmit = async (values) => {
    setLoaded(true);
    try {
      const res = await loginService({
        email: values?.email,
        password: values?.password,
      });
      if (res?.data?.status === 200) {
        setErrorMessage({
          color: "text-green-500",
          message: res?.data?.message,
        });
        localStorage.setItem("auth_token", res?.data?.accessToken);
        localStorage.setItem("refreshToken", res?.data?.refreshToken);
        localStorage.setItem("role", "USER");
        toast.info(res.data.message);
        setTimeout(() => {
          navigate("/admin/dashboard");
        }, 1000);
      } else if (res?.data?.status === 400) {
        setErrorMessage({
          color: "text-red-500",
          message: res?.data?.message || res?.data?.errors,
        });
        toast.info(res?.data?.message || res?.data?.errors);
      } else {
        setErrorMessage({
          color: "text-red-500",
          message: res?.data?.message || res?.data?.errors,
        });
        toast.info(res?.data?.message || res?.data?.errors);
      }
      setLoaded(false);
    } catch (error) {
      console.error("Error signing in:", error);
      toast.info("Something went wrong!!!");
      setErrorMessage({
        color: "text-red-500",
        message: error?.response?.data?.message || error.message || "Something went wrong!!!",
      });
      toast.error(error?.response?.data?.message || error.message);
      setLoaded(false);
    } finally {
      setLoaded(false);
    }
  };

  return (
    <div className="w-full min-h-screen flex items-center justify-center py-3 px-5" style={backgroundStyle}>
      <div className="w-full max-w-md xl:max-w-md  bg-black/65 backdrop-blur-sm border-2 border-slate-300 py-10 rounded-2xl">
        <div className="flex min-h-full flex-1 flex-col justify-center px-2 py-4 lg:px-2">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <img className="mx-auto h-20 w-auto" src={Logo} alt="Your Company" />
            <h2 className="mt-4 text-center text-xl font-bold leading-9 tracking-tight text-white">
              Please Use Your Credentials to Login
            </h2>
            <h4 className={`mt-1 text-center text-md font-bold leading-9 tracking-tight ${errorMessage?.color}`}>
              {errorMessage?.message}
            </h4>
          </div>

          <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-sm">
            <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
              <div className="mt-4">
                <Input
                  label={"Email"}
                  labelClasses={"!text-base !text-white"}
                  inputType={"text"}
                  inputClasses={"!text-black"}
                  inputPlaceholder={"Enter your email"}
                  value={values?.email}
                  inputName={"email"}
                  onChange={handleChange}
                  {...formValidate(errors, "email")}
                />
              </div>

              <div>
                <div className="mt-4">
                  <Input
                    label={"Password"}
                    labelClasses={"!text-base !text-white"}
                    inputType={"password"}
                    inputClasses={" !text-black"}
                    inputPlaceholder={"Enter your password"}
                    value={values?.password}
                    inputName={"password"}
                    onChange={handleChange}
                    {...formValidate(errors, "password")}
                  />
                </div>
              </div>

              <div className="mt-5">
                <Button
                  buttonClasses={
                    "w-full text-white hover:bg-secondaryColor focus-visible:outline justify-center rounded-md"
                  }
                  buttonHasLink={false}
                  buttonType={"submit"}
                  buttonLabel={"Log in"}
                  buttonIconPosition={"left"}
                  buttonIcon={"fa-light fa-right-to-bracket"}
                />
              </div>
              {/* <div className="mt-4 text-end">
                <div className="text-sm">
                  <Link
                    to="/forgotPassword"
                    className="font-semibold text-victoria hover:text-"
                  >
                    Forgot password?
                  </Link>
                </div>
              </div> */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
