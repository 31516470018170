import { apiRequest } from "../util/apiUtils";

export const getEmailtemplateList = async (params) => {
  try {
    const res = await apiRequest("get", "admin/email-template/list", {
      queries: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const editEmailtemlete = async (params) => {
  try {
    const res = await apiRequest(
      "post",
      "admin/email-template/edit",
      {
        body: { ...params },
      }
      // "multipart/form-data"
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const emailtemplateDetails = async (params) => {
  try {
    const res = await apiRequest("get", `admin/email-template/details/${params?.id}`, {});
    return res?.data;
  } catch (err) {
    return err;
  }
};
