import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  redirect: "",
  checkoutData: {},
};

export const checkoutSlice = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    setCheckoutDetails: (state, action) => {
      state.loading = false;
      state.checkoutData = action.payload;
    },
  },
});

export const { setCheckoutDetails } = checkoutSlice.actions;

export default checkoutSlice.reducer;

