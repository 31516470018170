import React, { useCallback, useEffect, useState } from "react";
import Button from "../components/form/Button";
import LogoutModal from "../components/elements/logoutModal/LogoutModal";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Input from "../components/form/Input";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import UserDropdown from "./UserDropdown";

const DefaultAdminHeader = ({ isMenuOpen, setIsMenuOpen, userData, isToggleIcon, siteData, settings, isSearch }) => {
  const [isOpen, setOpen] = useState(false);
  const [adminId, setAdminId] = useState();
  const [detailsData, setDetailsData] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const menuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const dropdownMenuAdmin = [
    {
      _id: 1,
      label: "My Profile",
      link: "/admin/profile",
      icon: "fa-solid fa-user",
    },
    {
      _id: 2,
      label: "Log Out",
      icon: "fa-regular fa-fw fa-arrow-right-from-arc",
      onclick: (val) => {
        setOpen(true);
      },
    },
  ];
  return (
    <>
      <header className="sticky top-0 py-1 z-50 w-full flex items-center px-6 bg-primary shadow gap-4">
        <div className="relative flex gap-2">
          <Button
            buttonType={"button"}
            buttonIcon={"fa-solid fa-bars"}
            buttonIconPosition={"left"}
            buttonClasses={"px-0 w-10 !bg-primary !text-slate-100  hover:!text-secondary"}
            buttonFunction={menuToggle}
          />
        </div>
        <div className="relative ml-auto flex justify-end items-center gap-3">
          {/* <div className="relative px-1 border-x border-slate-200">
            <NotificationDropdown xPlacement={"right"} />
          </div> */}
          <div className="relative  px-2 py-3">
            <UserDropdown xPlacement={"right"} data={dropdownMenuAdmin} userData={userData} />
          </div>
        </div>
      </header>
      <LogoutModal isOpen={isOpen} setOpen={setOpen} />
    </>
  );
};

export default DefaultAdminHeader;
