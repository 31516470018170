import React from "react";
import Skeleton from "./Skeleton"; 

const MyOrderLoader = () => {

  return (
    <div className="relative md:flex w-full bg-slate-50 rounded-md shadow py-6 border border-slate-200 md:mt-5 mt-5 md:px-10 px-6">
      {/* Image Section */}
      <div className="md:w-40 w-full aspect-square">
        <Skeleton type="square" className="h-full w-full" />
      </div>

      {/* Details Section */}
      <div className="w-full flex flex-col md:flex-row">
        <div className="w-full md:w-2/5 md:pl-8 pl-0">
          <div className="w-full md:space-y-1 space-y-0">
            <div className="md:!text-2xl text-lg font-bold">
              <Skeleton type="text" className="w-3/4" />
            </div>
            <div className="flex space-x-2 pt-1 items-center">
            
                <Skeleton type="text" className="w-2/3" />
             
            
            </div>
          </div>
        </div>

        {/* Price and Status */}
        <div className="w-full flex flex-col md:pl-8 pl-0 mt-4 md:mt-0">
          <div className="flex md:justify-end justify-start md:text-2xl text-lg text-red-600 font-bold">
            <Skeleton type="text" className="w-2/3" /> 
          </div>
          <div className="flex md:justify-end justify-start md:text-xl text-base text-gray-600 font-semibold mt-2">
            <Skeleton type="text" className="w-2/3" /> 
          </div>
         
          {/* Buttons */}
          <div className="flex w-full gap-3 items-end !md:justify-end justify-end mt-4">
            <Skeleton type="rectangle" className="w-2/3" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyOrderLoader;
