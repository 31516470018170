import { apiRequest } from "../util/apiUtils";

export const getUserList = async (params) => {
  try {
    const res = await apiRequest("get", "admin/user/list", {
      queries: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const changeUserStatus = async (payload = {}) => {
  try {
    const res = await apiRequest("post", "admin/user/statusChange", {
      body: payload,
    });
    return res;
  } catch (err) {
    return err;
    throw err;
  }
};

export const addUser = async (params) => {
  try {
    const res = await apiRequest(
      params.id ? "post" : "post",
      params.id ? "admin/user/edit" : "admin/user/add",
      {
        body: { ...params },
      },
      "multipart/form-data"
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const addBillingAddress = async (params) => {
  try {
    const res = await apiRequest(
      params.id ? "post" : "post",
      params.id ? "admin/user/manageBillingAddress" : "admin/user/manageBillingAddress",
      {
        body: { ...params },
      }
      // "multipart/form-data"
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const addShippingAddress = async (params) => {
  try {
    const res = await apiRequest(
      params.id ? "post" : "post",
      params.id ? "admin/user/manageShippingAddress" : "admin/user/manageShippingAddress",
      {
        body: { ...params },
      }
      // "multipart/form-data"
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const userDetails = async (params) => {
  try {
    const res = await apiRequest("get", `admin/user/details/${params?.id}`, {});
    return res?.data;
  } catch (err) {
    return err;
  }
};

export const userViewDetails = async (params) => {
  try {
    const res = await apiRequest("get", `admin/user/details/${params}`, {});
    return res?.data;
  } catch (err) {
    return err;
  }
};

export const getStateList = async (params) => {
  try {
    const res = await apiRequest("get", "common/stateList", {
      queries: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const deleteUser = async (payload = {}) => {
  try {
    const res = await apiRequest("post", "admin/user/delete", {
      body: payload,
    });
    return res;
  } catch (err) {
    return err;
    throw err;
  }
};
