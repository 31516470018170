import React from "react";
import Button from "../../../components/form/Button";
import { Link } from "react-router-dom";

const RecentOrderTable = ({ data }) => {
  return (
    <>
      <div className="overflow-x-auto">
        <table className="min-w-full">
          <thead className="bg-gray-100 shadow-xl">
            <tr>
              <th className="px-6 py-3 text-left text-base font-bold text-gray-500 uppercase tracking-wider">
                Order ID
              </th>
              <th className="px-6 py-3 text-left text-base font-bold text-gray-500 uppercase tracking-wider">Name</th>
              {/* <th className="px-6 py-3 text-left text-base font-bold text-gray-500 uppercase tracking-wider">
                VAT Charge
              </th> */}
              <th className="px-6 py-3 text-left text-base font-bold text-gray-500 uppercase tracking-wider">Price</th>
              {/* <th className="px-6 py-3 text-left text-base font-bold text-gray-500 uppercase tracking-wider">
                View
              </th> */}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {data?.map((item, index) => (
              <tr key={index}>
                <td className="whitespace-nowrap text-sm font-medium text-gray-900">
                  <Link to={`/admin/order-details/${item?.order_id}`} className="block px-6 py-4">
                    {item?.order_id}
                  </Link>
                </td>

                <td className="whitespace-nowrap text-sm text-gray-500">
                  <Link to={`/admin/order-details/${item?.order_id}`} className="block px-6 py-4">
                    {JSON.parse(item?.user_info)?.name}
                  </Link>
                </td>

                {/* <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {item.vat_charge}
                </td> */}

                <td className="whitespace-nowrap text-sm text-gray-500">
                  <Link to={`/admin/order-details/${item?.order_id}`} className="block px-6 py-4">
                    {item.total_price}
                  </Link>
                </td>

                {/* <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  <Button
                    buttonClasses={
                      "!bg-white !p-1 !text-red-600 !text-xl hover:!text-slate-900 h-8 flex items-center justify-center transition-all duration-200 !rounded-full"
                    }
                    buttonLabelClasses={""}
                    buttonIcon={"fa-regular fa-eye"}
                    buttonIconPosition={"left"}
                    buttonHasLink={true}
                    buttonLink={`/admin/order-details/${item?.order_id}`}
                  />
                </td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default RecentOrderTable;
