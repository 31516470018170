import React, { useCallback, useEffect, useState } from "react";
import PageHeader from "../../../shared/PageHeader";
import Button from "../../../components/form/Button";
import Input from "../../../components/form/Input";
import SingleImageUpload from "../../../components/form/SingleImageUpload";
import { formValidate } from "../../../helpers/formValidate";
import useForm from "../../../hooks/useForm";
import { Validation } from "../../../helpers/Validation";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
// import { addUser, userDetails } from "../../../services/userService";
import { setTitle } from "../../../helpers/MetaTag";
import DatePickerInput from "../../../components/form/DatePickerInput";
import { addBillingAddress, addShippingAddress, addUser, userDetails } from "../../../services/userService";
import GenarelField from "./GenarelField";
import Tabs from "../../../components/elements/Tabs";
import ShippingAddress from "./ShippingAddress";
import BillingAddress from "./BillingAddress";

const AddEditUser = () => {
  setTitle("HJL DESIGNS");
  const { id } = useParams();
  const validation = {
    name: { required: true, message: "Please enter your name !!!" },
    email: { required: true, message: "Please enter your email !!!" },
    phoneNumber: { required: true, message: "Please enter phone number !!!" },
    password: {
      required: id ? false : true,
      message: "Please enter your password !!!",
    },
  };
  const { values, handleChange, handleSubmit, errors, setFieldsValue } = useForm({}, validation);
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [date, setDate] = useState({ startDate: null, endDate: null });
  const [type, setType] = useState("");
  const [activeTab, setActiveTab] = useState(1);

  const validatePassword = (password) => {
    const errors = [];
    if (password.length < 8) {
      errors.push("Password must be at least 8 characters long.");
    }
    if (!/(?=.*\d)/.test(password)) {
      errors.push("Password must contain at least one digit.");
    }
    if (!/(?=.*[a-z])/.test(password)) {
      errors.push("Password must contain at least one lowercase letter.");
    }
    if (!/(?=.*[A-Z])/.test(password)) {
      errors.push("Password must contain at least one uppercase letter.");
    }
    if (!/(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])/.test(password)) {
      errors.push("Password must contain at least one special character (!@#$%^&*()_+-=[]{};':\"\\|,.<>/?).");
    }

    return errors;
  };

  const onSubmit = async (values) => {
    setLoaded(true);
    try {
      // if (!Validation("name", values.name)) {
      //   toast.error("Name cannot be empty or contain only spaces.");
      //   setLoaded(false);
      //   return;
      // }
      // if (!Validation("email", values.email)) {
      //   toast.error("Invalid email format. Please enter a valid email.");
      //   setLoaded(false);
      //   return;
      // }
      if (values.password) {
        const passwordErrors = validatePassword(values.password);
        if (passwordErrors.length > 0) {
          toast.error(passwordErrors.join(" "));
          setLoaded(false);
          return;
        }
      }
      if (values.password && !Validation("confirmPassword", values.confirmpassword, values.password)) {
        toast.error("Confirm password and password doesn`t match");
        setLoaded(false);
        return;
      }

      const res = await addUser({
        id: id,
        image_name: imageUrl,
        name: values?.name,
        email: values?.email,
        phone: values?.phoneNumber,
        role: "user",
        // active: values?.active?._id,
        password: values?.password || "",
        password_confirmation: values?.confirmpassword || "",
        // bday: date?.startDate,
        status: 1,
      });
      if (res?.data?.status === 200 || res?.data?.status === 201) {
        if (id) {
          // setActiveTab((prevTab) => Math.min(prevTab + 1, tabData.length));
          setActiveTab(2);
        } else {
          navigate(`/admin/user-edit/${res?.data?.data?.id}`);
          setActiveTab(2);
        }
        toast.info(res?.data?.message);
      } else {
        toast.info(res?.data?.message || res?.data?.errors || "Something went wrong");
      }

      setLoaded(false);
    } catch (err) {
      setLoaded(false);
      // console.error(err);
      toast.error("Somthing went wrong !!!");
    }
  };

  const tabData = [
    {
      _id: 1,
      name: "Personal Data",
      component: (
        <GenarelField
          loaded={loaded}
          setLoaded={setLoaded}
          imageUrl={imageUrl}
          setImageUrl={setImageUrl}
          date={date}
          setDate={setDate}
          onSubmit={onSubmit}
          setType={setType}
        />
      ),
    },
    {
      _id: 2,
      name: "Billing Address",
      component: <BillingAddress loaded={loaded} setLoaded={setLoaded} setActiveTab={setActiveTab} />,
    },
    {
      _id: 3,
      name: "Shipping Address",
      component: <ShippingAddress loaded={loaded} setLoaded={setLoaded} setActiveTab={setActiveTab} />,
    },
  ];

  const filteredTabData = id ? tabData : tabData.filter((tab) => tab._id === 1);

  return (
    <div className="w-full py-4 md:px-6 px-0">
      <div className="font-bold text-3xl m-1 text-secondary">{id ? "Edit User" : "Add User"}</div>
      <div className="relative w-full">
        <Tabs tabs={filteredTabData} selectedTabId={activeTab} onTabChange={(tabId) => setActiveTab(tabId)} />
      </div>
    </div>
  );
};

export default AddEditUser;
