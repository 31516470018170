import { apiRequest } from "../../util/apiUtils";

export const addReview = async (params) => {
    try {
      const res = await apiRequest("post", "front/product/addReview", {
        body: params,
      });
      return res;
    } catch (error) {
      return error;
    }
  };